import React from 'react';
import { Navigate } from 'react-router-dom';

const RoleBasedAccess = ({ children, allowedRoles }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
        // Si el usuario no está autenticado, redirigir a la página de inicio
        return <Navigate to="/" />;
    }

    if (!allowedRoles.includes(user.role)) {
        // Si el usuario no tiene un rol permitido, redirigir a su página de inicio según la empresa
        return user.company === 'plafesa' ? <Navigate to="/plafesa/home" /> : <Navigate to="/ibersteel/home" />;
    }

    // Si el usuario tiene un rol permitido, renderizar los componentes hijos
    return children;
};

export default RoleBasedAccess;