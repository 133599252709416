// config.js
import logoPlafesa from './logoPlafesa.png';
import logoIbersteel from './logoIbersteel.png';
export const companyConfig = {
    plafesa: {
        logo: logoPlafesa,
        color: '#2c2d2c', // Color para Portugal
        name: 'Plafesa',
        otherCompanyUrl: '/ibersteel/login',
        otherCompanyLogo: logoIbersteel,
        background: 'linear-gradient(to bottom,rgb(138, 189, 37), #ffffff)',
    },
    ibersteel: {
        logo: logoIbersteel,
        color: '#2c2d2c', // Color para España
        name: 'Ibersteel',
        otherCompanyUrl: '/plafesa/login',
        otherCompanyLogo: logoPlafesa,
        background: 'linear-gradient(to bottom,rgb(0, 149, 248), #ffffff)'
    },
    // Añade más países según sea necesario
};