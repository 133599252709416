import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Button, Snackbar, Alert, MenuItem,  FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {  styled } from '@mui/system';
import { companyConfig } from '../../utils/config';
import { round } from 'lodash';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción


const ResultadoPrimas = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
        const [quarter, setQuarter] = useState('');
        const [openSnackbar, setOpenSnackbar] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState('');
        const [snackbarSeverity, setSnackbarSeverity] = useState('success');
        const [colaboradores, setColaboradores] = useState([]);
        const navigate = useNavigate();
        const [user, setUser] = useState(null);
        const [loading, setLoading] = useState(false);
        const [fetchDataTrigger, SetFetchDataTrigger] = useState(false);
        const [formSubmitted, setFormSubmitted] = useState(false);
        const monthNames = [
            t('mes_janeiro'), t('mes_fevereiro'), t('mes_marco'), t('mes_abril'), t('mes_maio'), t('mes_junho'),
            t('mes_julho'), t('mes_agosto'), t('mes_setembro'), t('mes_outubro'), t('mes_novembro'), t('mes_dezembro')
        ];
        const [selectedPrima, setSelectedPrima] = useState(null);
        const [primaValue, setPrimaValue] = useState('');
        const [primaValues, setPrimaValues] = useState({});
        const [openDialog, setOpenDialog] = useState(false);
        
    
        useEffect(() => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const parsedUser = JSON.parse(userData);
                setUser(parsedUser);
            } else {
                navigate('/');
            }
        }, [navigate]);
    
        const fetchColaboradores = useCallback(async () => {
            if (!user) return;
            setColaboradores([]);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performancecolab?trimestre=${quarter}&ano=${year}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
    
                const results = await response.json();
    
                if (results.length === 0) {
                    setColaboradores([]);
                    setSnackbarMessage(t('estatisticasprimas_no_se_encontraron_registros'));
                    setSnackbarSeverity('info');
                    setOpenSnackbar(true);
                    return;
                }
                if (results){
                    setColaboradores(results);
                }
            
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        }, [year, quarter, user]);
    
        const getPrima = useCallback(async (ano, mes, colaborador) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/getprima?mes=${mes}&ano=${ano}&numero=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
                const prima = await response.json();
                return prima;
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return null;
            }
        }, [user]);
    
        const OperadorAuxiliar = useCallback(async (ano, mes, colaborador) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceop?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
                const operador = await response.json();
                if (!operador || operador.linha === null || operador.linha === 0 || !operador[0]) {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceaux?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    if (!response.ok) {
                        console.error('Error fetching data:', response);
                    }
                    const auxiliar = await response.json();
                    if (!auxiliar || auxiliar.linha === null || auxiliar.linha === 0 || !auxiliar[0]) { 
                        return { linha: 0, ton: 0, qt_turnos: 0, tempo_corte: 0, qt_of: 0, fases_cortadas: 0, lotes_cortados: 0, qt_bob: 0, total_bandas: 0, funcao: 0 };
                    } else {
                        return { ...auxiliar[0], funcao: 'AUX' };
                    }
                } else {
                    return { ...operador[0], funcao: 'OP' };
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return null;
            }
        }, [user]);
    
    
    
        const getStats = useCallback(async (ano, mes, linha) => {
            try {
                
                let statsLinhaResponse = null;
                if (linha !== 0) {
                    statsLinhaResponse = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslinha?mes=${mes}&ano=${ano}&linha=${linha}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                }
    
                const [statsFabricaResponse, objFabricaResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statsfabrica?mes=${mes}&ano=${ano}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/objfabrica?mes=${mes}&ano=${ano}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    })
                ]);
    
                if (!statsFabricaResponse.ok || !objFabricaResponse.ok) {
                    throw new Error('Error fetching stats');
                }
    
                if (statsLinhaResponse && statsLinhaResponse.ok) {
                    const [statsLinha, statsFabrica, objFabrica] = await Promise.all([
                        statsLinhaResponse.json(),
                        statsFabricaResponse.json(),
                        objFabricaResponse.json()
                    ]);
    
                    const combinedStats = {
                        ...statsLinha[0],
                        ...statsFabrica[0],
                        ...objFabrica[0]
                    };
                    return combinedStats;
                } else {
                    const [ statsFabrica, objFabrica] = await Promise.all([
                        statsFabricaResponse.json(),
                        objFabricaResponse.json()
                    ]);
                    const combinedStats = {
                        ...statsFabrica[0],
                        ...objFabrica[0]
                    };
                    return combinedStats;
                }
                
            } catch (error) {
                console.error('Error fetching stats:', error);
                setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return null;
            }
        }, [user]);
    
        const fetchData = useCallback(async () => {
            setPrimaValues({});
            const updatedColaboradores = await Promise.all(colaboradores.map(async (colaborador) => {
                if (!colaborador.numero) {
                    console.error('Colaborador sin número:', colaborador);
                    return null;
                }
                const { numero: n_col , mes_avaliacao: mes, pontualidade, p_conhecimento, p_resolucao_probl, p_responsabilidade, p_cooperacao, p_autonomia, p_organizacao, accidentes_trabalho, faltas_injustificadas, fte } = colaborador;
                const operadorAuxiliar = await OperadorAuxiliar(year, mes, n_col);
                if (!operadorAuxiliar) return null;
                const stats = await getStats(year, mes, operadorAuxiliar.linha);
                if (!stats) return null;
                const prima = await getPrima(year, mes, n_col);
                if (!prima) return null;

                let primaValue = 0;
                let primaColor = '';
                let primaId = prima ? prima.id : null;

                if (prima.valor !== 'N/A') {
                    primaValue = round(prima.valor, 2);
                    primaColor = 'yellow'; // Prima grabada
                } else if (operadorAuxiliar.funcao === 0 && operadorAuxiliar.linha === 0) {
                    primaValue = 0;
                    primaColor = 'red'; // Sin función/registro
                } else {
                // Realiza tus cálculos aquí
                    if (operadorAuxiliar.linha !== 0) {
                        const calculos = {
                            medFasesBob: round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1),
                            realTonTotalMes: (operadorAuxiliar.ton - (operadorAuxiliar.ton * 0.03)),
                            medBobinesTurno: round(operadorAuxiliar.qt_bob / operadorAuxiliar.qt_turnos, 1),
                            medTonMes: round(stats.obj_ton / round(stats.real_n_turnos / stats.dias_uteis_mes, 1)),
                            ponderacaoBob: operadorAuxiliar.medBobinesTurno / stats.media_bobines_turno,
                            ponderacaoLotesFases: 0,
                            produtividadeFabrica: round((stats.real_ton_fabrica / stats.obj_ton_fabrica) * 100),
                            ponderacaoFabrica: 0,
                            produtividadeLinha: round((stats.real_ton_linha / stats.obj_ton) * 100),
                            ponderacaoLinha: 0,
                            produtividadeIndividual: 0,
                            ponderacaoIndividual: 0,
                            total: 0,
                            ponderacaoTotal: 0 ,
                            ponderacaoProblemas: 0,
                            ponderacaoTolerancia: 0,
                            ponderacaoResponsabilidade: 0,
                            ponderacaoCooperacao: 0,
                            ponderacaoAutonomia: 0,
                            ponderacaoOrganizacao: 0,
                            ponderacaoSeguranca: 0,
                            ponderacaoAbsentismo: 0,
                            ponderacaoConhecimento: 0,
                            resultadoGlobal: 0,
                            PonderacaoAvalicaoTotal: 0,
                            PonderacaoTotalPessoal: 0,
                            PonderacaoTotal: 0,
                            perc_conhecimento: 0,
                            perc_problemas: 0,
                            perc_responsabilidade: 0,
                            perc_cooperacao: 0,
                            perc_autonomia: 0,
                            perc_organizacao: 0,
                            res_total: 0,
                            res_totalPessoal: 0,
                            vprima: 0,
                    
                        };
                        // Aplica la lógica de ponderación
                        if (calculos.ponderacaoBob < 0.5) {
                            calculos.ponderacaoBob = 0;
                        } else if (calculos.ponderacaoBob < 0.8) {
                            calculos.ponderacaoBob = 0.5;
                        } else if (calculos.ponderacaoBob < 1) {
                            calculos.ponderacaoBob = 0.8;
                        } else if (calculos.ponderacaoBob < 1.1) {
                            calculos.ponderacaoBob = 1;
                        } else if (calculos.ponderacaoBob < 1.5) {
                            calculos.ponderacaoBob = 1.1;
                        } else {
                            calculos.ponderacaoBob = 1.2;
                        }
                        // Aplica la lógica de ponderación para ponderacaoLotesFases
                        if (operadorAuxiliar.linha === 'CT1' || operadorAuxiliar.linha === 'CT2' || operadorAuxiliar.linha === 'CT5') {
                            calculos.media_paquetes_turno = 0;
                            calculos.media_fases_bobine_real = round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1);
                            calculos.ponderacaoLotesFases = calculos.media_fases_bobine_real / stats.media_fases_bobine;
                            if (calculos.ponderacaoLotesFases < 0.5) {
                                calculos.ponderacaoLotesFases = 0;
                            } else if (calculos.ponderacaoLotesFases < 0.8) {
                                calculos.ponderacaoLotesFases = 0.5;
                            } else if (calculos.ponderacaoLotesFases < 1) {
                                calculos.ponderacaoLotesFases = 0.8;
                            } else if (calculos.ponderacaoLotesFases < 1.1) {
                                calculos.ponderacaoLotesFases = 1;
                            } else if (calculos.ponderacaoLotesFases < 1.5) {
                                calculos.ponderacaoLotesFases = 1.1;
                            } else {
                                calculos.ponderacaoLotesFases = 1.2;
                            }
                        } else if (operadorAuxiliar.linha === 'CT3' || operadorAuxiliar.linha === 'CT4') {
                            calculos.media_paquetes_turno = round(operadorAuxiliar.lotes_cortados / operadorAuxiliar.qt_turnos, 1);
                            calculos.ponderacaoLotesFases = calculos.media_paquetes_turno / stats.media_lotes_turno;
                            if (calculos.ponderacaoLotesFases < 0.5) {
                                calculos.ponderacaoLotesFases = 0;
                            } else if (calculos.ponderacaoLotesFases < 0.8) {
                                calculos.ponderacaoLotesFases = 0.5;
                            } else if (calculos.ponderacaoLotesFases < 1) {
                                calculos.ponderacaoLotesFases = 0.8;
                            } else if (calculos.ponderacaoLotesFases < 1.1) {
                                calculos.ponderacaoLotesFases = 1;
                            } else if (calculos.ponderacaoLotesFases < 1.5) {
                                calculos.ponderacaoLotesFases = 1.1;
                            } else {
                                calculos.ponderacaoLotesFases = 1.2;
                            }
                        }
                        
                        calculos.ponderacaoFabrica = round(calculos.produtividadeFabrica * 0.2);
                        if (calculos.ponderacaoFabrica > 20) {
                            calculos.ponderacaoFabrica = 20;
                        }
                        calculos.ponderacaoLinha = round(calculos.produtividadeLinha * 0.5);
                        if (calculos.ponderacaoLinha > 50) {
                            calculos.ponderacaoLinha = 50;
                        }
                        calculos.produtividadeIndividual = round((((calculos.realTonTotalMes * calculos.ponderacaoBob * calculos.ponderacaoLotesFases) / operadorAuxiliar.qt_turnos) / (stats.real_ton_linha / stats.real_n_turnos)) * 100, 1);
                        calculos.ponderacaoIndividual = round(calculos.produtividadeIndividual * 0.3);
                        if (calculos.ponderacaoIndividual > 30) {
                            calculos.ponderacaoIndividual = 30;
                        }
                        calculos.total = calculos.ponderacaoFabrica + calculos.ponderacaoLinha + calculos.ponderacaoIndividual;
                        calculos.ponderacaoTotal = round(calculos.total * 0.7);
                        if (calculos.ponderacaoTotal > 70) {
                            calculos.ponderacaoTotal = 70;
                        }
            
                        //PESSOAL
            
                        if (pontualidade > 16) {
                            calculos.ponderacaoTolerancia = 0;
                        } else {
                            calculos.ponderacaoTolerancia = 40;
                        }
                        
                        calculos.ponderacaoConhecimento = p_conhecimento;
                        
            
                        calculos.ponderacaoProblemas = p_resolucao_probl;
                        
                        calculos.ponderacaoResponsabilidade = p_responsabilidade;
                        
                        calculos.ponderacaoCooperacao = p_cooperacao;
                        
                        calculos.ponderacaoAutonomia = p_autonomia;
                        
                        calculos.ponderacaoOrganizacao = p_organizacao;
                        
            
                        calculos.res_total = calculos.ponderacaoConhecimento + calculos.ponderacaoProblemas + calculos.ponderacaoResponsabilidade + calculos.ponderacaoCooperacao + calculos.ponderacaoAutonomia + calculos.ponderacaoOrganizacao;
                        calculos.PonderacaoAvalicaoTotal = calculos.res_total * 0.6;
            
                        calculos.res_totalPessoal = calculos.ponderacaoTolerancia + calculos.PonderacaoAvalicaoTotal;
                        calculos.PonderacaoTotalPessoal = calculos.res_totalPessoal * 0.3;
            
                        if (accidentes_trabalho > 0) {
                            calculos.ponderacaoSeguranca = 0;
                        }
                        else{
                            calculos.ponderacaoSeguranca = 50;
                        }
            
                        if (faltas_injustificadas > 0) {
                            calculos.ponderacaoAbsentismo = 0;
                        }
                        else{
                            calculos.ponderacaoAbsentismo = 50;
                        }
            
                        calculos.resultadoGlobal = (calculos.ponderacaoSeguranca*(calculos.ponderacaoTotal + calculos.PonderacaoTotalPessoal) + calculos.ponderacaoAbsentismo*(calculos.ponderacaoTotal + calculos.PonderacaoTotalPessoal))/100;
                        
                        if (operadorAuxiliar.funcao === 'OP') {
                            calculos.vprima = (calculos.resultadoGlobal/100) * 70;
                            calculos.vprima = calculos.vprima - (calculos.vprima * 0.15);
                            calculos.vprima = round(calculos.vprima * fte, 2 );
                            primaValue = calculos.vprima
                            primaColor = '';
                        } else if (operadorAuxiliar.funcao === 'AUX') {
                            calculos.vprima = ((calculos.resultadoGlobal/100) * 70)/2;
                            calculos.vprima = calculos.vprima - (calculos.vprima * 0.15);
                            calculos.vprima = round(calculos.vprima * fte, 2 );
                            primaValue = calculos.vprima;
                            primaColor = '';
                        } else {
                            primaValue = 0;
                            primaColor = 'red';
                        }
                    
                    } else {
                        primaValue = 0;
                        primaColor = 'red';
                    }
                }
                setPrimaValues((prev) => ({
                    ...prev,
                    [colaborador.numero]: {
                        ...prev[colaborador.numero],
                        [mes]: primaValue,
                    },
                }));
                return { ...colaborador, operadorAuxiliar, primaValue, primaColor, primaId  };
            
        }));
            setColaboradores(updatedColaboradores.filter(result => result !== null));
        }, [ colaboradores, OperadorAuxiliar, getStats, year, getPrima]);

        const registarPrima = async (primas) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarprima`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(primas),
                });
                if (!response.ok) {
                    throw new Error('Error al registrar la prima');
                }
                const result = await response.json();
                setSnackbarMessage(result.message);
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            } catch (error) {
                console.error('Error al registrar la prima:', error);
                setSnackbarMessage(t('resultado_primas_error_registrar_prima'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        };
        
        const editarPrima = async (prima) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/editarprima`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(prima),
                });
                if (!response.ok) {
                    throw new Error('Error al editar la prima');
                }
                const result = await response.json();
                setSnackbarMessage(result.message);
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            } catch (error) {
                console.error('Error al editar la prima:', error);
                setSnackbarMessage(t('resultado_primas_error_editar_prima'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        };
    
        useEffect(() => {
            if (!loading && colaboradores.length > 0 && fetchDataTrigger) { 
                fetchData();
                SetFetchDataTrigger(false);
            }
        }, [fetchColaboradores, fetchData, loading, colaboradores, fetchDataTrigger]);
    
        const handleYearChange = (event) => {
            setYear('');
            setYear(event.target.value);
        };
    
        const handleQuarterChange = (event) => {
            setQuarter('');
            setQuarter(event.target.value);
        };
    
        const handleSearch = async () => {
            setFormSubmitted(true);
            setLoading(true);
            setColaboradores([]);
            await fetchColaboradores();
            setLoading(false);
            SetFetchDataTrigger(true);
        };
    
        const handleSnackbarClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };

        const handleOpenDialog = (prima) => {
            setSelectedPrima(prima);
            setPrimaValue(prima.primaValue);
            setOpenDialog(true);
        };
        
        const handleCloseDialog = () => {
            setOpenDialog(false);
            setSelectedPrima(null);
            setPrimaValue('');
        };
        
        const handlePrimaChange = (event) => {
            setPrimaValue(event.target.value);
        };
        
        const handleSavePrima = async (prima) => {
            if (prima.primaColor === 'yellow') {
                // Editar prima
                const primaData = {
                    id: prima.primaId,
                    valor: primaValue,
                    colaborador: user.colaborador,
                };
                await editarPrima(primaData);
                
            } else {
                // Registrar primas
                const primas = usedMonths.map((monthIndex) => ({
                    ano: year,
                    mes: monthIndex,
                    numero: prima.numero,
                    valor: primaValues[prima.numero]?.[monthIndex] !== undefined ? primaValues[prima.numero][monthIndex] : prima.primaValue,
                    trimestre: quarter,
                    colaborador: user.colaborador,
                }));
                console.log('Primas:', primas);
                await registarPrima(primas);
            }
            handleCloseDialog();
            setColaboradores([]);
            await fetchColaboradores();
            setLoading(false);
            SetFetchDataTrigger(true);

        };

        const companySettings = user ? companyConfig[user.company] : {};
        
        const StyledTableHead = styled(TableHead)({
            backgroundColor: companySettings.color,
            '& th': {
                color: '#ffffff',
                fontWeight: 'bold',
            },
        });
        const getUsedMonths = (quarter) => {
            switch (quarter) {
                case 1:
                    return [1, 2, 3];
                case 2:
                    return [4, 5, 6];
                case 3:
                    return [7, 8, 9];
                case 4:
                    return [10, 11, 12];
                default:
                    return [];
            }
        };
        
        const usedMonths = getUsedMonths(quarter);

        const columns = [
            { id: 'n_col', label: t('resultado_primas_numero_colaborador') },
            { id: 'nome', label: t('resultado_primas_nome') },
            { id: 'funcao', label: t('resultado_primas_funcao') },
            ...usedMonths.map((monthIndex) => ({ id: `mes${monthIndex}`, label: monthNames[monthIndex - 1] })),
            { id: 'total', label: t('resultado_primas_total') },
            { id: 'gravar', label: t('resultado_primas_gravar') },
        ];
    const tableData = colaboradores.reduce((acc, row) => {
        if (!row.numero) {
            console.error('Colaborador sin número:', row);
            return acc;
        }
    
        const existing = acc.find(item => item.n_col === row.numero);
        if (existing) {
            const monthIndex = row.mes_avaliacao - 1;
            existing[`mes${monthIndex + 1}`] = (
                <TableCell style={{ backgroundColor: row.primaColor }}>
                    {row.primaColor === 'yellow' ? (
                        <>
                            {row.primaValue}
                            {user.role === 100 && (
                                <Button onClick={() => handleOpenDialog(row)}>Alterar</Button>
                            )}
                        </>
                    ) : (
                        <TextField
                            type="number"
                            value={primaValues[row.numero]?.[monthIndex] || row.primaValue}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setPrimaValues((prev) => ({
                                    ...prev,
                                    [row.numero]: {
                                        ...prev[row.numero],
                                        [monthIndex]: newValue !== '' ? newValue : row.primaValue,
                                    },
                                }));
                            }}
                        />
                    )}
                </TableCell>
            );
            existing.total = round((existing.total || 0) + row.primaValue, 2);
        } else {
            const newRow = {
                n_col: row.numero || 'N/A',
                nome: row.nome || 'N/A',
                funcao: row.operadorAuxiliar?.funcao ?? 'N/A',
                total: row.primaValue,
                gravar: row.primaColor !== 'yellow' ? (
                    <Button variant="contained" color="primary" onClick={() => handleSavePrima(row)}>{t('resultado_primas_gravar')}</Button>
                ) : 'Save',
            };
    
            usedMonths.forEach((monthIndex) => {
                newRow[`mes${monthIndex}`] = row.mes_avaliacao === monthIndex ? (
                    <TableCell style={{ backgroundColor: row.primaColor }}>
                        {row.primaColor === 'yellow' ? (
                            <>
                                {row.primaValue}
                                {user.role === 100 && (
                                    <Button onClick={() => handleOpenDialog(row)}>{t('resultado_primas_alterar')}</Button>
                                )}
                            </>
                        ) : (
                            <TextField
                                type="number"
                                value={primaValues[row.numero]?.[monthIndex] || row.primaValue}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    setPrimaValues((prev) => ({
                                        ...prev,
                                        [row.numero]: {
                                            ...prev[row.numero],
                                            [monthIndex]: newValue !== '' ? newValue : row.primaValue,
                                        },
                                    }));
                                }}
                            />
                        )}
                    </TableCell>
                ) : null;
            });
    
            acc.push(newRow);
        }
        return acc;
    }, []);
    
    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('resultado_primas_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                    <ValidatedSelect
                        labelId="year-label"
                        value={year}
                        margin="dense"
                        fullWidth
                        onChange={handleYearChange}
                        label={t('avaliacaocolaborador_ano')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        {Array.from({ length: 10 }, (_, i) => {
                            const yearOption = new Date().getFullYear() - i;
                            return (
                                <MenuItem key={yearOption} value={yearOption}>
                                    {yearOption}
                                </MenuItem>
                            );
                        })}
                    </ValidatedSelect>
                </FormControl>
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                    <ValidatedSelect
                        labelId="quarter-label"
                        value={quarter}
                        margin="dense"
                        fullWidth
                        onChange={handleQuarterChange}
                        label={t('avaliacaocolaborador_trimestre')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        <MenuItem value={1}>{t('avaliacaocolaborador_trimestre_janeiro_marco')}</MenuItem>
                        <MenuItem value={2}>{t('avaliacaocolaborador_trimestre_abril_junho')}</MenuItem>
                        <MenuItem value={3}>{t('avaliacaocolaborador_trimestre_julho_setembro')}</MenuItem>
                        <MenuItem value={4}>{t('avaliacaocolaborador_trimestre_outubro_dezembro')}</MenuItem>
                    </ValidatedSelect>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleSearch} >
                    {t('estatisticasprimas_buscar')}
                </Button>
            </Box>
            {loading ? (
                <Typography variant="h6" component="p" gutterBottom>
                    <CircularProgress />
                </Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {tableData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {column.id === 'total' ? `${row[column.id]} €` : row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('evaluacionlogistica_guardar')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('resultado_primas_modificar_valor')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('resultado_primas_prima')}
                        type="number"
                        fullWidth
                        value={primaValue}
                        onChange={handlePrimaChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t('evaluacionlogistica_cancelar')}
                    </Button>
                    <Button onClick={() => handleSavePrima(selectedPrima)} color="primary">
                        {t('evaluacionlogistica_guardar')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}
export default ResultadoPrimas;