import React from 'react';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ValidatedSelect = ({ value, onChange, required, label, formSubmitted, children, ...props }) => {
    const { t } = useTranslation(); // Hook para traducción

    const isError = required && formSubmitted && (value === '' || value === null || value === undefined);
    const labelId = `${label}-label`;

    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange({ target: { name, value } });
    };

    return (
        <FormControl fullWidth error={isError} {...props}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                value={value}
                onChange={handleChange}
                label={label}
                inputProps={{
                    'aria-label': label,
                    name: props.name,
                }}
            >
                {children}
            </Select>
            {isError && <FormHelperText>{t('validatedselect_required_field')}</FormHelperText>}
        </FormControl>
    );
};

export default ValidatedSelect;