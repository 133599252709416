import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, MenuItem, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from "@mui/material";
import ValidatedSelect from "../../components/ValidatedSelect";
import { companyConfig } from "../../utils/config";
import { useTranslation } from "react-i18next"; // Importar el hook de traducción

function Validar() {
    const { t } = useTranslation(); // Hook para traducción
    const [isLoading, setIsLoading] = useState(true); // Estado de carga
    const [turno, setTurno] = useState("");
    const [auxiliar, setAuxiliar] = useState("");
    const user = JSON.parse(localStorage.getItem("user"));
    const [ultimoTurno, setUltimoTurno] = useState(null);
    const [auxiliares, setAuxiliares] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigate = useNavigate();
    const companySettings = companyConfig[user.company];
    const  [validarT, setValidarT] = useState(true);
    const [company, setCompany] = useState(user.company);

    const now = useMemo(() => new Date(), []); // Memorizar la fecha actual
    const currentDate = useMemo(() => now.toISOString().split("T")[0], [now]); // Memorizar la fecha en formato YYYY-MM-DD

    const turnos = useMemo(() => ({
        "Manhã": { inicio: "06:00:00", fin: "14:00:00" },
        "Tarde": { inicio: "14:00:00", fin: "22:00:00" },
        "Noite": { inicio: "22:00:00", fin: "06:00:00" },
    }), []);
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));

        if (!user) {
            navigate("/");
        }
    }, [navigate]);

    const fetchUser = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/getSessao?colaborador=${user.colaborador}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            if (data.sucess) {
                setUltimoTurno(data.sessao);
            } else {
                setSnackbarMessage(data.message || t('validar_error_fetch_session'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('validar_error_fetch_session'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    }, [user, t]);

    const fetchAuxiliares = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/getauxiliares`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            if (data.sucess) {
                setAuxiliares(data.auxiliares);
            } else {
                setSnackbarMessage(data.message || t('validar_error_fetch_auxiliares'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('validar_error_fetch_auxiliares'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    }, [user, t]);

    const validarPeriodoTurno = () => {
        const turnoActual = Object.keys(turnos).find((key) => {
            const { inicio, fin } = turnos[key];
            const inicioTurno = new Date(`${currentDate}T${inicio}`);
            const finTurno = new Date(`${currentDate}T${fin}`);
    
            inicioTurno.setHours(inicioTurno.getHours() - 1); // 1 hora antes del inicio
            finTurno.setHours(finTurno.getHours() + 1); // 1 hora después del fin
    
            if (key === "Noite") {
                const inicioNoche = new Date(`${currentDate}T${inicio}`);
                const finNoche = new Date(`${currentDate}T06:00:00`);
                finNoche.setDate(finNoche.getDate() + 1);
                finNoche.setHours(finNoche.getHours() + 1);
                inicioNoche.setHours(inicioNoche.getHours() - 1);
                return now >= inicioNoche || now < finNoche;
            }
    
            return now >= inicioTurno && now <= finTurno;
        });
    
        return turno === turnoActual; // Retorna true si el turno seleccionado es válido
    };

    const validarTurno = useCallback(() => {
        if (!ultimoTurno) {
            setIsLoading(false);
            return;
        }
    
        const { periodo_turno } = ultimoTurno;
        const horaFinTurno = turnos[periodo_turno]?.fin;
        const ultimoTurnoFin = new Date(`${ultimoTurno.data}T${horaFinTurno}`);
        ultimoTurnoFin.setHours(ultimoTurnoFin.getHours() + 1);
    
        const diferenciaMsDesdeFin = now - ultimoTurnoFin;

        if (diferenciaMsDesdeFin > 0) {
            setSnackbarMessage(t('validar_turno_terminado'));
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
            setIsLoading(false);
            setValidarT(false);
            return;
        }
        
        const turno = ultimoTurno.numero_turno;
        const auxiliar = ultimoTurno.n_auxiliar;
        const updatedUser = { ...user, turno, auxiliar };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        localStorage.setItem("turno", true);
        setValidarT(false);
        navigate(`/${user.company}/home`);
    }, [ultimoTurno, now, turnos, navigate, user, t]);

    const handleValidar = async () => {
        setFormSubmitted(true);
        if (auxiliar === "" || auxiliar === null || auxiliar === undefined) {
            setSnackbarMessage(t('validar_error_select_auxiliar'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!turno) {
            setSnackbarMessage(t('validar_error_select_turno'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const turnoValido = validarPeriodoTurno();
        if (!turnoValido) {
            setSnackbarMessage(t('validar_error_invalid_turno'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setDialogOpen(true);
    };

    const confirmValidar = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/validarsessao`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ncolaborador: user.colaborador, nauxiliar: auxiliar, periodo: turno }),
                credentials: "include",
            });
            const data = await response.json();
            if (data.success) {
                const turno = data.turno;
                const auxiliar = data.n_auxiliar;
                const updatedUser = { ...user, turno, auxiliar };
                localStorage.setItem("user", JSON.stringify(updatedUser));
                localStorage.setItem("turno", true);
                navigate(`/${user.company}/home`);
            } else {
                setSnackbarMessage(data.message || t('validar_error_validate_session'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('validar_error_validate_session'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setDialogOpen(false);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            if (!ultimoTurno) {
                await fetchUser();
            }
            if (ultimoTurno && validarT) {
                validarTurno();
            }
            if (auxiliares.length === 0) {
                await fetchAuxiliares();
            }
            setIsLoading(false);
        };

        initialize();
    }, [ultimoTurno, validarTurno, fetchAuxiliares, fetchUser, auxiliares]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                    background: companySettings.background,
                }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                        {t('validar_loading')}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                background: "linear-gradient(to bottom, #f5f5f5, #ffffff)",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 400,
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "white",
                    textAlign: "center",
                }}
            >
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    <strong>{t('validar_title')}</strong>
                </Typography>
                <TextField
                    label={t('validar_user_label')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={user.colaborador}
                    disabled
                />
                <ValidatedSelect
                    value={turno}
                    onChange={(e) => setTurno(e.target.value)}
                    label={t('validar_select_turno')}
                    required
                    margin="normal"
                    formSubmitted={formSubmitted}
                >
                    <MenuItem value="Manhã">{t('validar_turno_manha')}</MenuItem>
                    <MenuItem value="Tarde">{t('validar_turno_tarde')}</MenuItem>
                    <MenuItem value="Noite">{t('validar_turno_noite')}</MenuItem>
                </ValidatedSelect>
                <ValidatedSelect
                    label={t('validar_select_auxiliar')}
                    value={auxiliar}
                    onChange={(e) => setAuxiliar(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    formSubmitted={formSubmitted}
                >
                    <MenuItem key={0} value={0}>{t('validar_auxiliar_none')}</MenuItem>
                    {auxiliares.map((aux) => (
                        <MenuItem key={aux.ncolaborador} value={aux.ncolaborador}>
                            {aux.colaborador}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: "#1976d2",
                        marginTop: "20px",
                        "&:hover": {
                            backgroundColor: "#115293",
                        },
                    }}
                    onClick={handleValidar}
                >
                    {t('validar_button_validate')}
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                        marginTop: "10px",
                        color: "#1976d2",
                        borderColor: "#1976d2",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                            borderColor: "#115293",
                        },
                    }}
                    onClick={() => {
                        localStorage.removeItem("user");
                        localStorage.removeItem("turno");
                        navigate(`/${company}/login`)}
                     } // Redirige al login
                >
                    {t('validar_button_back_to_login')}
                </Button>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>{t('validar_dialog_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('validar_dialog_text', { turno })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>{t('validar_dialog_cancel')}</Button>
                    <Button onClick={confirmValidar} autoFocus>
                        {t('validar_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Validar;