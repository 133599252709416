import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ValidatedTextField = ({ value, onChange, required, label, formSubmitted, ...props }) => {
    const { t } = useTranslation(); // Hook para traducción

    const isError = required && (formSubmitted) && (value === '' || value === null || value === undefined);

    return (
        <TextField
            value={value}
            onChange={onChange}
            required={required}
            label={label}
            error={isError}
            helperText={isError ? t('validatedselect_required_field') : ''}
            {...props}
        />
    );
};

export default ValidatedTextField;