import React, { useState, useEffect } from 'react';
import { Container,  Typography,  Button, Box, MenuItem, Snackbar, Alert, Switch, FormControlLabel   } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { companyConfig } from '../../utils/config';
import '../../components/geral.css';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const RegistarColab = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [user, setUser] = useState(null);
    const [perfil, setPerfil] = useState([]);
    const [colaborador, setColaborador] = useState({
        nome: '',
        apelido: '',
        ncolaborador: '',
        password: '',
        perfil: ''
    });
    const navigate = useNavigate();
    const [acessoPlataforma, setAcessoPlataforma] = useState(false);

    useEffect(() => {
                const userData = localStorage.getItem('user');
                if (userData) {
                    const parsedUser = JSON.parse(userData);
                    setUser(parsedUser);
                } else {
                    navigate('/');
                }
            }, [navigate]);
    useEffect(() => {
        const fetchPerfil = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/perfil`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                setPerfil(data);
            } catch (error) {
                console.error('Error fetching perfiles:', error);
            }
        };

        if (user) {
            fetchPerfil();
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setColaborador({ ...colaborador, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        
        // Validar que todos los campos requeridos estén completos
        const { nome, apelido, ncolaborador, password, perfil } = colaborador;
        const errors = {};

        // Validar campos requeridos
        if (!nome) errors.nome = true;
        if (!apelido) errors.apelido = true;
        if (!ncolaborador) errors.ncolaborador = true;
        if (!password && acessoPlataforma) errors.password = true;
        if (!perfil) errors.perfil = true;

        // Si hay errores, mostrar mensaje y detener el envío
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        // Determinar el valor de acesso
        let acesso = 0; // Por defecto, no tiene acceso
        if (acessoPlataforma) {
            acesso = 1; // Si tiene acceso a la plataforma, cambiar a 1
        }

        // Combinar nome y apelido en colab
        const colab = `${nome} ${apelido}`;

        // Añadir acesso y colab al objeto colaborador
        const colaboradorConAcesso = {
            ...colaborador,
            acesso,
            colab, // Nuevo campo combinado
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(colaboradorConAcesso),
            });

            if (response.ok) {
                setSnackbarMessage(t('registarcolab_colaborador_registrado_correctamente'));
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setColaborador({
                    nome: '',
                    apelido: '',
                    ncolaborador: '',
                    password: '',
                    perfil: '',
                });
                setFormSubmitted(false);
                setValidationErrors({});
            } else {
                const errorData = await response.json();
                if (response.status === 409) {
                    setSnackbarMessage(errorData.message);
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                } else {
                    setSnackbarMessage(errorData.message || t('registarcolab_error_registrando_colaborador'));
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                }
            }
        } catch (error) {
            setSnackbarMessage(t('registarcolab_error_registrando_colaborador'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            console.error('Error registrando colaborador:', error);
        }
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const companySettings = user ? companyConfig[user.company] : {};

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    Registrar Colaborador
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box>
                <ValidatedTextField
                    margin="dense"
                    label={t('listarcolab_nome')}
                    type="text"
                    name="nome"
                    fullWidth
                    value={colaborador.nome}
                    onChange={handleChange}
                    required
                    formSubmitted={formSubmitted}
                />
                <ValidatedTextField
                    margin="dense"
                    label={t('listarcolab_apelido')}
                    type="text"
                    name="apelido"
                    fullWidth
                    value={colaborador.apelido}
                    onChange={handleChange}
                    required
                    formSubmitted={formSubmitted}
                />
                <ValidatedTextField
                    margin="dense"
                    label={t('resultado_primas_numero_colaborador')}
                    type="text"
                    name="ncolaborador"
                    fullWidth
                    value={colaborador.ncolaborador}
                    onChange={(e) => {
                        setColaborador({ ...colaborador, ncolaborador: e.target.value.toUpperCase() });
                        setValidationErrors({ ...validationErrors, ncolaborador: '' });
                    }}
                    required
                    formSubmitted={formSubmitted}
                />
                {acessoPlataforma && (
                    <ValidatedTextField
                        margin="dense"
                        label={t('listarcolab_password')}
                        type="password"
                        name="password"
                        fullWidth
                        value={colaborador.password}
                        onChange={handleChange}
                        required
                        formSubmitted={formSubmitted}
                    />
                )}
                <ValidatedSelect
                    label={t('listarcolab_perfil')}
                    name="perfil"
                    value={colaborador.perfil}
                    onChange={(e) => setColaborador({ ...colaborador, perfil: e.target.value })}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                >
                    {perfil.map((perfil) => (
                        <MenuItem key={perfil.id} value={perfil.id}>
                            {perfil.perfil}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
                <FormControlLabel
                    control={
                        <Switch
                            checked={acessoPlataforma}
                            onChange={(e) => setAcessoPlataforma(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={t('listarcolab_acesso_plataforma')}
                />
            
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button variant="contained" color="primary" type="primary" onClick={handleSubmit}>
                        {t('registarcolab_registrar')}
                    </Button>
                </Box>
            </Box>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RegistarColab;