import React, { useState, useEffect, useCallback } from 'react';
import { Container, Box, Typography, Button, Snackbar, Alert, Dialog, DialogTitle, MenuItem, DialogContent, DialogActions } from '@mui/material';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const RegistoAuditoria = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
    const [semestre, setSemestre] = useState('');
    const [auditorias, setAuditorias] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [mensal, setMensal] = useState({ pSeguranca: '', pLimpeza: '' });
    const user = JSON.parse(localStorage.getItem('user'));
    const companySettings = user ? companyConfig[user.company] : {};
    const [formSubmittedA, setFormSubmittedA] = useState(false);
    const [procura, setProcura] = useState(false);
    
    

    const fetchStatsAuditoria = useCallback(async (ano, semestre) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statsauditoria?ano=${ano}&semestre=${semestre}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Error fetching stats for year ${ano}, semester ${semestre}:`, error);
            return null;
        }
    }, [user]);

    const fetchAuditorias = useCallback(async () => {
        if (!user || !year || !semestre) return;

        try {
            const data = await fetchStatsAuditoria(year, semestre);
            if (data && data.length > 0) {
                setAuditorias(data.map((item) => ({
                    semestre,
                    pSeguranca: item.pSeguranca || 'N/A',
                    pLimpeza: item.pLimpeza || 'N/A',
                })));
            } else {
                setAuditorias([{
                    semestre,
                    pSeguranca: 'N/A',
                    pLimpeza: 'N/A',
                }]);
            }
        } catch (error) {
            console.error('Error fetching auditorias:', error);
        }
    }, [user, year, semestre, fetchStatsAuditoria]);

    const handleSubmit = async () => {
        setFormSubmitted(true);

        if (!year || !semestre || !mensal.pSeguranca || !mensal.pLimpeza) {
            setSnackbarSeverity('error');
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarauditoria`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    ano: year,
                    semestre,
                    seguranca: mensal.pSeguranca,
                    limpeza: mensal.pLimpeza,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setSnackbarSeverity('success');
                setSnackbarMessage(data.message);
                setProcura(true);
                setFormSubmitted(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || t('registoauditoria_error_registrar_auditoria'));
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('login_error_server'));
            setSnackbarOpen(true);
        }
    };

    const handleEdit = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        setSemestre('');
        setAuditorias([]);
        setProcura(false);
    };

    const handleSemestreChange = (event) => {
        setSemestre(event.target.value);
        setAuditorias([]);
        setProcura(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const handleDialogSave = async () => {
        setFormSubmittedA(true);
        if (!selectedRow || !selectedRow.pSeguranca || !selectedRow.pLimpeza) {
            setSnackbarSeverity('error');
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/editarauditoria`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    ano: year,
                    semestre,
                    seguranca: selectedRow.pSeguranca,
                    limpeza: selectedRow.pLimpeza,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setSnackbarSeverity('success');
                setSnackbarMessage(data.message);
                fetchAuditorias();
                setFormSubmittedA(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || t('registoauditoria_error_registrar_auditoria'));
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('login_error_server'));
            setSnackbarOpen(true);
        }

        setOpenDialog(false);
    };

    const columns = [
            { id: 'pSeguranca', label: t('registoauditoria_seguranca_ambiente') },
            { id: 'pLimpeza', label: t('registoauditoria_limpeza_organizacao') },
            { id: 'actions', label: t('evaluacionlogistica_acciones') },
        ];
    
        const data = auditorias.map((evaluacion) => ({
            ...evaluacion,
            actions: (
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleEdit(evaluacion)}
                > <FaEdit />
                </Button>
            ),
        }));

    useEffect(() => {
        if (year && semestre && procura) {
            fetchAuditorias();
        }
    }, [year, semestre, fetchAuditorias]);

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('registo_auditoria_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <ValidatedSelect
                    value={year}
                    fullWidth
                    onChange={handleYearChange}
                    label={t('avaliacaocolaborador_ano')}
                    required
                    formSubmitted={formSubmitted}
                >
                    {Array.from({ length: 10 }, (_, i) => {
                        const yearOption = new Date().getFullYear() - i;
                        return (
                            <MenuItem key={yearOption} value={yearOption}>
                                {yearOption}
                            </MenuItem>
                        );
                    })}
                </ValidatedSelect>
                <ValidatedSelect
                    label={t('avaliacaocolaborador_semestre')}
                    value={semestre}
                    onChange={handleSemestreChange}
                    required
                    formSubmitted={formSubmitted}
                >
                    <MenuItem value={1}>{t('avaliacaocolaborador_semestre_1')}</MenuItem>
                    <MenuItem value={2}>{t('avaliacaocolaborador_semestre_2')}</MenuItem>
                </ValidatedSelect>
            </Box>
            {year && semestre ? (auditorias.every((auditoria) => auditoria.pSeguranca === 'N/A' && auditoria.pLimpeza === 'N/A') ? (
                <Box>
                <Box 
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 4,
                        marginTop: 4,
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            height: 2,
                            backgroundColor: companySettings.color,
                            marginRight: 2,
                        }}
                    />
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: companySettings.color }}
                    >
                        {t('registo_auditoria_ingresar_registros')}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            height: 2,
                            backgroundColor: companySettings.color,
                            marginLeft: 2,
                        }}
                    />
                </Box>
                    <Box
                        sx={{ display: 'flex', gap: 2, marginBottom: 2 }}
                    >
                        <ValidatedTextField
                        label={t('registoauditoria_seguranca_ambiente')}
                        type="number"
                        value={mensal.pSeguranca}
                        onChange={(e) => setMensal({ ...mensal, pSeguranca: e.target.value })}
                        fullWidth
                        required
                        formSubmitted={formSubmitted}
                    />
                    <ValidatedTextField
                        label={t('registoauditoria_limpeza_organizacao')}
                        type="number"
                        value={mensal.pLimpeza}
                        onChange={(e) => setMensal({ ...mensal, pLimpeza: e.target.value })}
                        fullWidth
                        required
                        formSubmitted={formSubmitted}
                    />
                    </Box>
                <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 4,
                            marginBottom: 4,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('evaluacionlogistica_guardar_evaluacion')}
                        </Button>
                    </Box>
                </Box>
            ) : (
                // Mostrar registros existentes si hay datos válidos
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 4,
                            marginTop: 4,
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginRight: 2,
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{ fontWeight: 'bold', color: companySettings.color }}
                        >
                            {t('evaluacionlogistica_registros_existentes')}
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginLeft: 2,
                            }}
                        />
                    </Box>
                    <SimpleTable columns={columns} data={data} />
                </Box>
            )) : null}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{t('registo_auditoria_editar_auditoria')}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        margin="dense"
                        label={t('registoauditoria_seguranca_ambiente')}
                        type="number"
                        value={selectedRow?.pSeguranca || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, pSeguranca: e.target.value })}
                        fullWidth
                        required
                        formSubmitted={formSubmittedA}
                    />
                    <ValidatedTextField
                        margin="dense"
                        label={t('registoauditoria_limpeza_organizacao')}
                        type="number"
                        value={selectedRow?.pLimpeza || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, pLimpeza: e.target.value })}
                        fullWidth
                        required
                        formSubmitted={formSubmittedA}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        {t('evaluacionlogistica_cancelar')}
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        {t('evaluacionlogistica_guardar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default RegistoAuditoria;