import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const ProducaoAnualChart = ({height}) => {
    const [data, setData] = useState([]);
    const year = new Date().getFullYear();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook para traducción

    useEffect(() => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const parsedUser = JSON.parse(userData);
                setUser(parsedUser);
            } else {
                navigate('/');
            }
        }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            if (!user) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/producaoanualpormes?customYear=${year}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const result = await response.json();
                setData(result.data);
            } catch (error) {
                console.error('Error fetching production data:', error);
            }
        };

        fetchData();
    }, [year, user]);

    return (
        <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
            <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', marginBottom: '16px' }}>
                {t('produccion_anual_titulo_grafico')} ({year})
            </Typography>
            <ResponsiveContainer width="100%" height={height}>
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="MES" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="COUNT" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ProducaoAnualChart;