import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, TablePagination, Typography, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const HistoricoEntradas = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [view, setView] = useState('barrote'); // 'barrote' or 'documento'
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [user, setUser] = useState(null);
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        }
    }, []);

    

    const fetchEntries = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/historicoentrada?view=${view}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setFilteredEntries(result.data);
            } else {
                console.error('Error fetching entries:', result);
            }
        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    }, [user, view]);

    useEffect(() => {
            fetchEntries();
    }, [fetchEntries]);

    const handleViewChange = (e) => {
        setView(e.target.value);
        setSearchTerm('');
        setPage(0); // Reset page to 0 when changing view
        fetchEntries(); // Fetch entries with the new view
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const StyledTableHead = styled(TableHead)({
        backgroundColor: companySettings.color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleViewDocumento = async (documento) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/documentodetails/${documento}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setDialogData(result);
                setOpenDialog(true);
            } else {
                console.error('Error fetching documento details:', result);
            }
        } catch (error) {
            console.error('Error fetching documento details:', error);
        }
    };
    
    const handleViewBarrote = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/barrotedetails/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setDialogData(result);
                setOpenDialog(true);
            } else {
                console.error('Error fetching barrote details:', result);
            }
        } catch (error) {
            console.error('Error fetching barrote details:', error);
        }
    };

    const columns = view === 'barrote' ? [
        { id: 'passaporte', label: t('construcao_paletes_passaporte'), sortable: true  },
        { id: 'artigo', label: t('entradamadeira_artigo'), sortable: true  },
        { id: 'comprimento', label: t('entradamadeira_comprimento'), sortable: true  },
        { id: 'largura', label: t('entradamadeira_largura'), sortable: true  },
        { id: 'altura', label: t('entradamadeira_altura'), sortable: true  },
        { id: 'quantidade_barrotes', label: t('historicoentradas_un_inicial'), sortable: true },
        { id: 'm3', label: t('historicoentradas_qt_m3'), sortable: true },
        { id: 'mov', label: t('evaluacionlogistica_acciones'), sortable: false }
    ] : [
        { id: 'data', label: t('producaoanalise_data'), sortable: true  },
        { id: 'colaborador', label: t('estatisticasprimas_colaborador'), sortable: true  },
        { id: 'documento', label: t('historicoentradas_documento'), sortable: true },
        { id: 'fornecedor', label: t('entradamadeira_fornecedor'), sortable: true  },
        { id: 'mov', label: t('evaluacionlogistica_acciones'), sortable: false }
    ];

    const handleSort = (columnId) => {
            if (orderBy === columnId) {
                setOrderDirection((prevDirection) => {
                    const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
                    return newDirection;
                });
            } else {
                setOrderBy(columnId);
                setOrderDirection('asc');
            }
        };
            
                const recordMatchesSearch = (record, searchTerm) => {
                    if (!searchTerm) return true;
                    return Object.values(record).some((value) =>
                        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    );
                };
                
                const filteredRecords = filteredEntries
                    .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general
            
            
                    const sortedRecords = useMemo(() => {
                        if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
                        return [...filteredRecords].sort((a, b) => {
                            const aValue = a[orderBy];
                            const bValue = b[orderBy];
                    
                            if (aValue === null || aValue === undefined) return 1;
                            if (bValue === null || bValue === undefined) return -1;
                    
                            // Si la columna es un número, comparar como números
                            if (typeof aValue === 'number' || !isNaN(aValue)) {
                                return orderDirection === 'asc'
                                    ? parseFloat(aValue) - parseFloat(bValue)
                                    : parseFloat(bValue) - parseFloat(aValue);
                            }
                    
                            // Si la columna es texto, comparar como cadenas
                            if (typeof aValue === 'string') {
                                return orderDirection === 'asc'
                                    ? aValue.localeCompare(bValue)
                                    : bValue.localeCompare(aValue);
                            }
                    
                            return 0; // Si no se puede comparar, no cambiar el orden
                        });
                    }, [filteredRecords, orderBy, orderDirection]);
            
                const paginatedRecords = useMemo(() => {
                    return sortedRecords.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                    );
                }, [sortedRecords, page, rowsPerPage]);
    
    const data = paginatedRecords.map((entry, index) => ({
        ...entry,
        mov: (
            <Button variant="contained" color="primary" onClick={() => view === 'barrote' ? handleViewBarrote(entry.id) : handleViewDocumento(entry.documento)}>
                <FaEye />
            </Button>
        )
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('historicoentradas_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>{t('historicoentradas_vista')}</InputLabel>
                    <Select value={view} onChange={handleViewChange}>
                        <MenuItem value="barrote">{t('historicoentradas_por_barrote')}</MenuItem>
                        <MenuItem value="documento">{t('historicoentradas_por_documento')}</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0); // Reinicia la paginación al buscar
                    }}
                    style={{ marginBottom: '20px' }}
                />
            </Box>
            <SimpleTable 
                columns={columns} 
                data={data}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredEntries.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <DialogTitle>{t('producaoanalise_detalhes_adicionais')}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <StyledTableHead>
                                <TableRow>
                                    {view === 'barrote' ? (
                                        <>
                                            <TableCell>OPP</TableCell>
                                            <TableCell>{t('construcao_paletes_modelo_palete')}</TableCell>
                                            <TableCell>{t('entradamadeira_artigo')}</TableCell>
                                            <TableCell>{t('historicoentradas_unidades')}</TableCell>
                                            <TableCell>{t('historicoentradas_m3')}</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>{t('construcao_paletes_passaporte')}</TableCell>
                                            <TableCell>{t('entradamadeira_artigo')}</TableCell>
                                            <TableCell>{t('entradamadeira_comprimento')}</TableCell>
                                            <TableCell>{t('entradamadeira_largura')}</TableCell>
                                            <TableCell>{t('entradamadeira_altura')}</TableCell>
                                            <TableCell>{t('historicoentradas_qt')}</TableCell>
                                            <TableCell>{t('historicoentradas_m3')}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {dialogData.map((item, index) => (
                                    <TableRow key={index}>
                                        {view === 'barrote' ? (
                                            <>
                                                <TableCell>{item.opp}</TableCell>
                                                <TableCell>{item.modelo_palete}</TableCell>
                                                <TableCell>{item.artigo}</TableCell>
                                                <TableCell>{item.quantidade}</TableCell>
                                                <TableCell>{item.m3}</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell>{item.passaporte}</TableCell>
                                                <TableCell>{item.artigo}</TableCell>
                                                <TableCell>{item.comprimento}</TableCell>
                                                <TableCell>{item.largura}</TableCell>
                                                <TableCell>{item.altura}</TableCell>
                                                <TableCell>{item.quantidade_barrotes}</TableCell>
                                                <TableCell>{item.m3}</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">{t('controlof_fechar')}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default HistoricoEntradas;