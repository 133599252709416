import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, MenuItem, FormControl, Snackbar, Alert } from '@mui/material';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const EntradaMadeira = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [data, setData] = useState('');
    const [fornecedor, setFornecedor] = useState('');
    const [guiaRemessa, setGuiaRemessa] = useState('');
    const [selectedArtigo, setSelectedArtigo] = useState('');
    const [artigosAdicionados, setArtigosAdicionados] = useState([]);
    const [qt, setQt] = useState('');
    const [fornecedores, setFornecedores] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [passaporte, setPassaporte] = useState('');
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedA, setFormSubmittedA] = useState(false);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);


    useEffect(() => {
        if (user) {
            const fetchFornecedores = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/fornecedores`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    const data = await response.json();
                    if (Array.isArray(data)) {
                        setFornecedores(data);
                    } else {
                        console.error('Error fetching fornecedores:', data);
                    }
                } catch (error) {
                    console.error('Error fetching fornecedores:', error);
                }
            };

            const fetchArtigos = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/artigosmadeira`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    const data = await response.json();
                    if (Array.isArray(data)) {
                        setArtigos(data);
                    } else {
                        console.error('Error fetching artigos:', data);
                    }
                } catch (error) {
                    console.error('Error fetching artigos:', error);
                }
            };

            fetchFornecedores();
            fetchArtigos();
        }
    }, [user]);

    const handleAddArtigo = () => {
        setFormSubmittedA(true);
        if (!selectedArtigo || !qt || !passaporte) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
    
        const artigo = artigos.find(a => a.id === parseInt(selectedArtigo));
        const totalM3 = (qt * artigo.comprimento * artigo.largura * artigo.altura) / 1000000000;
        setArtigosAdicionados([...artigosAdicionados, { ...artigo, qt, totalM3, passaporte }]);
        setSelectedArtigo('');
        setQt('');
        setPassaporte('');
        setFormSubmittedA(false);
    };

    const handleRegister = async () => {
        setFormSubmitted(true);
        const newErrors = {};
        if (!data) newErrors.data = true;
        if (!fornecedor) newErrors.fornecedor = true;
        if (!guiaRemessa) newErrors.guiaRemessa = true;
        if (artigosAdicionados.length === 0) newErrors.artigos = true;
    
        if (Object.keys(newErrors).length > 0) {
            setSnackbarMessage(t('entradamadeira_complete_campos_artigo'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } else {
            const artigos = artigosAdicionados.map(artigo => ({
                artigoId: artigo.id,
                passaporte: artigo.passaporte,
                qt: artigo.qt
            }));
            const datapost = {
                data,
                datahora: new Date().toISOString(),
                fornecedor,
                guiaRemessa,
                colab: user.colaborador,
                artigos
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/entradamadeira`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(datapost),
                });
    
                const result = await response.json();
                if (response.ok) {
                    setSnackbarMessage(t('entradamadeira_registro_exitoso'));
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);
                    // Limpiar los campos después del registro exitoso
                    setData('');
                    setFornecedor('');
                    setGuiaRemessa('');
                    setArtigosAdicionados([]);
                    setFormSubmitted(false);
                } else {
                    setSnackbarMessage(result.message || t('entradamadeira_error_registrar'));
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarMessage(t('entradamadeira_error_registrar'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        }
    };
    const handleRemoveArtigo = (index) => {
        const newArtigosAdicionados = [...artigosAdicionados];
        newArtigosAdicionados.splice(index, 1);
        setArtigosAdicionados(newArtigosAdicionados);
    };
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const columns = [
        { id: 'passaporte', label: t('construcao_paletes_passaporte') },
        { id: 'artigo', label: t('entradamadeira_artigo') },
        { id: 'qt', label: t('entradamadeira_qt') },
        { id: 'comprimento', label: t('entradamadeira_comprimento') },
        { id: 'largura', label: t('entradamadeira_largura') },
        { id: 'altura', label: t('entradamadeira_altura') },
        { id: 'totalM3', label: t('entradamadeira_total_m3') },
        { id: 'acoes', label: t('evaluacionlogistica_acciones') }
    ];
    
    const dataT = artigosAdicionados.map((artigo, index) => ({
        passaporte: artigo.passaporte,
        artigo: artigo.artigo,
        qt: artigo.qt,
        comprimento: artigo.comprimento,
        largura: artigo.largura,
        altura: artigo.altura,
        totalM3: artigo.totalM3.toFixed(2),
        acoes: (
            <Button variant="contained" color="secondary" onClick={() => handleRemoveArtigo(index)}>
                <DeleteIcon />
            </Button>
        )
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('entradamadeira_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <FormControl fullWidth sx={{ marginBottom: '16px', marginRight: '8px' }}>
                    <ValidatedTextField
                        label={t('producaoanalise_data')}
                        type="date"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        formSubmitted={formSubmitted}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '16px', marginRight: '8px' }}>
                    <ValidatedSelect
                        label={t('entradamadeira_fornecedor')}
                        value={fornecedor}
                        onChange={(e) => setFornecedor(e.target.value)}
                        required
                        formSubmitted={formSubmitted}
                    >
                        {fornecedores.map((fornecedor) => (
                            <MenuItem key={fornecedor.id} value={fornecedor.id}>
                                {fornecedor.fornecedor}
                            </MenuItem>
                        ))}
                    </ValidatedSelect>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                    <ValidatedTextField
                        label={t('entradamadeira_guia_remessa')}
                        value={guiaRemessa}
                        onChange={(e) => setGuiaRemessa(e.target.value)}
                        required
                        formSubmitted={formSubmitted}
                    />
                </FormControl>
                
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <FormControl sx={{ flex: 1, marginRight: '8px' }}>
                    <ValidatedSelect
                    label={t('entradamadeira_artigo')}
                        value={selectedArtigo}
                        onChange={(e) => setSelectedArtigo(e.target.value)}
                        required
                        formSubmitted={formSubmittedA}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem key={artigo.id} value={artigo.id}>
                                {artigo.artigo}
                            </MenuItem>
                        ))}
                    </ValidatedSelect>
                </FormControl>
                <FormControl sx={{ flex: 1, marginRight: '8px' }}>
                    <ValidatedTextField
                        label={t('entradamadeira_qt')}
                        type="number"
                        value={qt}
                        onChange={(e) => setQt(e.target.value)}
                        required
                        formSubmitted={formSubmittedA}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1, marginRight: '8px' }}>
                    <ValidatedTextField
                        label={t('construcao_paletes_passaporte')}
                        value={passaporte}
                        onChange={(e) => setPassaporte(e.target.value)}
                        required
                        formSubmitted={formSubmittedA}
                    />
                </FormControl>
                <Button variant="contained" onClick={handleAddArtigo}>
                    {t('entradamadeira_adicionar_artigo')}
                </Button>
            </Box>
            <SimpleTable columns={columns} data={dataT} />
            <Button variant="contained" color="primary" onClick={handleRegister} sx={{ marginTop: '16px' }}>
                {t('registarcolab_registrar')}
            </Button>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EntradaMadeira;