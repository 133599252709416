import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const User = () => {
    const { t } = useTranslation(); // Hook para traducción
    const user = JSON.parse(localStorage.getItem('user'))

    return (
        <Box sx={{ padding: 4 }}>
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, textAlign: 'center' }}>
                <Avatar
                    sx={{ width: 100, height: 100, margin: '0 auto', bgcolor: 'primary.main', fontSize: 40 }}
                >
                    {user.nome.charAt(0).toUpperCase()}{user.apelido.charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                    {user.nome} {user.apelido}
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 4 }}>
                    {user.colaborador}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('en_desarrollo')}
                </Typography>
            </Paper>
        </Box>
    );
};

export default User;