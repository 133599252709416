import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Box, Typography, TextField, TablePagination, Checkbox, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const StockMadeira = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Estado para el Snackbar
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Mensaje del Snackbar
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severidad del Snackbar

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchEntries = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/stockmadeira`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Error fetching entries:', result);
            }
        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    }, [page, rowsPerPage, searchTerm, user]);

    useEffect(() => {
        fetchEntries();
    }, [fetchEntries]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelect = (id) => {
        setSelectedIds(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(recordId => recordId !== id)
                : [...prevSelected, id]
        );
    };
    
    const handleFinalizar = () => {
        setOpenDialog(true);
    };
    
    const handleConfirmFinalizar = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/finalizarstock`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ ids: selectedIds }),
            });
            const result = await response.json();
            if (result) {
                fetchEntries();
                setSelectedIds([]);
                setOpenDialog(false);
                setSnackbarMessage(t('stockmadeira_registro_exitoso'));
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                console.error('Error finalizando registros:', result);
                setSnackbarMessage(t('stockmadeira_error_registrar'));
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error finalizando registros:', error);
            setSnackbarMessage(t('stockmadeira_error_registrar'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const columns = [
        {id: 'select', label: '', sortable: false},
        { id: 'documento', label: t('historicoentradas_documento'), sortable: true  },
        { id: 'passaporte', label: t('construcao_paletes_passaporte'), sortable: true  },
        { id: 'artigo', label: t('entradamadeira_artigo'), sortable: true  },
        { id: 'qt_left', label: t('stockmadeira_quantidade_restante'), sortable: true  },
    ];

    const handleSort = (columnId) => {
        if (orderBy === columnId) {
            setOrderDirection((prevDirection) => {
                const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
                return newDirection;
            });
        } else {
            setOrderBy(columnId);
            setOrderDirection('asc');
        }
    };
            
    const recordMatchesSearch = (record, searchTerm) => {
        if (!searchTerm) return true;
        return Object.values(record).some((value) =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    };
                
    const filteredRecords = data.filter((record) => recordMatchesSearch(record, searchTerm));
            
    const sortedRecords = useMemo(() => {
        if (!orderBy) return filteredRecords;
        return [...filteredRecords].sort((a, b) => {
            const aValue = a[orderBy];
            const bValue = b[orderBy];
                    
            if (aValue === null || aValue === undefined) return 1;
            if (bValue === null || bValue === undefined) return -1;
                    
            if (typeof aValue === 'number' || !isNaN(aValue)) {
                return orderDirection === 'asc'
                    ? parseFloat(aValue) - parseFloat(bValue)
                    : parseFloat(bValue) - parseFloat(aValue);
            }
                    
            if (typeof aValue === 'string') {
                return orderDirection === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }
                    
            return 0;
        });
    }, [filteredRecords, orderBy, orderDirection]);
            
    const paginatedRecords = useMemo(() => {
        return sortedRecords.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [sortedRecords, page, rowsPerPage]);

    const tableData = paginatedRecords.map((entry) => ({
        select: (
            <Checkbox
                checked={selectedIds.includes(entry.id)}
                onChange={() => handleSelect(entry.id)}
            />
        ),
        documento: entry.documento,
        passaporte: entry.passaporte,
        artigo: entry.artigo,
        qt_left: entry.qt_left,
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('stockmadeira_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0);
                    }}
                    style={{ marginBottom: '20px' }}
                />
                <Button variant="contained" color="primary" onClick={handleFinalizar} disabled={selectedIds.length === 0}>
                    {t('stockmadeira_finalizar_seleccionados')}
                </Button>
            </Box>
            <SimpleTable 
                columns={columns} 
                data={tableData}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.filter((record) => recordMatchesSearch(record, searchTerm)).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('stockmadeira_confirmar_finalizacion')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('stockmadeira_confirmar_finalizacion_texto')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t('stockmadeira_cancelar')}
                    </Button>
                    <Button onClick={handleConfirmFinalizar} color="primary">
                        {t('stockmadeira_finalizar')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default StockMadeira;