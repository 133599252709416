import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const ProducaoDiariaChart = ({height}) => {
    const [chartData, setChartData] = useState([]);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const { t } = useTranslation(); // Hook para traducción

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchData = useCallback(async () => {
        if (!user) return;
        // const customDate = new Date().toISOString().split('T')[0]; // Fecha actual en formato YYYY-MM-DD
        // const customDate = '2025-02-17'; // Fecha fija para pruebas
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/producaodiariaporlinha`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                const formattedData = result.data.map(item => ({
                    LINHA: item.LINHA,
                    COUNT: item.COUNT
                }));
                setChartData(formattedData);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
            <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', marginBottom: '16px' }}>
                {t('produccion_diaria_titulo_grafico')}
            </Typography>
            <ResponsiveContainer width="100%" height={height}>
                <PieChart>
                    <Pie
                        data={chartData}
                        dataKey="COUNT"
                        nameKey="LINHA"
                        cx="50%"
                        cy="50%"
                        outerRadius={Math.min(height / 2, 120)}
                        fill="#8884d8"
                        label
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ProducaoDiariaChart;