import React, { useState, useEffect } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import { FaBox, FaUser, FaClipboardList, FaSignOutAlt, FaMoneyBillWave, FaHome, FaBars, FaTimes, FaEye, FaTools, FaTree } from 'react-icons/fa';
import { useIdleTimer } from 'react-idle-timer';
import './Navbar.css';
import { companyConfig } from '../utils/config';
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const { t } = useTranslation(); // Hook para traducción

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const handleToggle = () => {
        setIsMobile(!isMobile);
    };

    const handleLogout = async () => {
        if (!user) { 
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/logout`, {
                method: 'POST',
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success) {
                // Eliminar 'user' y 'turno' del localStorage
                localStorage.removeItem('user');
                localStorage.removeItem('turno');
                navigate(`/${user.company}/login`);
            } else {
                localStorage.removeItem('user');
                alert(data.message || t('navbar_error_session'));
            }
        } catch (error) {
            console.error('Error:', error);
            alert(t('navbar_error_servidor'));
        }
    };

    const handleOnIdle = () => {
        handleLogout();
    };

    useIdleTimer({
        timeout: 1000 * 60 * 15, // 15 minutos
        onIdle: handleOnIdle,
        debounce: 500
    });

    const companySettings = user ? companyConfig[user.company] : {};

    return (
        <nav className="navbar" style={{ backgroundColor: companySettings.color }}>
            <div className="navbar-logo">
                <img src={companySettings.logo} alt="Logo" className="navbar-logo-2" />
                {user && (
                <div className="navbar-user-info">
                    <Link to={`/${user.company}/myuser`} className={'nav-link logout-button'}>
                        <FaUser className="nav-icon" /> {user.nome} {user.apelido}
                    </Link>
                </div>
                )}
            </div>
            <div className={`nav-links ${isMobile ? 'nav-links-mobile' : ''}`} style={{'--navbar-color': companySettings.color, '--navbar-hover-color': companySettings.color }}>
                {user && (
                <Link to={`/${user.company}/home`} className={'nav-link logout-button'}>
                    <FaHome className="nav-icon" /> Home
                </Link>
                )}
                {user && (user.role === 100 || user.role === 2 || user.role === 13 || user.role === 90 || user.role === 80) && (
                    <div className={`nav-link ${selectedMenu === 'RP produção' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('RP produção')} onMouseLeave={() => setSelectedMenu('')}>
                        <FaBox className="nav-icon" /> {t('navbar_menu_produccion')}
                        <div className="sub-menu">
                            {user && (user.role === 100 || user.role === 90 || user.role === 80) && (
                            <Link to={`/${user.company}/rp/validar`} className="sub-menu-link">{t('navbar_submenu_produccion_validar')}</Link>
                            )}
                            {user &&  user.role !== 80 && (
                            <Link to={`/${user.company}/rp/registopro`} className="sub-menu-link">{t('navbar_submenu_produccion_registro')}</Link>
                            )}
                        </div>
                    </div>
                )}
                {user && (user.role === 100 || user.role === 90) && (
                    <div className={`nav-link ${selectedMenu === 'Registos' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('Registos')} onMouseLeave={() => setSelectedMenu('')}>
                        <FaClipboardList className="nav-icon" /> {t('navbar_menu_registros')}
                        <div className="sub-menu">
                            <Link to={`/${user.company}/rh/registarcolab`} className="sub-menu-link">{t('navbar_submenu_registros_colaborador')}</Link>
                            <Link to={`/${user.company}/rh/listarcolab`} className="sub-menu-link">{t('navbar_submenu_registros_listar')}</Link>
                        </div>
                    </div>
                )}
                {user && (user.role === 100 || user.role === 90 || user.role === 13 || user.role === 5 || user.role === 80) && (
                <div className={`nav-link ${selectedMenu === 'Supervisão' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('Supervisão')} onMouseLeave={() => setSelectedMenu('')}>
                    <FaEye className="nav-icon" /> {t('navbar_menu_supervision')}
                    <div className="sub-menu">
                        <Link to={`/${user.company}/super/ofcontrol`} className="sub-menu-link">{t('navbar_submenu_supervision_control')}</Link>
                        <Link to={`/${user.company}/super/producao`} className="sub-menu-link">{t('navbar_submenu_supervision_diaria')}</Link>
                        <Link to={`/${user.company}/super/producaoanalise`} className="sub-menu-link">{t('navbar_submenu_supervision_analisis')}</Link>
                    </div>
                </div>
                )}
                {user && (user.role === 100 || user.role === 90 ) && user.company === 'plafesa' && (
                <div className={`nav-link ${selectedMenu === 'manutencao' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('manutencao')} onMouseLeave={() => setSelectedMenu('')}>
                    <FaTools className="nav-icon" /> {t('navbar_menu_manutencion')}
                    <div className="sub-menu">
                        <Link to={`/${user.company}/super/listatarefas`} className="sub-menu-link">{t('navbar_submenu_manutencion_tareas')}</Link>
                        <Link to={`/${user.company}/super/registotarefas`} className="sub-menu-link">{t('navbar_submenu_manutencion_registro')}</Link>
                        <Link to={`/${user.company}/super/realizarmanutencao`} className="sub-menu-link">{t('navbar_submenu_manutencion_realizar')}</Link>
                    </div>
                </div>
                )}
                {user && (user.role === 100 || user.role === 90 || user.role === 5 || user.role === 3) && user.company === 'plafesa' && (
                <div className={`nav-link ${selectedMenu === 'madeira' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('madeira')} onMouseLeave={() => setSelectedMenu('')}>
                    <FaTree className="nav-icon" /> {t('navbar_menu_madera')}
                    <div className="sub-menu">
                        {user && user.role !== 3 && (
                            <>
                            <Link to={`/${user.company}/madeira/loteopp`} className="sub-menu-link">
                                {t('navbar_submenu_madera_loteopp')}
                            </Link>
                            <Link to={`/${user.company}/madeira/entrada`} className="sub-menu-link">
                                {t('navbar_submenu_madera_entrada')}
                            </Link>
                            <Link to={`/${user.company}/madeira/historico`} className="sub-menu-link">
                                {t('navbar_submenu_madera_historico')}
                            </Link>
                            <Link to={`/${user.company}/madeira/listaopp`} className="sub-menu-link">
                                {t('navbar_submenu_madera_listaopp')}
                            </Link>
                            <Link to={`/${user.company}/madeira/stock`} className="sub-menu-link">
                                {t('navbar_submenu_madera_stock')}
                            </Link>
                            </>
                        )}
                        <Link to={`/${user.company}/madeira/construcao`} className="sub-menu-link">
                            {t('navbar_submenu_madera_construccion')}
                        </Link>
                    </div>
                </div>
                )}
                {user && (user.role === 100 || user.role === 80 || user.role === 8  ) && (
                <div className={`nav-link ${selectedMenu === 'primas' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('primas')} onMouseLeave={() => setSelectedMenu('')}>
                <FaMoneyBillWave className="nav-icon" /> {t('navbar_menu_primas')}
                <div className="sub-menu">
                    <Link to={`/${user.company}/primas/avaliacao`} className="sub-menu-link">
                        {t('navbar_submenu_primas_evaluacion_colaborador')}
                    </Link>
                    <Link to={`/${user.company}/primas/performance`} className="sub-menu-link">
                        {t('navbar_submenu_primas_rendimiento_operador')}
                    </Link>
                    <Link to={`/${user.company}/primas/estatisticas`} className="sub-menu-link">
                        {t('navbar_submenu_primas_estadisticas')}
                    </Link>
                    {user && user.company === 'plafesa' && (
                        <Link to={`/${user.company}/primas/resultados`} className="sub-menu-link">
                            {t('navbar_submenu_primas_resultados')}
                        </Link>
                    )}
                    {user && user.company === 'ibersteel' && (
                        <>
                            <Link to={`/${user.company}/primas/logistica`} className="sub-menu-link">
                                {t('navbar_submenu_primas_evaluacion_logistica')}
                            </Link>
                            <Link to={`/${user.company}/primas/auditoria`} className="sub-menu-link">
                                {t('navbar_submenu_primas_registro_auditoria')}
                            </Link>
                        </>
                    )}
                </div>
            </div>
                )}
                {user && (user.role === 2 || user.role === 1) && user.company === 'plafesa' && (
                <div className={`nav-link ${selectedMenu === 'Tarefas' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('Tarefas')} onMouseLeave={() => setSelectedMenu('')}>
                    <FaClipboardList className="nav-icon" /> {t('navbar_menu_tarefas')}
                    <div className="sub-menu">
                        <Link to={`/${user.company}/madeira/loteopp`} className="sub-menu-link">
                            {t('navbar_submenu_tarefas_loteopp')}
                        </Link>
                        <Link to={`/${user.company}/super/realizarmanutencao`} className="sub-menu-link">
                            {t('navbar_submenu_tarefas_manutencion_nivel1')}
                        </Link>
                    </div>
                </div>
                )}
                {user && (user.role === 3 || user.role === 13) && user.company === 'plafesa' && (
                    <div className={`nav-link ${selectedMenu === 'Tarefas' ? 'selected' : ''}`} onMouseEnter={() => setSelectedMenu('Tarefas')} onMouseLeave={() => setSelectedMenu('')}>
                        <FaClipboardList className="nav-icon" /> {t('navbar_menu_tarefas')}
                        <div className="sub-menu">
                            <Link to={`/${user.company}/madeira/construcao`} className="sub-menu-link">
                                {t('navbar_submenu_madera_construccion')}
                            </Link>
                        </div>
                    </div>
                )}
                {user && (
                    <Link to={`/${user.company}/login`} className={'nav-link logout-button'} onClick={handleLogout}>
                        <FaSignOutAlt className="nav-icon" /> {t('navbar_logout')}
                    </Link>
                )}
            </div>
            <button className="mobile-menu-icon" onClick={handleToggle}>
                {isMobile ? <FaTimes /> : <FaBars />}
            </button>
        </nav>
    );
};

export default Navbar;