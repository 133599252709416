import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Box, Typography } from '@mui/material';
import { companyConfig } from '../../utils/config';
import ProducaoDiariaChart from '../../components/ProducaoDiariaChart';
import TarefasPorFazer from '../../components/TarefasPorFazer';
import ProducaoAnualChart from '../../components/ProducaoAnualChart';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción
import './Home.css';

function Home() {
    const { t } = useTranslation(); // Hook para traducción
    const [user, setUser] = useState(null);
    const [showContent, setShowContent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 8000); // 8 segundos para que el cohete y el título desaparezcan

        return () => clearTimeout(timer);
    }, []);

    const companySettings = user ? companyConfig[user.company] : {};

    return (
        <Container maxWidth="lg" style={{ flex: 1 }}>
            {showContent && (
                <Grid container spacing={3} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            {user && (user.role === 100 || user.role === 90 || user.role === 2) && (
                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={3} style={{ padding: '16px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ProducaoDiariaChart height={200} />
                                    </Paper>
                                </Grid>
                            )}
                            {user && (user.role === 100 || user.role === 90 ) && (
                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={3} style={{ padding: '16px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ProducaoAnualChart height={200} />
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {user && (user.role === 100 || user.role === 90 || user.role === 2) && user.company === 'plafesa' && (
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} style={{ padding: '16px', height: '100%', maxHeight: '300px', overflowY: 'auto' }}>
                                <TarefasPorFazer user={user} />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            )}
            <Box className="home-background"></Box>
            <Box className="home-content">
                <Typography variant="h1" className="home-title" style={{ color: companySettings.color }}>
                    {t('home_welcome_message', { companyName: companySettings.name })}
                </Typography>
                <Box className="rocket"></Box>
            </Box>
        </Container>
    );
}

export default Home;