import React, { useState, useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, TextField, MenuItem, FormControl, Snackbar, Alert, IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { companyConfig } from '../../utils/config';
import ValidatedTextField from '../../components/ValidatedTextField';
import SimpleTable from '../../components/SimpleTable';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ConstrucaoPaletes = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [passaportes, setPassaportes] = useState([]);
    const [artigosAdicionados, setArtigosAdicionados] = useState([]);
    const [modeloPalete, setModeloPalete] = useState({ comprimento: '', largura: '', quantidade: '' });
    const [selectedPassaporte, setSelectedPassaporte] = useState('');
    const [barrote, setBarrote] = useState('');
    const [qt, setQt] = useState('');
    const [user, setUser] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const navigate = useNavigate();
    const currentDate = new Date();
    const start = new Date(currentDate.getFullYear(), 0, 0);
    const diff = currentDate - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const opp = Math.floor(diff / oneDay);
    const formattedOpp = `${opp}/${currentDate.getFullYear().toString().slice(-2)}`;
    const datahora = currentDate.toISOString();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedA, setFormSubmittedA] = useState(false);
    

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchPassaportes = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listapassaportes`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const result = await response.json();
                if (Array.isArray(result)) {
                    setPassaportes(result);
                } else {
                    console.error('Error fetching passaportes:', result);
                }
            } catch (error) {
                console.error('Error fetching passaportes:', error);
            }
        };

        if (user) {
            fetchPassaportes();
        }
    }, [user]);

    const handleAddArtigo = () => {
        setFormSubmittedA(true);
        if (!selectedPassaporte || !qt) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
        setArtigosAdicionados([...artigosAdicionados, { passaporte: selectedPassaporte, barrote, qt, desperdicio: '' }]);
        setSelectedPassaporte('');
        setBarrote('');
        setQt('');
        setFormSubmittedA(false);
    };

    const handleRemoveArtigo = (index) => {
        const newArtigosAdicionados = artigosAdicionados.filter((_, i) => i !== index);
        setArtigosAdicionados(newArtigosAdicionados);
    };

    const handleArtigoChange = (field, value) => {
        if (field === 'passaporte') {
            setSelectedPassaporte(value);
            fetchBarrote(value);
        } else if (field === 'qt') {
            setQt(value);
        }
    };

    const handleDesperdicioChange = (index, value) => {
        const newArtigosAdicionados = artigosAdicionados.map((artigo, i) => {
            if (i === index) {
                return { ...artigo, desperdicio: value };
            }
            return artigo;
        });
        setArtigosAdicionados(newArtigosAdicionados);
    };

    const fetchBarrote = async (passaporte) => {
        const selectedPassaporte = passaportes.find(p => p.passaporte === passaporte);
        if (selectedPassaporte) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listabarrotes/${selectedPassaporte.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const result = await response.json();
                if (Array.isArray(result) && result.length > 0) {
                    setBarrote(result[0].artigo);
                } else {
                    console.error('Error fetching barrote:', result);
                }
            } catch (error) {
                console.error('Error fetching barrote:', error);
            }
        }
    };

    const handleModeloPaleteChange = (field, value) => {
        setModeloPalete({ ...modeloPalete, [field]: value });
    };

    const handleRegister = async () => {
        setFormSubmitted(true);
        // Validaciones
        if (artigosAdicionados.length === 0) {
            setSnackbarMessage(t('construcao_paletes_add_article'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        for (const artigo of artigosAdicionados) {
            if (artigo.desperdicio === '') {
                setSnackbarMessage(t('construcao_paletes_fill_waste_field'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
        }

        if (!modeloPalete.comprimento || !modeloPalete.largura || !modeloPalete.quantidade) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
        const modeloPaleteString = `${modeloPalete.comprimento} X ${modeloPalete.largura}`;
        const desperdicioMetros = artigosAdicionados.map(a => a.desperdicio / 1000);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarpalete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    passaportes: artigosAdicionados.map(a => ({passaporte: a.passaporte, qt: a.qt})),
                    modeloPalete: modeloPaleteString,
                    quantidade: modeloPalete.quantidade,
                    opp: opp,
                    datahora: datahora, // Reemplaza con el valor correcto
                    desperdicio: desperdicioMetros,
                    colab: user.colaborador
                }),
            });

            const result = await response.json();
            if (response.ok) {
                setSnackbarMessage(t('construcao_paletes_registered_successfully'));
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                // Limpiar los campos después del registro exitoso
                setArtigosAdicionados([]);
                setFormSubmitted(false);
                setModeloPalete({ comprimento: '', largura: '', quantidade: '' });
            } else {
                setSnackbarMessage(result.message || t('construcao_paletes_error_registering'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (error) {
            setSnackbarMessage(t('construcao_paletes_error_registering'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const columns = [
        { id: 'passaporte', label: t('construcao_paletes_passaporte') },
        { id: 'barrote', label: t('construcao_paletes_barrote') },
        { id: 'qt', label: t('construcao_paletes_quantidade') },
        { id: 'desperdicio', label: t('construcao_paletes_desperdicio') },
        { id: 'acoes', label: t('evaluacionlogistica_acciones') }
    ];

    const data = artigosAdicionados.map((artigo, index) => ({
        passaporte: artigo.passaporte,
        barrote: artigo.barrote,
        qt: artigo.qt,
        desperdicio: (
            <ValidatedTextField
                type="number"
                value={artigo.desperdicio}
                onChange={(e) => handleDesperdicioChange(index, e.target.value)}
                required
                formSubmitted={formSubmitted}
            />
        ),
        acoes: (
            <IconButton onClick={() => handleRemoveArtigo(index)}>
                <DeleteIcon />
            </IconButton>
        )
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                {t('construcao_paletes_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Typography variant="h6" component="h2" gutterBottom align="center">
                OPP {formattedOpp}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                <FormControl fullWidth sx={{ marginBottom: '16px', marginRight: '8px' }}>
                    <ValidatedSelect
                        label={t('construcao_paletes_passaporte')}
                        value={selectedPassaporte}
                        onChange={(e) => handleArtigoChange('passaporte', e.target.value)}
                        required
                        formSubmitted={formSubmittedA}
                    >
                        {passaportes.map((passaporte) => (
                            <MenuItem key={passaporte.passaporte} value={passaporte.passaporte}>
                                {passaporte.passaporte}
                            </MenuItem>
                        ))}
                    </ValidatedSelect>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '16px', marginRight: '8px' }}>
                    <TextField
                        label={t('construcao_paletes_barrote')}
                        value={barrote}
                        onChange={(e) => handleArtigoChange('barrote', e.target.value)}
                        disabled
                    />
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '16px', marginRight: '8px' }}>
                    <ValidatedTextField
                        label={t('construcao_paletes_quantidade')}
                        type="number"
                        value={qt}
                        onChange={(e) => handleArtigoChange('qt', e.target.value)}
                        required
                        formSubmitted={formSubmittedA}
                    />
                </FormControl>
                <Button
                    variant="contained"
                    onClick={handleAddArtigo}
                    startIcon={<AddIcon />}
                    sx={{
                        backgroundColor: 'green',
                        color: 'white',
                        borderRadius: 2,
                        padding: '4px 8px',
                        minWidth: 'auto',
                        '& .MuiButton-startIcon': {
                            margin: 0,
                        },
                        '&:hover': {
                            backgroundColor: 'darkgreen',
                        },
                    }}
                >
                </Button>
            </Box>
            <SimpleTable columns={columns} data={data} />
            <Box sx={{ marginTop: 4 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('construcao_paletes_modelo_palete')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                    <ValidatedTextField
                        label={t('construcao_paletes_comprimento')}
                        type="number"
                        value={modeloPalete.comprimento}
                        onChange={(e) => handleModeloPaleteChange('comprimento', e.target.value)}
                        required
                        formSubmitted={formSubmitted}
                    />
                    <ValidatedTextField
                        label={t('controlof_largura')}
                        type="number"
                        value={modeloPalete.largura}
                        onChange={(e) => handleModeloPaleteChange('largura', e.target.value)}
                        required
                        formSubmitted={formSubmitted}
                    />
                    <ValidatedTextField
                        label={t('construcao_paletes_quantidade_paletes')}
                        type="number"
                        value={modeloPalete.quantidade}
                        onChange={(e) => handleModeloPaleteChange('quantidade', e.target.value)}
                        required
                        formSubmitted={formSubmitted}
                    />
                </Box>
                <Button variant="contained" color="primary" onClick={handleRegister}>
                    {t('registarcolab_registrar')}
                </Button>
            </Box>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ConstrucaoPaletes;