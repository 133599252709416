import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TablePagination, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Box, Button} from '@mui/material';
import { styled } from '@mui/system';
import { format, startOfWeek, addDays, subDays, isBefore, isSameDay } from 'date-fns';
import { companyConfig } from '../../utils/config';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SimpleTable from '../../components/SimpleTable';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const RegistoTarefas = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [user, setUser] = useState(null);
    const [lines, setLines] = useState([]);
    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [incidencias, setIncidencias] = useState([]);
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')
    

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchLines = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/linhas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setLines(result);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching lines:', error);
        }
    }, [user]);

    const fetchDailyRecords = useCallback(async (linhaId, date) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registospordia?linhaId=${linhaId}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setRecords(prevRecords => [...prevRecords, ...result]);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching daily records:', error);
        }
    }, [user]);

    const fetchRecords = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listaregistos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchLines();
            fetchRecords();
        }
    }, [user, fetchLines, fetchRecords, searchTerm]);

    useEffect(() => {
        if (lines.length > 0) {
            const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
            const daysOfWeek = [subDays(startOfWeekDate, 2), ...Array.from({ length: 5 }, (_, i) => addDays(startOfWeekDate, i + 1))];
            const fetchAllRecords = async () => {
                for (const line of lines) {
                    for (const day of daysOfWeek) {
                        await fetchDailyRecords(line.id, format(day, 'yyyy-MM-dd'));
                    }
                }
            };
            fetchAllRecords();
        }
    }, [lines, fetchDailyRecords]);

    const companySettings = user ? companyConfig[user.company] : {};

    const StyledTableHead = styled(TableHead)({
        backgroundColor: companySettings.color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    });


    const handleVerClick = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/incidencias/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setIncidencias(result);
                setOpen(true);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching incidencias:', error);
        }
    };

    const getStatus = (line, day) => {
        const today = new Date();
        if (isBefore(day, today)) {
            const record = records.find(record => record.linha_id === line.id && isSameDay(new Date(record.data_mov), day));
            return record
                ? { status: 'R', color: 'rgba(0, 128, 0, 0.5)' } // Verde más fuerte
                : { status: 'N/R', color: 'rgba(255, 0, 0, 0.5)' }; // Rojo más fuerte
        } else if (isSameDay(day, today)) {
            return { status: 'Wait', color: 'rgba(0, 0, 255, 0.5)' }; // Azul más fuerte
        } else {
            return { status: 'Wait', color: 'rgba(0, 0, 255, 0.3)' }; // Azul más suave
        }
    };

    const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const daysOfWeek = [subDays(startOfWeekDate, 2), ...Array.from({ length: 5 }, (_, i) => addDays(startOfWeekDate, i))];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const columnsIncidencias = [
        { id: 'tarefa', label: t('listatarefas_tarefa') },
        { id: 'motivo', label: t('registoproducao_motivo') },
        { id: 'comentario', label: t('registotarefas_comentario') }
    ];
    
    const dataIncidencias = incidencias.map((incidencia, index) => ({
        tarefa: incidencia.TAREFA,
        motivo: incidencia.MOTIVO,
        comentario: incidencia.COMENTARIOS
    }));
    const columnsRegistros = [
        { id: 'data', label: t('producaoanalise_data'), sortable: true },
        { id: 'linha', label: t('producaoanalise_linha'), sortable: true },
        { id: 'colaborador', label: t('producaoanalise_colaborador'), sortable: true },
        { id: 'frequencia', label: t('listatarefas_frequencia'), sortable: true },
        { id: 'comentarios', label: t('registotarefas_comentarios'), sortable: true },
        { id: 'T_R', label: t('registotarefas_tarefas_realizadas'), sortable: true },
        { id: 'T_NOK', label: t('registotarefas_incidencias'), sortable: true },
        { id: 'detalhe', label: t('registotarefas_detalhe'), sortable: false },
    ];

    const handleSort = (columnId) => {
    if (orderBy === columnId) {
        setOrderDirection((prevDirection) => {
            const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
            return newDirection;
        });
    } else {
        setOrderBy(columnId);
        setOrderDirection('asc');
    }
    };
    
        const recordMatchesSearch = (record, searchTerm) => {
            if (!searchTerm) return true;
            return Object.values(record).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        };
        
        const filteredRecords = data
            .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general
    
    
            const sortedRecords = useMemo(() => {
                if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
                return [...filteredRecords].sort((a, b) => {
                    const aValue = a[orderBy];
                    const bValue = b[orderBy];
            
                    if (aValue === null || aValue === undefined) return 1;
                    if (bValue === null || bValue === undefined) return -1;
            
                    // Si la columna es un número, comparar como números
                    if (typeof aValue === 'number' || !isNaN(aValue)) {
                        return orderDirection === 'asc'
                            ? parseFloat(aValue) - parseFloat(bValue)
                            : parseFloat(bValue) - parseFloat(aValue);
                    }
            
                    // Si la columna es texto, comparar como cadenas
                    if (typeof aValue === 'string') {
                        return orderDirection === 'asc'
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    }
            
                    return 0; // Si no se puede comparar, no cambiar el orden
                });
            }, [filteredRecords, orderBy, orderDirection]);
    
        const paginatedRecords = useMemo(() => {
            return sortedRecords.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );
        }, [sortedRecords, page, rowsPerPage]);

    const dataRegistros = paginatedRecords.map((row, index) => ({
        data: format(new Date(row.dta), 'dd/MM/yyyy'),
        linha: row.linha,
        colaborador: row.colaborador,
        frequencia: row.frequencia,
        comentarios: row.comentarios,
        T_R: row.T_R,
        T_NOK: row.T_NOK,
        detalhe: row.T_NOK > 0 ? (
            <Button variant="contained" color="primary" onClick={() => handleVerClick(row.id)}>
                <FaEye />
            </Button>
        ) : null
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('registotarefas_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                <Table>
                    <StyledTableHead>
                        <TableRow>
                            <TableCell>{t('performanceoperador_linha')}</TableCell>
                            <TableCell>{t('registotarefas_sabado')}</TableCell>
                            <TableCell>{t('registotarefas_segunda')}</TableCell>
                            <TableCell>{t('registotarefas_terca')}</TableCell>
                            <TableCell>{t('registotarefas_quarta')}</TableCell>
                            <TableCell>{t('registotarefas_quinta')}</TableCell>
                            <TableCell>{t('registotarefas_sexta')}</TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {lines.map((line, index) => (
                            <TableRow key={index}>
                                <TableCell>{line.linha}</TableCell>
                                {daysOfWeek.map((day, index) => {
                                    const { status, color } = getStatus(line, day);
                                    return (
                                        <TableCell key={index} sx={{ backgroundColor: color, color: '#ffffff' }}>
                                            {status}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0); // Reinicia la paginación al buscar
                    }}
                    style={{ marginBottom: '20px' }}
                />
            </Box>
            <SimpleTable 
                columns={columnsRegistros} 
                data={dataRegistros}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('registotarefas_incidencias')}</DialogTitle>
                <DialogContent>
                    <SimpleTable columns={columnsIncidencias} data={dataIncidencias} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('controlof_fechar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default RegistoTarefas;