import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Layout from './components/Layout';
import Login from './pages/Login/Login';
import CompanySelection from './pages/CompanySelection/CompanySelection';
import withAuth from './middleware/withAuth';
import ProtectedLoginRoute from './middleware/ProtectedLoginRoute';
import ValidarRegistos from './pages/ValidarRegistos/ValidarRegistos';
import RegistarColab from './pages/Registos/RegistarColab';
import ListarColab from './pages/Registos/ListarColab';
import ControlOF from './pages/Supervisao/ControlOF';
import ProducaoDiaria from './pages/Supervisao/ProducaoDiaria';
import ProducaoAnalise from './pages/Supervisao/ProducaoAnalise';
import ListaTarefas from './pages/Manutencao/ListaTarefas';
import RegistoTarefas from './pages/Manutencao/RegistoTarefas';
import RealizarManutencao from './pages/Manutencao/RealizarManutencao';
import LoteOpp from './pages/Madeira/LoteOpp';
import RoleBasedAccess from './middleware/RoleBassedAccess';
import SessionChecker from './middleware/SessionChecker'; // Importa el componente
import EntradaMadeira from './pages/Madeira/EntradaMadeira';
import HistoricoEntradas from './pages/Madeira/HistoricoEntradas';
import ListaOpp from './pages/Madeira/ListaOpp';
import StockMadeira from './pages/Madeira/StockMadeira';
import ConstrucaoPaletes from './pages/Madeira/ConstrucaoPaletes';
import AvaliacaoColaborador from './pages/Primas/AvaliacaoColaborador';
import PerformanceOperador from './pages/Primas/PerformanceOperador';
import EstatisticasPrimas from './pages/Primas/EstatisticasPrimas';
import ResultadoPrimas from './pages/Primas/ResultadoPrimas';
import EvaluacionLogistica from './pages/Primas/EvaluacionLogistica';
import RegistoAuditoria from './pages/Primas/RegistoAuditoria';
import Validar from './pages/Login/Validar';
import RegistoProducao from './pages/ValidarRegistos/RegistoProducao'
import LanguageSelector from "./components/LanguageSelector"; // Importar el selector de idioma
import User from "./pages/User/User";
import './App.css';

const ProtectedLayoutIBS = withAuth(Layout, 'ibersteel');
const ProtectedLayoutPLF = withAuth(Layout, 'plafesa');

function App() {
  const user = JSON.parse(localStorage.getItem('user'));
  


  return (
    <div className='app'>
      <Router>
      <LanguageSelector /> {/* Añadir el selector de idioma */}
        <SessionChecker>
          <Routes>
            <Route path="/" element={<CompanySelection />} />
            <Route path="/:company/login" element={<ProtectedLoginRoute element={Login} />}/>
            <Route path="/:company/validar" element={<ProtectedLoginRoute element={Validar} />}/>
            <Route path="/ibersteel/home" element={<ProtectedLayoutIBS/>}>
              <Route index element={<Home />} />
            </Route>
            <Route path="/plafesa/home" element={<ProtectedLayoutPLF/>}>
              <Route index element={<Home />} />
            </Route>
            <Route path="/ibersteel/myuser" element={<ProtectedLayoutIBS/>}>
              <Route index element={<User />} />
            </Route>
            <Route path="/plafesa/myuser" element={<ProtectedLayoutPLF/>}>
              <Route index element={<User />} />
            </Route>
            <Route path="/plafesa/rp/validar" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 80]}><ValidarRegistos /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/rp/validar" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 80]}><ValidarRegistos /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/rh/registarcolab" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><RegistarColab /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/rh/registarcolab" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><RegistarColab /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/rh/listarcolab" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><ListarColab /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/rh/listarcolab" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><ListarColab /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/ofcontrol" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 13, 5, 80]}><ControlOF /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/super/ofcontrol" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 13, 5, 80]}><ControlOF /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/producao" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 13, 5, 80]}><ProducaoDiaria /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/super/producao" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 13, 5, 80]}><ProducaoDiaria /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/producaoanalise" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 13, 5, 80]}><ProducaoAnalise /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/super/producaoanalise" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess><ProducaoAnalise /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/listatarefas" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><ListaTarefas /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/registotarefas" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90]}><RegistoTarefas /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/entrada" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 5]}><EntradaMadeira /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/historico" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 5]}><HistoricoEntradas /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/listaopp" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 5]}><ListaOpp /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/stock" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 5]}><StockMadeira /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/construcao" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 5, 3, 13]}><ConstrucaoPaletes /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/primas/avaliacao" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><AvaliacaoColaborador /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/primas/avaliacao" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><AvaliacaoColaborador /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/primas/performance" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><PerformanceOperador /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/primas/performance" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><PerformanceOperador /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/primas/estatisticas" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><EstatisticasPrimas /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/primas/estatisticas" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><EstatisticasPrimas /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/primas/resultados" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><ResultadoPrimas /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/primas/logistica" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><EvaluacionLogistica /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/primas/auditoria" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[100, 90, 8]}><RegistoAuditoria /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/super/realizarmanutencao" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[2, 1, 100, 90]}><RealizarManutencao /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/madeira/loteopp" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[2, 1, 100, 90, 5]}><LoteOpp /></RoleBasedAccess>} />
            </Route>
            <Route path="/plafesa/rp/registopro" element={<ProtectedLayoutPLF/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[2, 100, 90, 80, 13]}><RegistoProducao /></RoleBasedAccess>} />
            </Route>
            <Route path="/ibersteel/rp/registopro" element={<ProtectedLayoutIBS/>}>
              <Route index element={<RoleBasedAccess allowedRoles={[2, 100, 13, 90]}><RegistoProducao /></RoleBasedAccess>} />
            </Route>
            {/* Ruta para manejar rutas desconocidas */}
            <Route path="*" element={
              user ? (
                user.company === 'plafesa' ? <Navigate to="/plafesa/home" /> : <Navigate to="/ibersteel/home" />
              ) : (
                <Navigate to="/" />
              )
            } />
          </Routes>
        </SessionChecker>
      </Router>
    </div>
  );
}

export default App;
