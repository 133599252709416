import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción
import './CompanySelection.css';
import logoIbersteel from '../../utils/logoIbersteel.png';
import logoPlafesa from '../../utils/logoPlafesa.png';
import usericon from '../../utils/UserIcon.png';

function CompanySelection() {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Inicializar el hook de traducción

    const handlecompanySelect = (company) => {
        if (company === 'ibersteel') {
        navigate('/ibersteel/login');
        } else if (company === 'plafesa') {
        navigate('/plafesa/login');
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (user.role === 2) {
                navigate(`/${user.company}/validar`);
            } else {
                localStorage.setItem("turno", true);
                navigate(`/${user.company}/home`);
            }
        }
    }, [navigate]);
    

    return (
    <div className="country-selection-container">
        <img src={usericon} alt="Terra" className="terra" />
        <h1>{t('elegir_empresa')}</h1>
        <div className="country-buttons">
            <button onClick={() => handlecompanySelect('ibersteel')} className="country-button">
            <img src={logoIbersteel} alt="España" className="country-flag" />
            Ibersteel
            </button>
            <button onClick={() => handlecompanySelect('plafesa')} className="country-button">
            <img src={logoPlafesa} alt="Portugal" className="country-flag" />
            Plafesa
            </button>
        </div>
    </div>
    );
}

export default CompanySelection;