import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box, MenuItem, Button, Snackbar, Alert, FormControl, Divider } from '@mui/material';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const AvaliacaoColaborador = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
    const [trimestre, setTrimestre] = useState('');
    const[semestre, setSemestre] = useState('');
    const [colaboradores, setColaboradores] = useState([]);
    const [colaborador, setColaborador] = useState('');
    const [avaliacao, setAvaliacao] = useState({
        flexibilidade: '',
        disponibilidade: '',
    });
    const [avaliacoes, setAvaliacoes] = useState({
        conhecimento: '',
        resolucaoProblemas: '',
        responsabilidade: '',
        cooperacao: '',
        autonomia: '',
        organizacao: ''
    });
    const [mensal, setMensal] = useState({
        pontualidade: {},
        acidentes: {},
        faltas: {},
        fte: {}
    });
    const convertEvaluationValue = (value) => {
        switch (value) {
            case 1:
                return 5;
            case 2:
                return 10;
            case 3:
                return 15;
            case 4:
                return 20;
            default:
                return 0;
        }
    };
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const user = JSON.parse(localStorage.getItem('user'));
    const companySettings = user ? companyConfig[user.company] : {};
    const currentDate = new Date();
    const datahora = currentDate.toISOString();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const semestreMeses = {
        1: [t('avaliacaocolaborador_enero'), t('avaliacaocolaborador_febrero'), t('avaliacaocolaborador_marzo'), t('avaliacaocolaborador_abril'), t('avaliacaocolaborador_mayo'), t('avaliacaocolaborador_junio')],
        2: [t('avaliacaocolaborador_julio'), t('avaliacaocolaborador_agosto'), t('avaliacaocolaborador_septiembre'), t('avaliacaocolaborador_octubre'), t('avaliacaocolaborador_noviembre'), t('avaliacaocolaborador_diciembre')],
    };
    const trimestresMeses = {
        1: [t('avaliacaocolaborador_enero'), t('avaliacaocolaborador_febrero'), t('avaliacaocolaborador_marzo')],
        2: [t('avaliacaocolaborador_abril'), t('avaliacaocolaborador_mayo'), t('avaliacaocolaborador_junio')],
        3: [t('avaliacaocolaborador_julio'), t('avaliacaocolaborador_agosto'), t('avaliacaocolaborador_septiembre')],
        4: [t('avaliacaocolaborador_octubre'), t('avaliacaocolaborador_noviembre'), t('avaliacaocolaborador_diciembre')],
    };

    

    const fetchColaboradores = useCallback(async () => {
        try {
            let response;
            if (user.company === 'plafesa') {
                response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/colaboradoresprimas?ano=${year}&trimestre=${trimestre}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            } else {
                response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/colaboradoresprimas?ano=${year}&semestre=${semestre}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                
            }
            
            const result = await response.json();
            if (Array.isArray(result)) {
                const updatedColaboradores = result.map(colab => ({
                    ...colab,
                    id_perfil: user.company === 'ibersteel' ? colab.id_perfil : undefined
                }));
                setColaboradores(updatedColaboradores);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching colaboradores:', error);
        }
    }, [year, trimestre, user.company, semestre]);

    useEffect(() => {
        if ((year && trimestre) || (year && semestre)) {
            fetchColaboradores();
        }
    }, [year, trimestre, fetchColaboradores, semestre]);

    const handleAvaliacoesChange = (field, value) => {
        setAvaliacoes({ ...avaliacoes, [field]: value });
    };

    const handleMensalChange = (field, mes, value) => {
        setMensal({
            ...mensal,
            [field]: {
                ...mensal[field],
                [mes]: value
            }
        });
    };

    const handleSubmitPlafesa = async () => {
        setFormSubmitted(true);
        // Verificar que todos los campos de mensal estén completos
        const allMensalFieldsFilled = Object.keys(trimestresMeses[trimestre] || {}).every((mes, index) => 
            mensal.acidentes[index + 1] !== '' && mensal.acidentes[index + 1] !== null && mensal.acidentes[index + 1] !== undefined &&
            mensal.faltas[index + 1] !== '' && mensal.faltas[index + 1] !== null && mensal.faltas[index + 1] !== undefined &&
            mensal.fte[index + 1] !== '' && mensal.fte[index + 1] !== null && mensal.fte[index + 1] !== undefined &&
            mensal.pontualidade[index + 1] !== '' && mensal.pontualidade[index + 1] !== null && mensal.pontualidade[index + 1] !== undefined
        );
    
        // Verificar que todos los campos de avaliacoes estén completos
        const allAvaliacoesFieldsFilled = Object.values(avaliacoes).every(value => value !== '' && value !== null && value !== undefined);
    
        if (!year || !trimestre || !colaborador || !allAvaliacoesFieldsFilled || !allMensalFieldsFilled) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
        console.log('Colaborador:', colaborador);
        console.log('n_col:', colaborador.n_col);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registaravaliacao`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    datahora,
                    colaboradormov: user.colaborador,
                    colaborador_id: colaborador.n_col,
                    meses: trimestresMeses[trimestre].map((_, index) => index + 1), // Ajustar según el trimestre
                    trimestre,
                    year,
                    p_conhecimento: convertEvaluationValue(avaliacoes.conhecimento),
                    p_resolucao_probl: convertEvaluationValue(avaliacoes.resolucaoProblemas),
                    p_responsabilidade: convertEvaluationValue(avaliacoes.responsabilidade),
                    p_cooperacao: convertEvaluationValue(avaliacoes.cooperacao),
                    p_autonomia: convertEvaluationValue(avaliacoes.autonomia),
                    p_organizacao: convertEvaluationValue(avaliacoes.organizacao),
                    avaliacao: avaliacoes,
                    pontualidade: mensal.pontualidade,
                    acidentes: mensal.acidentes,
                    faltas: mensal.faltas,
                    fte: mensal.fte
                }),
            });
    
            if (response.ok) {
                setSnackbarMessage(t('avaliacaocolaborador_avaliacao_registrada_corretamente'));
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setYear('');
                setTrimestre('');
                setColaborador('');
                setAvaliacoes({
                    conhecimento: '',
                    resolucaoProblemas: '',
                    responsabilidade: '',
                    cooperacao: '',
                    autonomia: '',
                    organizacao: ''
                });
                setMensal({
                    pontualidade: {},
                    acidentes: {},
                    faltas: {},
                    fte: {}
                });
                setFormSubmitted(false);
            } else {
                const result = await response.json();
                setSnackbarMessage(result.message || t('avaliacaocolaborador_erro_ao_registrar_avaliacao'));
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('avaliacaocolaborador_erro_ao_registrar_avaliacao'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    const handleSubmitIbersteel = async () => {
        setFormSubmitted(true);
        // Verificar que todos los campos de mensal estén completos
        const allMensalFieldsFilled = Object.keys(semestreMeses[semestre] || {}).every((mes, index) => 
            mensal.acidentes[index + 1] !== '' && mensal.acidentes[index + 1] !== null && mensal.acidentes[index + 1] !== undefined &&
            mensal.faltas[index + 1] !== '' && mensal.faltas[index + 1] !== null && mensal.faltas[index + 1] !== undefined &&
            mensal.fte[index + 1] !== '' && mensal.fte[index + 1] !== null && mensal.fte[index + 1] !== undefined
        );

        let allAvaliacoesFieldsFilled = true;
        if (colaborador.id_perfil === 16) {
            allAvaliacoesFieldsFilled = avaliacao.flexibilidade !== '' && avaliacao.flexibilidade !== null && avaliacao.flexibilidade !== undefined &&
                avaliacao.disponibilidade !== '' && avaliacao.disponibilidade !== null && avaliacao.disponibilidade !== undefined;
        }
        if (!year || !semestre || !colaborador || !allMensalFieldsFilled || !allAvaliacoesFieldsFilled) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registaravaliacao`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    n_col: colaborador.n_col,
                    year,
                    meses: semestreMeses[semestre].map((_, index) => index + 1), // Ajustar según el semestre,
                    semestre,
                    acidentes: mensal.acidentes,
                    faltas: mensal.faltas,
                    fte: mensal.fte,
                    flexibilidade: avaliacao.flexibilidade,
                    disponibilidade: avaliacao.disponibilidade,
                    id_perfil: colaborador.id_perfil
                }),
            });
    
            if (response.ok) {
                setSnackbarMessage(t('avaliacaocolaborador_avaliacao_registrada_corretamente'));
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setYear('');
                setSemestre('');
                setColaborador('');
                setAvaliacoes({
                    conhecimento: '',
                    resolucaoProblemas: '',
                    responsabilidade: '',
                    cooperacao: '',
                    autonomia: '',
                    organizacao: ''
                });
                setMensal({
                    pontualidade: {},
                    acidentes: {},
                    faltas: {},
                    fte: {}
                });
                setFormSubmitted(false);
            } else {
                const result = await response.json();
                setSnackbarMessage(result.message || t('avaliacaocolaborador_erro_ao_registrar_avaliacao'));
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('avaliacaocolaborador_erro_ao_registrar_avaliacao'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleYearChange = (event) => {
        setYear('');
        setYear(event.target.value);
        setColaboradores([]);
        setColaborador('');
    };

    const columns = [
        { id: 'label', label: t('avaliacaocolaborador_topico') },
        { id: 'description', label: t('avaliacaocolaborador_descricao') },
        { id: 'nivel1', label: t('avaliacaocolaborador_nivel1') },
        { id: 'nivel2', label: t('avaliacaocolaborador_nivel2') },
        { id: 'nivel3', label: t('avaliacaocolaborador_nivel3') },
        { id: 'nivel4', label: t('avaliacaocolaborador_nivel4') }
    ];

    const data = [
        { label: t('avaliacaocolaborador_conhecimento'), description: t('avaliacaocolaborador_conhecimento_descricao'), 
            nivel1: <Button variant={avaliacoes.conhecimento === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('conhecimento', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.conhecimento === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('conhecimento', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.conhecimento === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('conhecimento', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.conhecimento === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('conhecimento', 4)}>4</Button> 
        },
        { label: t('avaliacaocolaborador_resolucao_problemas'), description: t('avaliacaocolaborador_resolucao_problemas_descricao'), 
            nivel1: <Button variant={avaliacoes.resolucaoProblemas === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('resolucaoProblemas', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.resolucaoProblemas === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('resolucaoProblemas', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.resolucaoProblemas === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('resolucaoProblemas', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.resolucaoProblemas === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('resolucaoProblemas', 4)}>4</Button> 
        },
        { label: t('avaliacaocolaborador_responsabilidade'), description: t('avaliacaocolaborador_responsabilidade_descricao'), 
            nivel1: <Button variant={avaliacoes.responsabilidade === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('responsabilidade', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.responsabilidade === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('responsabilidade', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.responsabilidade === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('responsabilidade', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.responsabilidade === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('responsabilidade', 4)}>4</Button> 
        },
        { label: t('avaliacaocolaborador_cooperacao'), description: t('avaliacaocolaborador_cooperacao_descricao'), 
            nivel1: <Button variant={avaliacoes.cooperacao === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('cooperacao', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.cooperacao === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('cooperacao', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.cooperacao === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('cooperacao', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.cooperacao === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('cooperacao', 4)}>4</Button> 
        },
        { label: t('avaliacaocolaborador_autonomia'), description: t('avaliacaocolaborador_autonomia_descricao'), 
            nivel1: <Button variant={avaliacoes.autonomia === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('autonomia', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.autonomia === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('autonomia', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.autonomia === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('autonomia', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.autonomia === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('autonomia', 4)}>4</Button> 
        },
        { label: t('avaliacaocolaborador_organizacao'), description: t('avaliacaocolaborador_organizacao_descricao'), 
            nivel1: <Button variant={avaliacoes.organizacao === 1 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('organizacao', 1)}>1</Button>, 
            nivel2: <Button variant={avaliacoes.organizacao === 2 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('organizacao', 2)}>2</Button>, 
            nivel3: <Button variant={avaliacoes.organizacao === 3 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('organizacao', 3)}>3</Button>, 
            nivel4: <Button variant={avaliacoes.organizacao === 4 ? 'contained' : 'outlined'} onClick={() => handleAvaliacoesChange('organizacao', 4)}>4</Button> 
        }
    ];

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('avaliacaocolaborador_formulario_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <ValidatedSelect
                        labelId="year-label"
                        value={year}
                        fullWidth
                        onChange={handleYearChange}
                        label="Ano"
                        required
                        formSubmitted={formSubmitted}
                    >
                        {Array.from({ length: 10 }, (_, i) => {
                            const yearOption = new Date().getFullYear() - i;
                            return (
                                <MenuItem key={yearOption} value={yearOption}>
                                    {yearOption}
                                </MenuItem>
                            );
                        })}
                </ValidatedSelect>
                {user.company === 'plafesa' ? (
                    <FormControl fullWidth>
                        <ValidatedSelect
                            label={'Trimestre'}
                            value={trimestre}
                            onChange={(e) => setTrimestre(e.target.value)}
                            required
                            formSubmitted={formSubmitted}
                        >
                            <MenuItem value={1}>{t('avaliacaocolaborador_trimestre_janeiro_marco')}</MenuItem>
                            <MenuItem value={2}>{t('avaliacaocolaborador_trimestre_abril_junho')}</MenuItem>
                            <MenuItem value={3}>{t('avaliacaocolaborador_trimestre_julho_setembro')}</MenuItem>
                            <MenuItem value={4}>{t('avaliacaocolaborador_trimestre_outubro_dezembro')}</MenuItem>
                        </ValidatedSelect>
                    </FormControl>
                ) : (
                    <FormControl fullWidth>
                        <ValidatedSelect
                            label={'Semestre'}
                            value={semestre}
                            onChange={(e) => setSemestre(e.target.value)}
                            required
                            formSubmitted={formSubmitted}
                        >
                            <MenuItem value={1}>{t('avaliacaocolaborador_semestre_1')}</MenuItem>
                            <MenuItem value={2}>{t('avaliacaocolaborador_semestre_2')}</MenuItem>
                        </ValidatedSelect>
                    </FormControl>
                )}
                <FormControl fullWidth>
                <ValidatedSelect
                    label={'Colaborador'}
                    value={colaborador.n_col || ''}
                    onChange={(e) => {
                        const selectedColaborador = colaboradores.find(colab => colab.n_col === e.target.value);
                        setColaborador({
                            n_col: selectedColaborador.n_col,
                            id_perfil: selectedColaborador.id_perfil
                        });
                    }}
                    disabled={!colaboradores.length}
                    required
                    formSubmitted={formSubmitted}
                >
                    {colaboradores.map((colab) => (
                        <MenuItem key={colab.n_col} value={colab.n_col}>
                            {colab.colaborador}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
                </FormControl>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Typography variant="h6" component="h2" gutterBottom>
                {t('avaliacaocolaborador_avaliacao_mensal')}
            </Typography>
            {(user.company === 'plafesa' ? trimestresMeses[trimestre] : semestreMeses[semestre])?.map((mes, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                {user.company === 'plafesa' && ( 
                    <ValidatedTextField
                        label={`${t('avaliacaocolaborador_pontualidade')} (${mes})`}
                        type="number"
                        value={mensal.pontualidade[index + 1] || ''}
                        onChange={(e) => handleMensalChange('pontualidade', index + 1, e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmitted}
                    />
                )}
                <ValidatedTextField
                    label={`${t('avaliacaocolaborador_acidentes')} (${mes})`}
                    type="number"
                    value={mensal.acidentes[index + 1] || ''}
                    onChange={(e) => handleMensalChange('acidentes', index + 1, e.target.value)}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                />
                <ValidatedTextField
                    label={`${t('avaliacaocolaborador_faltas')} (${mes})`}
                    type="number"
                    value={mensal.faltas[index + 1] || ''}
                    onChange={(e) => handleMensalChange('faltas', index + 1, e.target.value)}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                />
                <ValidatedTextField
                    label={`${t('avaliacaocolaborador_fte')} (${mes})`}
                    type="number"
                    value={mensal.fte[index + 1] || ''}
                    onChange={(e) => handleMensalChange('fte', index + 1, e.target.value)}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                />
            </Box>
            ))}
            {user.company === 'ibersteel' && colaborador.id_perfil === 16 && (
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                    <ValidatedTextField
                    label={t('avaliacaocolaborador_flexibilidade_polivalencia')}
                    type="number"
                    value={avaliacao.flexibilidade}
                    onChange={(e) => setAvaliacao({ ...avaliacao, flexibilidade: e.target.value })}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                />
                <ValidatedTextField
                    label={t('avaliacaocolaborador_disponibilidade')}
                    type="number"
                    value={avaliacao.disponibilidade}
                    onChange={(e) => setAvaliacao({ ...avaliacao, disponibilidade: e.target.value })}
                    fullWidth
                    required
                    formSubmitted={formSubmitted}
                />
                </Box>
            )}

            {user.company !== 'ibersteel' && (
                <>
                    <Divider sx={{ marginY: 2 }} />
                    <Typography variant="h6" component="h2" gutterBottom>
                        {t('avaliacaocolaborador_questionario_avaliacao')}
                    </Typography>
                    <SimpleTable columns={columns} data={data} />
                </>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={user.company === 'plafesa' ? handleSubmitPlafesa : handleSubmitIbersteel}
                sx={{ marginTop: 2 }}
            >
                {t('avaliacaocolaborador_enviar')}
            </Button>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AvaliacaoColaborador;