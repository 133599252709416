import React, { useEffect, useState, useCallback } from 'react';
import { format, isBefore, isSameDay, subDays, startOfWeek, addDays } from 'date-fns';
import { Box, Typography, Paper } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

const TarefasPorFazer = ({ user }) => {
    const { t } = useTranslation(); // Hook para traducción
    const [lines, setLines] = useState([]);
    const [records, setRecords] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const fetchLines = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/linhas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setLines(result);
            } else {
                console.error(t('tarefasporfazer_error_array'), result);
            }
        } catch (error) {
            console.error(t('tarefasporfazer_error_fetch_lines'), error);
        }
    }, [user, t]);

    const fetchDailyRecords = useCallback(async (linhaId, date) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registospordia?linhaId=${linhaId}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setRecords(prevRecords => [...prevRecords, ...result]);
            } else {
                console.error(t('tarefasporfazer_error_array'), result);
            }
        } catch (error) {
            console.error(t('tarefasporfazer_error_fetch_records'), error);
        }
    }, [user, t]);

    useEffect(() => {
        if (user) {
            fetchLines();
        }
    }, [user, fetchLines]);

    useEffect(() => {
        if (lines.length > 0) {
            const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
            const daysOfWeek = [subDays(startOfWeekDate, 2), ...Array.from({ length: 5 }, (_, i) => addDays(startOfWeekDate, i + 1))];
            lines.forEach(line => {
                daysOfWeek.forEach(day => {
                    fetchDailyRecords(line.id, format(day, 'yyyy-MM-dd'));
                });
            });
        }
    }, [lines, fetchDailyRecords]);

    useEffect(() => {
        const today = new Date();
        const newNotifications = [];

        lines.forEach(line => {
            const startOfWeekDate = startOfWeek(today, { weekStartsOn: 1 });
            const daysOfWeek = [
                subDays(startOfWeekDate, 2), // Sábado da semana passada
                startOfWeekDate, // Segunda-feira
                addDays(startOfWeekDate, 1), // Terça-feira
                addDays(startOfWeekDate, 2), // Quarta-feira
                addDays(startOfWeekDate, 3), // Quinta-feira
                addDays(startOfWeekDate, 4), // Sexta-feira
            ];

            daysOfWeek.forEach(day => {
                if (isBefore(day, today)) {
                    const record = records.find(record => record.linha_id === line.id && isSameDay(new Date(record.data_mov), day));
                    if (!record) {
                        newNotifications.push(
                            t('tarefasporfazer_notification', {
                                date: format(day, 'dd/MM/yyyy'),
                                line: line.linha,
                            })
                        );
                    }
                }
            });
        });

        setNotifications(newNotifications);
    }, [lines, records, t]);

    return (
        <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
            <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', marginBottom: '16px' }}>
                {t('tarefasporfazer_title')}
            </Typography>
            {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                    <Paper key={index} sx={{ display: 'flex', alignItems: 'center', padding: '8px', marginBottom: '8px', borderRadius: '4px', backgroundColor: '#fff', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}>
                        <WarningIcon sx={{ marginRight: '8px', color: '#ff9800' }} />
                        <Typography className="notification-text" sx={{ flex: 1 }}>
                            {notification}
                        </Typography>
                    </Paper>
                ))
            ) : (
                <Typography>{t('tarefasporfazer_no_notifications')}</Typography>
            )}
        </Box>
    );
};

export default TarefasPorFazer;