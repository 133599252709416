import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid,  Typography, Alert, Card, CardContent, Snackbar, Box, FormControl, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { companyConfig } from '../../utils/config';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const RealizarManutencao = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [user, setUser] = useState(null);
    const [lines, setLines] = useState([]);
    const [selectedLine, setSelectedLine] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [availableTypes, setAvailableTypes] = useState([]);
    const [pendingLines, setPendingLines] = useState([]);
    const [tarefas, setTarefas] = useState([]);
    const [selectedTarefas, setSelectedTarefas] = useState({});
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const checkPendingTasks = useCallback(async (lines) => {
        const pending = [];
        for (const line of lines) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/tarefasporfreq?linhaId=${line.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result) && result.length > 0) {
                pending.push(line);
            }
        }
        setPendingLines(pending);
    }, [user]);

    const fetchLines = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/linhas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                const linesWithTasks = await Promise.all(result.map(async (line) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/tarefasporfreq?linhaId=${line.id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    const tarefasResult = await response.json();
                    return Array.isArray(tarefasResult) && tarefasResult.length > 0 ? line : null;
                }));
                const filteredLines = linesWithTasks.filter(line => line !== null);
                setLines(filteredLines);
                checkPendingTasks(filteredLines);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching lines:', error);
        }
    }, [user, checkPendingTasks]);

    useEffect(() => {
        if (user) {
            fetchLines();
        }
    }, [user, fetchLines]);

    

    const handleLineChange = async (event) => {
        const selectedLineId = event.target.value;
        setSelectedLine(selectedLineId);
        await fetchAvailableTypes(selectedLineId);
    };

    const fetchAvailableTypes = async (lineId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/tarefasporfreq?linhaId=${lineId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setAvailableTypes(result);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching available types:', error);
        }
    };

    const handleVerTarefas = async () => {
        setFormSubmitted(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/tarefas?linhaId=${selectedLine}&frequenciaId=${selectedType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result) && result.length > 0) {
                setTarefas(result);
                setOpen(true);
                setFormSubmitted(false);
            } else {
                setSnackbarMessage(t('realizarmanutencao_linea_sin_tareas'));
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error fetching tarefas:', error);
            setSnackbarMessage(t('realizarmanutencao_error_obtener_tareas'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = async () => {
        
        const newValidationErrors = {};

        if (Object.keys(selectedTarefas).length === 0) {
            setSnackbarMessage(t('realizarmanutencao_seleccionar_tarefa'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        const allTarefasValid = tarefas.every((tarefa) => {
            const selectedTarefa = selectedTarefas[tarefa.ID];
            if (!selectedTarefa || !selectedTarefa.status) {
                newValidationErrors[tarefa.ID] = t('realizarmanutencao_estado_requerido');
                return false;
            }
            if (selectedTarefa.status === 'NOK' && (!selectedTarefa.reason || selectedTarefa.reason.trim() === '')) {
                newValidationErrors[tarefa.ID] = t('realizarmanutencao_motivo_requerido');
                return false;
            }
            return true;
        });

        setValidationErrors(newValidationErrors);
    
        if (!allTarefasValid) {
            setSnackbarMessage(t('realizarmanutencao_tareas_estado_motivo'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        const totalOk = Object.values(selectedTarefas).filter(tarefa => tarefa.status === 'OK').length;
        const totalNok = Object.values(selectedTarefas).filter(tarefa => tarefa.status === 'NOK').length;
        
        const incidencias = Object.entries(selectedTarefas)
            .filter(([id, tarefa]) => tarefa.status === 'NOK')
            .map(([id, tarefa]) => ({ tarefaId: id, motivo: tarefa.reason }));

        const now = new Date();
        const data = {
            data: now.toISOString(),
            ncolaborador: user.colaborador, // Asumiendo que el ID del colaborador está en el objeto user
            linhaId: selectedLine,
            freqId: selectedType,
            totalOk,
            totalNok,
            comentario: comment,
            incidencias,
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registartarefas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data),
            });
    
            const result = await response.json();
            if (response.ok) {
                setSnackbarMessage(t('realizarmanutencao_guardar_exito'));
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setOpen(false);
                // Actualizar la página después de guardar con éxito
                setSelectedLine('');
                fetchLines();
            } else {
                setSnackbarMessage(t('realizarmanutencao_guardar_error'));
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error saving tarefas:', error);
            setSnackbarMessage(t('realizarmanutencao_guardar_error'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const generateAlerts = () => {
        return (
            <Grid container spacing={2}>
                {pendingLines.map((line) => (
                    <Grid item xs={12} sm={6} md={4} key={line.id} marginBottom={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {line.linha}
                                </Typography>
                                <Alert severity="warning">{t('realizarmanutencao_por_fazer')}</Alert>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    };
    const handleSelect = (id, status) => {
        setSelectedTarefas((prev) => ({
            ...prev,
            [id]: { status, reason: prev[id]?.reason || '' },
        }));
    };
    
    const handleReasonChange = (id, reason) => {
        setSelectedTarefas((prev) => ({
            ...prev,
            [id]: { ...prev[id], reason },
        }));
    };

    const companySettings = user ? companyConfig[user.company] : {};
    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('realizarmanutencao_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            {generateAlerts()}
            <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <ValidatedSelect
                    value={selectedLine}
                    label={t('producaoanalise_linha')}
                    onChange={handleLineChange}
                    required
                    formSubmitted={formSubmitted}
                >
                    {lines.map((line) => (
                        <MenuItem key={line.id} value={line.id}>
                            {line.linha}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <ValidatedSelect
                    value={selectedType}
                    label={t('realizarmanutencao_tipo_manutencao')}
                    onChange={(e) => setSelectedType(e.target.value)}
                    disabled={!selectedLine}
                    required
                    formSubmitted={formSubmitted}
                >
                    {availableTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.tipo}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
            </FormControl>
            <Button variant="contained" color="primary" disabled={!selectedLine} onClick={handleVerTarefas}>
                {t('realizarmanutencao_ver_tarefas')}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>{t('navbar_menu_tarefas')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {tarefas.map((tarefa) => (
                            <Grid item xs={12} sm={6} key={tarefa.ID}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: validationErrors[tarefa.ID] ? '2px solid red' : 'none' }}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <img src={`/man/${tarefa.URL}.jpg`} alt={tarefa.TAREFA} style={{ width: '100%', height: 'auto' }} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="h6" component="div">
                                                    {tarefa.TAREFA}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {tarefa.TIPO}
                                                </Typography>
                                                {selectedTarefas[tarefa.ID]?.status === 'NOK' && (
                                                    <TextField
                                                        label={t('registoproducao_motivo')}
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{ marginTop: 2 }}
                                                        value={selectedTarefas[tarefa.ID]?.reason || ''}
                                                        onChange={(e) => handleReasonChange(tarefa.ID, e.target.value)}
                                                        error={!!validationErrors[tarefa.ID]}
                                                        helperText={validationErrors[tarefa.ID]}
                                                    />
                                                )}
                                                {!selectedTarefas[tarefa.ID]?.status && (
                                                    <Typography variant="body2" color="error">
                                                        {validationErrors[tarefa.ID]}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button
                                                variant={selectedTarefas[tarefa.ID]?.status === 'OK' ? 'contained' : 'outlined'}
                                                color="primary"
                                                onClick={() => handleSelect(tarefa.ID, 'OK')}
                                            >
                                                OK
                                            </Button>
                                            <Button
                                                variant={selectedTarefas[tarefa.ID]?.status === 'NOK' ? 'contained' : 'outlined'}
                                                color="secondary"
                                                onClick={() => handleSelect(tarefa.ID, 'NOK')}
                                            >
                                                NOK
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <TextField
                        label={t('realizarmanutencao_comentario')}
                        variant="outlined"
                        fullWidth
                        sx={{ marginRight: 2 }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Button onClick={handleClose} color="primary">
                        {t('controlof_fechar')}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {t('evaluacionlogistica_guardar')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RealizarManutencao;