import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, TablePagination, TextField } from '@mui/material';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ListaTarefas = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchData = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listatarefas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Expected an array but got:', data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [user, data]);

    useEffect(() => {
        if (!data.length) fetchData();
    }, [fetchData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const columns = useMemo(() => [
        { id: 'LINHA', label: t('producaoanalise_linha'), sortable: true },
        { id: 'FREQUENCIA', label: t('listatarefas_frequencia'), sortable: true },
        { id: 'TIPO', label: t('listatarefas_tipo'), sortable: true },
        { id: 'TAREFA', label: t('listatarefas_tarefa'), sortable: true },
        { id: 'SEQUENCIA', label: t('listatarefas_sequencia'), sortable: true },
    ], []);

    const handleSort = (columnId) => {
        if (orderBy === columnId) {
            setOrderDirection((prevDirection) => {
                const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
                return newDirection;
            });
        } else {
            setOrderBy(columnId);
            setOrderDirection('asc');
        }
    };
        
            const recordMatchesSearch = (record, searchTerm) => {
                if (!searchTerm) return true;
                return Object.values(record).some((value) =>
                    value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            };
            
            const filteredRecords = data
                .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general
        
        
                const sortedRecords = useMemo(() => {
                    if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
                    return [...filteredRecords].sort((a, b) => {
                        const aValue = a[orderBy];
                        const bValue = b[orderBy];
                
                        if (aValue === null || aValue === undefined) return 1;
                        if (bValue === null || bValue === undefined) return -1;
                
                        // Si la columna es un número, comparar como números
                        if (typeof aValue === 'number' || !isNaN(aValue)) {
                            return orderDirection === 'asc'
                                ? parseFloat(aValue) - parseFloat(bValue)
                                : parseFloat(bValue) - parseFloat(aValue);
                        }
                
                        // Si la columna es texto, comparar como cadenas
                        if (typeof aValue === 'string') {
                            return orderDirection === 'asc'
                                ? aValue.localeCompare(bValue)
                                : bValue.localeCompare(aValue);
                        }
                
                        return 0; // Si no se puede comparar, no cambiar el orden
                    });
                }, [filteredRecords, orderBy, orderDirection]);
        
            const paginatedRecords = useMemo(() => {
                return sortedRecords.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                );
            }, [sortedRecords, page, rowsPerPage]);
    
    const dataTarefas = paginatedRecords.map((row, index) => ({
        id: row.id,
        LINHA: row.LINHA,
        FREQUENCIA: row.FREQUENCIA,
        TIPO: row.TIPO,
        TAREFA: row.TAREFA,
        SEQUENCIA: row.SEQUENCIA
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('navbar_submenu_manutencion_tareas')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0); // Reinicia la paginación al buscar
                    }}
                    style={{ marginBottom: '20px' }}
                />
            </Box>
            <SimpleTable 
                columns={columns} 
                data={dataTarefas}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Container>
    );
};

export default ListaTarefas;