import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Typography, TextField, Button, Box, TablePagination, Dialog, DialogTitle, Switch, FormControlLabel, DialogContent, DialogActions, Snackbar, Alert, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { companyConfig } from '../../utils/config';
import '../../components/geral.css';
import { FaEdit } from 'react-icons/fa';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import SimpleTable from '../../components/SimpleTable';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ListarColab = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { t } = useTranslation(); // Hook para traducción
    const [colaboradores, setColaboradores] = useState([]);
    const [editColaborador, setEditColaborador] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [user, setUser] = useState(null);
    const [perfil, setPerfil] = useState([]);
    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')

    useEffect(() => {
                const userData = localStorage.getItem('user');
                if (userData) {
                    const parsedUser = JSON.parse(userData);
                    setUser(parsedUser);
                } else {
                    navigate('/');
                }
            }, [navigate]);

    useEffect(() => {
            const fetchPerfil = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/perfil`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    const data = await response.json();
                    setPerfil(data);
                } catch (error) {
                    console.error('Error fetching perfiles:', error);
                }
            };
    
            if (user) {
                fetchPerfil();
            }
        }, [user]);

        const fetchColaboradores = useCallback(async () => {
            if (!user) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listaColaboradores`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                if (data.colaboradores && Array.isArray(data.colaboradores)) {
                    setColaboradores(data.colaboradores);
                } else {
                    console.error('Expected an array but got:', data);
                }
            } catch (error) {
                console.error('Error fetching colaboradores:', error);
            }
        }, [user]);
    
    useEffect(() => {
            fetchColaboradores();
        }, [fetchColaboradores]);

    const handleEdit = (colaborador) => {
        setEditColaborador(colaborador);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSaveEdit = async () => {
        setFormSubmitted(true);
        if (!editColaborador) return;
        const { NOME, APELIDO, PERFIL_ID, PASSWORD, ACESSO, BLOQUEADO, ATIVO } = editColaborador;
        const errors = {};

        // Validar campos requeridos
        if (!NOME) errors.NOME = true;
        if (!APELIDO) errors.APELIDO = true;
        if (!PERFIL_ID) errors.PERFIL_ID = true;
        if (ACESSO === 1 && !PASSWORD) errors.PASSWORD = true; // Solo validar contraseña si ACESSO es 1

        if (Object.keys(errors).length > 0) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        // Concatenar NOME y APELIDO para crear COLAB
        const COLAB = `${NOME} ${APELIDO}`;

        // Crear el objeto que se enviará al backend
        const colaboradorActualizado = {
            ...editColaborador,
            COLAB, // Añadir el campo COLAB
            ACESSO, // Asegurarse de incluir ACESSO
            BLOQUEADO, // Incluir el estado de bloqueado
            ATIVO, // Incluir el estado de activo
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/editarColaborador/${editColaborador.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(colaboradorActualizado),
                credentials: 'include',
            });

            if (response.ok) {
                setOpenSnackbar(true);
                fetchColaboradores();  // Actualiza la lista de colaboradores
                setSnackbarMessage(t('listarcolab_colaborador_editado_correctamente'));
                setSnackbarSeverity('success');
                setEditColaborador(null);
                setFormSubmitted(false);
            } else {
                console.error('Error editing colaborador');
                setOpenSnackbar(true);
                setSnackbarMessage(t('listarcolab_error_editar_colaborador'));
                setSnackbarSeverity('error');
                setFormSubmitted(false);
            }
        } catch (error) {
            console.error('Error editing colaborador:', error);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const companySettings = user ? companyConfig[user.company] : {};

    

    

    const columns = useMemo(() => [
        { id: 'id', label: t('listarcolab_id'), sortable: true },
        { id: 'colaborador', label: t('listarcolab_colaborador'), sortable: true },
        { id: 'N_COLABORADOR', label: t('listarcolab_numero_colaborador'), sortable: true },
        { id: 'ACESSO', label: t('listarcolab_acesso'), sortable: true },
        { id: 'ATIVO', label: t('listarcolab_ativo'), sortable: true },
        { id: 'BLOQUEADO', label: t('listarcolab_bloqueado'), sortable: true },
        { id: 'PERFIL', label: t('listarcolab_perfil'), sortable: true },
        { id: 'acoes', label: t('listarcolab_acoes'), sortable: false },
    ], []);

    const handleSort = (columnId) => {
        if (orderBy === columnId) {
            setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            setOrderBy(columnId);
            setOrderDirection('asc');
        }
    };

    const colaboradorMatchesSearch = (colaborador, searchTerm) => {
        if (!searchTerm) return true;
        return Object.values(colaborador).some((value) =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const filteredData = colaboradores
        .filter((colaborador) => colaboradorMatchesSearch(colaborador, searchTerm));
    

        const sortedData = useMemo(() => {
            if (!orderBy) return filteredData;
            return [...filteredData].sort((a, b) => {
                const aValue = orderBy === 'colaborador' ? a.NOME : a[orderBy];
                const bValue = orderBy === 'colaborador' ? b.NOME : b[orderBy];
        
                if (aValue === null || aValue === undefined) return 1;
                if (bValue === null || bValue === undefined) return -1;
        
                if (typeof aValue === 'number' || !isNaN(aValue)) {
                    return orderDirection === 'asc'
                        ? parseFloat(aValue) - parseFloat(bValue)
                        : parseFloat(bValue) - parseFloat(aValue);
                }
        
                if (typeof aValue === 'string') {
                    return orderDirection === 'asc'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }
        
                return 0;
            });
        }, [filteredData, orderBy, orderDirection]);

    const paginatedData = useMemo(() => {
        return sortedData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [sortedData, page, rowsPerPage]);

    

    const data = paginatedData.map((colaborador) => ({
        id: colaborador.id,
        colaborador: `${colaborador.NOME} ${colaborador.APELIDO}`, // Concatenado solo para mostrar
        N_COLABORADOR: colaborador.N_COLABORADOR,
        ACESSO: colaborador.ACESSO,
        ATIVO: colaborador.ATIVO,
        BLOQUEADO: colaborador.BLOQUEADO,
        PERFIL: colaborador.PERFIL,
        acoes: (
            <Button variant="contained" color="warning" onClick={() => handleEdit(colaborador)} sx={{ marginRight: 1 }}>
                <FaEdit />
            </Button>
        )
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                {t('listarcolab_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <TextField
                label={t('estatisticasprimas_buscar')}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(0); // Reinicia la paginación al buscar
                }}
                style={{ marginBottom: '20px' }}
            />
            </Box>
            <SimpleTable
                columns={columns}
                data={data}
                orderBy={orderBy}
                orderDirection={orderDirection}
                onSort={handleSort}
            />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sortedData.length} // Total de registros después de filtrar y ordenar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {editColaborador && (
                <Dialog open={Boolean(editColaborador)} onClose={() => setEditColaborador(null)}>
                    <DialogTitle>{t('listarcolab_editar_colaborador')}</DialogTitle>
                    <DialogContent>
                        <ValidatedTextField
                            margin="dense"
                            label={t('listarcolab_nome')}
                            type="text"
                            fullWidth
                            name='NOME'
                            value={editColaborador.NOME}
                            onChange={(e) => setEditColaborador({ ...editColaborador, NOME: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('listarcolab_apelido')}
                            type="text"
                            fullWidth
                            name='APELIDO'
                            value={editColaborador.APELIDO}
                            onChange={(e) => setEditColaborador({ ...editColaborador, APELIDO: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <ValidatedSelect
                            label={t('listarcolab_perfil')}
                            value={editColaborador.PERFIL_ID}
                            onChange={(e) => setEditColaborador({ ...editColaborador, PERFIL_ID: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        >
                            {perfil.map((perfil) => (
                                <MenuItem key={perfil.id} value={perfil.id}>
                                    {perfil.perfil}
                                </MenuItem>
                            ))}
                        </ValidatedSelect>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editColaborador.ACESSO === 1}
                                    onChange={(e) =>
                                        setEditColaborador({ ...editColaborador, ACESSO: e.target.checked ? 1 : 0 })
                                    }
                                    color="primary"
                                />
                            }
                            label={t('listarcolab_acesso_plataforma')}
                        />
                        {editColaborador.ACESSO === 1 && (
                            <ValidatedTextField
                                margin="dense"
                                label={t('listarcolab_password')}
                                type="password"
                                fullWidth
                                name="PASSWORD"
                                value={editColaborador.PASSWORD}
                                onChange={(e) => setEditColaborador({ ...editColaborador, PASSWORD: e.target.value })}
                                required
                                formSubmitted={formSubmitted}
                            />
                        )}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editColaborador.BLOQUEADO === 1}
                                    onChange={(e) =>
                                        setEditColaborador({ ...editColaborador, BLOQUEADO: e.target.checked ? 1 : 0 })
                                    }
                                    color="secondary"
                                />
                            }
                            label={t('listarcolab_bloqueado')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editColaborador.ATIVO === 1}
                                    onChange={(e) =>
                                        setEditColaborador({ ...editColaborador, ATIVO: e.target.checked ? 1 : 0 })
                                    }
                                    color="success"
                                />
                            }
                            label={t('listarcolab_ativo')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditColaborador(null)} color="primary">
                            {t('evaluacionlogistica_cancelar')}
                        </Button>
                        <Button onClick={handleSaveEdit} color="primary">
                            {t('evaluacionlogistica_guardar')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ListarColab;