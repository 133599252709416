import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (Component, company) => {
    return (props) => {
        const [isAuthenticated, setIsAuthenticated] = useState(false);
        const navigate = useNavigate();


        useEffect(() => {
            const checkSession = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${company}/api/check-session`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                    const text = await response.text();
                    const data = JSON.parse(text);
                    if (data.isAuthenticated) {
                        setIsAuthenticated(true);

                        // Verificar si el usuario tiene un turno válido
                        const turnoValido = localStorage.getItem('turno');
                        if (!turnoValido || turnoValido !== 'true') {
                            navigate(`/${data.user.company}/validar`); // Redirigir a la página de validación si no tiene turno válido
                            return;
                        }

                        if (data.user.company !== company) {
                            navigate(`/${data.user.company}/home`);
                        }
                    } else {
                        localStorage.removeItem('turno'); // Limpiar el turno si no está autenticado
                        localStorage.removeItem('user')
                        navigate('/');
                    }
                } catch (error) {
                    localStorage.removeItem('turno'); // Limpiar el turno si no está autenticado
                    localStorage.removeItem('user')
                    navigate('/');
                }
            };

            checkSession();
        }, [navigate]);

        if (!isAuthenticated) {
            return null; // O un spinner de carga, si prefieres
        }


        return <Component {...props} />;
    };
};

export default withAuth;