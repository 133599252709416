import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Typography, Box, MenuItem, Button, Snackbar, Alert, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import ValidatedTextField from '../../components/ValidatedTextField';
import ValidatedSelect from '../../components/ValidatedSelect';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const EvaluacionLogistica = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
    const [semestre, setSemestre] = useState('');
    const [mensal, setMensal] = useState({
        nCamionesCargados: {},
        nBobinasRecibidas: {},
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const user = JSON.parse(localStorage.getItem('user'));
    const companySettings = user ? companyConfig[user.company] : {}
    const semestreMeses = useMemo(() => ({
        1: [t('avaliacaocolaborador_enero'), t('avaliacaocolaborador_febrero'), t('avaliacaocolaborador_marzo'), t('avaliacaocolaborador_abril'), t('avaliacaocolaborador_mayo'), t('avaliacaocolaborador_junio')],
        2: [t('avaliacaocolaborador_julio'), t('avaliacaocolaborador_agosto'), t('avaliacaocolaborador_septiembre'), t('avaliacaocolaborador_octubre'), t('avaliacaocolaborador_noviembre'), t('avaliacaocolaborador_diciembre')],
    }), []);
    const [procura, setProcura] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [formSubmittedA, setFormSubmittedA] = useState(false);


    const fetchStatsLogistica = useCallback(async (ano, mes) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslogistica2?ano=${ano}&mes=${mes}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data; // Devuelve los datos obtenidos
        } catch (error) {
            console.error(`Error fetching stats for year ${ano}, month ${mes}:`, error);
            return null; // Devuelve null en caso de error
        }
    }, [user]);

    const fetchEvaluation = useCallback(async () => {
        if (!user || !year || !semestre) return;
    
        try {
            const meses = semestreMeses[semestre];
            const results = await Promise.all(
                meses.map(async (mes, index) => {
                    // Ajustar el número del mes según el semestre
                    const mesNumero = semestre === 1 ? index + 1 : index + 7; // 1º semestre: 1-6, 2º semestre: 7-12
                    const data = await fetchStatsLogistica(year, mesNumero); // Llama a la API con el número correcto del mes
                    if (data && data.length > 0) {
                        // Si hay datos para el mes, usa los valores devueltos
                        return {
                            mes,
                            nCamionesCargados: data[0]?.n_camiones || 'N/A',
                            nBobinasRecibidas: data[0]?.n_bobinas || 'N/A',
                        };
                    } else {
                        // Si no hay datos, usa valores predeterminados
                        return {
                            mes,
                            nCamionesCargados: 'N/A',
                            nBobinasRecibidas: 'N/A',
                        };
                    }
                })
            );
            setEvaluaciones(results); // Guarda los datos obtenidos en el estado
            setProcura(false);
        } catch (error) {
            console.error('Error fetching evaluations:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('login_server_error'));
            setSnackbarOpen(true);
        }
    }, [user, year, semestre, semestreMeses, fetchStatsLogistica]);

    const handleSubmit = async () => {
        setFormSubmitted(true);
        const allMensalFieldsFilled = Object.keys(semestreMeses[semestre] || {}).every((mes, index) => 
            mensal.nCamionesCargados[index + 1] !== '' && mensal.nCamionesCargados[index + 1] !== null && mensal.nCamionesCargados[index + 1] !== undefined &&
            mensal.nBobinasRecibidas[index + 1] !== '' && mensal.nBobinasRecibidas[index + 1] !== null && mensal.nBobinasRecibidas[index + 1] !== undefined 
        );
        if (!year || !semestre || !allMensalFieldsFilled) {
            setSnackbarSeverity('error');
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarOpen(true);
            return; 
        }

        if (!user) return;
        try {
            const nCamiones = Object.fromEntries(
                semestreMeses[semestre].map((_, index) => {
                    const mesNumero = semestre === 1 ? index + 1 : index + 7;
                    return [mesNumero, Number(mensal.nCamionesCargados[index + 1]) || 0];
                })
            );
            
            const nBobinas = Object.fromEntries(
                semestreMeses[semestre].map((_, index) => {
                    const mesNumero = semestre === 1 ? index + 1 : index + 7;
                    return [mesNumero, Number(mensal.nBobinasRecibidas[index + 1]) || 0];
                })
            );

            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarstatslogistica`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    ano: year,
                    meses: semestreMeses[semestre].map((_, index) => 
                        semestre === 1 ? index + 1 : index + 7
                    ),
                    nCamiones,
                    nBobinas,
                }),
            });
            const data = await response.json();
            if (data.success) {
                setSnackbarSeverity('success');
                setSnackbarMessage(data.message);
                setProcura(true);
                setFormSubmitted(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || t('avaliacaocolaborador_erro_ao_registrar_avaliacao'));
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('login_server_error'));
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
        setSemestre('');
        setMensal({ nCamionesCargados: {}, nBobinasRecibidas: {} });
        setEvaluaciones([]);
        setProcura(false);
    };

    const handleSemestreChange = (event) => {
        setSemestre(event.target.value);
        setMensal({ nCamionesCargados: {}, nBobinasRecibidas: {} });
        setEvaluaciones([]);
        setProcura(true);
    };

    const handleMensalChange = (field, mes, value) => {
        setMensal({
            ...mensal,
            [field]: {
                ...mensal[field],
                [mes]: value
            }
        });
    };
    const handleEdit = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };
    
    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };
    
    const handleDialogSave = async () => {
        setFormSubmittedA(true);
        if (!selectedRow || !selectedRow.nCamionesCargados || !selectedRow.nBobinasRecibidas) {
            setSnackbarSeverity('error');
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarOpen(true);
            return;
        }
        if (!user) return;
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/editarstatslogistica`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    ano: year,
                    mes: semestreMeses[semestre].indexOf(selectedRow.mes) + 1, // Obtener el número del mes
                    nCamiones: selectedRow.nCamionesCargados,
                    nBobinas: selectedRow.nBobinasRecibidas,
                }),
            });
    
            const data = await response.json();
            if (data.success) {
                setSnackbarSeverity('success');
                setSnackbarMessage(data.message);
                fetchEvaluation(); // Refrescar los datos después de editar
                setFormSubmittedA(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || t('evaluacionlogistica_error_editar_evaluacion'));
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('login_server_error'));
            setSnackbarOpen(true);
        }
    
        setOpenDialog(false);
    };

    const columns = [
        { id: 'mes', label: t('estatisticasprimas_mes') },
        { id: 'nCamionesCargados', label: t('evaluacionlogistica_camiones_cargados') },
        { id: 'nBobinasRecibidas', label: t('evaluacionlogistica_bobinas_recibidas') },
        { id: 'actions', label: t('evaluacionlogistica_acciones') },
    ];

    const data = evaluaciones.map((evaluacion) => ({
        ...evaluacion,
        actions: (
            <Button
                variant="contained"
                color="warning"
                onClick={() => handleEdit(evaluacion)}
            > <FaEdit />
            </Button>
        ),
    }));


    useEffect(() => {
        if (year && semestre && procura) {
            fetchEvaluation();
        }
    }, [fetchEvaluation, year, semestre, procura]);


    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('evaluacionlogistica_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <ValidatedSelect
                        value={year}
                        fullWidth
                        onChange={handleYearChange}
                        label={t('estatisticasprimas_ano')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        {Array.from({ length: 10 }, (_, i) => {
                            const yearOption = new Date().getFullYear() - i;
                            return (
                                <MenuItem key={yearOption} value={yearOption}>
                                    {yearOption}
                                </MenuItem>
                            );
                        })}
                </ValidatedSelect>
                <ValidatedSelect
                    label={t('avaliacaocolaborador_semestre')}
                    value={semestre}
                    onChange={handleSemestreChange}
                    required
                    formSubmitted={formSubmitted}
                >
                    <MenuItem value={1}>{t('avaliacaocolaborador_semestre_1')}</MenuItem>
                    <MenuItem value={2}>{t('avaliacaocolaborador_semestre_2')}</MenuItem>
                </ValidatedSelect>
            </Box>
            {/* Mostrar el resto del contenido solo si year y semestre están seleccionados */}
        {year && semestre ? (
            evaluaciones.every(
                (evaluacion) =>
                    evaluacion.nCamionesCargados === 'N/A' &&
                    evaluacion.nBobinasRecibidas === 'N/A'
            ) ? (
                // Mostrar inputs si todos los valores son 'N/A'
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 4,
                            marginTop: 4,
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginRight: 2,
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{ fontWeight: 'bold', color: companySettings.color }}
                        >
                            {t('evaluacionlogistica_ingresar_evaluacion')}
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginLeft: 2,
                            }}
                        />
                    </Box>
                    {semestreMeses[semestre]?.map((mes, index) => (
                        <Box
                            key={index}
                            sx={{ display: 'flex', gap: 2, marginBottom: 2 }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    minWidth: '120px',
                                    fontWeight: 'bold',
                                    alignSelf: 'center',
                                }}
                            >
                                {mes}
                            </Typography>
                            <ValidatedTextField
                                label={t('evaluacionlogistica_camiones_cargados')}
                                type="number"
                                value={
                                    mensal.nCamionesCargados[index + 1] || ''
                                }
                                onChange={(e) =>
                                    handleMensalChange(
                                        'nCamionesCargados',
                                        index + 1,
                                        e.target.value
                                    )
                                }
                                fullWidth
                                required
                                formSubmitted={formSubmitted}
                            />
                            <ValidatedTextField
                                label={t('evaluacionlogistica_bobinas_recibidas')}
                                type="number"
                                value={
                                    mensal.nBobinasRecibidas[index + 1] || ''
                                }
                                onChange={(e) =>
                                    handleMensalChange(
                                        'nBobinasRecibidas',
                                        index + 1,
                                        e.target.value
                                    )
                                }
                                fullWidth
                                required
                                formSubmitted={formSubmitted}
                            />
                        </Box>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 4,
                            marginBottom: 4,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('evaluacionlogistica_guardar_evaluacion')}
                        </Button>
                    </Box>
                </Box>
            ) : (
                // Mostrar registros existentes si hay datos válidos
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 4,
                            marginTop: 4,
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginRight: 2,
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{ fontWeight: 'bold', color: companySettings.color }}
                        >
                            {t('evaluacionlogistica_registros_existentes')} {year} - {semestre}
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                height: 2,
                                backgroundColor: companySettings.color,
                                marginLeft: 2,
                            }}
                        />
                    </Box>
                    <SimpleTable columns={columns} data={data} />
                </Box>
            )
        ) : null}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>{t('evaluacionlogistica_editar_evaluacion')}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        margin="dense"
                        label={t('evaluacionlogistica_camiones_cargados')}
                        type="number"
                        value={selectedRow?.nCamionesCargados || ''}
                        onChange={(e) =>
                            setSelectedRow((prev) => ({
                                ...prev,
                                nCamionesCargados: e.target.value,
                            }))
                        }
                        fullWidth
                        required
                        formSubmitted={formSubmittedA}
                    />
                    <ValidatedTextField
                        margin="dense"
                        label={t('evaluacionlogistica_bobinas_recibidas')}
                        type="number"
                        value={selectedRow?.nBobinasRecibidas || ''}
                        onChange={(e) =>
                            setSelectedRow((prev) => ({
                                ...prev,
                                nBobinasRecibidas: e.target.value,
                            }))
                        }
                        fullWidth
                        required
                        formSubmitted={formSubmittedA}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        {t('evaluacionlogistica_cancelar')}
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        {t('evaluacionlogistica_guardar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default EvaluacionLogistica;