import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.css"; // Archivo CSS para estilos
import spainFlag from "../utils/spainflag.jpg"; // Ruta de la bandera de España
import portugalFlag from "../utils/portugalflag.jpg"; // Ruta de la bandera de Portugal
import englishFlag from "../utils/englishflag.jpg"; // Ruta de la bandera de Inglaterra o EE.UU.

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        if (i18n.language !== language) { // Verifica si el idioma es diferente
            i18n.changeLanguage(language); // Cambia el idioma
            window.location.reload(); // Recarga la página
        }
    };

    return (
        <div className="language-selector">
        <img
            src={spainFlag}
            alt="Español"
            className="language-flag"
            onClick={() => changeLanguage("es")}
        />
        <img
            src={portugalFlag}
            alt="Português"
            className="language-flag"
            onClick={() => changeLanguage("pt")}
        />
        <img
            src={englishFlag} // Ruta de la bandera de Inglaterra o EE.UU.
            alt="English"
            className="language-flag"
            onClick={() => changeLanguage("en")}
        />
        </div>
    );
};

export default LanguageSelector;