import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, CircularProgress, Box, Button, Snackbar, Alert, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SimpleTable from '../../components/SimpleTable';
import { companyConfig } from '../../utils/config';
import { round } from 'lodash';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const PerformanceOperador = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
    const [quarter, setQuarter] = useState('');
    const [semestre, setSemestre] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [colaboradores, setColaboradores] = useState([]);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetchDataTrigger, SetFetchDataTrigger] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchColaboradores = useCallback(async () => {
        if (!user) return;
        setColaboradores([]);
        const perfil = 2;
        try {
            let response;
            if (user.company === 'plafesa') {
                response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performancecolab?trimestre=${quarter}&ano=${year}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            } else {
                response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performancestatsop?semestre=${semestre}&ano=${year}&perfil=${perfil}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            }

            if (!response.ok) {
                console.error('Error fetching data:', response);
            }

            const results = await response.json();

            if (results.length === 0) {
                setColaboradores([]);
                setSnackbarMessage(t('estatisticasprimas_no_se_encontraron_registros'));
                setSnackbarSeverity('info');
                setOpenSnackbar(true);
                return;
            }
            // Verifica que results sea un array
            if (Array.isArray(results)) {
                setColaboradores(results);
            } else {
                setColaboradores([]);
            }
        
        } catch (error) {
            console.error('Error fetching data:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    }, [year, quarter, user, semestre]);

    

    const OperadorAuxiliar = useCallback(async (ano, mes, colaborador) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceop?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            
            if (!response.ok) {
                console.error('Error fetching data:', response);
            }
            const operador = await response.json();
            
            if ((!operador || operador.linha === null || operador.linha === 0 || !operador[0]) && user.company === 'plafesa') {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceaux?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
                const auxiliar = await response.json();
                if (!auxiliar || auxiliar.linha === null || auxiliar.linha === 0 || !auxiliar[0]) { 
                    return { linha: 0, ton: 0, qt_turnos: 0, tempo_corte: 0, qt_of: 0, fases_cortadas: 0, lotes_cortados: 0, qt_bob: 0, total_bandas: 0, funcao: 0 };
                } else {
                    return { ...auxiliar[0], funcao: 'AUX' };
                }
            } else if (operador.length === 0) {
                return { linha: 0, ton: 0, qt_turnos: 0, tempo_corte: 0, qt_of: 0, fases_cortadas: 0, lotes_cortados: 0, qt_bob: 0, total_bandas: 0, funcao: 0 };
            }
            else {
                return { ...operador[0], funcao: 'OP' };
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user]);



    const getStats = useCallback(async (ano, mes, linha) => {
        try {
            
            let statsLinhaResponse = null;
            if (linha !== 0) {
                statsLinhaResponse = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslinha?mes=${mes}&ano=${ano}&linha=${linha}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            }

            const [statsFabricaResponse, objFabricaResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statsfabrica?mes=${mes}&ano=${ano}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                }),
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/objfabrica?mes=${mes}&ano=${ano}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                })
            ]);

            if (!statsFabricaResponse.ok || !objFabricaResponse.ok) {
                throw new Error('Error fetching stats');
            }

            if (statsLinhaResponse && statsLinhaResponse.ok) {
                const [statsLinha, statsFabrica, objFabrica] = await Promise.all([
                    statsLinhaResponse.json(),
                    statsFabricaResponse.json(),
                    objFabricaResponse.json()
                ]);

                const combinedStats = {
                    ...statsLinha[0],
                    ...statsFabrica[0],
                    ...objFabrica[0]
                };
                return combinedStats;
            } else {
                const [ statsFabrica, objFabrica] = await Promise.all([
                    statsFabricaResponse.json(),
                    objFabricaResponse.json()
                ]);
                const combinedStats = {
                    ...statsFabrica[0],
                    ...objFabrica[0]
                };
                return combinedStats;
            }
            
        } catch (error) {
            console.error('Error fetching stats:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user]);

    const fetchData = useCallback(async () => {
        const updatedColaboradores = await Promise.all(colaboradores.map(async (colaborador) => {
            const { numero: n_col , mes_avaliacao: mes} = colaborador;
            const operadorAuxiliar = await OperadorAuxiliar(year, mes, n_col);
            if (!operadorAuxiliar) return null;
            const stats = await getStats(year, mes, operadorAuxiliar.linha);
            if (!stats) return null;
            // Realiza tus cálculos aquí
            if (operadorAuxiliar.linha !== 0) {
                const calculos = {
                    medFasesBob: round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1),
                    realTonTotalMes: round(operadorAuxiliar.ton - (operadorAuxiliar.ton * 0.03)),
                    medBobinesTurno: round(operadorAuxiliar.qt_bob / operadorAuxiliar.qt_turnos, 1),
                    medTonMes: round(stats.obj_ton / round(stats.real_n_turnos / stats.dias_uteis_mes, 1)),
                    ponderacaoBob: 0,
                    ponderacaoLotesFases: 0,
                    produtividadeFabrica: round((stats.real_ton_fabrica / stats.obj_ton_fabrica) * 100),
                    ponderacaoFabrica: 0,
                    produtividadeLinha: round((stats.real_ton_linha / stats.obj_ton) * 100),
                    ponderacaoLinha: 0,
                    produtividadeIndividual: 0,
                    ponderacaoIndividual: 0,
                    total: 0,
                    ponderacaoTotal: 0
                };
                calculos.ponderacaoBob = calculos.medBobinesTurno / stats.media_bobines_turno;
                // Aplica la lógica de ponderación
                if (calculos.ponderacaoBob < 0.5) {
                    calculos.ponderacaoBob = 0;
                } else if (calculos.ponderacaoBob < 0.8) {
                    calculos.ponderacaoBob = 0.5;
                } else if (calculos.ponderacaoBob < 1) {
                    calculos.ponderacaoBob = 0.8;
                } else if (calculos.ponderacaoBob < 1.1) {
                    calculos.ponderacaoBob = 1;
                } else if (calculos.ponderacaoBob < 1.5) {
                    calculos.ponderacaoBob = 1.1;
                } else {
                    calculos.ponderacaoBob = 1.2;
                }
                // Aplica la lógica de ponderación para ponderacaoLotesFases
                if (operadorAuxiliar.linha === 'CT1' ||  (user.company === 'plafesa' ? operadorAuxiliar.linha === 'CT2' : operadorAuxiliar.linha === 'CT4') || operadorAuxiliar.linha === 'CT5') {
                    calculos.media_fases_bobine_real = round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1);
                    calculos.ponderacaoLotesFases = calculos.media_fases_bobine_real / stats.media_fases_bobine;
                } else if (operadorAuxiliar.linha === 'CT3' || (user.company === 'plafesa' ? operadorAuxiliar.linha === 'CT4' : operadorAuxiliar.linha === 'CT2')) {
                    calculos.media_paquetes_turno = round(operadorAuxiliar.lotes_cortados / operadorAuxiliar.qt_turnos, 1);
                    calculos.ponderacaoLotesFases = calculos.media_paquetes_turno / stats.media_lotes_turno;
                }
                if (calculos.ponderacaoLotesFases < 0.5) {
                    calculos.ponderacaoLotesFases = 0;
                } else if (calculos.ponderacaoLotesFases < 0.8) {
                    calculos.ponderacaoLotesFases = 0.5;
                } else if (calculos.ponderacaoLotesFases < 1) {
                    calculos.ponderacaoLotesFases = 0.8;
                } else if (calculos.ponderacaoLotesFases < 1.1) {
                    calculos.ponderacaoLotesFases = 1;
                } else if (calculos.ponderacaoLotesFases < 1.5) {
                    calculos.ponderacaoLotesFases = 1.1;
                } else {
                    calculos.ponderacaoLotesFases = 1.2;
                }
                calculos.ponderacaoFabrica = round(calculos.produtividadeFabrica * 0.2);
                if (calculos.ponderacaoFabrica > 20) {
                    calculos.ponderacaoFabrica = 20;
                }
                calculos.ponderacaoLinha = round(calculos.produtividadeLinha * 0.5);
                if (calculos.ponderacaoLinha > 50) {
                    calculos.ponderacaoLinha = 50;
                }
                calculos.produtividadeIndividual = round((((calculos.realTonTotalMes * calculos.ponderacaoBob * calculos.ponderacaoLotesFases) / operadorAuxiliar.qt_turnos) / (stats.real_ton_linha / stats.real_n_turnos)) * 100);
                calculos.ponderacaoIndividual = round(calculos.produtividadeIndividual * 0.3);
                if (calculos.ponderacaoIndividual > 30) {
                    calculos.ponderacaoIndividual = 30;
                }
                calculos.total = calculos.ponderacaoFabrica + calculos.ponderacaoLinha + calculos.ponderacaoIndividual;
                calculos.ponderacaoTotal = round(calculos.total * 0.7);
                if (calculos.ponderacaoTotal > 70) {
                    calculos.ponderacaoTotal = 70;
                }
                return { ...colaborador, operadorAuxiliar, stats, calculos };
            } else {
                const calculos = {
                    realTonTotalMes: 0,
                    produtividadeIndividual: 0,
                    produdutividadeLinha: 0,
                    produtividadeFabrica: round((stats.real_ton_fabrica / stats.obj_ton_fabrica) * 100),
                    ponderacaoFabrica: 0,
                    ponderacaoLinha: 0,
                    ponderacaoTotal: 0,
                    ponderacaoIndividual: 0,
                    total: 0
                }
                calculos.ponderacaoFabrica = round(calculos.produtividadeFabrica * 0.2);
                if (calculos.ponderacaoFabrica > 20) {
                    calculos.ponderacaoFabrica = 20;
                }
                calculos.total = calculos.ponderacaoFabrica + calculos.ponderacaoLinha + calculos.ponderacaoIndividual;
                calculos.ponderacaoTotal = round(calculos.total * 0.7);
                if (calculos.ponderacaoTotal > 70) {
                    calculos.ponderacaoTotal = 70;
                }
                return { ...colaborador, operadorAuxiliar, stats, calculos };
            }
        
            }));
        setColaboradores(updatedColaboradores.filter(result => result !== null));
        setLoading(false);
    }, [ colaboradores, OperadorAuxiliar, getStats, year, user]);

    useEffect(() => {
        if (loading && colaboradores.length > 0 && fetchDataTrigger) { 
            fetchData();
            SetFetchDataTrigger(false);
        }
    }, [fetchColaboradores, fetchData, loading, colaboradores, fetchDataTrigger]);

    const handleYearChange = (event) => {
        setYear('');
        setYear(event.target.value);
    };

    const handleQuarterChange = (event) => {
        setQuarter('');
        setQuarter(event.target.value);
    };

    const handleSemestreChange = (event) => {
        setSemestre('');
        setSemestre(event.target.value);
    };

    const handleSearch = async () => {
        setFormSubmitted(true);
        setLoading(true);
        setColaboradores([]);
        await fetchColaboradores();
        SetFetchDataTrigger(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const columns = [
        { id: 'colaborador', label: t('performanceoperador_colaborador') },
        { id: 'mes', label: t('performanceoperador_mes') },
        { id: 'linha', label: t('performanceoperador_linha') },
        { id: 'funcao', label: t('performanceoperador_funcao') },
        { id: 'qtBobines', label: t('performanceoperador_qt_bobines') },
        { id: 'qtFases', label: t('performanceoperador_qt_fases') },
        { id: 'qtLotes', label: t('performanceoperador_qt_lotes') },
        { id: 'qtTurnos', label: t('performanceoperador_qt_turnos') },
        { id: 'medFasesBob', label: t('performanceoperador_med_fases_bob') },
        { id: 'objFasesTurnoMes', label: t('performanceoperador_obj_fases_turno_mes') },
        { id: 'medPaquetesTurno', label: t('performanceoperador_med_paquetes_turno') },
        { id: 'objPaquetesTurnoMes', label: t('performanceoperador_obj_paquetes_turno_mes') },
        { id: 'medBobinesTurno', label: t('performanceoperador_med_bobines_turno') },
        { id: 'objBobinesTurnoMes', label: t('performanceoperador_obj_bobines_turno_mes') },
        { id: 'tonMes', label: t('performanceoperador_ton_mes') },
        { id: 'objTonTurnoMes', label: t('performanceoperador_obj_ton_turno_mes') },
        { id: 'realTonTotalMes', label: t('performanceoperador_real_ton_total_mes') },
        { id: 'objTonTotalMes', label: t('performanceoperador_obj_ton_total_mes') },
        { id: 'ponderacaoBob', label: t('performanceoperador_ponderacao_bob') },
        { id: 'ponderacaoLotesFases', label: t('performanceoperador_ponderacao_lotes_fases') },
        { id: 'resultadoIndividual', label: t('performanceoperador_resultado_individual') },
        { id: 'resultadoLinha', label: t('performanceoperador_resultado_linha') },
        { id: 'resultadoFabrica', label: t('performanceoperador_resultado_fabrica') },
        { id: 'resultadoGlobal', label: t('performanceoperador_resultado_global') },
    ];
    

    const tableData = colaboradores.map((row) => {

        return {
            colaborador: row.nome,
            mes: row.mes_avaliacao, // Asegúrate de que esta propiedad existe
            linha: row.operadorAuxiliar?.linha ?? '', // Verifica si operadorAuxiliar existe
            funcao: row.operadorAuxiliar?.funcao ?? '', // Verifica si operadorAuxiliar existe
            qtBobines: row.operadorAuxiliar?.qt_bob ?? 0, // Verifica si operadorAuxiliar existe
            qtFases: row.operadorAuxiliar?.fases_cortadas ?? 0, // Verifica si operadorAuxiliar existe
            qtLotes: row.operadorAuxiliar?.lotes_cortados ?? 0, // Verifica si operadorAuxiliar existe
            qtTurnos: row.operadorAuxiliar?.qt_turnos ?? 0, // Verifica si operadorAuxiliar existe
            medFasesBob: row.calculos?.medFasesBob ?? 0, // Verifica si calculos existe
            objFasesTurnoMes: row.stats?.media_fases_bobine ?? 0, // Verifica si stats existe
            medPaquetesTurno: row.calculos?.media_paquetes_turno ?? 0, // Verifica si calculos existe
            objPaquetesTurnoMes: row.stats?.media_lotes_turno ?? 0, // Verifica si stats existe
            medBobinesTurno: row.calculos?.medBobinesTurno ?? 0, // Verifica si calculos existe
            objBobinesTurnoMes: round(row.stats?.media_bobines_turno ?? 0), // Verifica si stats existe
            tonMes: row.calculos?.realTonTotalMes ?? 0, // Verifica si calculos existe
            objTonTurnoMes: row.calculos?.medTonMes ?? 0, // Verifica si calculos existe
            realTonTotalMes: round(row.stats?.real_ton_linha ?? 0), // Verifica si stats existe
            objTonTotalMes: row.stats?.obj_ton ?? 0, // Verifica si stats existe
            ponderacaoBob: row.calculos?.ponderacaoBob ?? 0, // Verifica si calculos existe
            ponderacaoLotesFases: row.calculos?.ponderacaoLotesFases ?? 0, // Verifica si calculos existe
            resultadoIndividual: `${row.calculos?.produtividadeIndividual ?? 0}%`, // Verifica si calculos existe
            resultadoLinha: `${row.calculos?.produtividadeLinha ?? 0}%`, // Verifica si calculos existe
            resultadoFabrica: `${row.calculos?.produtividadeFabrica ?? 0}%`, // Verifica si calculos existe
            resultadoGlobal: `${row.calculos?.total ?? 0}%`, // Verifica si calculos existe
        };
    });

    return (
        <Container maxWidth={false} sx={{ padding: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                {t('performanceoperador_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
            <FormControl fullWidth sx={{ marginRight: 2 }}>
                <ValidatedSelect
                    value={year}
                    fullWidth
                    onChange={handleYearChange}
                    label={t('estatisticasprimas_ano')}
                    required
                    formSubmitted={formSubmitted}
                >
                    {Array.from({ length: 10 }, (_, i) => {
                        const yearOption = new Date().getFullYear() - i;
                        return (
                            <MenuItem key={yearOption} value={yearOption}>
                                {yearOption}
                            </MenuItem>
                        );
                    })}
                </ValidatedSelect>
            </FormControl>
            {user && user.company === 'plafesa' ? (
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                        <ValidatedSelect
                        value={quarter}
                        fullWidth
                        onChange={handleQuarterChange}
                        label={t('avaliacaocolaborador_trimestre')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        <MenuItem value={1}>{t('avaliacaocolaborador_trimestre_janeiro_marco')}</MenuItem>
                        <MenuItem value={2}>{t('avaliacaocolaborador_trimestre_abril_junho')}</MenuItem>
                        <MenuItem value={3}>{t('avaliacaocolaborador_trimestre_julho_setembro')}</MenuItem>
                        <MenuItem value={4}>{t('avaliacaocolaborador_trimestre_outubro_dezembro')}</MenuItem>
                    </ValidatedSelect>
                    </FormControl>
            ) : (
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                        <ValidatedSelect
                        value={semestre}
                        fullWidth
                        onChange={handleSemestreChange}
                        label={t('avaliacaocolaborador_semestre')}
                        required
                        formSubmitted={formSubmitted}
                        >
                        <MenuItem value={1}>{t('avaliacaocolaborador_semestre_1')}</MenuItem>
                        <MenuItem value={2}>{t('avaliacaocolaborador_semestre_2')}</MenuItem>
                    </ValidatedSelect>  
                </FormControl>
            )}
                <Button variant="contained" color="primary" onClick={handleSearch}>
                    {t('estatisticasprimas_buscar')}
                </Button>
            </Box>
            {loading ? (
                <Typography variant="h6" component="p" gutterBottom>
                    <CircularProgress />
                </Typography>
            ) : (
                <SimpleTable columns={columns} data={tableData} rowSpanColumn={"colaborador"}  />
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default PerformanceOperador;