import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/geral.css';
import { Container, Typography, Box, Button, TablePagination, Card, CardContent, Grid,  TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { format } from 'date-fns';
import { companyConfig } from '../../utils/config';
import ProducaoDiariaChart from '../../components/ProducaoDiariaChart'
import SimpleTable from '../../components/SimpleTable';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ProducaoDiaria = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [paragens, setParagens] = useState([]);
    const [observacoes, setObservacoes] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')

    const handleRowClick = (rowId) => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchData = useCallback(async () => {
        if (!user) return;
        // const customDate = '2025-02-17'; // Cambiar por la fecha actual
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/producaodiaria`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [user]);

    const fetchParagens = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/paragens/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setParagens(result);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching paragens:', error);
        }
    };
    const fetchObservacoes = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/observacoes/${id}`, {   
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setObservacoes(result);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching observacoes:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const companySettings = user ? companyConfig[user.company] : {};

    const handleView = (row) => {
        setSelectedRow(row);
        fetchParagens(row.id);
        fetchObservacoes(row.id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null);
        setParagens([])
        setObservacoes([]);
    };

    const formatDateHour = (dateString) => {
            return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
        };
        const formatDate = (dateString) => {
            return format(new Date(dateString), 'dd/MM/yyyy');
        };

    const today = new Date().toISOString().split('T')[0];

    const columnsProducao = useMemo(() => [
        { id: 'ESTADO', label: t('producaoanalise_estado'),sortable: true },
        { id: 'PERIODO', label: t('producaoanalise_periodo_turno'), sortable: true },
        { id: 'LINHA', label: t('producaoanalise_linha'), sortable: true },
        { id: 'N_COLABORADOR', label: t('producaoanalise_colaborador'), sortable: true },
        { id: 'N_AUXILIAR', label: t('producaoanalise_numero_auxiliar'), sortable: true },
        { id: 'TEMPO_CORTE', label: t('producaoanalise_tempo_corte'), sortable: true },
        { id: 'OF', label: t('producaoanalise_of'), sortable: true },
        { id: 'BOBINE', label: t('producaoanalise_bobine'), sortable: true },
        { id: 'PESO_C', label: t('producaoanalise_peso_cortado'), sortable: true },
        { id: 'T_PAR', label: t('producaoanalise_paragem'), sortable: true },
        { id: 'info', label: ('producaoanalise_info'), sortable: false }
    ], []);

    const handleSort = (columnId) => {
            if (orderBy === columnId) {
                // Si ya se está ordenando por esta columna, alternar la dirección
                setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
            } else {
                // Si es una nueva columna, establecerla como la columna de ordenación
                setOrderBy(columnId);
                setOrderDirection('asc');
            }
        };
    
        const recordMatchesSearch = (record, searchTerm) => {
            if (!searchTerm) return true;
            return Object.values(record).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        };
        
        const filteredRecords = data
            .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general
    
    
            const sortedRecords = useMemo(() => {
                if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
                return [...filteredRecords].sort((a, b) => {
                    const aValue = a[orderBy];
                    const bValue = b[orderBy];
            
                    if (aValue === null || aValue === undefined) return 1;
                    if (bValue === null || bValue === undefined) return -1;
            
                    // Si la columna es una fecha, convertir a objetos Date
                    if (orderBy === 'DATA' || orderBy === 'DATA_INI' || orderBy === 'DATA_FIM') {
                        return orderDirection === 'asc'
                            ? new Date(aValue) - new Date(bValue)
                            : new Date(bValue) - new Date(aValue);
                    }
            
                    // Si la columna es un número, comparar como números
                    if (typeof aValue === 'number' || !isNaN(aValue)) {
                        return orderDirection === 'asc'
                            ? parseFloat(aValue) - parseFloat(bValue)
                            : parseFloat(bValue) - parseFloat(aValue);
                    }
            
                    // Si la columna es texto, comparar como cadenas
                    if (typeof aValue === 'string') {
                        return orderDirection === 'asc'
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    }
            
                    return 0; // Si no se puede comparar, no cambiar el orden
                });
            }, [filteredRecords, orderBy, orderDirection]);
    
        const paginatedRecords = useMemo(() => {
            return sortedRecords.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );
        }, [sortedRecords, page, rowsPerPage]);
    
    const dataProducao = paginatedRecords.map((row, index) => ({
        id: row.id,
        ESTADO: row.ESTADO,
        DATA: formatDate(row.DATA),
        PERIODO: row.PERIODO,
        LINHA: row.LINHA,
        N_COLABORADOR: row.N_COLABORADOR,
        N_AUXILIAR: row.N_AUXILIAR,
        HORA_INICIO: row.HORA_INICIO,
        HORA_FIM: row.HORA_FIM,
        TEMPO_CORTE: row.TEMPO_CORTE,
        OF: row.OF,
        BOBINE: row.BOBINE,
        FAM: row.FAM,
        LARGURA: row.LARGURA,
        ESP: row.ESP,
        FASES_T: row.FASES_T,
        FASES_C: row.FASES_C,
        LOTES_T: row.LOTES_T,
        LOTES_C: row.LOTES_C,
        PESO_C: row.PESO_C,
        T_PAR: row.T_PAR,
        OBSERVACAO: row.OBSERVACAO,
        info: (
            <Button variant="contained" color="primary" onClick={() => handleView(row)}>
                <FaEye />
            </Button>
        )
    }));

    const renderExpandedRow = (row) => (
        <Card sx={{ margin: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>Detalhes Adicionais</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>{t('producaoanalise_data')}:</strong> {row.DATA}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_hora_inicio')}:</strong> {row.HORA_INICIO}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_hora_fim')}:</strong> {row.HORA_FIM}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_familia')}:</strong> {row.FAM}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_espessura')}:</strong> {row.ESP}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_largura')}:</strong> {row.LARGURA}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_fases_t')}:</strong> {row.FASES_T}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_fases_c')}:</strong> {row.FASES_C}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_lotes_t')}:</strong> {row.LOTES_T}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_lotes_c')}:</strong> {row.LOTES_C}</Typography>
                <Typography variant="body2"><strong>{t('producaoanalise_obs')}:</strong> {row.OBSERVACAO}</Typography>
            </Grid>
            </Grid>
        </CardContent>
    </Card>
    );
    
    // Para la tabla de detalles en el diálogo:
    const columnsDetalhes = [
        { id: 'turno', label: t('producaoanalise_turno') },
        { id: 'data', label: t('producaoanalise_data') },
        { id: 'dataInicio', label: t('producaoanalise_data_inicio') },
        { id: 'dataFim', label: t('producaoanalise_data_fim') },
        { id: 'periodo', label: t('producaoanalise_periodo_turno') },
        { id: 'linha', label: t('producaoanalise_linha') },
        { id: 'colaborador', label: t('producaoanalise_colaborador') },
        { id: 'auxiliar', label: t('producaoanalise_numero_auxiliar') },
        { id: 'horaInicio', label: t('producaoanalise_hora_inicio') },
        { id: 'horaFim', label: t('producaoanalise_hora_fim') },
        { id: 'tempoCorte', label: t('producaoanalise_tempo_corte') },
        { id: 'largura', label: t('producaoanalise_largura') },
        { id: 'espessura', label: t('producaoanalise_espessura') },
        { id: 'fasesT', label: t('producaoanalise_fases_t') },
        { id: 'fasesC', label: t('producaoanalise_fases_c') },
        { id: 'lotesT', label: t('producaoanalise_lotes_t') },
        { id: 'lotesC', label: t('producaoanalise_lotes_c') },
        { id: 'of', label: t('producaoanalise_of') },
        { id: 'bobine', label: t('producaoanalise_bobine') },
        { id: 'pesoInicial', label: t('producaoanalise_peso_inicial') },
        { id: 'pesoTotal', label: t('producaoanalise_peso_total') },
        { id: 'pesoCortado', label: t('producaoanalise_peso_cortado') }
    ];
    
    const dataDetalhes = selectedRow ? [{
        turno: selectedRow.TURNO,
        data: formatDate(selectedRow.DATA),
        dataInicio: formatDateHour(selectedRow.DATA_MOV_INICIO),
        dataFim: formatDateHour(selectedRow.DATA_MOV_FIM),
        periodo: selectedRow.PERIODO,
        linha: selectedRow.LINHA,
        colaborador: `${selectedRow.N_COLABORADOR} - ${selectedRow.NOME_COLABORADOR}`,
        auxiliar: selectedRow.N_AUXILIAR,
        horaInicio: selectedRow.HORA_INICIO,
        horaFim: selectedRow.HORA_FIM,
        tempoCorte: selectedRow.TEMPO_CORTE,
        largura: selectedRow.LARGURA,
        espessura: selectedRow.ESP,
        fasesT: selectedRow.FASES_T,
        fasesC: selectedRow.FASES_C,
        lotesT: selectedRow.LOTES_T,
        lotesC: selectedRow.LOTES_C,
        of: selectedRow.OF,
        bobine: selectedRow.BOBINE,
        pesoInicial: selectedRow.PESO_INICIAL,
        pesoTotal: selectedRow.PESO_TOTAL,
        pesoCortado: selectedRow.PESO_C
    }] : [];
    
    // Para la tabla de paragens en el diálogo:
    const columnsParagens = [
        { id: 'assunto', label: t('producaoanalise_assunto') },
        { id: 'descricao', label: t('avaliacaocolaborador_descricao') },
        { id: 'horaInicio', label: t('producaoanalise_hora_inicio') },
        { id: 'horaFim', label: t('producaoanalise_hora_fim') },
        { id: 'tempo', label: t('producaoanalise_tempo') },
        { id: 'categoria', label: t('producaoanalise_categoria') }
    ];
    
    const dataParagens = paragens.map((paragem, index) => ({
        assunto: paragem.ASSUNTO,
        descricao: paragem.DESCRICAO,
        horaInicio: paragem.HORA_INICIO,
        horaFim: paragem.HORA_FIM,
        tempo: paragem.TEMPO,
        categoria: paragem.CATEGORIA
    }));
    
    // Para la tabla de observações en el diálogo:
    const columnsObservacoes = [
        { id: 'observacao', label: t('controlof_observacoes') }
    ];
    
    const dataObservacoes = observacoes.map((observacao, index) => ({
        observacao: observacao.OBSERVACAO
    }));

    const calculatePageTotals = (data) => {
        let tempoCorte = 0;
        let pesoCortado = 0;
        let ofCount = new Set();
        let bobineCount = new Set();
    
        data.forEach(item => {
            tempoCorte += parseFloat(item.TEMPO_CORTE) || 0;
            pesoCortado += parseFloat(item.PESO_C) || 0;
            ofCount.add(item.OF);
            bobineCount.add(item.BOBINE);
        });
    
        return {
            tempoCorte,
            pesoCortado,
            totalOF: ofCount.size,
            totalBobine: bobineCount.size
        };
    };

    const pageTotals = calculatePageTotals(dataProducao);

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('producaodiaria_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', marginBottom: 4, fontWeight: 'bold'   }}>
                {today}
            </Typography>
            <ProducaoDiariaChart height={400}/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, marginTop: 4 }}>
            <TextField
                label={t('estatisticasprimas_buscar')}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(0); // Reinicia la paginación al buscar
                }}
                style={{ marginBottom: '20px' }}
            />
            </Box>
            <SimpleTable 
                columns={columnsProducao} 
                data={dataProducao} 
                totals={pageTotals}
                expandedRow={expandedRow}
                handleRowClick={handleRowClick}
                renderExpandedRow={renderExpandedRow}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>{t('producaoanalise_detalles_linea')}</DialogTitle>
                <DialogContent>
                {selectedRow && (
                    <SimpleTable columns={columnsDetalhes} data={dataDetalhes} />
                )}
                {paragens.length > 0 && (
                    <>
                        <Typography variant="h6" component="h2" gutterBottom sx={{ marginTop: 4 }}>
                            {t('producaoanalise_paragens')}
                        </Typography>
                        <SimpleTable columns={columnsParagens} data={dataParagens} />
                    </>
                )}
                {observacoes.length > 0 && (
                    <>
                        <Typography variant="h6" component="h2" gutterBottom sx={{ marginTop: 4 }}>
                            {t('controlof_observacoes')}
                        </Typography>
                        <SimpleTable columns={columnsObservacoes} data={dataObservacoes} />
                    </>
                )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t('controlof_fechar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ProducaoDiaria;