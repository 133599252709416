import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { companyConfig } from '../utils/config';
import { styled } from '@mui/system';
import { useTranslation } from "react-i18next";

const SimpleTable = ({ columns, data, totals, rowSpanColumn, expandedRow, handleRowClick, renderExpandedRow, orderBy, orderDirection, onSort}) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook para traducción

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const companySettings = user ? companyConfig[user.company] : {};
    const StyledTableHead = styled(TableHead)({
        backgroundColor: companySettings.color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    });

    return (
        <TableContainer component={Paper}>
            <Table>
                <StyledTableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            onClick={() => column.sortable && onSort && onSort(column.id)} // Solo permite ordenar si es sortable
                            style={{ cursor: column.sortable ? 'pointer' : 'default' }} // Cambia el cursor si es clicable
                        >
                            {column.label}
                            {orderBy === column.id && column.sortable && (
                                <span>
                                    {orderDirection === 'asc' ? ' 🔼' : ' 🔽'} {/* Indicador de orden */}
                                </span>
                            )}
                        </TableCell>
                        ))}
                    </TableRow>
                </StyledTableHead>
                <TableBody>
                    {data.map((row, rowIndex, array) => (
                        <React.Fragment key={rowIndex}>
                            <TableRow onClick={() => handleRowClick && handleRowClick(row.id)}>
                                {columns.map((column, colIndex) => {
                                    if (column.id === rowSpanColumn && rowIndex > 0 && array[rowIndex - 1][rowSpanColumn] === row[rowSpanColumn]) {
                                        return null;
                                    }
                                    const rowSpan = column.id === rowSpanColumn ? array.filter(item => item[rowSpanColumn] === row[rowSpanColumn]).length : 1;
                                    return (
                                        <TableCell key={colIndex} rowSpan={rowSpan}>
                                            {row[column.id]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            {expandedRow === row.id && renderExpandedRow && (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        {renderExpandedRow(row)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                    {totals && (
                        <TableRow>
                            <TableCell>{t('simpletable_totales')}</TableCell>
                            <TableCell colSpan={4}></TableCell>
                            <TableCell>{totals.tempoCorte}</TableCell>
                            <TableCell>{totals.totalOF}</TableCell>
                            <TableCell>{totals.totalBobine}</TableCell>
                            <TableCell>{totals.pesoCortado}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SimpleTable;