import React, { useEffect, useState } from "react";
import { FaUser, FaLock } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Alert, Box, Typography, InputAdornment } from "@mui/material";
import { companyConfig } from '../../utils/config';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

function Login() {
    const { t } = useTranslation(); // Hook para traducción
    const [colaborador, setColaborador] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const { company } = useParams();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (user.role === 2) {
                navigate(`/${user.company}/validar`);
            } else {
                localStorage.setItem("turno", true);
                navigate(`/${user.company}/home`);
            }
        }
    }, [navigate, company]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setShowError(false); // Resetear el mensaje de error
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${company}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ colaborador, password }),
                credentials: "include",
            });
            const data = await response.json();
            if (data.success) {
                localStorage.setItem("user", JSON.stringify(data.user));
                if (data.user.role === 2) {
                    navigate(`/${company}/validar`);
                } else {
                    localStorage.setItem("turno", true);
                    navigate(`/${company}/home`);
                }
            } else {
                setErrorMessage(data.message || t('login_error_message'));
                setShowError(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage(t('login_server_error'));
            setShowError(true);
        }
    };

    const companySettings = companyConfig[company];

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                background: companySettings.background,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 400,
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "linear-gradient(to bottom, black, white)",
                    textAlign: "center",
                }}
            >
                <img
                    src={companySettings.logo}
                    alt="Logo"
                    style={{ width: 100, marginBottom: 16 }}
                />
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    <strong>{t('login_title')}</strong>
                </Typography>
                {showError && (
                    <Alert severity="error" sx={{ marginBottom: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={handleLogin}>
                    <TextField
                        label={t('login_user_label')}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={colaborador}
                        onChange={(e) => setColaborador(e.target.value)}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaUser />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t('login_password_label')}
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaLock />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            backgroundColor: companySettings.color,
                            "&:hover": {
                                backgroundColor: companySettings.colorHover || "#555",
                            },
                        }}
                    >
                        {t('login_button')}
                    </Button>
                </form>
                <Button
                    onClick={() => navigate(companySettings.otherCompanyUrl)}
                    sx={{
                        marginTop: 2,
                        color: companySettings.color,
                        textTransform: "none",
                    }}
                >
                    {t('login_switch_company', { company: company === "plafesa" ? "Ibersteel" : "Plafesa" })}
                </Button>
            </Box>
        </Box>
    );
}

export default Login;