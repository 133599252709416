import React, { useState, useEffect, useCallback } from 'react';
import { Container, Autocomplete, Button, Snackbar, Alert, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { companyConfig } from '../../utils/config';
import ValidatedTextField from '../../components/ValidatedTextField';
import { t } from 'i18next';

const LoteOpp = () => {
    const [user, setUser] = useState(null);
    const [lote, setLote] = useState('');
    const [opp, setOpp] = useState('');
    const [of, setOf] = useState('');
    const [ofList, setOfList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchOfList = useCallback(async (search) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listaof?search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setOfList(data); // Establece la lista solo si es un array
            } else {
                setOfList([]); // Si no es un array, establece un array vacío
            }
        } catch (error) {
            console.error('Error fetching OF list:', error);
            setOfList([]); // En caso de error, establece un array vacío
        }
    }, [user]);

    const fetchLoteByOf = useCallback(async (ofId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/loteopp/${ofId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (data.length > 0) {
                setLote(data[0].lote_filho);
            } else {
                setLote('');
            }
        } catch (error) {
            console.error('Error fetching Lote by OF:', error);
        }
    }, [user]);

    const handleOfChange = (event, value) => {
        setOf(value);
        if (value) {
            fetchLoteByOf(value.id);
        } else {
            setLote('');
        }
    };
    const handleOfInputChange = (event, value) => {
        if (value.length >= 3) { // Realizar la búsqueda solo si hay al menos 3 caracteres
            fetchOfList(value);
        }
    };

    const handleLoteChange = (event) => {
        setLote(event.target.value);
    };

    const handleOppChange = (event) => {
        setOpp(event.target.value);
    };

    const handleRegister = async () => {
        setFormSubmitted(true);
        const errors = {};
        if (!lote) errors.lote = true;
        if (!opp) errors.opp = true;
        if (!of) errors.of = true;

        if (Object.keys(errors).length > 0) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarloteopp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    data: new Date().toISOString(), // Puedes ajustar el formato de la fecha según sea necesario
                    colaborador: user.colaborador, // Asegúrate de que `user` tenga una propiedad `name`
                    lote,
                    opp
                })
            });

            const result = await response.json();
            if (response.ok) {
                setSnackbarMessage(t('loteopp_registro_exitoso'));
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setLote('');
                setOpp('');
                setOf('');
                setOfList([]);
                setFormSubmitted(false);
            } else {
                setSnackbarMessage(t('loteopp_error_registrar'));
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error registering LoteOpp:', error);
            setSnackbarMessage(t('loteopp_error_registrar'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('loteopp_title')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Autocomplete
                options={ofList || []}
                getOptionLabel={(option) => option ? option.of.toString() : ''}
                value={of}
                onChange={handleOfChange}
                onInputChange={handleOfInputChange}
                renderInput={(params) => (
                    <ValidatedTextField
                        {...params}
                        margin="dense"
                        label={t('controlof_numero_of')}
                        type='number'
                        fullWidth
                        required
                        formSubmitted={formSubmitted}
                        
                    />
                )}
            />
            <ValidatedTextField
                label={t('loteopp_lote')}
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 4 }}
                value={lote}
                onChange={handleLoteChange}
                type="number"
                required
                formSubmitted={formSubmitted}
            />
            <ValidatedTextField
                label="OPP"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 4 }}
                value={opp}
                onChange={handleOppChange}
                type="number"
                required
                formSubmitted={formSubmitted}
            />
            <Button variant="contained" color="primary" onClick={handleRegister}>
                {t('registarcolab_registrar')}
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default LoteOpp;