import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Autocomplete, Grid, Divider, Typography, Button, Box, MenuItem,  Snackbar, Alert, TablePagination , Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { companyConfig } from '../../utils/config';
import '../../components/geral.css';
import ValidatedSelect from '../../components/ValidatedSelect';
import ValidatedTextField from '../../components/ValidatedTextField';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción
import SimpleTable from '../../components/SimpleTable';


const ControlOF = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedA, setFormSubmittedA] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [user, setUser] = useState(null);
    const [ofData, setOfData] = useState({
        ofNumber: '',
        action: '',
    });
    const initialManualOfData = {
        linha: '',
        numeroOfs: '',
        bobinas: [],
    };
    const [manualOfData, setManualOfData] = useState(initialManualOfData);
    const [bobinaCount, setBobinaCount] = useState(1);
    const [ofList, setOfList] = useState([]);
    const [linhas, setLinhas] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [linhaSelecionada, setLinhaSelecionada] = useState('');
    const [ofsLinha, setOfsLinha] = useState([]);
    const [selectedOFs, setSelectedOFs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDialog, setOpenDialog] = useState(false); // Estado para el diálogo
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    

    const fetchLinhas = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/linhas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            setLinhas(data);
        } catch (error) {
            console.error('Error fetching linhas:', error);
        }
    }, [user]);
    const fetchFamilias = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/familia`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            setFamilias(data);
        } catch (error) {
            console.error('Error fetching familias:', error);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchLinhas();
            fetchFamilias();
        }
    }, [user, fetchLinhas, fetchFamilias]);
        // Simulación de la obtención de la lista de OFs desde el servidor
    const fetchOfList = async (search) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listaof?search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setOfList(data); // Establece la lista solo si es un array
            } else {
                setOfList([]); // Si no es un array, establece un array vacío
            }
        } catch (error) {
            console.error('Error fetching OF list:', error);
            setOfList([]); // En caso de error, establece un array vacío
        }
    };

    const fetchOfsPorLinha = async (linha) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/oflinha?linha=${linha}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success) {
                setOfsLinha(data.ofs);
            } else {
                setOfsLinha([]);
            }
        } catch (error) {
            console.error('Error fetching OFs por linha:', error);
            setOfsLinha([]);
        }
    };

    const filteredData = ofsLinha.filter((of) =>
        of.of.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const paginatedData = useMemo(() => {
        return filteredData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [filteredData, page, rowsPerPage]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(0); // Reinicia a la primera página al realizar una búsqueda
    };

    const handleOfChange = async (event, value) => {
        // Actualiza solo el número de OF en el estado
        setOfData((prevState) => ({ ...prevState, ofNumber: value }));
    
        // Si se selecciona una OF, obtener las bobinas asociadas
        if (value) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/bobinas?of=${value.of}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                if (data.length > 0) {
                    // Actualiza las bobinas sin sobrescribir otros valores del estado
                    setOfData((prevState) => ({ ...prevState, bobinas: data }));
                } else {
                    setOfData((prevState) => ({ ...prevState, bobinas: [] }));
                }
            } catch (error) {
                console.error('Error fetching bobinas:', error);
                setOfData((prevState) => ({ ...prevState, bobinas: [] }));
            }
        } else {
            // Si no hay OF seleccionada, limpia las bobinas
            setOfData((prevState) => ({ ...prevState, bobinas: [] }));
        }
    };

    const handleOfInputChange = (event, value) => {
        if (value.length >= 3) { // Realizar la búsqueda solo si hay al menos 3 caracteres
            fetchOfList(value);
        }
    };

    const handleManualOfChange = (e) => {
        const { name, value } = e.target;
        setManualOfData({ ...manualOfData, [name]: value });
    };

    const handleBobinaChange = (index, e) => {
        const { name, value } = e.target;
        const newBobinas = [...manualOfData.bobinas];
        newBobinas[index] = { ...newBobinas[index], [name]: value };
        setManualOfData({ ...manualOfData, bobinas: newBobinas });
    };

    const handleAddBobina = () => {
        setBobinaCount(bobinaCount + 1);
        setManualOfData({ ...manualOfData, bobinas: [...manualOfData.bobinas, {}] });
    };

    const handleRemoveBobina = (index) => {
        const newBobinas = manualOfData.bobinas.filter((_, i) => i !== index);
        setBobinaCount(bobinaCount - 1);
        setManualOfData({ ...manualOfData, bobinas: newBobinas });
    };

    const handleOfSubmit = async (e) => {
        e.preventDefault();
        setFormSubmittedA(true);
    
        // Validar que todos los campos requeridos estén completos
        const { ofNumber, action, selectedBobina } = ofData;
        const errors = {};
        if (!ofNumber) errors.ofNumber = true;
        if (action === '') errors.action = true;
        if (!selectedBobina) errors.selectedBobina = true;
    
        if (Object.keys(errors).length > 0) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/alterarof`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    of: ofNumber.of,
                    estado: action,
                    bobina: selectedBobina, // Incluye la bobina seleccionada
                }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                setSnackbarMessage(t('controlof_of_alterada_exito'));
                setSnackbarSeverity('success');
                setOfData({ ofNumber: '', action: '', selectedBobina: '' }); // Limpia los campos
                setFormSubmittedA(false);
            } else {
                setSnackbarMessage(result.message || t('controlof_error_alterar_of'));
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(t('navbar_error_servidor'));
            setSnackbarSeverity('error');
        }
    
        setOpenSnackbar(true);
    };

    const handleManualOfSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        // Validar que todos los campos requeridos estén completos
        const { linha, numeroOfs, bobinas } = manualOfData;
        const errors = {};
        if (!linha) errors.linha = true;
        if (!numeroOfs) errors.numeroOfs = true;
        if (bobinas.length === 0) {
            setSnackbarMessage(t('controlof_agregar_bobina'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return
        }
        bobinas.forEach((bobina, index) => {
            if (!bobina.numero) errors[`bobinaNumero${index}`] = true;
            if (!bobina.largura) errors[`bobinaLargura${index}`] = true;
            if (!bobina.espessura) errors[`bobinaEspessura${index}`] = true;
            if (!bobina.pesoCortar) errors[`bobinaPesoCortar${index}`] = true;
            if (!bobina.fases) errors[`bobinaFases${index}`] = true;
            if (!bobina.lotesPorFase) errors[`bobinaLotesPorFase${index}`] = true;
            if (!bobina.familia) errors[`bobinaFamilia${index}`] = true;
        });

        // Convertir los valores numéricos a enteros o flotantes
        const convertedBobinas = bobinas.map(bobina => ({
            ...bobina,
            numero: parseInt(bobina.numero, 10),
            largura: parseFloat(bobina.largura),
            espessura: parseFloat(bobina.espessura),
            pesoCortar: parseFloat(bobina.pesoCortar),
            fases: parseInt(bobina.fases, 10),
            lotesPorFase: parseInt(bobina.lotesPorFase, 10)
        }));

        if (Object.keys(errors).length > 0) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarof`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ linha, of: numeroOfs, estado: 0, bobinas: convertedBobinas }),
            });

            const result = await response.json();

            if (response.ok) {
                setSnackbarMessage(t('controlof_of_registrada_exito'));
                setSnackbarSeverity('success');
                setBobinaCount(1);
                setManualOfData(initialManualOfData);
                setFormSubmitted(false);
            } else {
                setSnackbarMessage(result.message || t('controlof_error_registrar_of'));
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(t('navbar_error_servidor'));
            setSnackbarSeverity('error');
        }

        setOpenSnackbar(true);
    };

    const handleCerrarMultipleOFs = async () => {
        if (selectedOFs.length === 0) {
            setSnackbarMessage(t('controlof_seleccionar_almenos_of'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/cerrarmultipleof`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ ofs: selectedOFs }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                setSnackbarMessage(t('controlof_cerradas_sucess'));
                setSnackbarSeverity('success');
                setSelectedOFs([]);
                fetchOfsPorLinha(linhaSelecionada); // Refrescar la lista de OFs
            } else {
                setSnackbarMessage(result.message || t('control_cerradas_error'));
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(t('navbar_error_servidor'));
            setSnackbarSeverity('error');
        }
    
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleOpenDialog = () => {
        if (selectedOFs.length === 0) {
            setSnackbarMessage(t('controlof_seleccionar_almenos_of'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
        setOpenDialog(true); // Abre el diálogo
    };

    const handleCloseDialog = () => {
        setOpenDialog(false); // Cierra el diálogo
    };

    const handleConfirmCerrarOFs = async () => {
        setOpenDialog(false); // Cierra el diálogo
        await handleCerrarMultipleOFs(); // Llama a la función para cerrar las OFs
    };

    const companySettings = user ? companyConfig[user.company] : {};

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('controlof_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>

            {/* Formulario de Abertura/Fecho Manual de OFs */}
            <Box>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('controlof_abertura_fecho')}
                </Typography>
                <Autocomplete
                    options={ofList || []}
                    getOptionLabel={(option) => option ? option.of.toString() : ''}
                    value={ofData.ofNumber}
                    onChange={handleOfChange}
                    onInputChange={handleOfInputChange}
                    renderInput={(params) => (
                        <ValidatedTextField
                            {...params}
                            margin="dense"
                            label={t('controlof_numero_of')}
                            fullWidth
                            required
                            formSubmitted={formSubmittedA}
                        />
                    )}
                />
                <ValidatedSelect
                        label={t('controlof_acao')}
                        value={ofData.action}
                        onChange={(e) => setOfData({ ...ofData, action: Number(e.target.value) })}
                        required
                        margin="dense"
                        formSubmitted={formSubmittedA}
                >
                        <MenuItem value={0}>{t('controlof_abrir')}</MenuItem>
                        <MenuItem value={1}>{t('controlof_fechar')}</MenuItem>
                </ValidatedSelect>

                {/* ValidatedSelect para las bobinas */}
                {ofData.bobinas && ofData.bobinas.length > 0 && (
                    <ValidatedSelect
                        label={t('controlof_bobinas')}
                        value={ofData.selectedBobina || ''}
                        onChange={(e) => setOfData({ ...ofData, selectedBobina: e.target.value })}
                        required
                        margin="dense"
                        formSubmitted={formSubmittedA}
                    >
                        {ofData.bobinas.map((bobina) => (
                            <MenuItem key={bobina.bobine} value={bobina.bobine}>
                                {bobina.bobine}
                            </MenuItem>
                        ))}
                    </ValidatedSelect>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button variant="contained" color="primary" type="primary" onClick={handleOfSubmit}>
                        {t('resultado_primas_alterar')}
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ marginTop: 4, backgroundColor: companySettings.color, height: 2, marginBottom: 4 }} />

            {/* Formulario de Registro Manual de OF */}
            <Box>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('controlof_registro_manual_of')}
                </Typography>
                <ValidatedSelect
                    label={t('performanceoperador_linha')}
                    name="linha"
                    value={manualOfData.linha}
                    onChange={handleManualOfChange}
                    required
                    formSubmitted={formSubmitted}
                >
                    {linhas.map((linha) => (
                        <MenuItem key={linha.codigo} value={linha.codigo}>
                            {linha.linha}
                        </MenuItem>
                    ))}
                </ValidatedSelect>
                <ValidatedTextField
                    margin="dense"
                    label={t('controlof_numero_of')}
                    type="number"
                    fullWidth
                    name="numeroOfs"
                    value={manualOfData.numeroOfs}
                    onChange={handleManualOfChange}
                    required
                    formSubmitted={formSubmitted}
                />
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('controlof_bobinas')}
                </Typography>
                <Grid container spacing={2}>
                    {manualOfData.bobinas.map((bobina, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {t('controlof_bobina')} {index + 1}
                                </Typography>
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_numero')}
                                    type="number"
                                    fullWidth
                                    name="numero"
                                    value={bobina.numero || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_largura')}
                                    type="number"
                                    fullWidth
                                    name="largura"
                                    value={bobina.largura || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_espessura')}
                                    type="number"
                                    fullWidth
                                    name="espessura"
                                    value={bobina.espessura || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_peso_cortar')}
                                    type="number"
                                    fullWidth
                                    name="pesoCortar"
                                    value={bobina.pesoCortar || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_fases')}
                                    type="number"
                                    fullWidth
                                    name="fases"
                                    value={bobina.fases || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_lotes_por_fase')}
                                    type="number"
                                    fullWidth
                                    name="lotesPorFase"
                                    value={bobina.lotesPorFase || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                />
                                <ValidatedSelect
                                    label={t('controlof_familia')}
                                    name="familia"
                                    value={bobina.familia || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                    required
                                    formSubmitted={formSubmitted}
                                >
                                    {familias.map((familia) => (
                                        <MenuItem key={familia.familia} value={familia.familia}>
                                            {familia.descricao}
                                        </MenuItem>
                                    ))}
                                </ValidatedSelect>
                                <ValidatedTextField
                                    margin="dense"
                                    label={t('controlof_observacoes')}
                                    type="text"
                                    fullWidth
                                    name="observacoes"
                                    value={bobina.observacoes || ''}
                                    onChange={(e) => handleBobinaChange(index, e)}
                                />
                                <Button variant="contained" color="secondary" onClick={() => handleRemoveBobina(index)} sx={{ marginTop: 1 }}>
                                    {t('controlof_remover_bobina')} {index + 1}
                                </Button>
                            </Box>
                            <Divider sx={{ marginY: 2, backgroundColor: companySettings.color }} />
                        </Grid>
                    ))}
                </Grid>
                <Button variant="contained" color="primary" onClick={handleAddBobina} sx={{ marginTop: 2 }}>
                    {t('controlof_adicionar_bobina')} {bobinaCount}
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button variant="contained" color="primary" type="primary" onClick={handleManualOfSubmit}>
                        {t('registarcolab_registrar')}
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ marginTop: 4, backgroundColor: companySettings.color, height: 2, marginBottom: 4 }} />

            <Box>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('controlof_seleccionar_of_linea')}
                </Typography>
                <ValidatedSelect
                    label={t('performanceoperador_linha')}
                    value={linhaSelecionada}
                    margin="dense"
                    onChange={(e) => {
                        setLinhaSelecionada(e.target.value);
                        fetchOfsPorLinha(e.target.value);
                    }}
                    required
                >
                    {linhas.map((linha) => (
                        <MenuItem key={linha.codigo} value={linha.codigo}>
                            {linha.linha}
                        </MenuItem>
                    ))}
                </ValidatedSelect>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ marginTop: 2 }}>
                        {t('controlof_cerrar_ofs')}
                    </Button>
                    <ValidatedTextField
                        margin="dense"
                        label={t('controlof_buscar_of')}
                        type="text"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ maxWidth: '300px' }}
                    />
                </Box>

                <SimpleTable
                    columns={[
                        { id: 'select', label: t('controlof_seleccionar'), sortable: false },
                        { id: 'of', label: t('controlof_numero_of'), sortable: false },
                    ]}
                    data={paginatedData.map((of) => ({
                        ...of,
                        select: (
                            <Checkbox
                                checked={selectedOFs.includes(of.of)}
                                onChange={() => {
                                    setSelectedOFs((prevSelected) =>
                                        prevSelected.includes(of.of)
                                            ? prevSelected.filter((selected) => selected !== of.of)
                                            : [...prevSelected, of.of]
                                    );
                                }}
                            />
                        ),
                    }))}
                    orderBy="of"
                    orderDirection="asc"
                    onSort={(columnId) => {
                        // Implementar lógica de ordenamiento si es necesario
                    }}
                />

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {/* Diálogo de confirmación */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t('validar_dialog_confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('controlof_confirmar_cierre_of')}
                    </DialogContentText>
                    <ul>
                        {selectedOFs.map((of) => (
                            <li key={of}>{of}</li>
                        ))}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        {t('validar_dialog_cancel')}
                    </Button>
                    <Button onClick={handleConfirmCerrarOFs} color="primary">
                        {t('validar_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ControlOF;