import React, { useState, useEffect, useCallback } from "react";
import { Container, Box, Typography, Button, Paper, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Switch, MenuItem, Card, CardContent, CardHeader } from "@mui/material";
import ValidatedSelect from "../../components/ValidatedSelect";
import { companyConfig } from "../../utils/config";
import ValidatedTextField from "../../components/ValidatedTextField";
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const RegistoProducao = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [linhas, setLinhas] = useState([]); // Lista de líneas desde el backend
    const [linhaSelecionada, setLinhaSelecionada] = useState(null); // Línea seleccionada
    const [ultimoRegisto, setUltimoRegisto] = useState(null); // Último registro de la línea
    const [ordensFabrico, setOrdensFabrico] = useState([]); // Lista de ordens de fabrico
    const [ordemSelecionada, setOrdemSelecionada] = useState(""); // Ordem de fabrico seleccionada
    const [bobineId, setBobineId] = useState(""); // ID de la bobine seleccionada
    const [ofId, setOfId] = useState(""); // ID de la ordem de fabrico seleccionada
    const [bobines, setBobines] = useState([]); // Lista de bobines
    const [bobineSelecionada, setBobineSelecionada] = useState(""); // Bobine seleccionada
    const [dadosOrdem, setDadosOrdem] = useState(null); // Datos de la ordem de fabrico
    const user = JSON.parse(localStorage.getItem("user"));
    const [auxiliares, setAuxiliares] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [auxiliar, setAuxiliar] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedM, setFormSubmittedM] = useState(false);
    const [formSubmittedP, setFormSubmittedP] = useState(false);
    const [formSubmittedC, setFormSubmittedC] = useState(false);   
    const [formSubmittedE, setFormSubmittedE] = useState(false); 
    const [pesoCortar, setPesoCortar] = useState("");
    const [horaInicio, setHoraInicio] = useState("");
    const [observacoes, setObservacoes] = useState("");
    const [observacoesFinal, setObservacoesFinal] = useState("");
    const [producaoIniciada, setProducaoIniciada] = useState(false);
    const [velocidade, setVelocidade] = useState("");
    const [penetracao, setPenetracao] = useState("");
    const [prensa, setPrensa] = useState("");
    const [filtros, setFiltros] = useState("");
    const [traccao, setTraccao] = useState("");
    const [basculacao, setBasculacao] = useState("");
    const [ponte, setPonte] = useState("");
    const [contraRolos, setContraRolos] = useState([]);
    const [basculacaoEntrada, setBasculacaoEntrada] = useState("");
    const [basculacaoSaida, setBasculacaoSaida] = useState("");
    const [aplanadoraGrande, setAplanadoraGrande] = useState(false);
    const [parametrosRegistrados, setParametrosRegistrados] = useState(false); // Estado para controlar si los inputs están deshabilitados
    const [dialogOpen, setDialogOpen] = useState(false); // Controla si el diálogo está abierto
    const [categoriaParagem, setCategoriaParagem] = useState(""); // Valor del select de categoría
    const [categoriasParagem, setCategoriasParagem] = useState([]); // Lista de categorías de paragem
    const [assuntoParagem, setAssuntoParagem] = useState(""); // Valor del input de assunto
    const [horaInicioParagem, setHoraInicioParagem] = useState(""); // Valor de la hora de inicio
    const [horaFimParagem, setHoraFimParagem] = useState(""); // Valor de la hora de fim
    const [descricaoParagem, setDescricaoParagem] = useState(""); // Valor de la descripción
    const [pesoDialogOpen, setPesoDialogOpen] = useState(false); // Controla si el diálogo está abierto
    const [pesoConfirmado, setPesoConfirmado] = useState(false); // Controla si el peso fue confirmado
    const [rp_id, setRp_id] = useState(null); // ID del último registro de paragem
    const [paragens, setParagens] = useState([]); // Lista de paragens
    const [corteDialogOpen, setCorteDialogOpen] = useState(false);
    const [corteNaoFinalizado, setCorteNaoFinalizado] = useState(false); // Diferenciar entre finalizado y no finalizado
    const [horaFim, setHoraFim] = useState("");
    const [fasesCortadas, setFasesCortadas] = useState("");
    const [pesoCortado, setPesoCortado] = useState("");
    const [verficarIniciadas, setVerficarIniciadas] = useState(false);
    const [paragemId, setParagemId] = useState(null); // ID de la paragem seleccionada
    const [EditarDialogOpen, setEditDialogOpen] = useState(false); // Controla si el diálogo está abierto
    const [producaoNaoFinalizada, setProducaoNaoFinalizada] = useState(true); // Controla si la producción no está finalizada
    const isAdmin = user && [100, 13, 90].includes(user.role); // Verifica si el usuario es administrador
    const [isAdminViewing, setIsAdminViewing] = useState(false); // Controla si el admin está en modo de solo visualización
    const [dialogForzarFechoOpen, setDialogForzarFechoOpen] = useState(false); // Controla si el diálogo está abierto
    const [motivoForzarFecho, setMotivoForzarFecho] = useState(""); // Motivo para forzar el cierre
    const [notificacaoDialogOpen, setNotificacaoDialogOpen] = useState(false); // Controla si el diálogo está abierto
    const [lote, setLote] = useState(""); // Número de lote
    const [observacaoq, setObservacaoq] = useState(""); // Observación
    const [formSubmittedN, setFormSubmittedN] = useState(false); // Controla si el formulario fue enviado
    const [loading, setLoading] = useState(false); // Estado para controlar la carga

    const companySettings = user ? companyConfig[user.company] : {};

    useEffect(() => {
        if (auxiliar === "" && !isAdmin) {
            setAuxiliar(user.auxiliar); // Establecer el auxiliar desde localStorage
        }
    }, []);

    const fetchLinhas = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/linhasestado?ncolaborador=${user.colaborador}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            if (!response.ok) {
                console.error(data.message);
                return;
            }
            setLinhas(data); // Lista de líneas con información de disponibilidad
        } catch (error) {
            console.error("Erro ao buscar linhas:", error);
        }
    }, [user]);

    const fetchAuxiliares = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/getauxiliares`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            if (data.sucess) {
                setAuxiliares(data.auxiliares);
            } else {
                setSnackbarMessage(data.message || t('validar_error_fetch_auxiliares'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t('validar_error_fetch_auxiliares'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    }, [user]);

    const verificarOFIniciada = useCallback(async (turno) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/dadosofiniciada?turno=${turno}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();

            if (!response.ok) {
                console.error(data.message);
                return;
            }

            if (data.success && data.ofs.length > 0) {
                // Si hay una OF iniciada, configurar los estados necesarios
                const ofIniciada = data.ofs[0]; // Tomar la primera OF iniciada
                setProducaoIniciada(true);
                setVerficarIniciadas(true);
                setLinhaSelecionada({ linha: ofIniciada.linha, codigo: ofIniciada.codigo });
                setUltimoRegisto(ofIniciada);
                setOfId(ofIniciada.of_id);
                setBobineId(ofIniciada.bobine_id);
                setOrdemSelecionada(ofIniciada.of);
                setBobineSelecionada(ofIniciada.bobine);
                setHoraInicio(ofIniciada.hora_ini);
                setDadosOrdem({
                    largura: ofIniciada.largura,
                    espessura: ofIniciada.espessura,
                    familia: ofIniciada.familia,
                    peso_cortar: ofIniciada.peso_cortar,
                    fases: ofIniciada.fases,
                    n_lotes: ofIniciada.n_lotes,
                    descricao: ofIniciada.descricao,
                    obs_of: ofIniciada.obs_of,
                });
                setPesoCortar(ofIniciada.peso_cortar);
                setObservacoes(ofIniciada.obs_of || "");
                setRp_id(ofIniciada.rp_id);

                // Configurar los parámetros registrados si existen
                if (ofIniciada.parametros) {
                    const { velocidade, penetracao, prensa, filtros, traccao_3, traccao_4, basculacao, ponte, basculacao_i, basculacao_f, contraRolos } = ofIniciada.parametros;
                
                    // Verificar si al menos uno de los parámetros tiene un valor válido
                    const hasValidParametros = 
                        velocidade || penetracao || prensa || filtros || traccao_3 || traccao_4 || 
                        basculacao || ponte || basculacao_i || basculacao_f || (contraRolos && contraRolos.length > 0);
                
                    setParametrosRegistrados(hasValidParametros); // Solo establecer como true si hay parámetros válidos
                
                    // Configurar los valores de los parámetros
                    setVelocidade(velocidade || "");
                    setPenetracao(penetracao || "");
                    setPrensa(prensa || "");
                    setFiltros(filtros || "");
                    setTraccao(traccao_3 || traccao_4 || "");
                    setBasculacao(basculacao || "");
                    setPonte(ponte || "");
                    setBasculacaoEntrada(basculacao_i || "");
                    setBasculacaoSaida(basculacao_f || "");
                    setAplanadoraGrande(ofIniciada.parametros.aplanadora === 1);
                    setContraRolos(contraRolos || []);
                }

                // Configurar las paragens si existen
                if (ofIniciada.paragens && ofIniciada.paragens.length > 0) {
                    setParagens(ofIniciada.paragens);
                } else {
                    setParagens([]);
                }
            } else {
                setProducaoIniciada(false);
                setVerficarIniciadas(true);
            }
        } catch (error) {
            console.error("Erro ao verificar OF iniciada:", error);
        }
    }, [user]);

    useEffect(() => {
        if (!verficarIniciadas) {
            verificarOFIniciada(user.turno);
        }
    }, [user, verficarIniciadas, verificarOFIniciada]);

    const verificarOFNaoFinalizada = async (linha) => {
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/dadosofnaofinalizada?linha=${linha.codigo}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
        
            if (!response.ok) {
                console.error(data.message);
                return;
            }
        
            if (data.success && data.ofs.length > 0) {
                // Si hay una OF no finalizada, configurar los estados necesarios
                const ofNaoFinalizada = data.ofs[0];
                setUltimoRegisto(ofNaoFinalizada);
                setOfId(ofNaoFinalizada.of_id);
                setBobineId(ofNaoFinalizada.bobine_id);
                setOrdemSelecionada(ofNaoFinalizada.of);
                setBobineSelecionada(ofNaoFinalizada.bobine);
                setDadosOrdem({
                    largura: ofNaoFinalizada.largura,
                    espessura: ofNaoFinalizada.espessura,
                    familia: ofNaoFinalizada.familia,
                    peso_cortar: (ofNaoFinalizada.peso_cortar_t - ofNaoFinalizada.peso_cortado)/1000, // Peso restante
                    fases: ofNaoFinalizada.fases - ofNaoFinalizada.n_fases_cortado, // Fases restantes
                    n_lotes: ofNaoFinalizada.n_lotes - ofNaoFinalizada.n_lotes_cortado, // Lotes restantes
                    descricao: ofNaoFinalizada.descricao,
                    obs_of: ofNaoFinalizada.obs_of,
                });
                setPesoCortar(ofNaoFinalizada.peso_cortar_t - ofNaoFinalizada.peso_cortado); // Peso restante
                setRp_id(ofNaoFinalizada.rp_id);
            } else {
                setProducaoNaoFinalizada(false);
            }
        } catch (error) {
            console.error("Erro ao verificar OF não finalizada:", error);
        }
    };

    useEffect(() => {
        if (linhas && linhas.length === 0 ) {
            fetchLinhas();
        }
        if (auxiliares && auxiliares.length === 0) {
            fetchAuxiliares();
        }
    }, [fetchLinhas, linhas, auxiliares, fetchAuxiliares]);

    const handleLinhaSelecionada = async (linha) => {
        setLinhaSelecionada(linha);
    
        try {
            // Fetch último registro de la línea
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/ultimoregisto?linha=${linha.codigo}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
    
            if (!response.ok) {
                console.error(data.message);
                setSnackbarMessage(t('registoproducao_erro_buscar_ultimo_registo'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            if (!data.info) {
                setSnackbarMessage(t('registoproducao_nenhum_registo_encontrado'));
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
                return;
            }
    
            if (linha.estado === 1 && isAdmin) {
                verificarOFIniciada(data.info.turno);
                setIsAdminViewing(true);
                setSnackbarMessage(t('registoproducao_visualizando_como_admin'));
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
                return;
            } else if (linha.estado === 2 && isAdmin) {
                verificarOFNaoFinalizada(linha);
                setIsAdminViewing(true);
                setSnackbarMessage(t('registoproducao_visualizando_como_admin'));
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
                return;
            }
    
            setUltimoRegisto(data.info);
    
            // Verificar el estado de la línea
            if (linha.estado === 3) {
                setSnackbarMessage(t('registoproducao_linha_finalizada'));
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                setLinhaSelecionada(null);
                return;
            } else if (linha.estado === 1 && data.info.turno !== user.turno) {
                setSnackbarMessage(t('registoproducao_linha_ocupada'));
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                setLinhaSelecionada(null);
                return;
            } else if (data.info.estado === 1) {
                setProducaoIniciada(true);
            } else {
                setProducaoIniciada(false);
                }
        } catch (error) {
            console.error(t('registoproducao_erro_buscar_ultimo_registo'), error);
            setSnackbarMessage(t('registoproducao_erro_buscar_ultimo_registo'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    const listaof = async (linha) => {
        const ordensResponse = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/oflinha?linha=${linha.codigo}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const ordensData = await ordensResponse.json();
        if (!ordensResponse.ok) {
            console.error(ordensResponse.message);
            return;
        } else {
            setOrdensFabrico(ordensData.ofs);
        }
    };

    const handleAlterarLinha = () => {
        if (producaoIniciada && !isAdmin) {
            setSnackbarMessage(t('registoproducao_nao_pode_alterar_linha'));
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
            return;
        }
    
        // Restablecer todos los estados necesarios
        setIsAdminViewing(false);
        setLinhaSelecionada(null);
        setUltimoRegisto(null);
        setOrdemSelecionada("");
        setBobineSelecionada("");
        setDadosOrdem(null);
        setPesoCortar("");
        setHoraInicio("");
        setObservacoes("");
        setObservacoesFinal("");
        setParagens([]);
        setParametrosRegistrados(false);
        setVelocidade("");
        setPenetracao("");
        setPrensa("");
        setFiltros("");
        setTraccao("");
        setBasculacao("");
        setPonte("");
        setContraRolos([]);
        setBasculacaoEntrada("");
        setBasculacaoSaida("");
        setAplanadoraGrande(false);
        setProducaoNaoFinalizada(true);
        setRp_id(null);
        setCorteNaoFinalizado(false);
        setHoraFim("");
        setFasesCortadas("");
        setPesoCortado("");
        fetchLinhas();
        };

    const handleOrdemSelecionada = async (ordem) => {
        setOrdemSelecionada(ordem);
        setBobineSelecionada("");
        setDadosOrdem(null);

        // Fetch bobines para la ordem seleccionada
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/bobinesof?of=${ordem}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json();
        if (!response.ok) {
            console.error(data.message);
            return;
        } else {
            setBobines(data.bobines);
        }
    };
    const handleBobineSelecionada = async (bobine) => {
        setBobineSelecionada(bobine);
        // Fetch dados da ordem de fabrico
        const dadosResponse = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/dadosbobine?bobine=${bobine}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const dadosData = await dadosResponse.json();
        if (!dadosResponse.ok) {
            console.error(dadosData.message);
            return;
        }
        else {
            setDadosOrdem(dadosData.bobine);
        }
    };

    const handleIniciarProducao = useCallback(async () => {
        setFormSubmitted(true);
        if (auxiliar === "" || auxiliar === null || auxiliar === undefined) {
            setSnackbarMessage(t('registoproducao_selecione_auxiliar_valido'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        if (!pesoCortar || !horaInicio) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        if (pesoCortar <= 0) {
            setSnackbarMessage(t('registoproducao_peso_maior_zero'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (pesoCortar > (dadosOrdem.peso_cortar*1000)) {
            setSnackbarMessage(t('registoproducao_peso_maior_total'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        
        if (horaInicio > new Date().toLocaleTimeString().slice(0, 5)) {
            setSnackbarMessage(t('registoproducao_hora_inicio_maior_atual'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        
        if (
            horaInicio < ultimoRegisto.hora_fim &&
            new Date(ultimoRegisto.data.split('/').reverse().join('-')).toLocaleDateString() === new Date().toLocaleDateString() &&
            ultimoRegisto.turno === user.turno
        ) {
            setSnackbarMessage(t('registoproducao_hora_inicio_menor_fim'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return; // Ensure the function exits if this condition is met
        }

        const tolerancia = dadosOrdem.peso_cortar * 10;
        if (Math.abs((dadosOrdem.peso_cortar * 1000) - pesoCortar)  > tolerancia && !pesoConfirmado) {
            setPesoDialogOpen(true); // Abrir el diálogo de confirmación
            return; // Detener la ejecución hasta que el usuario confirme
        }

        if (
            ultimoRegisto.hora_fim < horaInicio &&
            new Date(ultimoRegisto.data.split('/').reverse().join('-')).toLocaleDateString() === new Date().toLocaleDateString() &&
            ultimoRegisto.turno === user.turno
        ) {
            const horaFimDate = new Date();
            const horaInicioDate = new Date();
            const [horaFimHours, horaFimMinutes] = ultimoRegisto.hora_fim.split(":");
            const [horaInicioHours, horaInicioMinutes] = horaInicio.split(":");
            horaFimDate.setHours(horaFimHours, horaFimMinutes);
            horaInicioDate.setHours(horaInicioHours, horaInicioMinutes);
        
            const diffInMinutes = (horaInicioDate - horaFimDate) / (1000 * 60);
        
            if (diffInMinutes > 10) {
                const atrasoMessage = ` - ${t('registoproducao_tempo_atraso', { minutos: Math.floor(diffInMinutes) })}`;
        
                // Si no hay observaciones, mostrar un mensaje de error
                if (observacoes.trim() === "") {
                    setSnackbarMessage(t('registoproducao_insira_comentario_atraso'));
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                    return;
                }
        
                // Concatenar el tiempo de atraso a las observaciones
                setObservacoes((prevObservacoes) => `${prevObservacoes ? prevObservacoes + " " : ""}${atrasoMessage}`);
            }
        }
        

        let obs = 0
        if (observacoes.length > 0) {
            obs = 1
        }
        let pesoNOK = 0
        if (Math.abs((dadosOrdem.peso_cortar * 1000) - pesoCortar  ) > 0.001) {
            pesoNOK = 1
        }
        // Usa variables locales para asegurarte de que los valores correctos se envíen
            const finalOfId = ofId || ordemSelecionada;
            const finalBobineId = bobineId || bobineSelecionada;
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/iniciarproducao`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                ncolaborador: user.colaborador,
                linha: linhaSelecionada.codigo,
                of: finalOfId ,
                bobine: finalBobineId ,
                auxiliar,
                pesoCortar,
                horaInicio,
                pesoT: dadosOrdem.peso_cortar*1000,
                observacao: observacoes,
                obs,
                nturno: user.turno,
                pesoNOK,
                producaoNaoFinalizada,
                rp_id: rp_id,
            }),
        });
    
        const data = await response.json();
        if (!response.ok) {
            console.error(data.message);
            setSnackbarMessage(data.message || t('registoproducao_erro_iniciar_producao'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        } else {
            setPesoConfirmado(false); // Restablecer el estado de confirmación de peso
            setRp_id(data.id) // Actualizar el ID del último registro
            setBobineSelecionada(data.bobine);
            setOrdemSelecionada(data.of);
            setSnackbarMessage(t('registoproducao_sucesso_iniciar_producao'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setProducaoIniciada(true);
        }
    }, [auxiliar, bobineId, bobineSelecionada, dadosOrdem, linhaSelecionada, observacoes, ofId, ordemSelecionada, pesoCortar, producaoNaoFinalizada, rp_id, user, horaInicio, pesoConfirmado, ultimoRegisto]);

    const handleRegistarParametros = async () => {
        try {
            setFormSubmittedM(true);
            // Validar los campos obligatorios antes de enviar
            if (
                (linhaSelecionada.codigo === "CT1" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT5") &&
                (!velocidade || !penetracao || !prensa || !filtros)  && user.company === 'plafesa'
            ) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }

            if (
                (linhaSelecionada.codigo === "CT1" || linhaSelecionada.codigo === "CT4" || linhaSelecionada.codigo === "CT5") &&
                (!velocidade || !penetracao || !prensa || !filtros)  && user.company === 'ibersteel'
            ) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            if (
                linhaSelecionada.codigo === "CT3" &&
                (!traccao || !basculacao || !ponte || contraRolos.length < 7)
            ) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            if (
                ((linhaSelecionada.codigo === "CT4" && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7" ) && user.company === 'ibersteel')) &&
                (!traccao || !basculacaoEntrada || !basculacaoSaida || contraRolos.length < (aplanadoraGrande ? 3 : 9))
            ) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Enviar los parámetros al backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarparametros`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    linha: linhaSelecionada.codigo,
                    parametros: {
                        rp_id: rp_id, // ID del último registro
                        velocidade,
                        penetracao,
                        prensa,
                        filtros,
                        traccao,
                        basculacao,
                        ponte,
                        contraRolos,
                        basculacao_i: basculacaoEntrada,
                        basculacao_f: basculacaoSaida,
                        aplanadora: aplanadoraGrande ? 1 : 0, // 1 para grande, 0 para pequeña
                    },
                    turno: user.turno,
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                setSnackbarMessage(data.message || t('registoproducao_erro_registar_parametros'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Mostrar mensaje de éxito y deshabilitar los inputs
            setSnackbarMessage(t('registoproducao_sucesso_registar_parametros'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setParametrosRegistrados(true); // Deshabilitar los inputs
    
            // Guardar los datos registrados para mostrarlos
            setUltimoRegisto((prev) => ({
                ...prev,
                parametros: {
                    velocidade,
                    penetracao,
                    prensa,
                    filtros,
                    traccao,
                    basculacao,
                    ponte,
                    contraRolos,
                    basculacao_i: basculacaoEntrada,
                    basculacao_f: basculacaoSaida,
                    aplanadora: aplanadoraGrande ? t('registoproducao_aplanadora_grande') : t('registoproducao_aplanadora_pequena')
                },
            }));
        } catch (error) {
            console.error(t('registoproducao_erro_registar_parametros'), error);
            setSnackbarMessage(t('registoproducao_erro_registar_parametros'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };
    
    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditDialogOpen(false);
        // Opcional: Limpiar los valores de los inputs al cerrar
        setCategoriaParagem("");
        setAssuntoParagem("");
        setHoraInicioParagem("");
        setHoraFimParagem("");
        setDescricaoParagem("");
    };

    useEffect(() => {
        if (producaoIniciada && categoriasParagem.length === 0) {
            const fetchCategoriasParagem = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/categoriasparagem`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                    });
                    const data = await response.json();
        
                    if (!response.ok) {
                        console.error(data.message);
                        setSnackbarMessage(t('registoproducao_erro_buscar_categorias_paragens'));
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                        return;
                    }
        
                    setCategoriasParagem(data.categorias);
                } catch (error) {
                    console.error(t('registoproducao_erro_buscar_categorias_paragens'), error);
                    setSnackbarMessage(t('registoproducao_erro_buscar_categorias_paragens'));
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            };
        
            fetchCategoriasParagem();
        }
    }, [user, producaoIniciada, categoriasParagem.length]);

    const handleGuardarParagem = async () => {
        setFormSubmittedP(true);
        try {
            // Validar los campos antes de enviar
            if (!categoriaParagem || !assuntoParagem || !horaInicioParagem || !horaFimParagem || !descricaoParagem) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            
            
            
            if (horaInicioParagem < horaInicio) {
                setSnackbarMessage(t('registoproducao_hora_inicio_menor_producao'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Calcular el tiempo de la paragem
            const horaIniciop = new Date(`1970-01-01T${horaInicioParagem}:00`);
            const horaFimp = new Date(`1970-01-01T${horaFimParagem}:00`);
            const diffInMinutes = (horaFimp - horaIniciop) / (1000 * 60);
    
            if (diffInMinutes <= 0) {
                setSnackbarMessage(t('registoproducao_hora_fim_menor_inicio'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            setLoading(true)
    
            // Enviar los datos al backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/registarparagem`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    paragens: [
                        {
                            rp_id: rp_id, // ID del registro de produção
                            categoria: categoriaParagem,
                            hora_ini: horaInicioParagem,
                            hora_fim: horaFimParagem,
                            tempo: diffInMinutes, // Tiempo calculado en minutos
                            assunto: assuntoParagem,
                            descricao: descricaoParagem,
                            turno: user.turno,
                            linha: linhaSelecionada.codigo,
                            operador: user.nome + " " + user.apelido,
                        },
                    ],
                    
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                setSnackbarMessage(data.message || t('registoproducao_erro_registar_paragem'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Mostrar mensaje de éxito y cerrar el diálogo
            setSnackbarMessage(t('registoproducao_sucesso_registar_paragem'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setFormSubmittedP(false)
            handleCloseDialog();
            setParagens((prev) => [
                ...prev,
                {
                    id: data.id,
                    categoria: categoriaParagem,
                    hora_ini: horaInicioParagem,
                    hora_fim: horaFimParagem,
                    tempo: diffInMinutes,
                    assunto: assuntoParagem,
                    descricao: descricaoParagem,
                },
            ]);
        } catch (error) {
            console.error(t('registoproducao_erro_registar_paragem'), error);
            setSnackbarMessage(t('registoproducao_erro_registar_paragem'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false)
        }
    };

    const handleOpenCorteDialog = (naoFinalizado) => {
        setCorteNaoFinalizado(naoFinalizado);
        setCorteDialogOpen(true);
    };
    
    const handleCloseCorteDialog = () => {
        setCorteDialogOpen(false);
        setHoraFim("");
        setFasesCortadas("");
        setPesoCortado("");
    };

    const handleGuardarCorte = async () => {
        setFormSubmittedC(true);
        if (!horaFim || !fasesCortadas || !pesoCortado) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
    
        if (((linhaSelecionada === 'CT1' || linhaSelecionada === 'CT2' || linhaSelecionada === 'CT5') && user.company === 'plafesa') || ((linhaSelecionada === 'CT1' || linhaSelecionada === 'CT4' || linhaSelecionada === 'CT5') && user.company === 'ibersteel')) {
            if (fasesCortadas > (dadosOrdem.fases + 1)) {
                setSnackbarMessage(t('registoproducao_fases_cortadas_acima_planeadas'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
        }
        if (((linhaSelecionada === 'CT3' || linhaSelecionada === 'CT4') && user.company === 'plafesa') || ((linhaSelecionada === 'CT2' || linhaSelecionada === 'CT3' || linhaSelecionada === 'CT6' || linhaSelecionada === 'CT7') && user.company === 'ibersteel')) {
            if (fasesCortadas > (dadosOrdem.n_lotes + 1)) {
                setSnackbarMessage(t('registoproducao_lotes_cortados_acima_planeados'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
        }
    
        if (pesoCortado > pesoCortar) {
            setSnackbarMessage(t('registoproducao_peso_cortado_maior'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
    
        if (pesoCortado < pesoCortar && !corteNaoFinalizado) {
            setSnackbarMessage(t('registoproducao_peso_cortado_menor'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        if (horaFim < horaInicio) {
            setSnackbarMessage(t('registoproducao_hora_fim_menor_inicio'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        // Validar que `horaFim` no sea mayor que la hora actual con una tolerancia de 15 minutos
        const horaFimDate = new Date();
        const horaInicioDate = new Date();
        const [horaFimHours, horaFimMinutes] = horaFim.split(":");
        horaFimDate.setHours(horaFimHours, horaFimMinutes);
        const diffInMinutes = (horaFimDate - horaInicioDate) / (1000 * 60);


        if (diffInMinutes > 15) {
            setSnackbarMessage(t('registoproducao_hora_fim_tolerancia_excedida'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        // Adicionar observação se a hora de fim for maior que a hora atual
        let observacoesParaEnviar = observacoesFinal;
        if (diffInMinutes > 0) {
            const newObservacoes = `${observacoesFinal ? observacoesFinal + " " : ""}${t('registoproducao_hora_fim_diferenca', { minutos: Math.ceil(diffInMinutes) })}`;
        
            if (observacoesFinal !== newObservacoes) {
                setObservacoesFinal(newObservacoes);
            }
        
            // Enviar al backend usando la variable local
            observacoesParaEnviar = newObservacoes;
        
            // Aquí puedes usar `observacoesParaEnviar` para enviar al backend
        }
        

        // Validar que las paragens coincidan con el tiempo de produção solo si existen
        if (paragens.length > 0) {
            const horaInicioDate = new Date(`1970-01-01T${horaInicio}:00`);
            const paragensInvalidas = paragens.filter((paragem) => {
                const paragemInicio = new Date(`1970-01-01T${paragem.hora_ini}:00`);
                const paragemFim = new Date(`1970-01-01T${paragem.hora_fim}:00`);
                return paragemInicio < horaInicioDate || paragemFim > horaFimDate;
            });

            if (paragensInvalidas.length > 0) {
                setSnackbarMessage(t('registoproducao_paragens_fora_intervalo'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
        }
    
        // Determinar el estado
    const estado = corteNaoFinalizado ? 2 : 3;

    // Enviar los datos al backend
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/finalizarproducao`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                id: rp_id,
                linha: linhaSelecionada.codigo,
                estado,
                hora_fim: horaFim,
                peso_cortado: pesoCortado,
                observacao: observacoesParaEnviar,
                n_fases_cortado: fasesCortadas,
                n_lotes_cortado: fasesCortadas, // Si aplica para CT3 o CT4
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            setSnackbarMessage(data.message || t('registoproducao_erro_finalizar_producao'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setSnackbarMessage(t('registoproducao_sucesso_finalizar_producao'));
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleCloseCorteDialog();

        // Reiniciar estados
        verificarOFNaoFinalizada(linhaSelecionada);
        setProducaoIniciada(false);
        setUltimoRegisto(null);
        setOrdemSelecionada("");
        setBobineSelecionada("");
        setDadosOrdem(null);
        setPesoCortar("");
        setHoraInicio("");
        setObservacoes("");
        setObservacoesFinal("");
        setParagens([]);
        handleLinhaSelecionada(linhaSelecionada);
        if (!corteNaoFinalizado) {
            listaof(linhaSelecionada)
        }
    } catch (error) {
        console.error(t('registoproducao_erro_finalizar_producao'), error);
        setSnackbarMessage(t('registoproducao_erro_finalizar_producao'));
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    }
};

    const handleEditParagem = (index) => {
        const paragem = paragens[index];
        setParagemId(paragem.id); // Guardar el ID de la paragem seleccionada
        setHoraInicioParagem(paragem.hora_ini); // Solo las horas
        setHoraFimParagem(paragem.hora_fim); // Solo las horas
        setEditDialogOpen(true); // Abrir el diálogo
    };
    const handleEditarParagem = async () => {
        setFormSubmittedE(true);
        try {
            // Validar los campos antes de enviar
            if (!horaInicioParagem || !horaFimParagem) {
                setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            if (horaFimParagem <= horaInicioParagem) {
                setSnackbarMessage(t('registoproducao_hora_fim_menor_inicio'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Validar que la hora de início de la paragem sea al menos después de la hora de início da produção
            if (horaInicioParagem < horaInicio) {
                setSnackbarMessage(t('registoproducao_hora_inicio_menor_producao'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Asegurarse de que las horas estén en formato HH:mm:ss
            const horaInicioParagemFull = horaInicioParagem.length === 5 ? `${horaInicioParagem}:00` : horaInicioParagem;
            const horaFimParagemFull = horaFimParagem.length === 5 ? `${horaFimParagem}:00` : horaFimParagem;

            // Calcular el tiempo de la paragem en minutos
            const horaInicioDate = new Date(`1970-01-01T${horaInicioParagemFull}`);
            const horaFimDate = new Date(`1970-01-01T${horaFimParagemFull}`);
            const diffInMinutes = (horaFimDate - horaInicioDate) / (1000 * 60);
    
            // Enviar los datos al backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/atualizarparagem`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    id: paragemId, // ID de la paragem a actualizar
                    hora_ini: horaInicioParagem,
                    hora_fim: horaFimParagem,
                    tempo: diffInMinutes, // Tiempo calculado en minutos
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                setSnackbarMessage(data.message || t('registoproducao_erro_atualizar_horas_paragem'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            // Mostrar mensaje de éxito y cerrar el diálogo
            setSnackbarMessage(t('registoproducao_sucesso_atualizar_horas_paragem'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            handleCloseDialog();
    
            // Actualizar la lista de paragens en el frontend
            setParagens((prev) =>
                prev.map((paragem) =>
                    paragem.id === paragemId
                        ? { ...paragem, hora_ini: horaInicioParagem, hora_fim: horaFimParagem, tempo: diffInMinutes }
                        : paragem
                )
            );
        } catch (error) {
            console.error(t('registoproducao_erro_atualizar_horas_paragem'), error);
            setSnackbarMessage(t('registoproducao_erro_atualizar_horas_paragem'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    

    const handleForzarFecho = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/finalizaradmin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    colaborador: user.colaborador,
                    motivo: motivoForzarFecho,
                    id: rp_id, // ID del registro de produção
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                setSnackbarMessage(data.message || t('registoproducao_erro_forcar_fecho'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
    
            setSnackbarMessage(t('registoproducao_sucesso_forcar_fecho'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setDialogForzarFechoOpen(false); // Cierra el diálogo

            // Reiniciar estados
            // Restablecer todos los estados necesarios
            setIsAdminViewing(false);
            setLinhaSelecionada(null);
            setUltimoRegisto(null);
            setOrdemSelecionada("");
            setBobineSelecionada("");
            setDadosOrdem(null);
            setPesoCortar("");
            setHoraInicio("");
            setObservacoes("");
            setObservacoesFinal("");
            setParagens([]);
            setParametrosRegistrados(false);
            setVelocidade("");
            setPenetracao("");
            setPrensa("");
            setFiltros("");
            setTraccao("");
            setBasculacao("");
            setPonte("");
            setContraRolos([]);
            setBasculacaoEntrada("");
            setBasculacaoSaida("");
            setAplanadoraGrande(false);
            setProducaoNaoFinalizada(true);
            setRp_id(null);
            setCorteNaoFinalizado(false);
            setHoraFim("");
            setFasesCortadas("");
            setPesoCortado("");
            try {
                await fetchLinhas();
            } catch (error) {
                console.error(t('registoproducao_erro_atualizar_linhas_forcar_fecho'), error);
                setSnackbarMessage(t('registoproducao_erro_atualizar_linhas_forcar_fecho'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
            
        } catch (error) {
            console.error(t('registoproducao_erro_forcar_fecho'), error);
            setSnackbarMessage(t('registoproducao_erro_forcar_fecho'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleOpenNotificacaoDialog = () => {
        setNotificacaoDialogOpen(true);
    };

    const handleCloseNotificacaoDialog = () => {
        setNotificacaoDialogOpen(false);
        setLote("");
        setObservacaoq("");
        setFormSubmittedN(false);
    };

    const handleEnviarNotificacao = async () => {
        setFormSubmittedN(true);

        // Validar campos
        if (!lote || !observacaoq) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setLoading(true); // Inicia el estado de carga

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/enviarnotificacao`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    rp_id: rp_id, // ID del registro de produção
                    lote,
                    observacaoq,
                    turno: user.turno,
                    linha: linhaSelecionada.codigo,
                    operador: user.nome + " " + user.apelido,
                    bobine: bobineSelecionada,
                    of: ordemSelecionada
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                setSnackbarMessage(data.message || t('registoproducao_erro_enviar_notificacao'));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }

            // Mostrar mensaje de éxito y cerrar el diálogo
            setSnackbarMessage(t('registoproducao_sucesso_enviar_notificacao'));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            handleCloseNotificacaoDialog();
        } catch (error) {
            console.error(t('registoproducao_erro_enviar_notificacao'), error);
            setSnackbarMessage(t('registoproducao_erro_enviar_notificacao'));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    

    return (
        <Container>
            {/* Título principal */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('registoproducao_titulo')} {isAdmin ? '(ADMIN)' : ''}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
    
            {/* Selección de línea */}
            {!linhaSelecionada && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4 }}>
                {/* Botones de líneas */}
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 2 }}>
                {linhas?.map((linha) => (
                    <Button
                        key={linha.codigo}
                        variant="contained"
                        color={
                            linha.estado === 1 && !linha.disponible
                                ? "error" // Rojo para líneas ocupadas
                                : linha.estado === 2
                                ? "success" // Verde para líneas no finalizadas
                                : "primary" // Azul para líneas disponibles
                        }
                        onClick={() => {
                            if (linha.estado === 1 && !linha.disponible && !isAdmin) {
                                // Mostrar mensaje si la línea está ocupada
                                setSnackbarMessage(t('registoproducao_linha_ocupada'));
                                setSnackbarSeverity("warning");
                                setSnackbarOpen(true);
                            } else if (linha.estado === 0 && isAdmin) {
                                // Mostrar mensaje para admin
                                setSnackbarMessage(t('registoproducao_sem_registos_linha'));
                                setSnackbarSeverity("info");
                                setSnackbarOpen(true);
                            }  else if (linha.estado === 1 && isAdmin) {
                                handleLinhaSelecionada(linha);
                            }  else {
                                // Permitir acceso si la línea está disponible
                                handleLinhaSelecionada(linha);
                                listaof(linha);
                                verificarOFNaoFinalizada(linha);
                            }
                        }}
                        sx={{
                            padding: 2,
                            minWidth: 150,
                            cursor: linha.estado === 1 && !linha.disponible ? "not-allowed" : "pointer", // Cambiar el cursor
                        }}
                    >
                        {linha.linha}
                    </Button>
                ))}
            </Box>
    
                {/* Cuadro de información (ahora abajo) */}
                <Box sx={{ width: "100%", padding: 2, backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                        {t('registoproducao_legenda_cores')}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 1 }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: "#d32f2f", borderRadius: "50%" }} />
                        <Typography>{t('registoproducao_legenda_registo_aberto')}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 1 }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: "#2e7d32", borderRadius: "50%" }} />
                        <Typography>{t('registoproducao_legenda_registo_nao_finalizado')}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: "#1976d2", borderRadius: "50%" }} />
                        <Typography>{t('registoproducao_legenda_linha_disponivel')}</Typography>
                    </Box>
                </Box>
            

            {/* Botón para sincronizar OFs manualmente */}
            {isAdmin && (
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSnackbarMessage(t('registoproducao_sucesso_sincronizar_ofs'));
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                    }}>
                    {t('registoproducao_sincronizar_ofs')}
                </Button>
            </Box>
            )}
            </Box>


            )}
    
            {/* Información de la línea seleccionada */}
            {linhaSelecionada && (
                <Card sx={{ marginTop: 4, padding: 3, backgroundColor: "#f9f9f9" }}>
                    <CardHeader
                        title={`${linhaSelecionada.linha} (${linhaSelecionada.codigo}) ${isAdminViewing ? ' Modo espetador' : '' } `}
                        titleTypographyProps={{ variant: "h5", color: companySettings.color }}
                    />
                    <CardContent>
                        {/* Último registro */}
                        <Box sx={{ marginBottom: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                    {t('registoproducao_ultimo_registo_linha')} 
                                </Typography>
                                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                            </Box>
                            <Paper sx={{ padding: 2, backgroundColor: "#e3f2fd", borderRadius: 2 }}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_of')}:</strong></Typography>
                                        <Typography>{ultimoRegisto?.of || "N/A"}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_bobine')}:</strong></Typography>
                                        <Typography>{ultimoRegisto?.bobine || "N/A"}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_data')}:</strong></Typography>
                                        <Typography>{ultimoRegisto?.data || "N/A"}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_hora_fim')}:</strong></Typography>
                                        <Typography>{ultimoRegisto?.hora_fim || "N/A"}</Typography>
                                    </Paper>
                                </Box>
                            </Paper>
                        </Box>
                    {(!producaoIniciada)  ? (
                        <>
                        {(!producaoNaoFinalizada) ? (
                            <>
                        {/* Selección de Ordem de Fabrico */}
                        <Box
                            sx={{
                                marginBottom: 3,
                                boxShadow: !ordemSelecionada ? "0 0 10px 2px #ffeb3b" : "none",
                                transition: "box-shadow 0.3s ease-in-out",
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                    {t('registoproducao_selecao_ordem_fabrico')}
                                </Typography>
                                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                            </Box>
                            <Paper sx={{ padding: 2, backgroundColor: "#f1f8e9", borderRadius: 2 }}>
                                <ValidatedSelect
                                    label={t('registoproducao_selecione_ordem_fabrico')}
                                    value={ordemSelecionada}
                                    onChange={(e) => handleOrdemSelecionada(e.target.value)}
                                    fullWidth
                                >
                                    {ordensFabrico.map((of) => (
                                        <MenuItem key={of.id} value={of.id}>
                                            {of.of}
                                        </MenuItem>
                                    ))}
                                </ValidatedSelect>
                            </Paper>
                        </Box>
    
                        {/* Selección de Bobine */}
                        {ordemSelecionada && (
                            <Box
                                sx={{
                                    marginBottom: 3,
                                    boxShadow: !bobineSelecionada ? "0 0 10px 2px #ffeb3b" : "none",
                                    transition: "box-shadow 0.3s ease-in-out",
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                        {t('registoproducao_selecao_bobine')}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                                </Box>
                                <Paper sx={{ padding: 2, backgroundColor: "#fff3e0", borderRadius: 2 }}>
                                    <ValidatedSelect
                                        label={t('registoproducao_selecione_bobine')}
                                        value={bobineSelecionada}
                                        onChange={(e) => handleBobineSelecionada(e.target.value)}
                                        fullWidth
                                    >
                                        {bobines.map((bobine) => (
                                            <MenuItem key={bobine.id} value={bobine.id}>
                                                {bobine.bobine}
                                            </MenuItem>
                                        ))}
                                    </ValidatedSelect>
                                </Paper>
                            </Box>
                        )}
    
                        {/* Dados da Ordem de Fabrico */}
                        {bobineSelecionada && dadosOrdem && (
                            <Box sx={{ marginBottom: 3 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                        {t('registoproducao_dados_ordem_fabrico')}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                                </Box>
                                <Paper sx={{ padding: 2, backgroundColor: "#ede7f6", borderRadius: 2 }}>
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                            <Typography variant="body1"><strong>{t('registoproducao_familia')}:</strong></Typography>
                                            <Typography>{dadosOrdem.descricao}</Typography>
                                        </Paper>
                                        <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                            <Typography variant="body1"><strong>{t('registoproducao_largura')}:</strong></Typography>
                                            <Typography>{dadosOrdem.largura}</Typography>
                                        </Paper>
                                        <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                            <Typography variant="body1"><strong>{t('registoproducao_espessura')}:</strong></Typography>
                                            <Typography>{dadosOrdem.espessura}</Typography>
                                        </Paper>
                                        {(((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT4") && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7") && user.company === 'ibersteel')) ? (
                                            <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                                <Typography variant="body1"><strong>{t('registoproducao_numero_lotes')}:</strong></Typography>
                                                <Typography>{dadosOrdem.n_lotes}</Typography>
                                            </Paper>
                                        ) : (
                                            <>
                                                <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                                    <Typography variant="body1"><strong>{t('registoproducao_numero_bandas')}:</strong></Typography>
                                                    <Typography>{dadosOrdem.n_lotes}</Typography>
                                                </Paper>
                                                <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                                    <Typography variant="body1"><strong>{t('registoproducao_numero_fases')}:</strong></Typography>
                                                    <Typography>{dadosOrdem.fases}</Typography>
                                                </Paper>
                                            </>
                                        )}
                                    </Box>
                                    {/* Observacões ocupa toda la línea */}
                                    <Box sx={{ marginTop: 2 }}>
                                        <Paper sx={{ padding: 2, textAlign: "center", backgroundColor: "#ffffff" }}>
                                            <Typography variant="body1"><strong>{t('registoproducao_observacoes_of')}:</strong></Typography>
                                            <Typography>{dadosOrdem.obs_of}</Typography>
                                        </Paper>
                                    </Box>
                                </Paper>
                            </Box>
                        )}
                        </>
                        ) : null}
    
                        {/* Controlo do Registo de Produção */}
                        {(bobineSelecionada || producaoNaoFinalizada) && (
                            <>
                        {producaoNaoFinalizada ? (
                            <>
                                <Box sx={{ marginBottom: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                        <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                            {t('registoproducao_dados_of_nao_finalizada')}
                                        </Typography>
                                        <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                                    </Box>
                                    <Paper sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: 2, marginBottom: 3 }}>
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                            <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                                <Typography variant="body1"><strong>{t('registoproducao_peso_restante')}:</strong></Typography>
                                                <Typography>{dadosOrdem?.peso_cortar*1000} kg</Typography>
                                            </Paper>
                                            <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                                <Typography variant="body1"><strong>{linhaSelecionada && (((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT4") && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7") && user.company === 'ibersteel')) ? t('registoproducao_lotes_restantes') : t('registoproducao_fases_restantes')}:</strong></Typography>
                                                <Typography>{linhaSelecionada && (((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT4") && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7") && user.company === 'ibersteel')) ? dadosOrdem?.n_lotes : dadosOrdem?.fases}</Typography>
                                            </Paper>
                                        </Box>
                                    </Paper>
                                </Box>
                                </>
                        ) : null}
                            <Box
                                sx={{
                                    boxShadow: "0 0 10px 2px #ffeb3b",
                                    transition: "box-shadow 0.3s ease-in-out",
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: companySettings.color }}>
                                        {t('registoproducao_controlo_registo_inicio')}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
                                </Box>
                                <Paper sx={{ padding: 2, backgroundColor: "#e8f5e9", borderRadius: 2 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                        {/* Peso a Cortar */}
                                        <ValidatedTextField
                                            label={t('controlof_peso_cortar')}
                                            value={pesoCortar}
                                            onChange={(e) => setPesoCortar(e.target.value)}
                                            fullWidth
                                            required
                                            margin="normal"
                                            formSubmitted={formSubmitted}
                                            disabled={(isAdmin && isAdminViewing)}
                                        />

                                        {/* Hora de Início */}
                                        <ValidatedTextField
                                            label={t('producaoanalise_hora_inicio')}
                                            type="time"
                                            value={horaInicio}
                                            onChange={(e) => setHoraInicio(e.target.value)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                            formSubmitted={formSubmitted}
                                            disabled={(isAdmin && isAdminViewing)}
                                        />

                                        {/* Seleção de Auxiliar */}
                                        <ValidatedSelect
                                            label={t('validar_select_auxiliar')}
                                            value={auxiliar}
                                            onChange={(e) => setAuxiliar(e.target.value)}
                                            fullWidth
                                            required
                                            margin="normal"
                                            formSubmitted={formSubmitted}
                                            disabled={(isAdmin && isAdminViewing)}
                                        >
                                            < MenuItem key={0} value={0}>{t('validar_auxiliar_none')}</MenuItem>

                                            {auxiliares.map((aux) => (
                                                <MenuItem key={aux.ncolaborador} value={aux.ncolaborador}>
                                                    {aux.colaborador}
                                                </MenuItem>
                                            ))}
                                        </ValidatedSelect>

                                        {/* Observações */}
                                        <ValidatedTextField
                                            label={t('controlof_observacoes')}
                                            value={observacoes}
                                            onChange={(e) => setObservacoes(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            disabled={(isAdmin && isAdminViewing)}
                                        />

                                        {/* Botón para iniciar produção */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ marginTop: 2 }}
                                            onClick={handleIniciarProducao}
                                            disabled={(isAdmin && isAdminViewing)}
                                        >
                                            {t('registoproducao_iniciar_producao')}
                                        </Button>
                                    </Box>
                                </Paper>
                            </Box>
                            </>
                        )}
                        </>
                    ) : (producaoIniciada) ? (
                        // Mostrar los dados da OF atual y los botones
                            <Box>
                            <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold", color: companySettings.color }}>
                                {t('registoproducao_dados_of_atual')}
                            </Typography>
                            <Paper sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: 2, marginBottom: 3 }}>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_of')}:</strong></Typography>
                                        <Typography>{ordemSelecionada}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_bobine')}:</strong></Typography>
                                        <Typography>{bobineSelecionada}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_largura')}:</strong></Typography>
                                        <Typography>{dadosOrdem?.largura}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_espessura')}:</strong></Typography>
                                        <Typography>{dadosOrdem?.espessura}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('producaoanalise_familia')}:</strong></Typography>
                                        <Typography>{dadosOrdem?.descricao}</Typography>
                                    </Paper>
                                    <Paper sx={{ padding: 2, flex: 1, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('controlof_peso_cortar')}:</strong></Typography>
                                        <Typography>{pesoCortar}</Typography>
                                    </Paper>
                                    </Box>
                                    {/* Observações ocupa toda la línea */}
                                <Box sx={{ marginTop: 2 }}>
                                    <Paper sx={{ padding: 2, textAlign: "center", backgroundColor: "#ffffff" }}>
                                        <Typography variant="body1"><strong>{t('controlof_observacoes')}:</strong></Typography>
                                        <Typography>{observacoes || "N/A"}</Typography>
                                    </Paper>
                                </Box>
                            </Paper>

                            {/* Parámetros de la máquina */}
                            {user.company === 'plafesa' && (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold", color: companySettings.color }}>
                                        {t('registoproducao_parametros_maquina')} 
                                    </Typography>
                                    <Paper sx={{ padding: 2, backgroundColor: "#e8f5e9", borderRadius: 2 }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            {((linhaSelecionada.codigo === "CT1" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT5") && user.company==='plafesa') || ((linhaSelecionada.codigo === "CT1" || linhaSelecionada.codigo === "CT4" || linhaSelecionada.codigo === "CT5") && user.company==='ibersteel') ? (
                                                <>
                                                    <ValidatedTextField
                                                        label={t('registoproducao_velocidade')}
                                                        value={velocidade}
                                                        onChange={(e) => setVelocidade(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_penetracao')}
                                                        value={penetracao}
                                                        onChange={(e) => setPenetracao(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_prensa')}
                                                        value={prensa}
                                                        onChange={(e) => setPrensa(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedSelect
                                                        label={t('registoproducao_filtros')}
                                                        value={filtros}
                                                        onChange={(e) => setFiltros(e.target.value)}
                                                        fullWidth
                                                        required
                                                        margin="normal"
                                                        formSubmitted={formSubmittedM}
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    >
                                                        <MenuItem value="N">{t('registoproducao_filtros_nenhum')}</MenuItem>
                                                        <MenuItem value="BR-N">{t('registoproducao_filtros_branco_novo')}</MenuItem>
                                                        <MenuItem value="CZ-N">{t('registoproducao_filtros_cinza_novo')}</MenuItem>
                                                        <MenuItem value="FIB1">{t('registoproducao_filtros_fib1')}</MenuItem>
                                                        <MenuItem value="BR-U">{t('registoproducao_filtros_branco_usado')}</MenuItem>
                                                        <MenuItem value="CZ-U">{t('registoproducao_filtros_cinza_usado')}</MenuItem>
                                                        <MenuItem value="AMBOS">{t('registoproducao_filtros_ambos')}</MenuItem>
                                                    </ValidatedSelect>
                                                </>
                                            ) : linhaSelecionada.codigo === "CT3" ? (
                                                <>
                                                    <ValidatedTextField
                                                        label={t('registoproducao_traccao')}
                                                        value={traccao}
                                                        onChange={(e) => setTraccao(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_basculacao')}
                                                        value={basculacao}
                                                        onChange={(e) => setBasculacao(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_ponte')}
                                                        value={ponte}
                                                        onChange={(e) => setPonte(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    {[...Array(7)].map((_, index) => (
                                                        <ValidatedTextField
                                                            key={index}
                                                            label={t('registoproducao_contra_rolo', { index: index + 1 })}
                                                            value={contraRolos[index] || ""}
                                                            onChange={(e) => {
                                                                const newContraRolos = [...contraRolos];
                                                                newContraRolos[index] = e.target.value;
                                                                setContraRolos(newContraRolos);
                                                            }}
                                                            fullWidth
                                                            required
                                                            formSubmitted={formSubmittedM}
                                                            margin="normal"
                                                            disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                        />
                                                    ))}
                                                </>
                                            ) : (linhaSelecionada.codigo === "CT4" && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7") && user.company === 'ibersteel')  (
                                                <>
                                                    <ValidatedTextField
                                                        label={t('registoproducao_traccao')}
                                                        value={traccao}
                                                        onChange={(e) => setTraccao(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_basculacao_entrada')}
                                                        value={basculacaoEntrada}
                                                        onChange={(e) => setBasculacaoEntrada(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <ValidatedTextField
                                                        label={t('registoproducao_basculacao_saida')}
                                                        value={basculacaoSaida}
                                                        onChange={(e) => setBasculacaoSaida(e.target.value)}
                                                        fullWidth
                                                        required
                                                        formSubmitted={formSubmittedM}
                                                        margin="normal"
                                                        disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                    />
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Typography>{t('registoproducao_aplanadora')}:</Typography>
                                                        <Switch
                                                            checked={aplanadoraGrande}
                                                            onChange={(e) => setAplanadoraGrande(e.target.checked)}
                                                        />
                                                        <Typography>{aplanadoraGrande ? t('registoproducao_aplanadora_grande') : t('registoproducao_aplanadora_pequena')}</Typography>
                                                    </Box>
                                                    {[...Array(aplanadoraGrande ? 3 : 9)].map((_, index) => (
                                                        <ValidatedTextField
                                                            key={index}
                                                            label={t('registoproducao_contra_rolo', { index: index + 1 })}
                                                            value={contraRolos[index] || ""}
                                                            onChange={(e) => {
                                                                const newContraRolos = [...contraRolos];
                                                                newContraRolos[index] = e.target.value;
                                                                setContraRolos(newContraRolos);
                                                            }}
                                                            fullWidth
                                                            required
                                                            formSubmitted={formSubmittedM}
                                                            margin="normal"
                                                            disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar si los parámetros ya están registrados
                                                        />
                                                    ))}
                                                </>
                                            )}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                sx={{ marginTop: 2 }}
                                                onClick={handleRegistarParametros}
                                                disabled={parametrosRegistrados || (isAdmin && isAdminViewing)} // Deshabilitar el botón si ya se registraron los parámetros
                                            >
                                                {t('registoproducao_registar_parametros')}
                                            </Button>
                                        </Box>
                                    </Paper>
                                </Box>
                            )}

                            {/* Botones de acción */}
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    fullWidth
                                    onClick={handleOpenNotificacaoDialog}
                                    disabled={(isAdmin && isAdminViewing)} // Deshabilitar si el admin está en modo de solo visualización
                                >
                                    {t('registoproducao_enviar_notificacao')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    fullWidth
                                    onClick={handleOpenDialog}
                                    disabled={(isAdmin && isAdminViewing)} // Deshabilitar si el admin está en modo de solo visualización
                                >
                                    {t('registoproducao_registar_paragens')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    fullWidth
                                    onClick={() => handleOpenCorteDialog(true)} // Abrir el diálogo para "Corte Não Finalizado"
                                    disabled={(isAdmin && isAdminViewing)} // Deshabilitar si el admin está en modo de solo visualización
                                >
                                    {t('registoproducao_corte_nao_finalizado')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    onClick={() => handleOpenCorteDialog(false)} // Abrir el diálogo para "Corte Finalizado"
                                    disabled={(isAdmin && isAdminViewing)} // Deshabilitar si el admin está en modo de solo visualización
                                >
                                    {t('registoproducao_corte_finalizado')}
                                </Button>
                            </Box>
                        </Box>
                        ) : null}
                        {isAdmin && (
                                <Box sx={{ marginTop: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => setDialogForzarFechoOpen(true)} // Abre el diálogo
                                    >
                                        {t('registoproducao_forcar_fecho')}
                                    </Button>
                                </Box>
                            )}
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleAlterarLinha}
                            sx={{ marginTop: 4 }}
                        >
                            {t('registoproducao_alterar_linha')}
                        </Button>
                    </CardContent>
                </Card>
            )}
            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{t('registoproducao_registar_paragem')}</DialogTitle>
                <DialogContent>
                    <ValidatedSelect
                        label={t('producaoanalise_categoria')}
                        value={categoriaParagem}
                        onChange={(e) => setCategoriaParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedP}
                        margin="normal"
                    >
                        {categoriasParagem.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                                {categoria.categoria}
                            </MenuItem>
                        ))}
                    </ValidatedSelect>
                    <ValidatedTextField
                        label={t('producaoanalise_assunto')}
                        value={assuntoParagem}
                        onChange={(e) => setAssuntoParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedP}
                        margin="normal"
                    />
                    <ValidatedTextField
                        label={t('producaoanalise_hora_inicio')}
                        type="time"
                        value={horaInicioParagem}
                        onChange={(e) => setHoraInicioParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedP}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <ValidatedTextField
                        label={t('producaoanalise_hora_fim')}
                        type="time"
                        value={horaFimParagem}
                        onChange={(e) => setHoraFimParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedP}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <ValidatedTextField
                        label={t('avaliacaocolaborador_descricao')}
                        value={descricaoParagem}
                        onChange={(e) => setDescricaoParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedP}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary" disabled={loading}>
                        {t('navbar_logout')}
                    </Button>
                    <Button onClick={handleGuardarParagem} color="primary" disabled={loading}>
                        {loading ? t('validar_loading2') : t('evaluacionlogistica_guardar')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pesoDialogOpen} onClose={() => setPesoDialogOpen(false)}>
                <DialogTitle>{t('registoproducao_confirmar_peso')}</DialogTitle>
                <DialogContent>
                    <Typography>
                    {t('registoproducao_confirmar_peso_mensagem', {
                        pesoCortar: pesoCortar || "N/A",
                    })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { if (!pesoConfirmado) {setPesoDialogOpen(false)}}} color="secondary">
                        {t('validar_dialog_cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setPesoConfirmado(true);
                            setPesoDialogOpen(false);
                            handleIniciarProducao(); // Llama directamente aquí
                        }}
                        color="primary"
                    >
                        {t('validar_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={corteDialogOpen} onClose={handleCloseCorteDialog}>
                <DialogTitle>{corteNaoFinalizado ? "Corte Não Finalizado" : "Corte Finalizado"}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        label={t('producaoanalise_hora_fim')}
                        type="time"
                        value={horaFim}
                        onChange={(e) => setHoraFim(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedC}
                        margin="normal"
                    />
                    <ValidatedTextField
                        label={linhaSelecionada && (((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT4") && user.company === 'plafesa') || ((linhaSelecionada.codigo === "CT3" || linhaSelecionada.codigo === "CT2" || linhaSelecionada.codigo === "CT6" || linhaSelecionada.codigo === "CT7") && user.company === 'ibersteel')) ? "Lotes Cortados" : "Fases Cortadas"}
                        type="number"
                        value={fasesCortadas}
                        onChange={(e) => setFasesCortadas(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedC}
                        margin="normal"
                    />
                    <ValidatedTextField
                        label={t('producaoanalise_peso_cortado')}
                        type="number"
                        value={pesoCortado}
                        onChange={(e) => setPesoCortado(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedC}
                        margin="normal"
                    />
                    <ValidatedTextField
                        label={t('producaoanalise_obs')}
                        value={observacoesFinal}
                        onChange={(e) => setObservacoesFinal(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>{t('producaoanalise_paragens')}</Typography>
                    {paragens.map((paragem, index) => (
                        <Box key={index} sx={{ marginBottom: 2 }}>
                            <Typography variant="body1"><strong>{t('producaoanalise_categoria')}:</strong> {paragem.categoria_id}</Typography>
                            <Typography variant="body1"><strong>{t('producaoanalise_hora_inicio')}:</strong> {paragem.hora_ini}</Typography>
                            <Typography variant="body1"><strong>{t('producaoanalise_hora_fim')}:</strong> {paragem.hora_fim}</Typography>
                            <Typography variant="body1"><strong>{t('producaoanalise_tempo')}:</strong> {paragem.tempo} minutos</Typography>
                            <Typography variant="body1"><strong>{t('producaoanalise_assunto')}:</strong> {paragem.assunto}</Typography>
                            <Typography variant="body1"><strong>{t('avaliacaocolaborador_descricao')}:</strong> {paragem.descricao}</Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleEditParagem(index)}
                            >
                                {t('registoproducao_editar')}
                            </Button>
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCorteDialog} color="secondary">{t('evaluacionlogistica_cancelar')}</Button>
                    <Button onClick={handleGuardarCorte} color="primary">{t('evaluacionlogistica_guardar')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={EditarDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{t('registoproducao_editar_horas_paragem')}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        label={t('producaoanalise_hora_inicio')}
                        type="time"
                        value={horaInicioParagem}
                        onChange={(e) => setHoraInicioParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedE}
                        margin="normal"
                    />
                    <ValidatedTextField
                        label={t('producaoanalise_hora_fim')}
                        type="time"
                        value={horaFimParagem}
                        onChange={(e) => setHoraFimParagem(e.target.value)}
                        fullWidth
                        required
                        formSubmitted={formSubmittedE}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">{t('evaluacionlogistica_cancelar')}</Button>
                    <Button onClick={handleEditarParagem} color="primary">{t('evaluacionlogistica_guardar')}</Button>
                </DialogActions>
            </Dialog>
            {/* Diálogo para forzar fecho */}
            <Dialog open={dialogForzarFechoOpen} onClose={() => setDialogForzarFechoOpen(false)}>
                <DialogTitle>{t('registoproducao_forcar_fecho_producao')}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        label={t('registoproducao_motivo')}
                        value={motivoForzarFecho}
                        onChange={(e) => setMotivoForzarFecho(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogForzarFechoOpen(false)} color="secondary">
                        {t('evaluacionlogistica_cancelar')}
                    </Button>
                    <Button
                        onClick={handleForzarFecho}
                        color="primary"
                        disabled={!motivoForzarFecho.trim()} // Deshabilitar si no hay motivo
                    >
                        {t('validar_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Diálogo para enviar notificación */}
            <Dialog open={notificacaoDialogOpen} onClose={handleCloseNotificacaoDialog}>
                <DialogTitle>{t('registoproducao_enviar_notificacao')}</DialogTitle>
                <DialogContent>
                    <ValidatedTextField
                        label={t('registoproducao_numero_lote')}
                        value={lote}
                        onChange={(e) => setLote(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        formSubmitted={formSubmittedN}
                    />
                    <ValidatedTextField
                        label={t('registoproducao_observacao')}
                        value={observacaoq}
                        onChange={(e) => setObservacaoq(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        multiline
                        rows={4}
                        formSubmitted={formSubmittedN}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNotificacaoDialog} color="secondary" disabled={loading}>
                        {t('evaluacionlogistica_cancelar')}
                    </Button>
                    <Button onClick={handleEnviarNotificacao} color="primary" disabled={loading}>
                    {loading ? t('validar_loading2') : t('validar_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default RegistoProducao;