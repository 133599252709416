import { Alert } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SessionChecker = ({ children }) => {
    const { t } = useTranslation(); // Hook para traducción
    const navigate = useNavigate();
    const location = useLocation(); // Obtener la ruta actual
    let inactivityTimer;

    // Función para manejar el cierre de sesión por inactividad
    const handleLogout = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            localStorage.removeItem('user');
            localStorage.removeItem('turno');
            navigate('/');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/logout`, {
                method: 'POST',
                credentials: 'include',
            });
            const data = await response.json();
            if (data.success) {
                // Eliminar 'user' y 'turno' del localStorage
                localStorage.removeItem('user');
                localStorage.removeItem('turno');
                navigate(`/${user.company}/login`);
            } else {
                localStorage.removeItem('user');
                alert(data.message || t('navbar_error_session'));
            }
        } catch (error) {
            console.error('Error:', error);
            alert(t('navbar_error_servidor'));
        }
    };

    // Función para reiniciar el temporizador de inactividad
    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
            handleLogout();
        }, 1000 * 60 * 20); // 20 minutos de inactividad
    };

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/check-session`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (!response.ok) {
                    // Si la sesión ha expirado, redirigir al usuario a la página de inicio y borrar el localStorage
                    localStorage.removeItem('user');
                    localStorage.removeItem('turno');
                    navigate('/');
                    Alert(t('sessionchecker_session_expired'), { variant: 'error' });
                }
            } catch (error) {
                localStorage.removeItem('user');
                localStorage.removeItem('turno');
                navigate('/');
            }
        };

        const sessionInterval = setInterval(checkSession, 1000 * 60); // Verificar la sesión cada minuto

        // Escuchar eventos de actividad del usuario
        const events = ['mousemove', 'keydown', 'scroll', 'click'];
        events.forEach((event) => window.addEventListener(event, resetInactivityTimer));

        // Configurar el temporizador inicial
        resetInactivityTimer();

        return () => {
            clearInterval(sessionInterval);
            clearTimeout(inactivityTimer);
            events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
        };

    }, [navigate, t]);

    // Cambiar dinámicamente el título y el favicon
    useEffect(() => {
        if (location.pathname === '/') {
            document.title = t('sessionchecker_home_title');
            const favicon = document.querySelector("link[rel='icon']");
            if (favicon) favicon.href = '/default-favicon.png'; // Favicon para la página de inicio
        } else if (location.pathname === '/plafesa/login') {
            document.title = t('sessionchecker_plafesa_login_title');
            const favicon = document.querySelector("link[rel='icon']");
            if (favicon) favicon.href = '/plafesa-favicon.png';
        } else if (location.pathname === '/ibersteel/login') {
            document.title = t('sessionchecker_ibersteel_login_title');
            const favicon = document.querySelector("link[rel='icon']");
            if (favicon) favicon.href = '/ibersteel-favicon.png';
        } else {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.company === 'plafesa') {
                document.title = t('sessionchecker_plafesa_dashboard_title');
                const favicon = document.querySelector("link[rel='icon']");
                if (favicon) favicon.href = '/plafesa-favicon.png';
            } else if (user && user.company === 'ibersteel') {
                document.title = t('sessionchecker_ibersteel_dashboard_title');
                const favicon = document.querySelector("link[rel='icon']");
                if (favicon) favicon.href = '/ibersteel-favicon.png';
            }
        }
    }, [location, t]);

    return children;
};

export default SessionChecker;