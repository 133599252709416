import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const allowedCompany = ['ibersteel', 'plafesa'];

const ProtectedLoginRoute = ({ element: Component, ...rest }) => {
  const { company } = useParams();

  if (!allowedCompany.includes(company)) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default ProtectedLoginRoute;