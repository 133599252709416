import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Button, Snackbar, Alert, MenuItem,  FormControl} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {  styled } from '@mui/system';
import { companyConfig } from '../../utils/config';
import { round } from 'lodash';
import ValidatedSelect from '../../components/ValidatedSelect';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const EstatisticasPrimas = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [year, setYear] = useState('');
    const [quarter, setQuarter] = useState('');
    const [semestre, setSemestre] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [colaboradores, setColaboradores] = useState([]);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetchDataTrigger, SetFetchDataTrigger] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [view, setView] = useState('Operador'); // Estado para manejar la vista seleccionada
    

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchColaboradores = useCallback(async () => {
            if (!user) return;
            setColaboradores([]);
            let perfil = 0;
            if (view === 'Operador'){
                perfil = 2;
            } else {
                perfil = 16;
            }
            try {
                let response;
                if (user.company === 'plafesa') {
                    response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performancecolab?trimestre=${quarter}&ano=${year}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                } else {
                    response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performancestatsop?semestre=${semestre}&ano=${year}&perfil=${perfil}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });
                }
    
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
    
                const results = await response.json();
    
                if (results.length === 0) {
                    setColaboradores([]);
                    setSnackbarMessage(t('estatisticasprimas_no_se_encontraron_registros'));
                    setSnackbarSeverity('info');
                    setOpenSnackbar(true);
                    return;
                }
                // Verifica que results sea un array
                if (Array.isArray(results)) {
                    setColaboradores(results);
                } else {
                    setColaboradores([]);
                }
            
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        }, [year, quarter, user, semestre, view]);

    

    const OperadorAuxiliar = useCallback(async (ano, mes, colaborador) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceop?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            if (!response.ok) {
                console.error('Error fetching data:', response);
            }
            const operador = await response.json();
            if ((!operador || operador.linha === null || operador.linha === 0 || !operador[0]) && user.company === 'plafesa') {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/performanceaux?mes=${mes}&ano=${ano}&colaborador=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (!response.ok) {
                    console.error('Error fetching data:', response);
                }
                const auxiliar = await response.json();
                if (!auxiliar || auxiliar.linha === null || auxiliar.linha === 0 || !auxiliar[0]) { 
                    return { linha: 0, ton: 0, qt_turnos: 0, tempo_corte: 0, qt_of: 0, fases_cortadas: 0, lotes_cortados: 0, qt_bob: 0, total_bandas: 0, funcao: 0 };
                } else {
                    return { ...auxiliar[0], funcao: 'AUX' };
                }
            } else if (operador.length === 0) {
                return { linha: 0, ton: 0, qt_turnos: 0, tempo_corte: 0, qt_of: 0, fases_cortadas: 0, lotes_cortados: 0, qt_bob: 0, total_bandas: 0, funcao: 0 };
            }
            else {
                return { ...operador[0], funcao: 'OP' };
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user]);

    const StatsLogisitca = useCallback(async (ano, mes, colaborador) => {
        try {
            const [response1, response2] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslogistica1?semestre=${semestre}&ano=${ano}&colaborador=${colaborador}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                }),
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslogistica2?mes=${mes}&ano=${ano}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                })
            ]);
            if (!response1.ok || !response2.ok) {
                console.error('Error fetching data:', response1, response2);
            }
            const [stats1, stats2] = await Promise.all([response1.json(), response2.json()]);
            if (!stats1 || stats1.length === 0 || !stats2 || stats2.length === 0) {

                return null;
            }
            const combinedStats = {
                ...stats1[0],
                ...stats2[0]
            };
            return combinedStats ;
        } catch (error) {
            console.error('Error fetching data:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user, semestre]);

    const StatsIbersteel = useCallback(async (ano, semestre) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statsauditoria?semestre=${semestre}&ano=${ano}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            if (!response.ok) {
                console.error('Error fetching data:', response);
            }
            const stats = await response.json();
            if (!stats || stats.length === 0) {
                return null;
            }
            return stats[0];
        } catch (error) {
            console.error('Error fetching data:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user]);

    const getStats = useCallback(async (ano, mes, linha) => {
        try {
            
            let statsLinhaResponse = null;
            if (linha !== 0) {
                statsLinhaResponse = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statslinha?mes=${mes}&ano=${ano}&linha=${linha}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
            }

            const [statsFabricaResponse, objFabricaResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/statsfabrica?mes=${mes}&ano=${ano}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                }),
                fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/objfabrica?mes=${mes}&ano=${ano}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                })
            ]);

            if (!statsFabricaResponse.ok || !objFabricaResponse.ok) {
                throw new Error('Error fetching stats');
            }

            if (statsLinhaResponse && statsLinhaResponse.ok) {
                const [statsLinha, statsFabrica, objFabrica] = await Promise.all([
                    statsLinhaResponse.json(),
                    statsFabricaResponse.json(),
                    objFabricaResponse.json()
                ]);

                const combinedStats = {
                    ...statsLinha[0],
                    ...statsFabrica[0],
                    ...objFabrica[0]
                };
                return combinedStats;
            } else {
                const [ statsFabrica, objFabrica] = await Promise.all([
                    statsFabricaResponse.json(),
                    objFabricaResponse.json()
                ]);
                const combinedStats = {
                    ...statsFabrica[0],
                    ...objFabrica[0]
                };
                return combinedStats;
            }
            
        } catch (error) {
            console.error('Error fetching stats:', error);
            setSnackbarMessage(t('estatisticasprimas_error_obtener_datos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return null;
        }
    }, [user]);

    const fetchData = useCallback(async () => {
        const updatedColaboradores = await Promise.all(colaboradores.map(async (colaborador) => {
            const { numero: n_col , mes_avaliacao: mes, pontualidade, p_conhecimento, p_resolucao_probl, p_responsabilidade, p_cooperacao, p_autonomia, p_organizacao, accidentes_trabalho, faltas_injustificadas } = colaborador;
            const operadorAuxiliar = await OperadorAuxiliar(year, mes, n_col);
            if (!operadorAuxiliar) return null;
            let statsIbersteel = null;
            if (user.company === 'ibersteel') {
                statsIbersteel = await StatsIbersteel(year, semestre);
                if (!statsIbersteel) return null;
            }
            const stats = await getStats(year, mes, operadorAuxiliar.linha);
            if (!stats) return null;

            if (view === 'Logistica') {
                const statsLogistica = await StatsLogisitca(year, mes, n_col);
                if (!statsLogistica) return null;

                const calculos = {
                    produtividadeFabrica: round((stats.real_ton_fabrica / stats.obj_ton_fabrica) * 100),
                    ponderacaoFabrica: 0,
                    objCamioes: round(stats.obj_ton_fabrica / 23),
                    objBobines: round((stats.obj_ton_fabrica*0.9) / 23),
                    res_cargas:0,
                    res_descargas:0,
                    produtividadeLogistica:0,
                    ponderadoLogistica:0,
                    res_flexibilidade:0,
                    res_disponibilidade:0,
                    res_personal:0,
                    ponderadoPersonal:0,
                    total:0,
                    ponderadoTotal:0,
                    gLimpeza:0,
                    gSeguranca:0,
                    gTotalAuditoria:0,
                    pTotalAuditoria:0,
                    ponderacaoSeguranca:0,
                    ponderacaoAbsentismo:0,
                    resultadoGlobal:0,
                };

                calculos.ponderacaoFabrica = round(calculos.produtividadeFabrica * 0.2);
                if (calculos.ponderacaoFabrica > 20) {
                    calculos.ponderacaoFabrica = 20;
                }

                calculos.res_cargas = round(((statsLogistica.n_camiones / calculos.objCamioes) * 100), 1);
                calculos.res_descargas = round(((statsLogistica.n_bobinas / calculos.objBobines) * 100), 1);
                calculos.produtividadeLogistica = ((calculos.res_cargas * 0.5) + (calculos.res_descargas * 0.5));
                
                calculos.ponderadoLogistica = round(calculos.produtividadeLogistica * 0.5);

                if(calculos.ponderadoLogistica > 50){
                    calculos.ponderadoLogistica = 50;
                }
                calculos.res_flexibilidade = round((statsLogistica.flexibilidade * 0.5), 1);
                calculos.res_disponibilidade = round((statsLogistica.disponibilidade * 0.5), 1);
                calculos.res_personal = (calculos.res_flexibilidade + calculos.res_disponibilidade);

                calculos.ponderadoPersonal = round(calculos.res_personal * 0.3);
                if(calculos.ponderadoPersonal > 30){
                    calculos.ponderadoPersonal = 30;
                }

                calculos.total = calculos.ponderacaoFabrica + calculos.ponderadoLogistica + calculos.ponderadoPersonal;
                calculos.ponderadoTotal = round(calculos.total * 0.7);
                if(calculos.ponderadoTotal > 70){
                    calculos.ponderadoTotal = 70;
                }


                if (statsIbersteel.pLimpeza === 50){
                    calculos.gLimpeza = 100
                } else {
                    calculos.gLimpeza = (statsIbersteel.pLimpeza*100)/50
                }

                if(statsIbersteel.pSeguranca === 50){
                    calculos.gSeguranca = 100
                } else {
                    calculos.gSeguranca = (statsIbersteel.pSeguranca*100)/50
                }  
                calculos.gTotalAuditoria = statsIbersteel.pLimpeza + statsIbersteel.pSeguranca
                calculos.pTotalAuditoria = (calculos.gTotalAuditoria*30)/100
    
                if (accidentes_trabalho > 0) {
                    calculos.ponderacaoSeguranca = 0;
                }
                else{
                    calculos.ponderacaoSeguranca = 50;
                }

                if (faltas_injustificadas > 0) {
                    calculos.ponderacaoAbsentismo = 0;
                }
                else{
                    calculos.ponderacaoAbsentismo = 50;
                }
                

                calculos.resultadoGlobal = (calculos.ponderacaoSeguranca*(calculos.ponderadoTotal + calculos.pTotalAuditoria) + calculos.ponderacaoAbsentismo*(calculos.ponderadoTotal + calculos.pTotalAuditoria))/100;
                return { ...colaborador, calculos, statsLogistica, statsIbersteel, stats, operadorAuxiliar };
            // Realiza tus cálculos aquí
            } else if (operadorAuxiliar.linha !== 0) {
                const calculos = {
                    medFasesBob: round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1),
                    realTonTotalMes: (operadorAuxiliar.ton - (operadorAuxiliar.ton * 0.03)),
                    medBobinesTurno: round(operadorAuxiliar.qt_bob / operadorAuxiliar.qt_turnos, 1),
                    medTonMes: round(stats.obj_ton / round(stats.real_n_turnos / stats.dias_uteis_mes, 1)),
                    ponderacaoBob: 0, 
                    ponderacaoLotesFases: 0,
                    produtividadeFabrica: round((stats.real_ton_fabrica / stats.obj_ton_fabrica) * 100),
                    ponderacaoFabrica: 0,
                    produtividadeLinha: round((stats.real_ton_linha / stats.obj_ton) * 100),
                    ponderacaoLinha: 0,
                    produtividadeIndividual: 0,
                    ponderacaoIndividual: 0,
                    total: 0,
                    ponderacaoTotal: 0 ,
                    ponderacaoProblemas: 0,
                    ponderacaoTolerancia: 0,
                    ponderacaoResponsabilidade: 0,
                    ponderacaoCooperacao: 0,
                    ponderacaoAutonomia: 0,
                    ponderacaoOrganizacao: 0,
                    ponderacaoSeguranca: 0,
                    ponderacaoAbsentismo: 0,
                    ponderacaoConhecimento: 0,
                    resultadoGlobal: 0,
                    PonderacaoAvalicaoTotal: 0,
                    PonderacaoTotalPessoal: 0,
                    PonderacaoTotal: 0,
                    perc_conhecimento: 0,
                    perc_problemas: 0,
                    perc_responsabilidade: 0,
                    perc_cooperacao: 0,
                    perc_autonomia: 0,
                    perc_organizacao: 0,
                    res_total: 0,
                    res_totalPessoal: 0,
                    gTotalAuditoria: 0,
                    pTotalAuditoria: 0,
                    gLimpeza: 0,
                    gSeguranca: 0,
            
                };

                calculos.ponderacaoBob = calculos.medBobinesTurno / stats.media_bobines_turno
                // Aplica la lógica de ponderación
                if (calculos.ponderacaoBob < 0.5) {
                    calculos.ponderacaoBob = 0;
                } else if (calculos.ponderacaoBob < 0.8) {
                    calculos.ponderacaoBob = 0.5;
                } else if (calculos.ponderacaoBob < 1) {
                    calculos.ponderacaoBob = 0.8;
                } else if (calculos.ponderacaoBob < 1.1) {
                    calculos.ponderacaoBob = 1;
                } else if (calculos.ponderacaoBob < 1.5) {
                    calculos.ponderacaoBob = 1.1;
                } else {
                    calculos.ponderacaoBob = 1.2;
                }
                // Aplica la lógica de ponderación para ponderacaoLotesFases
                if (operadorAuxiliar.linha === 'CT1' ||  (user.company === 'plafesa' ? operadorAuxiliar.linha === 'CT2' : operadorAuxiliar.linha === 'CT4') || operadorAuxiliar.linha === 'CT5') {
                    calculos.media_fases_bobine_real = round(operadorAuxiliar.fases_cortadas / operadorAuxiliar.qt_bob, 1);
                    calculos.ponderacaoLotesFases = calculos.media_fases_bobine_real / stats.media_fases_bobine;
                } else if (operadorAuxiliar.linha === 'CT3' || (user.company === 'plafesa' ? operadorAuxiliar.linha === 'CT4' : operadorAuxiliar.linha === 'CT2')) {
                    calculos.media_paquetes_turno = round(operadorAuxiliar.lotes_cortados / operadorAuxiliar.qt_turnos, 1);
                    calculos.ponderacaoLotesFases = calculos.media_paquetes_turno / stats.media_lotes_turno;
                }
                if (calculos.ponderacaoLotesFases < 0.5) {
                    calculos.ponderacaoLotesFases = 0;
                } else if (calculos.ponderacaoLotesFases < 0.8) {
                    calculos.ponderacaoLotesFases = 0.5;
                } else if (calculos.ponderacaoLotesFases < 1) {
                    calculos.ponderacaoLotesFases = 0.8;
                } else if (calculos.ponderacaoLotesFases < 1.1) {
                    calculos.ponderacaoLotesFases = 1;
                } else if (calculos.ponderacaoLotesFases < 1.5) {
                    calculos.ponderacaoLotesFases = 1.1;
                } else {
                    calculos.ponderacaoLotesFases = 1.2;
                }
                
                calculos.ponderacaoFabrica = round(calculos.produtividadeFabrica * 0.2);
                if (calculos.ponderacaoFabrica > 20) {
                    calculos.ponderacaoFabrica = 20;
                }
                calculos.ponderacaoLinha = round(calculos.produtividadeLinha * 0.5);
                if (calculos.ponderacaoLinha > 50) {
                    calculos.ponderacaoLinha = 50;
                }
                calculos.produtividadeIndividual = round((((calculos.realTonTotalMes * calculos.ponderacaoBob * calculos.ponderacaoLotesFases) / operadorAuxiliar.qt_turnos) / (stats.real_ton_linha / stats.real_n_turnos)) * 100, 1);
                calculos.ponderacaoIndividual = round(calculos.produtividadeIndividual * 0.3);
                if (calculos.ponderacaoIndividual > 30) {
                    calculos.ponderacaoIndividual = 30;
                }
                calculos.total = calculos.ponderacaoFabrica + calculos.ponderacaoLinha + calculos.ponderacaoIndividual;
                calculos.ponderacaoTotal = round(calculos.total * 0.7);
                if (calculos.ponderacaoTotal > 70) {
                    calculos.ponderacaoTotal = 70;
                }
    
                //PESSOAL
                if (user.company === 'plafesa') {
                    if (pontualidade > 16) {
                        calculos.ponderacaoTolerancia = 0;
                    } else {
                        calculos.ponderacaoTolerancia = 40;
                    }
                    
                    calculos.ponderacaoConhecimento = p_conhecimento;
                    if(p_conhecimento === 5){
                        calculos.perc_conhecimento = 33
                    }else if(p_conhecimento === 10){
                        calculos.perc_conhecimento = 67
                    }else if(p_conhecimento === 15){
                        calculos.perc_conhecimento = 100
                    } else{
                        calculos.perc_conhecimento = 0
                    }
        
                    calculos.ponderacaoProblemas = p_resolucao_probl;
                    if(p_resolucao_probl === 5){
                        calculos.perc_problemas = 33
                    }else if(p_resolucao_probl === 10){
                        calculos.perc_problemas = 67 
                    }
                    else if(p_resolucao_probl === 15){
                        calculos.perc_problemas = 100
                    }
                    else{
                        calculos.perc_problemas = 0
                    }
                    calculos.ponderacaoResponsabilidade = p_responsabilidade;
                    if(p_responsabilidade === 10){
                        calculos.perc_responsabilidade = 33
                    }
                    else if(p_responsabilidade === 15){
                        calculos.perc_responsabilidade = 67
                    }
                    else if(p_responsabilidade === 20){
                        calculos.perc_responsabilidade = 100
                    }
                    else{
                        calculos.perc_responsabilidade = 0
                    }
                    calculos.ponderacaoCooperacao = p_cooperacao;
                    if(p_cooperacao === 10){
                        calculos.perc_cooperacao = 33
                    }
                    else if(p_cooperacao === 15){
                        calculos.perc_cooperacao = 67
                    }
                    else if(p_cooperacao === 20){
                        calculos.perc_cooperacao = 100
                    }
                    else{
                        calculos.perc_cooperacao = 0
                    }
                    calculos.ponderacaoAutonomia = p_autonomia;
                    if(p_autonomia === 5){
                        calculos.perc_autonomia = 33
                    }
                    else if(p_autonomia === 10){
                        calculos.perc_autonomia = 67
                    }
                    else if(p_autonomia === 15){
                        calculos.perc_autonomia = 100
                    }
                    else{
                        calculos.perc_autonomia = 0
                    }
                    calculos.ponderacaoOrganizacao = p_organizacao;
                    if(p_organizacao === 5){
                        calculos.perc_organizacao = 33
                    }
                    else if(p_organizacao === 10){
                        calculos.perc_organizacao = 67
                    }
                    else if(p_organizacao === 15){
                        calculos.perc_organizacao = 100
                    }
                    else{
                        calculos.perc_organizacao = 0
                    }
        
                    calculos.res_total = calculos.ponderacaoConhecimento + calculos.ponderacaoProblemas + calculos.ponderacaoResponsabilidade + calculos.ponderacaoCooperacao + calculos.ponderacaoAutonomia + calculos.ponderacaoOrganizacao;
                    calculos.PonderacaoAvalicaoTotal = calculos.res_total * 0.6;
        
                    calculos.res_totalPessoal = calculos.ponderacaoTolerancia + calculos.PonderacaoAvalicaoTotal;
                    calculos.PonderacaoTotalPessoal = calculos.res_totalPessoal * 0.3;

                } else {
                    if (statsIbersteel.pLimpeza === 50){
                        calculos.gLimpeza = 100
                    } else {
                        calculos.gLimpeza = (statsIbersteel.pLimpeza*100)/50
                    }

                    if(statsIbersteel.pSeguranca === 50){
                        calculos.gSeguranca = 100
                    } else {
                        calculos.gSeguranca = (statsIbersteel.pSeguranca*100)/50
                    }  
                    calculos.gTotalAuditoria = statsIbersteel.pLimpeza + statsIbersteel.pSeguranca
                    calculos.pTotalAuditoria = (calculos.gTotalAuditoria*30)/100
                }
        
                if (accidentes_trabalho > 0) {
                    calculos.ponderacaoSeguranca = 0;
                }
                else{
                    calculos.ponderacaoSeguranca = 50;
                }
    
                if (faltas_injustificadas > 0) {
                    calculos.ponderacaoAbsentismo = 0;
                }
                else{
                    calculos.ponderacaoAbsentismo = 50;
                }
    
                if (user.company === 'plafesa') { calculos.resultadoGlobal = (calculos.ponderacaoSeguranca*(calculos.ponderacaoTotal + calculos.PonderacaoTotalPessoal) + calculos.ponderacaoAbsentismo*(calculos.ponderacaoTotal + calculos.PonderacaoTotalPessoal))/100;}
                else {calculos.resultadoGlobal = (calculos.ponderacaoSeguranca*(calculos.ponderacaoTotal + calculos.pTotalAuditoria) + calculos.ponderacaoAbsentismo*(calculos.ponderacaoTotal + calculos.pTotalAuditoria))/100;}
                calculos.resultadoGlobal = `${round(calculos.resultadoGlobal ?? 0, 1)}`;
                return { ...colaborador, operadorAuxiliar, stats, calculos, statsIbersteel };

            } else {
                const calculos = {
                    media_fases_bobine: 0,
                    medFasesBob: 0,
                    media_paquetes_turno: 0,
                    media_lotes_turno: 0,
                    medBobinesTurno: 0,
                    media_bobines_turno: 0,
                    realTonTotalMes: 0,
                    medTonMes: 0,
                    ponderacaoBob: 0,
                    ponderacaoLotesFases: 0,
                    produtividadeFabrica: 0,
                    ponderacaoFabrica: 0,
                    produtividadeLinha: 0,
                    ponderacaoLinha: 0,
                    produtividadeIndividual: 0,
                    ponderacaoIndividual: 0,
                    total: 0,
                    ponderacaoTotal: 0,
                    gTotalAuditoria: 0,
                    pTotalAuditoria: 0,
                    gLimpeza: 0,
                    gSeguranca: 0,
                    resultadoGlobal: '0',
                }

            if (user.company === 'ibersteel') {
                if (statsIbersteel.pLimpeza === 50){
                    calculos.gLimpeza = 100
                } else {
                    calculos.gLimpeza = (statsIbersteel.pLimpeza*100)/50
                }

                if(statsIbersteel.pSeguranca === 50){
                    calculos.gSeguranca = 100
                } else {
                    calculos.gSeguranca = (statsIbersteel.pSeguranca*100)/50
                }  
                calculos.gTotalAuditoria = statsIbersteel.pLimpeza + statsIbersteel.pSeguranca
                calculos.pTotalAuditoria = (calculos.gTotalAuditoria*30)/100
    
                if (accidentes_trabalho > 0) {
                    calculos.ponderacaoSeguranca = 0;
                }
                else{
                    calculos.ponderacaoSeguranca = 50;
                }

                if (faltas_injustificadas > 0) {
                    calculos.ponderacaoAbsentismo = 0;
                }
                else{
                    calculos.ponderacaoAbsentismo = 50;
                }

                calculos.resultadoGlobal = 'F/R'
            }   
    
                return { ...colaborador, operadorAuxiliar, stats, calculos, statsIbersteel };
            }
        
            }));
        setColaboradores(updatedColaboradores.filter(result => result !== null));
        setLoading(false);
    }, [ colaboradores, OperadorAuxiliar, getStats, year, semestre, StatsIbersteel, user, StatsLogisitca, view]);

    useEffect(() => {
        if (loading && colaboradores.length > 0 && fetchDataTrigger) { 
            fetchData();
            SetFetchDataTrigger(false);
        }
    }, [fetchColaboradores, fetchData, loading, colaboradores, fetchDataTrigger]);

    const handleYearChange = (event) => {
        setYear('');
        setYear(event.target.value);
    };

    const handleQuarterChange = (event) => {
        setQuarter('');
        setQuarter(event.target.value);
    };

    const handleSearch = async () => {
        setFormSubmitted(true);
        setLoading(true);
        setColaboradores([]);
        await fetchColaboradores();
        SetFetchDataTrigger(true);
    };

    const handleViewChange = (event) => {
        setView(event.target.value);
        setColaboradores([]);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleSemestreChange = (event) => {
        setSemestre('');
        setSemestre(event.target.value);
    };

    const companySettings = user ? companyConfig[user.company] : {};

    const StyledTableHead = styled(TableHead)({
        backgroundColor: companySettings.color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    });
    
    const CustomTableHead = styled(TableHead)(({ theme, color }) => ({
        backgroundColor: color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
            border: '1px solid #dddddd', // Agrega borde a las celdas de la cabecera
        },
    }));
    
    const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
        backgroundColor: color,
        border: '1px solid #dddddd',
        color: '#ffffff',
        fontWeight: 'bold', // Agrega borde a las celdas de la tabla
    }));

    const thickBorderStyleTop = {
        borderTop: '3px solid #dddddd', // Borde más grueso
    };
    const thickBorderStyleBottom = {
        borderBottom: '3px solid #dddddd', // Borde más grueso
    };

    const columns = [
        { id: 'colaborador', label: t('estatisticasprimas_colaborador') },
        { id: 'mes', label: t('estatisticasprimas_mes') },
        { id: 'G.Fabrica', label: 'G.' },
        { id: 'P.Fabrica', label: 'P.' },
        ...(user && user.company === 'ibersteel' && view === 'Logistica'
            ? [
                { id: 'G.Logistica', label: 'G.' },
                { id: 'P.Logistica', label: 'P.' },
                { id: 'G.Personal', label: 'G.' },
                { id: 'P.Personal', label: 'P.' },
            ]
            : [
                { id: 'G.Linha', label: 'G.' },
                { id: 'P.Linha', label: 'P.' },
                { id: 'G.Individual', label: 'G.' },
                { id: 'P.Individual', label: 'P.' },
            ]),
        { id: 'G.Total', label: 'G.' },
        { id: 'P.Total', label: 'P.' },
        ...(user && user.company === 'plafesa'
            ? [
                { id: 'R.Pontualidade', label: 'R.' },
                { id: 'P.Pontualidade', label: 'P.' },
                { id: 'G.Conhecimento', label: 'G.' },
                { id: 'P.Conhecimento', label: 'P.' },
                { id: 'G.Resolucao', label: 'G.' },
                { id: 'P.Resolucao', label: 'P.' },
                { id: 'G.Responsabilidade', label: 'G.' },
                { id: 'P.Responsabilidade', label: 'P.' },
                { id: 'G.Cooperacao', label: 'G.' },
                { id: 'P.Cooperacao', label: 'P.' },
                { id: 'G.Autonomia', label: 'G.' },
                { id: 'P.Autonomia', label: 'P.' },
                { id: 'G.Organizacao', label: 'G.' },
                { id: 'P.Organizacao', label: 'P.' },
                { id: 'G.Total2', label: 'G.' },
                { id: 'P.Total2', label: 'P.' },
                { id: 'G.TotalPessoal', label: 'G.' },
                { id: 'P.TotalPessoal', label: 'P.' },
            ]
            : [
                { id: 'G.Limpeza', label: 'G.' },
                { id: 'P.Limpeza', label: 'P.' },
                { id: 'G.Seguridad', label: 'G.' },
                { id: 'P.Seguridad', label: 'P.' },
                { id: 'G.TotalAud', label: 'G.' },
                { id: 'P.TotalAud', label: 'P.' },
            ]),
            { id: 'nAccidentes', label: t('estatisticasprimas_numero_accidentes') },
            { id: 'P.Seguranca', label: t('estatisticasprimas_p_seguranca') },
            { id: 'nFaltas', label: t('estatisticasprimas_numero_faltas_injustificadas') },
            { id: 'P.Absentismo', label: t('estatisticasprimas_p_absentismo') },
            { id: 'ResultadoGlobal', label: t('estatisticasprimas_resultado_global') },
            
    ];
    
    const tableData = useMemo(() => colaboradores.map((row, index, array) => {
        const plafesaData = user && user.company === 'plafesa' ? {
            'G.Fabrica': `${round(row.calculos?.produtividadeFabrica ?? 0)}%`,
            'P.Fabrica': `${round(row.calculos?.ponderacaoFabrica ?? 0)}%`,
            'G.Linha': `${round(row.calculos?.produtividadeLinha ?? 0)}%`,
            'P.Linha': `${round(row.calculos?.ponderacaoLinha ?? 0)}%`,
            'G.Individual': `${round(row.calculos?.produtividadeIndividual ?? 0)}%`,
            'P.Individual': `${round(row.calculos?.ponderacaoIndividual ?? 0)}%`,
            'G.Total': `${round(row.calculos?.total ?? 0)}%`,
            'P.Total': `${round(row.calculos?.ponderacaoTotal ?? 0)}%`,
            'R.Pontualidade': round(row.pontualidade ?? 0),
            'P.Pontualidade': `${round(row.calculos?.ponderacaoTolerancia ?? 0)}%`,
            'G.Conhecimento': `${round(row.calculos?.perc_conhecimento ?? 0)}%`,
            'P.Conhecimento': round(row.calculos?.ponderacaoConhecimento ?? 0),
            'G.Resolucao': `${round(row.calculos?.perc_problemas ?? 0)}%`,
            'P.Resolucao': round(row.calculos?.ponderacaoProblemas ?? 0),
            'G.Responsabilidade': `${round(row.calculos?.perc_responsabilidade ?? 0)}%`,
            'P.Responsabilidade': round(row.calculos?.ponderacaoResponsabilidade ?? 0),
            'G.Cooperacao': `${round(row.calculos?.perc_cooperacao ?? 0)}%`,
            'P.Cooperacao': round(row.calculos?.ponderacaoCooperacao ?? 0),
            'G.Autonomia': `${round(row.calculos?.perc_autonomia ?? 0)}%`,
            'P.Autonomia': round(row.calculos?.ponderacaoAutonomia ?? 0),
            'G.Organizacao': `${round(row.calculos?.perc_organizacao ?? 0)}%`,
            'P.Organizacao': round(row.calculos?.ponderacaoOrganizacao ?? 0),
            'G.Total2': `${round(row.calculos?.res_total ?? 0)}%`,
            'P.Total2': `${round(row.calculos?.PonderacaoAvalicaoTotal ?? 0)}%`,
            'G.TotalPessoal': `${round(row.calculos?.res_totalPessoal ?? 0)}%`,
            'P.TotalPessoal': `${round(row.calculos?.PonderacaoTotalPessoal ?? 0, 1)}%`,
            
        } : {};

        const ibersteelData = user && user.company !== 'plafesa' && view === 'Operador' ? {
            'G.Fabrica': `${round(row.calculos?.produtividadeFabrica ?? 0)}%`,
            'P.Fabrica': `${round(row.calculos?.ponderacaoFabrica ?? 0)}%`,
            'G.Linha': `${round(row.calculos?.produtividadeLinha ?? 0)}%`,
            'P.Linha': `${round(row.calculos?.ponderacaoLinha ?? 0)}%`,
            'G.Individual': `${round(row.calculos?.produtividadeIndividual ?? 0)}%`,
            'P.Individual': `${round(row.calculos?.ponderacaoIndividual ?? 0)}%`,
            'G.Total': `${round(row.calculos?.total ?? 0)}%`,
            'P.Total': `${round(row.calculos?.ponderacaoTotal ?? 0)}%`,
            'G.Limpeza': `${round(row.calculos?.gLimpeza ?? 0)}%`,
            'P.Limpeza': `${round(row.statsIbersteel?.pLimpeza ?? 0)}%`,
            'G.Seguridad': `${round(row.calculos?.gSeguranca ?? 0)}%`,
            'P.Seguridad': `${round(row.statsIbersteel?.pSeguranca ?? 0)}%`,
            'G.TotalAud': `${round(row.calculos?.gTotalAuditoria ?? 0)}%`,
            'P.TotalAud': `${round(row.calculos?.pTotalAuditoria ?? 0)}%`,
        } : {};

        const ibersteelDataLog = user && user.company !== 'plafesa' && view === 'Logistica' ? {
            'G.Fabrica': `${round(row.calculos?.produtividadeFabrica ?? 0)}%`,
            'P.Fabrica': `${round(row.calculos?.ponderacaoFabrica ?? 0)}%`,
            'G.Logistica': `${round(row.calculos?.produtividadeLogistica ?? 0)}%`,
            'P.Logistica': `${round(row.calculos?.ponderadoLogistica ?? 0)}%`,
            'G.Personal': `${round(row.calculos?.res_personal ?? 0)}%`,
            'P.Personal': `${round(row.calculos?.ponderadoPersonal ?? 0)}%`,
            'G.Total': `${round(row.calculos?.total ?? 0)}%`,
            'P.Total': `${round(row.calculos?.ponderadoTotal ?? 0)}%`,
            'G.Limpeza': `${round(row.calculos?.gLimpeza ?? 0)}%`,
            'P.Limpeza': `${round(row.statsIbersteel?.pLimpeza ?? 0)}%`,
            'G.Seguridad': `${round(row.calculos?.gSeguranca ?? 0)}%`,
            'P.Seguridad': `${round(row.statsIbersteel?.pSeguranca ?? 0)}%`,
            'G.TotalAud': `${round(row.calculos?.gTotalAuditoria ?? 0)}%`,
            'P.TotalAud': `${round(row.calculos?.pTotalAuditoria ?? 0)}%`,
        } : {}

        return {
            colaborador: row.nome || '',
            mes: row.mes_avaliacao || '',
            ...plafesaData,
            ...ibersteelData,
            ...ibersteelDataLog,
            nAccidentes: round(row.accidentes_trabalho ?? 0),
            'P.Seguranca': `${round(row.calculos?.ponderacaoSeguranca ?? 0)}%`,
            nFaltas: round(row.faltas_injustificadas ?? 0),
            'P.Absentismo': `${round(row.calculos?.ponderacaoAbsentismo ?? 0)}%`,
            ResultadoGlobal: `${row.calculos?.resultadoGlobal ?? 0}%`,
        };
    }), [colaboradores, user, view]);

    const columnColors = [
        companySettings.color, // Colaborador
        companySettings.color, // Mês
        '#B62640', '#B62640', // P. Fábrica (20%)
        '#B62640', '#B62640', // P. Linha (50%)
        '#B62640', '#B62640', // P. Individual (30%)
        '#B62640', '#B62640', // Total
        ...(user && user.company === 'plafesa'
            ? ['#9F23B7', '#9F23B7', // Pontualidade (40%)
                '#9F23B7', '#9F23B7', // Conhecimento
                '#9F23B7', '#9F23B7', // Resolução de Problemas
                '#9F23B7', '#9F23B7', // Responsabilidade
                '#9F23B7', '#9F23B7', // Cooperação
                '#9F23B7', '#9F23B7', // Autonomia
                '#9F23B7', '#9F23B7', // Organização
                '#9F23B7', '#9F23B7', // Total
                '#9F23B7', '#9F23B7'  // Total Pessoal
            ]
            : ['#9F23B7', '#9F23B7', // Organização/Limpeza
                '#9F23B7', '#9F23B7', // Seguridade/Ambiente
                '#9F23B7', '#9F23B7'  // Total 
            ]),
        '#1849DF', '#1849DF', // Segurança
        '#E01111', '#E01111', // Absentismo
        companySettings.color, 
        companySettings.color,
    ];

    return (
        <Container maxWidth={false} sx={{ padding: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('estatisticasprimas_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
            {user && user.company === 'ibersteel' && (
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                    <ValidatedSelect
                        labelId="view-label"
                        value={view}
                        fullWidth
                        onChange={handleViewChange}
                        label={t('estatisticasprimas_vista')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        <MenuItem value="Operador">{t('estatisticasprimas_operador')}</MenuItem>
                        <MenuItem value="Logistica">{t('estatisticasprimas_logistica')}</MenuItem>
                    </ValidatedSelect>
                </FormControl>
            )}
            <FormControl fullWidth sx={{ marginRight: 2 }}>
                <ValidatedSelect
                    labelId="year-label"
                    value={year}
                    fullWidth
                    onChange={handleYearChange}
                    label={t('estatisticasprimas_ano')}
                    required
                    formSubmitted={formSubmitted}
                >
                    {Array.from({ length: 10 }, (_, i) => {
                        const yearOption = new Date().getFullYear() - i;
                        return (
                            <MenuItem key={yearOption} value={yearOption}>
                                {yearOption}
                            </MenuItem>
                        );
                    })}
                </ValidatedSelect>
            </FormControl>
            {user && user.company === 'plafesa' ? (
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                        <ValidatedSelect
                        labelId="quarter-label"
                        value={quarter}
                        fullWidth
                        onChange={handleQuarterChange}
                        label={t('avaliacaocolaborador_trimestre')}
                        required
                        formSubmitted={formSubmitted}
                    >
                        <MenuItem value={1}>{t('avaliacaocolaborador_trimestre_janeiro_marco')}</MenuItem>
                        <MenuItem value={2}>{t('avaliacaocolaborador_trimestre_abril_junho')}</MenuItem>
                        <MenuItem value={3}>{t('avaliacaocolaborador_trimestre_julho_setembro')}</MenuItem>
                        <MenuItem value={4}>{t('avaliacaocolaborador_trimestre_outubro_dezembro')}</MenuItem>
                    </ValidatedSelect>
                    </FormControl>
            ) : (
                <FormControl fullWidth sx={{ marginRight: 2 }}>
                        <ValidatedSelect
                        labelId="semestre-label"
                        value={semestre}
                        fullWidth
                        onChange={handleSemestreChange}
                        label={t('avaliacaocolaborador_semestre')}
                        required
                        formSubmitted={formSubmitted}
                        >
                        <MenuItem value={1}>{t('avaliacaocolaborador_semestre_1')}</MenuItem>
                        <MenuItem value={2}>{t('avaliacaocolaborador_semestre_2')}</MenuItem>
                    </ValidatedSelect>  
                </FormControl>
            )}
                <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
                    {t('estatisticasprimas_buscar')}
                </Button>
            </Box>
            {loading ? (
                <Typography variant="h6" component="p" gutterBottom>
                    <CircularProgress />
                </Typography>
            ) : (
                <TableContainer component={Paper}>
                <Table>
                    
                <TableHead>
                    <TableRow>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                        <StyledTableCell colSpan={8} align="center" color='#B62640'>{t('estatisticasprimas_nivel_producao_produtividade')}</StyledTableCell>
                        {user && user.company === 'plafesa' ? (
                            <>
                                <StyledTableCell colSpan={18} align="center" color='#9F23B7'>{t('estatisticasprimas_pessoal')}</StyledTableCell>
                            </>
                        ) : (
                            <>
                                <StyledTableCell colSpan={6} align="center" color='#9F23B7'>{t('estatisticasprimas_auditoria_externa')}</StyledTableCell>
                            </>
                        )}
                        <StyledTableCell colSpan={2} align="center" color='#1849DF'>{t('estatisticasprimas_seguranca')}</StyledTableCell>
                        <StyledTableCell colSpan={2} align="center" color='#E01111'>{t('estatisticasprimas_absentismo')}</StyledTableCell>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                    </TableRow>
                </TableHead>
                {user && user.company === 'plafesa' ? (
                <CustomTableHead color="#add8e6">
                    <TableRow>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                        <StyledTableCell colSpan={8} color='#B62640'></StyledTableCell>
                        <StyledTableCell colSpan={2} color='#9F23B7'></StyledTableCell>
                        <StyledTableCell colSpan={14} align="center" color='#9F23B7'>{t('estatisticasprimas_desenvolvimento_pessoal_interpessoal')}</StyledTableCell>
                        <StyledTableCell colSpan={2} color='#9F23B7'></StyledTableCell>
                        <StyledTableCell colSpan={2} color='#1849DF'></StyledTableCell>
                        <StyledTableCell colSpan={2} color='#E01111'></StyledTableCell>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                    </TableRow>
                </CustomTableHead>
                ): null}
                <CustomTableHead color="#add8e6">
                    <TableRow>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                        <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_p_fabrica')}</StyledTableCell>
                        {user && user.company === 'ibersteel' && view === 'Logistica' ? (
                            <>
                                <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_p_logistica')}</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_e_personal')}</StyledTableCell>
                            </>
                        ) : (
                            <>
                            <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_p_linha')}</StyledTableCell>
                            <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_p_individual')}</StyledTableCell>
                            </>
                        )}
                        <StyledTableCell colSpan={2} align="center" color='#B62640'>{t('estatisticasprimas_total')}</StyledTableCell>
                        {user && user.company === 'plafesa' ? (
                            <>
                                <StyledTableCell colSpan={2} align="center" color='#9F23B7'>{t('estatisticasprimas_pontualidade')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_conhecimento')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_resolucao_problemas')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_responsabilidade')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_cooperacao')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_autonomia')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_organizacao')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_total')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_total_pessoal')}</StyledTableCell>
                            </>
                        ) : (
                            <>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_limpieza_organizacion')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_seguridad_ambiente')}</StyledTableCell>
                                <StyledTableCell colSpan={2} color='#9F23B7'>{t('estatisticasprimas_total')}</StyledTableCell>
                            </>
                        )}
                        <StyledTableCell colSpan={1} align="center" color='#1849DF'>{t('estatisticasprimas_numero_accidentes')}</StyledTableCell>
                        <StyledTableCell colSpan={1} align="center" color='#1849DF'>{t('estatisticasprimas_p_seguranca')}</StyledTableCell>
                        <StyledTableCell colSpan={1} align="center" color='#E01111'>{t('estatisticasprimas_numero_faltas_injustificadas')}</StyledTableCell>
                        <StyledTableCell colSpan={1} align="center" color='#E01111'>{t('estatisticasprimas_p_absentismo')}</StyledTableCell>
                        <StyledTableCell colSpan={2} color={companySettings.color}></StyledTableCell>
                    </TableRow>
                    </CustomTableHead>
                    <StyledTableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {tableData.map((row, rowIndex, array) => (
                            <TableRow key={rowIndex}>
                            {columns.map((column, colIndex) => {
                                if (column.id === 'colaborador' && rowIndex > 0 && array[rowIndex - 1]['colaborador'] === row['colaborador']) {
                                    return null;
                                }
                                const rowSpan = column.id === 'colaborador' ? array.filter(item => item['colaborador'] === row['colaborador']).length : 1;
                                const isFirstRow = rowIndex === 0 || array[rowIndex - 1]['colaborador'] !== row['colaborador'];
                                const isLastRow = rowIndex === array.length - 1 || array[rowIndex + 1]['colaborador'] !== row['colaborador'];
            
                                const cellStyle = {
                                    backgroundColor: columnColors[colIndex],
                                    ...(isFirstRow ? thickBorderStyleTop : {}),
                                    ...(isLastRow ? thickBorderStyleBottom : {}),
                                };
            
                                return (
                                    <StyledTableCell key={colIndex} rowSpan={rowSpan} style={cellStyle}>
                                        {row[column.id]}
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EstatisticasPrimas;