import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/geral.css';
import { Container, Typography,  CircularProgress, Card, Grid, CardContent, TableCell, Box, Button, Checkbox, List, ListItem, ListItemText, TablePagination, Tooltip, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert } from '@mui/material';
import { format } from 'date-fns';
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import { companyConfig } from '../../utils/config';
import ValidatedTextField from '../../components/ValidatedTextField';
import SimpleTable from '../../components/SimpleTable';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ValidarRegistos = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [records, setRecords] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [user, setUser] = useState(null);
    const [editRecord, setEditRecord] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [expandedRow, setExpandedRow] = useState(null);
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')
    const [avisoDetails, setAvisoDetails] = useState(null);
    const [observacoes, setObservacoes] = useState([])
    const [openDialog, setOpenDialog] = useState(false)

    const handleRowClick = (rowId) => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };


    useEffect(() => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const parsedUser = JSON.parse(userData);
                setUser(parsedUser);
            } else {
                navigate('/');
            }
        }, [navigate]);

    const fetchRecords = useCallback(async () => {
        if (!user) return;
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listarRegistos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const data = await response.json();
            if (data.records && Array.isArray(data.records)) {
                setRecords(data.records);
            } else {
                console.error('Expected an array but got:', data);
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setLoading(false);
        }
    }, [user]);

    useEffect(() => {
        fetchRecords();
    }, [fetchRecords]);

    const handleEdit = (record) => {
        setEditRecord(record);
    };
    const handleDelete = (record) => {
        setRecordToDelete(record);
        setDeleteConfirmOpen(true);
    };

    const fetchObservacoes = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/observacoes/${id}`, {   
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setObservacoes(result);
            } else {
                console.error('Expected an array but got:', result);
            }
        } catch (error) {
            console.error('Error fetching observacoes:', error);
        }
    };

    const confirmDelete = async () => {
        if (!user || !recordToDelete ) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/eliminarRegistos/${recordToDelete.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                setRecords((prevRecords) => prevRecords.filter(record => record.id !== recordToDelete.id));
                setSnackbarMessage(t('validarregistos_delete_success'));
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            } else {
                console.error('Error deleting record');
                setSnackbarMessage(t('validarregistos_delete_error'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            setSnackbarMessage(t('validarregistos_delete_error'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setDeleteConfirmOpen(false);
            setRecordToDelete(null);
        }
    };

    const handleSelect = (id) => {
        setSelectedRecords(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(recordId => recordId !== id)
                : [...prevSelected, id]
        );
    };

    const handleValidateSelected = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/validarRegistos`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ids: selectedRecords }),
                credentials: 'include',
            });

            if (response.ok) {
                setSelectedRecords([]);
                fetchRecords();
                setSnackbarMessage(t('validarregistos_validate_success'));
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            } else {
                console.error('Error validating records');
                setSnackbarMessage(t('validarregistos_validate_error'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error validating records:', error);
            setSnackbarMessage(t('validarregistos_validate_error'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    }, [selectedRecords, fetchRecords, user, t]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDateHour = (dateString) => {
        return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
    };
    const formatDate = (dateString) => {
        return format(new Date(dateString), 'dd/MM/yyyy');
    };

    const handleView = (record) => {
        fetchObservacoes(record.id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setObservacoes([]);
    };
    

    const companySettings = user ? companyConfig[user.company] : {};

    const handleSaveEdit = async () => {
        setFormSubmitted(true);
        if (!user || !editRecord) return;
        // Validar que todos los campos requeridos estén completos
        const { TURNO, DATA, LINHA, HORA_INICIO, HORA_FIM, FASES_C, PESO_I, PESO_C } = editRecord;
        const errors = {};
        if (TURNO === null || TURNO === undefined || TURNO === '') errors.TURNO = true;
        if (!DATA) errors.DATA = true;
        if (!LINHA) errors.LINHA = true;
        if (!HORA_INICIO) errors.HORA_INICIO = true;
        if (!HORA_FIM) errors.HORA_FIM = true;
        if (FASES_C === null || FASES_C === undefined || FASES_C === '') errors.FASES_C = true;
        if (PESO_I === null || PESO_I === undefined || PESO_I === '') errors.PESO_I = true;
        if (PESO_C === null || PESO_C === undefined || PESO_C === '') errors.PESO_C = true;

        if (Object.keys(errors).length > 0) {
            setSnackbarMessage(t('avaliacaocolaborador_preencha_todos_os_campos'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }
        // Convertir la fecha a una cadena de texto
        const recordToSave = {
            ...editRecord,
            DATA: new Date(editRecord.DATA).toISOString().split('T')[0], // Convierte la fecha a una cadena de texto en formato ISO
            PESO_I: parseInt(editRecord.PESO_I, 10),
            PESO_C: parseInt(editRecord.PESO_C, 10),
            FASES_C: parseInt(editRecord.FASES_C, 10),
            LOTES_C: parseInt(editRecord.LOTES_C, 10),
            // Agrega otras conversiones necesarias aquí
        };
        console.log('Edit Record:', recordToSave); // Verifica el contenido de editRecord
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/editarRegistos/${recordToSave.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(recordToSave),
                credentials: 'include',
            });

            if (response.ok) {
                setEditRecord(null);
                fetchRecords();
                setSnackbarMessage(t('validarregistos_edit_success'));
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setFormSubmitted(false);
            } else {
                console.error('Error saving record');
                setSnackbarMessage(t('validarregistos_edit_error'));
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error saving record:', error);
            setSnackbarMessage(t('validarregistos_edit_error'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const getAviso = (record) => {
        const avisos = [];
    
        if (record.T_PAR > 100) {
            avisos.push(t('validarregistos_aviso_tempo_paragem_maior_100'));
        }
        if (record.TEMPO_CORTE === 0) {
            avisos.push(t('validarregistos_aviso_tempo_corte_igual_0'));
        }
        if (record.TEMPO_CORTE > 100) {
            avisos.push(t('validarregistos_aviso_tempo_corte_maior_100'));
        }
        if (record.PESO_C !== record.PESO_T) {
            avisos.push(t('validarregistos_aviso_peso_cortado_diferente'));
        }
        if (record.LOTES_C !== record.LOTES_T && (((record.LINHA === 'CT3' || record.LINHA === 'CT4') && user.company === 'plafesa') || ((record.LINHA === 'CT2' || record.LINHA === 'CT3') && user.company === 'ibersteel')))  {
            avisos.push(t('validarregistos_aviso_lotes_cortados_diferente'));
        }
        if (record.FASES_C !== record.FASES_T && (((record.LINHA === 'CT1' || record.LINHA === 'CT2' || record.LINHA === 'CT5') && user.company === 'plafesa' ) || ((record.LINHA === 'CT1' || record.LINHA === 'CT4' || record.LINHA === 'CT5') && user.company === 'ibersteel'))) {
            avisos.push(t('validarregistos_aviso_fases_cortadas_diferente'));
        }
        if (record.PESO_I !== record.PESO_T) {
            avisos.push(t('validarregistos_aviso_peso_a_cortar_diferente'));
        }
    
        return avisos.length > 0 ? avisos : null; // Devuelve null si no hay avisos
    };

    const columns = useMemo(() => [
        { id: 'select', label: '', sortable: true  },
        { id: 'TURNO', label: t('producaoanalise_turno'), sortable: true  },
        { id: 'DATA', label: t('producaoanalise_data'), sortable: true  },
        { id: 'PERIODO', label: t('producaoanalise_periodo_turno'), sortable: true  },
        { id: 'LINHA', label: t('producaoanalise_linha'), sortable: true  },
        { id: 'N_COLABORADOR', label: t('resultado_primas_numero_colaborador'), sortable: true  },
        { id: 'TEMPO_CORTE', label: t('producaoanalise_tempo_corte'), sortable: true  },
        { id: 'OF', label: t('producaoanalise_of'), sortable: true  },
        { id: 'BOBINE', label: t('producaoanalise_bobine'), sortable: true  },
        { id: 'T_PAR', label: t('producaoanalise_paragem'), sortable: true  },
        { id: 'aviso', label: 'Aviso', sortable: false  }, // Nueva columna para los aviso
        { id: 'actions', label: t('evaluacionlogistica_acciones'), sortable: false }
    ], [t]);

    const handleSort = (columnId) => {
        if (orderBy === columnId) {
            // Si ya se está ordenando por esta columna, alternar la dirección
            setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            // Si es una nueva columna, establecerla como la columna de ordenación
            setOrderBy(columnId);
            setOrderDirection('asc');
        }
    };

    const recordMatchesSearch = (record, searchTerm) => {
        if (!searchTerm) return true;
        return Object.values(record).some((value) =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    };
    
    const filteredRecords = records
        .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general


        const sortedRecords = useMemo(() => {
            if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
            return [...filteredRecords].sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];
        
                if (aValue === null || aValue === undefined) return 1;
                if (bValue === null || bValue === undefined) return -1;
        
                // Si la columna es una fecha, convertir a objetos Date
                if (orderBy === 'DATA' || orderBy === 'DATA_INI' || orderBy === 'DATA_FIM') {
                    return orderDirection === 'asc'
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                }
        
                // Si la columna es un número, comparar como números
                if (typeof aValue === 'number' || !isNaN(aValue)) {
                    return orderDirection === 'asc'
                        ? parseFloat(aValue) - parseFloat(bValue)
                        : parseFloat(bValue) - parseFloat(aValue);
                }
        
                // Si la columna es texto, comparar como cadenas
                if (typeof aValue === 'string') {
                    return orderDirection === 'asc'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }
        
                return 0; // Si no se puede comparar, no cambiar el orden
            });
        }, [filteredRecords, orderBy, orderDirection]);

    const paginatedRecords = useMemo(() => {
        return sortedRecords.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [sortedRecords, page, rowsPerPage]);

    const data = paginatedRecords.map((record, index, array) => {
        const isSameAsPrevious = (field) => index > 0 && array[index - 1][field] === record[field];
        const isSameAsNext = (field) => index < array.length - 1 && array[index + 1][field] === record[field];
        const aviso = getAviso(record); // Generar el aviso para cada registro
        return {
        select: (
            <Checkbox
                checked={selectedRecords.includes(record.id)}
                onChange={() => handleSelect(record.id)}
            />
        ),
        id: record.id,
        TURNO: record.TURNO,
        DATA: formatDate(record.DATA),
        DATA_INI: formatDateHour(record.DATA_INI),
        DATA_FIM: formatDateHour(record.DATA_FIM),
        PERIODO: record.PERIODO,
        LINHA: record.LINHA,
        N_COLABORADOR: record.N_COLABORADOR,
        N_AUXILIAR: record.N_AUXILIAR,
        HORA_INICIO: record.HORA_INICIO,
        HORA_FIM: record.HORA_FIM,
        TEMPO_CORTE: (
            <TableCell style={{ backgroundColor: record.TEMPO_CORTE > 100 || record.TEMPO_CORTE === 0 ? 'yellow' : 'inherit' }}>
                {record.TEMPO_CORTE}
            </TableCell>
        ),
        LARGURA: record.LARGURA,
        ESP: record.ESP,
        FASES_T: record.FASES_T,
        FASES_C: record.FASES_C,
        LOTES_T: record.LOTES_T,
        LOTES_C: record.LOTES_C,
        OF: (
            <TableCell style={{ backgroundColor: isSameAsPrevious('OF') || isSameAsNext('OF') ? 'lightblue' : 'inherit' }}>
                {record.OF}
            </TableCell>
        ),
        BOBINE: (
            <TableCell style={{ backgroundColor: isSameAsPrevious('BOBINE') || isSameAsNext('BOBINE') ? 'lightblue' : 'inherit' }}>
                {record.BOBINE}
            </TableCell>
        ),
        PESO_I: record.PESO_I,
        PESO_T: record.PESO_T,
        PESO_C: record.PESO_C,
        PARAGEM: record.PARAGEM,
        T_PAR: (
            <TableCell style={{ backgroundColor: record.T_PAR > 0 ? 'yellow' : 'inherit' }}>
                {record.T_PAR}
            </TableCell>
        ),
        OBSERVACAO: record.OBSERVACAO,
        aviso: (
            aviso && aviso.length > 0 ? (
                <Tooltip
                    title={
                        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                            {aviso.map((msg, index) => (
                                <li key={index} style={{ color: 'black' }}>- {msg}</li>
                            ))}
                        </ul>
                    }
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: '#fdecea', // Fondo rojo suave
                                color: 'black', // Texto blanco
                                borderRadius: '8px', // Bordes redondeados
                                padding: '8px', // Espaciado interno
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra ligera
                            },
                        },
                    }}
                >
                    <span
                        style={{ color: '#d32f2f', cursor: 'pointer' }}
                        onClick={() => setAvisoDetails(aviso)}
                    >
                        ⚠️
                    </span>
                </Tooltip>
            ) : null
        ),
        actions: (
            <>
                <Button variant="contained" color="warning" onClick={() => handleEdit(record)} sx={{ marginRight: 1 }}>
                    <FaEdit />
                </Button>
                <Button variant="contained" color="error" onClick={() => handleDelete(record)}>
                    <FaTrash />
                </Button>
                {record.OBSERVACAO === 1 ? (
                    <Button variant="contained" color="primary" onClick={() => handleView(record)}>
                                    <FaEye />
                    </Button>
                ) : null}
            </>
        )
    };
    });

    const renderExpandedRow = (row) => (
        <Card sx={{ margin: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>{t('producaoanalise_detalhes_adicionais')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>{t('listarcolab_id')}:</strong> {row.id}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_numero_auxiliar')}:</strong> {row.N_AUXILIAR}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_data_inicio')}:</strong> {row.DATA_INI}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_data_fim')}:</strong> {row.DATA_FIM}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_largura')}:</strong> {row.LARGURA}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_espessura')}:</strong> {row.ESP}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_fases_t')}:</strong> {row.FASES_T}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_fases_c')}:</strong> {row.FASES_C}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_paragens')}:</strong> {row.PARAGEM}</Typography>
                    

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>{t('producaoanalise_hora_inicio')}:</strong> {row.HORA_INICIO}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_hora_fim')}:</strong> {row.HORA_FIM}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_lotes_t')}:</strong> {row.LOTES_T}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_lotes_c')}:</strong> {row.LOTES_C}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_peso_i')}:</strong> {row.PESO_I}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_peso_t')}:</strong> {row.PESO_T}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_peso_c')}:</strong> {row.PESO_C}</Typography>
                    <Typography variant="body2"><strong>{t('producaoanalise_obs')}:</strong> {row.OBSERVACAO}</Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
    );
    // Para la tabla de observações en el diálogo:
    const columnsObservacoes = [
        { id: 'observacao', label: t('controlof_observacoes') }
    ];
    
    const dataObservacoes = observacoes.map((observacao, index) => ({
        observacao: observacao.OBSERVACAO
    }));

    // Especificaciones de las alertas
    const alertSpecifications = [
        t('validarregistos_aviso_tempo_paragem_maior_100'),
        t('validarregistos_aviso_tempo_corte_igual_0'),
        t('validarregistos_aviso_tempo_corte_maior_100'),
        t('validarregistos_aviso_peso_cortado_diferente'),
        t('validarregistos_aviso_lotes_cortados_diferente'),
        t('validarregistos_aviso_fases_cortadas_diferente'),
        t('validarregistos_aviso_peso_a_cortar_diferente'),
    ];

    

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('validarregistos_titulo')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            {/* Card con las especificaciones de las alertas */}
            <Card sx={{ marginBottom: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {t('validarregistos_detalhes_aviso')}
                    </Typography>
                    <List>
                        {alertSpecifications.map((spec, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemText primary={`- ${spec}`} />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0); // Reinicia la paginación al buscar
                    }}
                    style={{ marginBottom: '20px' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidateSelected}
                    disabled={selectedRecords.length === 0}
                    sx={{ marginBottom: 2 }}
                >
                    {t('validarregistos_validar_selecionados')}
                </Button>
                </Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <SimpleTable
                        columns={columns}
                        data={data}
                        expandedRow={expandedRow}
                        handleRowClick={handleRowClick}
                        renderExpandedRow={renderExpandedRow}
                        onSort={handleSort}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                    />
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={records.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {editRecord && (
                <Dialog open={Boolean(editRecord)} onClose={() => setEditRecord(null)}>
                    <DialogTitle>{t('validarregistos_editar_registro')}</DialogTitle>
                    <DialogContent>
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_turno')}
                            type="text"
                            fullWidth
                            value={editRecord.TURNO}
                            onChange={(e) => setEditRecord({ ...editRecord, TURNO: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_data')}
                            type="date"
                            fullWidth
                            value={editRecord.DATA ? editRecord.DATA.split('T')[0] : ''}
                            onChange={(e) => setEditRecord({ ...editRecord, DATA: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <TextField
                            margin="dense"
                            label={t('producaoanalise_periodo_turno')}
                            type="text"
                            fullWidth
                            value={editRecord.PERIODO}
                            onChange={(e) => setEditRecord({ ...editRecord, PERIODO: e.target.value })}
                            disabled
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('performanceoperador_linha')}
                            type="text"
                            fullWidth
                            value={editRecord.LINHA}
                            onChange={(e) => setEditRecord({ ...editRecord, LINHA: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <TextField
                            margin="dense"
                            label={t('estatisticasprimas_operador')}
                            type="text"
                            fullWidth
                            value={editRecord.NOME}
                            onChange={(e) => setEditRecord({ ...editRecord, N_COLABORADOR: e.target.value })}
                            disabled
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_hora_inicio')}
                            type="time"
                            fullWidth
                            value={editRecord.HORA_INICIO}
                            onChange={(e) => setEditRecord({ ...editRecord, HORA_INICIO: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_hora_fim')}
                            type="time"
                            fullWidth
                            value={editRecord.HORA_FIM}
                            onChange={(e) => setEditRecord({ ...editRecord, HORA_FIM: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <TextField
                            margin="dense"
                            label={t('producaoanalise_fases_programadas')}
                            type="text"
                            fullWidth
                            value={editRecord.FASES_T}
                            onChange={(e) => setEditRecord({ ...editRecord, FASES_T: e.target.value })}
                            disabled
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_fases_cortadas')}
                            type="text"
                            fullWidth
                            value={editRecord.FASES_C}
                            onChange={(e) => setEditRecord({ ...editRecord, FASES_C: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <TextField
                            margin="dense"
                            label={t('producaoanalise_lotes_t')}
                            type="text"
                            fullWidth
                            value={editRecord.LOTES_T}
                            onChange={(e) => setEditRecord({ ...editRecord, LOTES_T: e.target.value })}
                            disabled
                        />
                        <TextField
                            margin="dense"
                            label={t('producaoanalise_lotes_c')}
                            type="text"
                            fullWidth
                            value={editRecord.LOTES_C}
                            onChange={(e) => setEditRecord({ ...editRecord, LOTES_C: e.target.value })}
                            disabled
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('controlof_peso_cortar')}
                            type="text"
                            fullWidth
                            value={editRecord.PESO_I}
                            onChange={(e) => setEditRecord({ ...editRecord, PESO_I: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                        <TextField
                            margin="dense"
                            label={t('producaoanalise_peso_programado')}
                            type="text"
                            fullWidth
                            value={editRecord.PESO_T}
                            onChange={(e) => setEditRecord({ ...editRecord, PESO_T: e.target.value })}
                            disabled
                        />
                        <ValidatedTextField
                            margin="dense"
                            label={t('producaoanalise_peso_cortado')}
                            type="text"
                            fullWidth
                            value={editRecord.PESO_C}
                            onChange={(e) => setEditRecord({ ...editRecord, PESO_C: e.target.value })}
                            required
                            formSubmitted={formSubmitted}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setEditRecord(null)} color="primary">
                            {t('validar_dialog_cancel')}
                        </Button>
                        <Button onClick={handleSaveEdit} color="primary">
                            {t('evaluacionlogistica_guardar')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
                <Dialog
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                >
                    <DialogTitle>{t('validarregistos_confirmar_eliminacion')}</DialogTitle>
                    <DialogContent>
                        <Typography>{t('validarregistos_confirmar_eliminacion_texto')}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                            {t('validar_dialog_cancel')}
                        </Button>
                        <Button onClick={confirmDelete} color="error">
                            {t('validar_dialog_confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={Boolean(avisoDetails)} onClose={() => setAvisoDetails(null)}>
                    <DialogTitle sx={{ color: '#d32f2f' }}>{t('validarregistos_detalhes_aviso')}</DialogTitle>
                    <DialogContent
                        sx={{
                            backgroundColor: '#fdecea', // Fondo rojo suave
                            borderRadius: '8px', // Bordes redondeados
                        }}
                    >
                        {avisoDetails && avisoDetails.length > 0 ? (
                            <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                                {avisoDetails.map((msg, index) => (
                                    <li key={index} style={{ color: '#d32f2f', marginBottom: '8px' }}>
                                        - {msg}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography>{t('validarregistos_no_hay_detalles')}</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAvisoDetails(null)} color="primary">
                            {t('controlof_fechar')}
                        </Button>
                    </DialogActions>
                </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
            <DialogTitle>{t('producaoanalise_detalles_linea')}</DialogTitle>
                <DialogContent>
                    <SimpleTable columns={columnsObservacoes} data={dataObservacoes} />
                </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    {t('controlof_fechar')}
                </Button>
            </DialogActions>
        </Dialog>
        </Container>
    );
};

export default ValidarRegistos;