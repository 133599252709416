import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    es: {
        translation: {
            elegir_empresa: "Elige tu empresa",
            navbar_error_session: "Error al cerrar sesión",
            navbar_error_servidor: "Error en el servidor",
            navbar_menu_produccion: "Producción",
            navbar_submenu_produccion_validar: "Validar Registros",
            navbar_submenu_produccion_registro: "Registro de Producción",
            navbar_menu_registros: "Registros",
            navbar_submenu_registros_colaborador: "Registrar Colaborador",
            navbar_submenu_registros_listar: "Listar Colaboradores",
            navbar_menu_supervision: "Supervision",
            navbar_submenu_supervision_control: "Control Manual de OF",
            navbar_submenu_supervision_diaria: "Producción Diária",
            navbar_submenu_supervision_analisis: "Análisis de Producción",
            navbar_menu_manutencion: "Mantenimiento",
            navbar_submenu_manutencion_tareas: "Lista de Tareas",
            navbar_submenu_manutencion_registro: "Registro de Manutención",
            navbar_submenu_manutencion_realizar: "Realizar Manutención",
            navbar_menu_madera: "Madera",
            navbar_submenu_madera_loteopp: "Lote - OPP",
            navbar_submenu_madera_entrada: "Entrada de Madera",
            navbar_submenu_madera_historico: "Histórico de Entradas",
            navbar_submenu_madera_listaopp: "Lista de OPP",
            navbar_submenu_madera_stock: "Stock",
            navbar_submenu_madera_construccion: "Construcción de Palets",
            navbar_menu_primas: "Primas",
            navbar_submenu_primas_evaluacion_colaborador: "Evaluación Colaborador",
            navbar_submenu_primas_rendimiento_operador: "Rendimiento Operador",
            navbar_submenu_primas_estadisticas: "Estadísticas",
            navbar_submenu_primas_resultados: "Resultados",
            navbar_submenu_primas_evaluacion_logistica: "Evaluación Logística",
            navbar_submenu_primas_registro_auditoria: "Registro Auditoría",
            navbar_menu_tarefas: "Tareas",
            navbar_submenu_tarefas_loteopp: "Lote - OPP",
            navbar_submenu_tarefas_manutencion_nivel1: "Mantenimiento 1er Nivel",
            navbar_logout: "Salir",
            produccion_anual_titulo_grafico: "Producción Anual por Mes",
            produccion_diaria_titulo_grafico: "Producción Diária por Linea",
            simpletable_totales: 'Totales:',
            tarefasporfazer_title: "Notificaciones",
            tarefasporfazer_no_notifications: "No hay notificaciones pendientes",
            tarefasporfazer_notification: "No se realizó mantenimiento el {{date}} para la línea {{line}}",
            tarefasporfazer_error_array: "Se esperaba un array, pero se recibió:",
            tarefasporfazer_error_fetch_lines: "Error al obtener las líneas:",
            tarefasporfazer_error_fetch_records: "Error al obtener los registros diarios:",
            validatedselect_required_field: "Campo obligatorio",
            sessionchecker_session_expired: "Sesión expirada",
            sessionchecker_home_title: "Inicio",
            sessionchecker_plafesa_login_title: "Plafesa - Login",
            sessionchecker_ibersteel_login_title: "Ibersteel - Login",
            sessionchecker_plafesa_dashboard_title: "Plafesa - Dashboard",
            sessionchecker_ibersteel_dashboard_title: "Ibersteel - Dashboard",
            home_welcome_message: "Bienvenido a la Web de {{companyName}}",
            login_title: "Login",
            login_user_label: "Número de Usuario",
            login_password_label: "Contraseña",
            login_button: "Iniciar sesión",
            login_switch_company: "Ir a {{company}}",
            login_error_message: "Error en el login",
            login_server_error: "Error al conectar con el servidor",
            validar_title: "Validar Turno",
            validar_user_label: "Número de Colaborador",
            validar_select_turno: "Seleccione el Turno",
            validar_turno_manha: "Mañana",
            validar_turno_tarde: "Tarde",
            validar_turno_noite: "Noche",
            validar_select_auxiliar: "Seleccione el Auxiliar",
            validar_auxiliar_none: "Sin Auxiliar",
            validar_button_validate: "Validar",
            validar_dialog_title: "Confirmar Turno",
            validar_dialog_text: "¿Está seguro de que se encuentra en este turno \"{{turno}}\"?",
            validar_dialog_cancel: "Cancelar",
            validar_dialog_confirm: "Confirmar",
            validar_loading: "Validando...",
            validar_error_fetch_session: "Error al obtener la sesión",
            validar_error_fetch_auxiliares: "Error al obtener auxiliares",
            validar_error_select_auxiliar: "Seleccione un auxiliar válido.",
            validar_error_select_turno: "Seleccione un turno válido.",
            validar_error_invalid_turno: "El turno seleccionado no es válido.",
            validar_error_validate_session: "Error al validar la sesión",
            validar_turno_terminado: "El turno anterior ya terminó. Por favor, cree un nuevo turno.",
            avaliacaocolaborador_enero: "enero",
            avaliacaocolaborador_febrero: "febrero",
            avaliacaocolaborador_marzo: "marzo",
            avaliacaocolaborador_abril: "abril",
            avaliacaocolaborador_mayo: "mayo",
            avaliacaocolaborador_junio: "junio",
            avaliacaocolaborador_julio: "julio",
            avaliacaocolaborador_agosto: "agosto",
            avaliacaocolaborador_septiembre: "septiembre",
            avaliacaocolaborador_octubre: "octubre",
            avaliacaocolaborador_noviembre: "noviembre",
            avaliacaocolaborador_diciembre: "diciembre",
            avaliacaocolaborador_preencha_todos_os_campos: "Por favor, complete todos los campos.",
            avaliacaocolaborador_avaliacao_registrada_corretamente: "Evaluación registrada correctamente.",
            avaliacaocolaborador_erro_ao_registrar_avaliacao: "Error al registrar la evaluación.",
            avaliacaocolaborador_topico: "Tópico",
            avaliacaocolaborador_descricao: "Descripción",
            avaliacaocolaborador_nivel1: "Nivel 1",
            avaliacaocolaborador_nivel2: "Nivel 2",
            avaliacaocolaborador_nivel3: "Nivel 3",
            avaliacaocolaborador_nivel4: "Nivel 4",
            avaliacaocolaborador_conhecimento: "Conocimiento",
            avaliacaocolaborador_conhecimento_descricao: "Adquiere y comprende los conocimientos técnicos aplicándolos con rigor en contextos específicos.",
            avaliacaocolaborador_resolucao_problemas: "Resolución de Problemas",
            avaliacaocolaborador_resolucao_problemas_descricao: "Adopta estrategias adecuadas para la resolución de problemas.",
            avaliacaocolaborador_responsabilidade: "Responsabilidad",
            avaliacaocolaborador_responsabilidade_descricao: "Adequa actitudes y posturas en diferentes contextos, revelando sentido de responsabilidad.",
            avaliacaocolaborador_cooperacao: "Cooperación",
            avaliacaocolaborador_cooperacao_descricao: "Coopera con los demás, trabajando en equipo y valorando las diferentes perspectivas.",
            avaliacaocolaborador_autonomia: "Autonomía",
            avaliacaocolaborador_autonomia_descricao: "Demuestra autonomía y compromiso.",
            avaliacaocolaborador_organizacao: "Organización",
            avaliacaocolaborador_organizacao_descricao: "Es organizado y busca soluciones ante las dificultades.",
            avaliacaocolaborador_formulario_titulo: "Formulario de Evaluación del Colaborador",
            avaliacaocolaborador_ano: "Año",
            avaliacaocolaborador_trimestre: "Trimestre",
            avaliacaocolaborador_trimestre_janeiro_marco: "Enero - Marzo",
            avaliacaocolaborador_trimestre_abril_junho: "Abril - Junio",
            avaliacaocolaborador_trimestre_julho_setembro: "Julio - Septiembre",
            avaliacaocolaborador_trimestre_outubro_dezembro: "Octubre - Diciembre",
            avaliacaocolaborador_semestre: "Semestre",
            avaliacaocolaborador_semestre_1: "Enero - Junio",
            avaliacaocolaborador_semestre_2: "Julio - Diciembre",
            avaliacaocolaborador_avaliacao_mensal: "Evaluación Mensual",
            avaliacaocolaborador_pontualidade: "Puntualidad",
            avaliacaocolaborador_acidentes: "Accidentes",
            avaliacaocolaborador_faltas: "Faltas",
            avaliacaocolaborador_fte: "FTE",
            avaliacaocolaborador_flexibilidade_polivalencia: "Flexibilidad y Polivalencia",
            avaliacaocolaborador_disponibilidade: "Disponibilidad",
            avaliacaocolaborador_questionario_avaliacao: "Cuestionario de Evaluación",
            avaliacaocolaborador_enviar: "Enviar",
            estatisticasprimas_no_se_encontraron_registros: "No se encontraron registros",
            estatisticasprimas_error_obtener_datos: "Error al obtener los datos",
            estatisticasprimas_colaborador: "Colaborador",
            estatisticasprimas_mes: "Mes",
            estatisticasprimas_numero_accidentes: "Nº de Accidentes",
            estatisticasprimas_numero_faltas_injustificadas: "Nº Faltas Inj.",
            estatisticasprimas_p_absentismo: "P.",
            estatisticasprimas_resultado_global: "Resultado Global",
            estatisticasprimas_titulo: "Estadísticas Primas",
            estatisticasprimas_vista: "Vista",
            estatisticasprimas_operador: "Operador",
            estatisticasprimas_logistica: "Logística",
            estatisticasprimas_ano: "Año",
            estatisticasprimas_buscar: "Buscar",
            estatisticasprimas_nivel_producao_produtividade: "Nivel de Producción y Productividad (70%)",
            estatisticasprimas_pessoal: "Personal (30%)",
            estatisticasprimas_auditoria_externa: "Auditoría Externa (30%)",
            estatisticasprimas_seguranca: "Seguridad",
            estatisticasprimas_absentismo: "Absentismo",
            estatisticasprimas_desenvolvimento_pessoal_interpessoal: "Desarrollo Personal e Interpersonal (60%)",
            estatisticasprimas_p_fabrica: "P. Fábrica (20%)",
            estatisticasprimas_p_logistica: "P. Logística (50%)",
            estatisticasprimas_e_personal: "E. Personal (30%)",
            estatisticasprimas_p_linha: "P. Línea (50%)",
            estatisticasprimas_p_individual: "P. Individual (30%)",
            estatisticasprimas_total: "Total",
            estatisticasprimas_pontualidade: "Pontualidad (40%)",
            estatisticasprimas_conhecimento: "Conocimiento",
            estatisticasprimas_resolucao_problemas: "Resolución de Problemas",
            estatisticasprimas_responsabilidade: "Responsabilidad",
            estatisticasprimas_cooperacao: "Cooperación",
            estatisticasprimas_autonomia: "Autonomía",
            estatisticasprimas_organizacao: "Organización",
            estatisticasprimas_total_pessoal: "Total Personal",
            estatisticasprimas_limpieza_organizacion: "Limpieza/Organización (50%)",
            estatisticasprimas_seguridad_ambiente: "Seguridad/Ambiente (50%)",
            estatisticasprimas_p_seguranca: "P.",
            evaluacionlogistica_error_editar_evaluacion: "Error al editar la evaluación",
            evaluacionlogistica_camiones_cargados: "Camiones cargados",
            evaluacionlogistica_bobinas_recibidas: "Bobinas recibidas",
            evaluacionlogistica_acciones: "Acciones",
            evaluacionlogistica_titulo: "Evaluación Logística",
            evaluacionlogistica_ingresar_evaluacion: "Ingresar evaluación",
            evaluacionlogistica_guardar_evaluacion: "Guardar Evaluación",
            evaluacionlogistica_registros_existentes: "Registros Existentes",
            evaluacionlogistica_editar_evaluacion: "Editar Evaluación",
            evaluacionlogistica_cancelar: "Cancelar",
            evaluacionlogistica_guardar: "Guardar",
            performanceoperador_colaborador: "Colaborador",
            performanceoperador_mes: "Mes",
            performanceoperador_linha: "Línea",
            performanceoperador_funcao: "Función",
            performanceoperador_qt_bobines: "Qt. Bobinas",
            performanceoperador_qt_fases: "Qt. Fases",
            performanceoperador_qt_lotes: "Qt. Lotes",
            performanceoperador_qt_turnos: "Qt. Turnos",
            performanceoperador_med_fases_bob: "Med. Fases/Bob",
            performanceoperador_obj_fases_turno_mes: "Obj - Fases/Turno Mes",
            performanceoperador_med_paquetes_turno: "Med. Paquetes/Turno",
            performanceoperador_obj_paquetes_turno_mes: "Obj - Paquetes/Turno Mes",
            performanceoperador_med_bobines_turno: "Med. Bobinas/Turno",
            performanceoperador_obj_bobines_turno_mes: "Obj - Bobinas/Turno Mes",
            performanceoperador_ton_mes: "Ton/mes",
            performanceoperador_obj_ton_turno_mes: "Obj - Ton/Turno Mes",
            performanceoperador_real_ton_total_mes: "Real - Ton/(total) Mes",
            performanceoperador_obj_ton_total_mes: "Obj - Ton(total) Mes",
            performanceoperador_ponderacao_bob: "Ponderación Bob.",
            performanceoperador_ponderacao_lotes_fases: "Ponderación Lotes/Fases",
            performanceoperador_resultado_individual: "Resultado Individual",
            performanceoperador_resultado_linha: "Resultado Línea",
            performanceoperador_resultado_fabrica: "Resultado Fábrica",
            performanceoperador_resultado_global: "Resultado Global",
            performanceoperador_titulo: "Performance del Operador",
            registoauditoria_error_registrar_auditoria: "Error al registrar la auditoría",
            registoauditoria_seguranca_ambiente: "Seguridad/Ambiente",
            registoauditoria_limpeza_organizacao: "Limpieza/Organización",
            registo_auditoria_title: "Registro de Auditoría",
            registo_auditoria_ingresar_registros: "Ingresar Registros",
            registo_auditoria_editar_auditoria: "Editar Auditoría",
            mes_janeiro: "Enero",
            mes_fevereiro: "Febrero",
            mes_marco: "Marzo",
            mes_abril: "Abril",
            mes_maio: "Mayo",
            mes_junho: "Junio",
            mes_julho: "Julio",
            mes_agosto: "Agosto",
            mes_setembro: "Septiembre",
            mes_outubro: "Octubre",
            mes_novembro: "Noviembre",
            mes_dezembro: "Diciembre",
            resultado_primas_error_registrar_prima: "Error al registrar la prima",
            resultado_primas_error_editar_prima: "Error al editar la prima",
            resultado_primas_numero_colaborador: "Nº Colaborador",
            resultado_primas_nome: "Nombre",
            resultado_primas_funcao: "Función",
            resultado_primas_total: "Total",
            resultado_primas_gravar: "Guardar",
            resultado_primas_alterar: "Modificar",
            resultado_primas_titulo: "Resultado Primas",
            resultado_primas_alterar_prima: "Modificar Prima",
            resultado_primas_modificar_valor: "Modifique el valor de la prima.",
            resultado_primas_prima: "Prima",
            listarcolab_colaborador_editado_correctamente: "Colaborador editado correctamente!",
            listarcolab_error_editar_colaborador: "Error al editar colaborador",
            listarcolab_id: "ID",
            listarcolab_colaborador: "Colaborador",
            listarcolab_numero_colaborador: "Nº Colaborador",
            listarcolab_acesso: "Acceso",
            listarcolab_ativo: "Activo",
            listarcolab_bloqueado: "Bloqueado",
            listarcolab_perfil: "Perfil",
            listarcolab_acoes: "Acciones",
            listarcolab_titulo: "Listar Colaboradores",
            listarcolab_editar_colaborador: "Editar Colaborador",
            listarcolab_nome: "Nombre",
            listarcolab_apelido: "Apellido",
            listarcolab_acesso_plataforma: "Acceso a la Plataforma",
            listarcolab_password: "Contraseña",
            registarcolab_colaborador_registrado_correctamente: "Colaborador registrado correctamente!",
            registarcolab_error_registrando_colaborador: "Error registrando colaborador",
            registarcolab_registrar: "Registrar",
            controlof_of_alterada_exito: "OF alterada con éxito.",
            controlof_error_alterar_of: "Error al alterar OF.",
            controlof_agregar_bobina: "Por favor, agregue al menos una bobina.",
            controlof_of_registrada_exito: "OF registrada con éxito.",
            controlof_error_registrar_of: "Error al registrar OF.",
            controlof_numero_of: "Número de OF",
            controlof_acao: "Acción",
            controlof_abrir: "Abrir",
            controlof_fechar: "Cerrar",
            controlof_registro_manual_of: "Registro Manual de OF",
            controlof_bobinas: "Bobinas",
            controlof_bobina: "Bobina",
            controlof_numero: "Número",
            controlof_largura: "Largura",
            controlof_espessura: "Espesor",
            controlof_peso_cortar: "Peso a Cortar",
            controlof_fases: "Fases",
            controlof_lotes_por_fase: "Nº de Lotes por Fase",
            controlof_familia: "Familia",
            controlof_observacoes: "Observaciones",
            controlof_remover_bobina: "Remover Bobina",
            controlof_adicionar_bobina: "Adicionar Bobina",
            producaoanalise_rango_datas_invalido: "Ingrese un rango de fechas válido.",
            producaoanalise_estado: "Estado",
            producaoanalise_periodo_turno: "Periodo",
            producaoanalise_linha: "Línea",
            producaoanalise_colaborador: "Colaborador",
            producaoanalise_numero_auxiliar: "Nº Auxiliar",
            producaoanalise_tempo_corte: "Tiempo de Corte (min.)",
            producaoanalise_of: "OF",
            producaoanalise_bobine: "Bobina",
            producaoanalise_peso_cortado: "Peso Cortado",
            producaoanalise_paragem: "Parada (min.)",
            producaoanalise_info: "Info",
            producaoanalise_data: "Fecha",
            producaoanalise_hora_inicio: "Hora Inicio",
            producaoanalise_hora_fim: "Hora Fin",
            producaoanalise_familia: "Familia",
            producaoanalise_espessura: "Espesor",
            producaoanalise_largura: "Ancho",
            producaoanalise_fases_t: "Fases T",
            producaoanalise_fases_c: "Fases C",
            producaoanalise_lotes_t: "Lotes T",
            producaoanalise_lotes_c: "Lotes C",
            producaoanalise_obs: "Observaciones",
            producaoanalise_detalhes_adicionais: "Detalles Adicionales",
            producaoanalise_turno: "Turno",
            producaoanalise_data_inicio: "Fecha de Inicio",
            producaoanalise_data_fim: "Fecha de Fin",
            producaoanalise_peso_inicial: "Peso Inicial",
            producaoanalise_peso_total: "Peso Total",
            producaoanalise_assunto: "Asunto",
            producaoanalise_tempo: "Tiempo",
            producaoanalise_categoria: "Categoría",
            producaoanalise_titulo: "Análisis de Producción",
            producaoanalise_detalles_linea: "Detalles de la Línea",
            producaoanalise_paragens: "Paradas",
            producaodiaria_titulo: "Producción Diaria",
            registoproducao_erro_buscar_ultimo_registo: "Error al buscar el último registro de la línea.",
            registoproducao_nenhum_registo_encontrado: "No se encontró ningún registro para esta línea.",
            registoproducao_visualizando_como_admin: "Estás visualizando esta línea como administrador.",
            registoproducao_linha_finalizada: "Esta línea está finalizada y no puede ser accedida.",
            registoproducao_linha_ocupada: "Esta línea está ocupada por otro operador.",
            registoproducao_nao_pode_alterar_linha: "No puede alterar la línea hasta finalizar la producción.",
            registoproducao_selecione_auxiliar_valido: "Seleccione un auxiliar válido.",
            registoproducao_peso_maior_zero: "El peso a cortar debe ser mayor que cero.",
            registoproducao_peso_maior_total: "El peso a cortar no puede ser mayor que el peso total.",
            registoproducao_hora_inicio_maior_atual: "La hora de inicio no puede ser mayor que la hora actual.",
            registoproducao_hora_inicio_menor_fim: "La hora de inicio no puede ser menor que la hora de fin del último registro.",
            registoproducao_tempo_atraso: "Tiempo de atraso: {{minutos}} minutos.",
            registoproducao_insira_comentario_atraso: "Por favor, ingrese un comentario explicando la razón del atraso.",
            registoproducao_erro_iniciar_producao: "Error al iniciar la producción.",
            registoproducao_sucesso_iniciar_producao: "Producción iniciada con éxito.",
            registoproducao_erro_registar_parametros: "Error al registrar los parámetros de la máquina.",
            registoproducao_sucesso_registar_parametros: "Parámetros de la máquina registrados con éxito.",
            registoproducao_erro_buscar_categorias_paragens: "Error al buscar categorías de paradas.",
            registoproducao_hora_inicio_menor_producao: "La hora de inicio no puede ser menor que la hora de inicio de la producción.",
            registoproducao_hora_fim_menor_inicio: "La hora de fin debe ser mayor que la hora de inicio.",
            registoproducao_erro_registar_paragem: "Error al registrar la parada.",
            registoproducao_sucesso_registar_paragem: "Parada registrada con éxito.",
            registoproducao_fases_cortadas_acima_planeadas: "Las fases cortadas no pueden ser más de una por encima de las fases planeadas.",
            registoproducao_lotes_cortados_acima_planeados: "Los lotes cortados no pueden ser más de uno por encima de los lotes planeados.",
            registoproducao_peso_cortado_maior: "El peso cortado no puede ser mayor que el peso a cortar.",
            registoproducao_peso_cortado_menor: "El peso cortado es menor que el peso a cortar. Marque como 'No Finalizado'.",
            registoproducao_hora_fim_tolerancia_excedida: "La hora de fin no puede ser mayor que la hora actual con más de 15 minutos de tolerancia.",
            registoproducao_hora_fim_diferenca: "Hora de fin con una diferencia de {{minutos}} minutos después de la hora actual.",
            registoproducao_paragens_fora_intervalo: "Existen paradas fuera del intervalo de producción. Edítelas antes de continuar.",
            registoproducao_erro_atualizar_horas_paragem: "Error al actualizar las horas de la parada.",
            registoproducao_sucesso_atualizar_horas_paragem: "Horas de la parada actualizadas con éxito.",
            registoproducao_erro_forcar_fecho: "Error al forzar el cierre de la producción.",
            registoproducao_sucesso_forcar_fecho: "Producción forzada con éxito.",
            registoproducao_erro_atualizar_linhas_forcar_fecho: "Error al actualizar las líneas después de forzar el cierre.",
            registoproducao_titulo: "Registro de Producción",
            registoproducao_sem_registos_linha: "Sin registros en la línea.",
            registoproducao_legenda_cores: "Leyenda de Colores",
            registoproducao_legenda_registo_aberto: "Línea con registro abierto (Solo puede ser seleccionada por un ADMIN).",
            registoproducao_legenda_registo_nao_finalizado: "Línea con registro no finalizado.",
            registoproducao_legenda_linha_disponivel: "Línea disponible.",
            registoproducao_ultimo_registo_linha: "Último Registro de la Línea",
            registoproducao_selecao_ordem_fabrico: "Selección de Orden de Fabricación",
            registoproducao_selecione_ordem_fabrico: "Seleccione la Orden de Fabricación",
            registoproducao_selecao_bobine: "Selección de Bobina",
            registoproducao_selecione_bobine: "Seleccione la Bobina",
            registoproducao_dados_ordem_fabrico: "Datos de la Orden de Fabricación",
            registoproducao_familia: "Familia",
            registoproducao_largura: "Ancho",
            registoproducao_espessura: "Espesor",
            registoproducao_numero_lotes: "Nº de Lotes",
            registoproducao_numero_bandas: "Nº de Bandas",
            registoproducao_numero_fases: "Nº de Fases",
            registoproducao_observacoes_of: "Observaciones OF",
            registoproducao_dados_of_nao_finalizada: "Datos de la OF no finalizada",
            registoproducao_peso_restante: "Peso Restante",
            registoproducao_lotes_restantes: "Lotes Restantes",
            registoproducao_fases_restantes: "Fases Restantes",
            registoproducao_controlo_registo_inicio: "Control del Registro de Producción - Inicio",
            registoproducao_iniciar_producao: "Iniciar Producción",
            registoproducao_dados_of_atual: "Datos de la OF Actual",
            registoproducao_parametros_maquina: "Parámetros de la Máquina",
            registoproducao_velocidade: "Velocidad",
            registoproducao_penetracao: "Penetración",
            registoproducao_prensa: "Prensa",
            registoproducao_filtros: "Filtros",
            registoproducao_traccao: "Tracción",
            registoproducao_basculacao: "Basculación",
            registoproducao_ponte: "Puente",
            registoproducao_contra_rolo: "Contra-Rodillo {{index}}",
            registoproducao_basculacao_entrada: "Basculación - Entrada",
            registoproducao_basculacao_saida: "Basculación - Salida",
            registoproducao_aplanadora: "Aplanadora",
            registoproducao_aplanadora_grande: "Grande",
            registoproducao_aplanadora_pequena: "Pequeña",
            registoproducao_registar_parametros: "Registrar Parámetros de la Máquina",
            registoproducao_enviar_notificacao: "Enviar Notificación de Calidad",
            registoproducao_registar_paragens: "Registrar Paradas",
            registoproducao_corte_nao_finalizado: "Corte No Finalizado",
            registoproducao_corte_finalizado: "Corte Finalizado",
            registoproducao_forcar_fecho: "Forzar Cierre",
            registoproducao_alterar_linha: "Cambiar Línea",
            registoproducao_registar_paragem: "Registrar Parada",
            registoproducao_confirmar_peso: "Confirmar Peso",
            registoproducao_confirmar_peso_mensagem: "El peso a cortar ({{pesoCortar}} kg) no coincide con el peso programado. ¿Está seguro de que desea continuar con este peso? (haga doble clic para confirmar)",
            registoproducao_editar_horas_paragem: "Editar Horas de la Parada",
            registoproducao_forcar_fecho_producao: "Forzar Cierre de Producción",
            registoproducao_editar: "Editar",
            registoproducao_motivo: "Motivo",
            validar_button_back_to_login: "Volver al Login",
            validarregistos_delete_success: "Registro eliminado correctamente!",
            validarregistos_delete_error: "Error al eliminar el registro",
            validarregistos_validate_success: "Registros validados correctamente!",
            validarregistos_validate_error: "Error al validar los registros",
            validarregistos_edit_success: "Registro editado correctamente!",
            validarregistos_edit_error: "Error al editar el registro",
            validarregistos_aviso_tempo_paragem_maior_100: "Tiempo de parada mayor que 100.",
            validarregistos_aviso_tempo_corte_igual_0: "Tiempo de corte igual a 0.",
            validarregistos_aviso_tempo_corte_maior_100: "Tiempo de corte mayor que 100.",
            validarregistos_aviso_peso_cortado_diferente: "El peso cortado es diferente al peso programado.",
            validarregistos_aviso_lotes_cortados_diferente: "El número de lotes cortados es diferente al número de lotes programados.",
            validarregistos_aviso_fases_cortadas_diferente: "El número de fases cortadas es diferente al número de fases programadas.",
            validarregistos_aviso_peso_a_cortar_diferente: "El peso a cortar es diferente al peso programado.",
            producaoanalise_peso_i: "Peso Inicial",
            producaoanalise_peso_t: "Peso Total",
            producaoanalise_peso_c: "Peso Cortado",
            validarregistos_titulo: "Validación de Registros de Producción",
            validarregistos_validar_selecionados: "Validar Seleccionados",
            validarregistos_editar_registro: "Editar Registro",
            producaoanalise_fases_programadas: "Fases Programadas",
            producaoanalise_fases_cortadas: "Fases Cortadas",
            producaoanalise_peso_programado: "Peso Programado",
            validarregistos_confirmar_eliminacion: "Confirmar Eliminación",
            validarregistos_confirmar_eliminacion_texto: "¿Estás seguro de que quieres eliminar este registro?",
            validarregistos_detalhes_aviso: "Detalles del Aviso",
            validarregistos_no_hay_detalles: "No hay detalles disponibles.",
            controlof_abertura_fecho: "Apertura/Cierre Manual de OFs.",
            controlof_titulo:"Control Manual de OFs",
            controlof_seleccionar_of_linea: "Seleccionar OF por Línea",
            controlof_cerrar_ofs: "Cerrar OFs",
            controlof_buscar_of: "Buscar OF",
            controlof_seleccionar: "Seleccionar",
            controlof_confirmar_cierre_of: "¿Está seguro de que desea cerrar esta OF?",
            controlof_seleccionar_almenos_of: "Debe seleccionar al menos una OF.",
            controlof_cerradas_sucess: "Las OFs seleccionadas se cerraron con éxito.",
            control_cerradas_error: "Error al cerrar las OFs seleccionadas.",
            registoproducao_numero_lote: "Número de Lote",
            registoproducao_observacao: "Observación",
            registoproducao_erro_enviar_notificacao: "Error al enviar la notificación",
            registoproducao_sucesso_enviar_notificacao: "Notificación enviada con éxito",
            registoproducao_sincronizar_ofs: "Sincronizar OFs",
            registoproducao_sucesso_sincronizar_ofs: "Sincronización de OFs realizada con éxito.",
            registoproducao_erro_sincronizar_ofs: "Error al sincronizar las OFs.",
            registoproducao_filtros_nenhum: "Ninguno",
            registoproducao_filtros_branco_novo: "Blanco Nuevo",
            registoproducao_filtros_cinza_novo: "Gris Nuevo",
            registoproducao_filtros_fib1: "FIB1",
            registoproducao_filtros_branco_usado: "Blanco Usado",
            registoproducao_filtros_cinza_usado: "Gris Usado",
            registoproducao_filtros_ambos: "Ambos",
            validar_loading2: "Cargando...",
            construcao_paletes_add_article: "Por favor, añade al menos un artículo.",
            construcao_paletes_fill_waste_field: "Por favor, completa el campo de desperdicio para todos los artículos.",
            construcao_paletes_registered_successfully: "Palete registrado correctamente.",
            construcao_paletes_error_registering: "Error al registrar el palete.",
            construcao_paletes_passaporte: "Passaporte",
            construcao_paletes_barrote: "Barrote",
            construcao_paletes_quantidade: "Cantidad",
            construcao_paletes_desperdicio: "Desperdicio (mm)",
            construcao_paletes_title: "Construcción de Palets",
            construcao_paletes_modelo_palete: "Modelo Palete",
            construcao_paletes_comprimento: "Longitud",
            construcao_paletes_quantidade_paletes: "Cantidad de Palets",
            entradamadeira_complete_campos_artigo: "Por favor, complete todos los campos y añada al menos un artículo.",
            entradamadeira_registro_exitoso: "Registro exitoso.",
            entradamadeira_error_registrar: "Error al registrar.",
            entradamadeira_artigo: "Artículo",
            entradamadeira_qt: "Cant.",
            entradamadeira_comprimento: "Long.",
            entradamadeira_largura: "Ancho",
            entradamadeira_altura: "Altura",
            entradamadeira_total_m3: "Total m3",
            entradamadeira_title: "Entrada de Madera",
            entradamadeira_fornecedor: "Proveedor",
            entradamadeira_guia_remessa: "Guía de Remesa",
            entradamadeira_adicionar_artigo: "Añadir Artículo",
            historicoentradas_un_inicial: "Un (Inicial)",
            historicoentradas_qt_m3: "Qt m3",
            historicoentradas_documento: "Documento",
            historicoentradas_title: "Histórico de Entradas",
            historicoentradas_vista: "Vista",
            historicoentradas_por_barrote: "Por Barrote",
            historicoentradas_por_documento: "Por Documento",
            historicoentradas_unidades: "Unidades",
            historicoentradas_m3: "M3",
            historicoentradas_qt: "Cantidad",
            listaopp_comprimento_mm: "Comp. (mm)",
            listaopp_altura_mm: "Alt. (mm)",
            listaopp_largura_mm: "Larg. (mm)",
            listaopp_quantidade_barrotes_un: "Qt. B (un)",
            listaopp_m3_total: "m3 Total",
            listaopp_desperdicio_mm: "Desperdicio (mm)",
            listaopp_desperdicio_total_m3: "Desp. Total (m3)",
            listaopp_paletes_un: "Palets (un)",
            loteopp_registro_exitoso: "Registro exitoso.",
            loteopp_error_registrar: "Error al registrar LoteOpp.",
            loteopp_title: "Registrar Lote y OPP",
            loteopp_lote: "Lote",
            stockmadeira_registro_exitoso: "Registros finalizados correctamente.",
            stockmadeira_error_registrar: "Error al finalizar los registros.",
            stockmadeira_quantidade_restante: "Cantidad Restante",
            stockmadeira_title: "Stock de Madera",
            stockmadeira_finalizar_seleccionados: "Finalizar Seleccionados",
            stockmadeira_confirmar_finalizacion: "Confirmar Finalización",
            stockmadeira_confirmar_finalizacion_texto: "¿Seguro que quiere finalizar estos registros?",
            stockmadeira_cancelar: "Cancelar",
            stockmadeira_finalizar: "Finalizar",
            listatarefas_frequencia: "Frecuencia",
            listatarefas_tipo: "Tipo",
            listatarefas_tarefa: "Tarea",
            listatarefas_sequencia: "Secuencia",
            realizarmanutencao_linea_sin_tareas: "Esta línea no tiene tareas.",
            realizarmanutencao_error_obtener_tareas: "Error al obtener las tareas.",
            realizarmanutencao_seleccionar_tarefa: "Debe seleccionar al menos una tarea.",
            realizarmanutencao_estado_requerido: "Estado requerido.",
            realizarmanutencao_motivo_requerido: "Motivo requerido.",
            realizarmanutencao_tareas_estado_motivo: "Todas las tareas deben tener un estado OK o NOK, y las tareas con NOK deben tener un motivo.",
            realizarmanutencao_guardar_exito: "Tareas guardadas con éxito.",
            realizarmanutencao_guardar_error: "Error al guardar las tareas.",
            realizarmanutencao_por_fazer: "Por hacer",
            realizarmanutencao_title: "Realizar Mantenimiento",
            realizarmanutencao_tipo_manutencao: "Tipo de Mantenimiento",
            realizarmanutencao_ver_tarefas: "Ver Tareas",
            realizarmanutencao_comentario: "Breve comentario sobre el Mantenimiento",
            registotarefas_comentarios: "Comentarios",
            registotarefas_tarefas_realizadas: "Tareas Realizadas",
            registotarefas_incidencias: "Incidencias",
            registotarefas_detalhe: "Detalle",
            registotarefas_comentario: "Comentario",
            registotarefas_title: "Registro de Tareas de Mantenimiento",
            registotarefas_sabado: "Sábado",
            registotarefas_segunda: "Lunes",
            registotarefas_terca: "Martes",
            registotarefas_quarta: "Miércoles",
            registotarefas_quinta: "Jueves",
            registotarefas_sexta: "Viernes",
            en_desarrollo: "En desarrollo...",

            
            





















        },
    },
    pt: {
        translation: {
            elegir_empresa: "Escolha sua empresa",
            navbar_error_session: "Erro ao encerrar sessão",
            navbar_error_servidor: "Erro no servidor",
            navbar_menu_produccion: "Produção",
            navbar_submenu_produccion_validar: "Validar Registros",
            navbar_submenu_produccion_registro: "Registro de Produção",
            navbar_menu_registros: "Registros",
            navbar_submenu_registros_colaborador: "Registrar Colaborador",
            navbar_submenu_registros_listar: "Listar Colaboradores",
            navbar_menu_supervision: "Supervisão",
            navbar_submenu_supervision_control: "Controle Manual de OF",
            navbar_submenu_supervision_diaria: "Produção Diária",
            navbar_submenu_supervision_analisis: "Análise de Produção",
            navbar_menu_manutencion: "Manutenção",
            navbar_submenu_manutencion_tareas: "Lista de Tarefas",
            navbar_submenu_manutencion_registro: "Registro de Manutenção",
            navbar_submenu_manutencion_realizar: "Realizar Manutenção",
            navbar_menu_madera: "Madeira",
            navbar_submenu_madera_loteopp: "Lote - OPP",
            navbar_submenu_madera_entrada: "Entrada de Madeira",
            navbar_submenu_madera_historico: "Histórico de Entradas",
            navbar_submenu_madera_listaopp: "Lista de OPP",
            navbar_submenu_madera_stock: "Estoque",
            navbar_submenu_madera_construccion: "Construção de Paletes",
            navbar_menu_primas: "Primas",
            navbar_submenu_primas_evaluacion_colaborador: "Avaliação Colaborador",
            navbar_submenu_primas_rendimiento_operador: "Performance Operador",
            navbar_submenu_primas_estadisticas: "Estatísticas",
            navbar_submenu_primas_resultados: "Resultados",
            navbar_submenu_primas_evaluacion_logistica: "Avaliação Logística",
            navbar_submenu_primas_registro_auditoria: "Registro de Auditoria",
            navbar_menu_tarefas: "Tarefas",
            navbar_submenu_tarefas_loteopp: "Lote - OPP",
            navbar_submenu_tarefas_manutencion_nivel1: "Manutenção 1º Nível",
            navbar_logout: "Sair",
            produccion_anual_titulo_grafico: "Produção Anual por Mês",
            produccion_diaria_titulo_grafico: "Produção Diária por Linha",
            simpletable_totales: "Totais:",
            tarefasporfazer_title: "Notificações",
            tarefasporfazer_no_notifications: "Sem notificações pendentes",
            tarefasporfazer_notification: "Nenhuma manutenção foi realizada em {{date}} para a linha {{line}}",
            tarefasporfazer_error_array: "Era esperado um array, mas foi recebido:",
            tarefasporfazer_error_fetch_lines: "Erro ao obter as linhas:",
            tarefasporfazer_error_fetch_records: "Erro ao obter os registros diários:",
            validatedselect_required_field: "Campo obrigatório",
            sessionchecker_session_expired: "Sessão expirada",
            sessionchecker_home_title: "Início",
            sessionchecker_plafesa_login_title: "Plafesa - Login",
            sessionchecker_ibersteel_login_title: "Ibersteel - Login",
            sessionchecker_plafesa_dashboard_title: "Plafesa - Dashboard",
            sessionchecker_ibersteel_dashboard_title: "Ibersteel - Dashboard",
            home_welcome_message: "Bem-vindo à Web de {{companyName}}",
            login_title: "Login",
            login_user_label: "Número de Usuário",
            login_password_label: "Senha",
            login_button: "Entrar",
            login_switch_company: "Ir para {{company}}",
            login_error_message: "Erro no login",
            login_server_error: "Erro ao conectar com o servidor",
            validar_title: "Validar Turno",
            validar_user_label: "Número de Colaborador",
            validar_select_turno: "Selecione o Turno",
            validar_turno_manha: "Manhã",
            validar_turno_tarde: "Tarde",
            validar_turno_noite: "Noite",
            validar_select_auxiliar: "Selecione o Auxiliar",
            validar_auxiliar_none: "Sem Auxiliar",
            validar_button_validate: "Validar",
            validar_dialog_title: "Confirmar Turno",
            validar_dialog_text: "Tem certeza que se encontra neste turno \"{{turno}}\"?",
            validar_dialog_cancel: "Cancelar",
            validar_dialog_confirm: "Confirmar",
            validar_loading: "Validando...",
            validar_error_fetch_session: "Erro ao obter a sessão",
            validar_error_fetch_auxiliares: "Erro ao obter auxiliares",
            validar_error_select_auxiliar: "Selecione um auxiliar válido.",
            validar_error_select_turno: "Selecione um turno válido.",
            validar_error_invalid_turno: "O turno selecionado não é válido.",
            validar_error_validate_session: "Erro ao validar a sessão",
            validar_turno_terminado: "O turno anterior já terminou. Por favor, crie um novo turno.",
            avaliacaocolaborador_enero: "janeiro",
            avaliacaocolaborador_febrero: "fevereiro",
            avaliacaocolaborador_marzo: "março",
            avaliacaocolaborador_abril: "abril",
            avaliacaocolaborador_mayo: "maio",
            avaliacaocolaborador_junio: "junho",
            avaliacaocolaborador_julio: "julho",
            avaliacaocolaborador_agosto: "agosto",
            avaliacaocolaborador_septiembre: "setembro",
            avaliacaocolaborador_octubre: "outubro",
            avaliacaocolaborador_noviembre: "novembro",
            avaliacaocolaborador_diciembre: "dezembro",
            avaliacaocolaborador_preencha_todos_os_campos: "Por favor, preencha todos os campos.",
            avaliacaocolaborador_avaliacao_registrada_corretamente: "Avaliação registrada corretamente.",
            avaliacaocolaborador_erro_ao_registrar_avaliacao: "Erro ao registrar a avaliação.",
            avaliacaocolaborador_topico: "Tópico",
            avaliacaocolaborador_descricao: "Descrição",
            avaliacaocolaborador_nivel1: "Nível 1",
            avaliacaocolaborador_nivel2: "Nível 2",
            avaliacaocolaborador_nivel3: "Nível 3",
            avaliacaocolaborador_nivel4: "Nível 4",
            avaliacaocolaborador_conhecimento: "Conhecimento",
            avaliacaocolaborador_conhecimento_descricao: "Adquire e compreende os conhecimentos técnicos aplicando com rigor em contextos específicos.",
            avaliacaocolaborador_resolucao_problemas: "Resolução de Problemas",
            avaliacaocolaborador_resolucao_problemas_descricao: "Adota estratégias adequadas para resolução de problemas.",
            avaliacaocolaborador_responsabilidade: "Responsabilidade",
            avaliacaocolaborador_responsabilidade_descricao: "Adequa atitudes e posturas em diferentes contextos, revelando sentido de responsabilidade.",
            avaliacaocolaborador_cooperacao: "Cooperação",
            avaliacaocolaborador_cooperacao_descricao: "Coopera com os outros, trabalhando em equipa e valorizando as diferentes perspectivas.",
            avaliacaocolaborador_autonomia: "Autonomia",
            avaliacaocolaborador_autonomia_descricao: "Demonstra autonomia e empenho.",
            avaliacaocolaborador_organizacao: "Organização",
            avaliacaocolaborador_organizacao_descricao: "É organizado e procura soluções perante as dificuldades.",
            avaliacaocolaborador_formulario_titulo: "Formulário de Avaliação do Colaborador",
            avaliacaocolaborador_ano: "Ano",
            avaliacaocolaborador_trimestre: "Trimestre",
            avaliacaocolaborador_trimestre_janeiro_marco: "Janeiro - Março",
            avaliacaocolaborador_trimestre_abril_junho: "Abril - Junho",
            avaliacaocolaborador_trimestre_julho_setembro: "Julho - Setembro",
            avaliacaocolaborador_trimestre_outubro_dezembro: "Outubro - Dezembro",
            avaliacaocolaborador_semestre: "Semestre",
            avaliacaocolaborador_semestre_1: "Janeiro - Junho",
            avaliacaocolaborador_semestre_2: "Julho - Dezembro",
            avaliacaocolaborador_avaliacao_mensal: "Avaliação Mensal",
            avaliacaocolaborador_pontualidade: "Pontualidade",
            avaliacaocolaborador_acidentes: "Acidentes",
            avaliacaocolaborador_faltas: "Faltas",
            avaliacaocolaborador_fte: "FTE",
            avaliacaocolaborador_flexibilidade_polivalencia: "Flexibilidade e Polivalência",
            avaliacaocolaborador_disponibilidade: "Disponibilidade",
            avaliacaocolaborador_questionario_avaliacao: "Questionário de Avaliação",
            avaliacaocolaborador_enviar: "Enviar",
            estatisticasprimas_no_se_encontraron_registros: "Não foram encontrados registros",
            estatisticasprimas_error_obtener_datos: "Erro ao obter os dados",
            estatisticasprimas_colaborador: "Colaborador",
            estatisticasprimas_mes: "Mês",
            estatisticasprimas_resultado_global: "Resultado Global",
            estatisticasprimas_titulo: "Estatísticas Primas",
            estatisticasprimas_vista: "Vista",
            estatisticasprimas_operador: "Operador",
            estatisticasprimas_logistica: "Logística",
            estatisticasprimas_ano: "Ano",
            estatisticasprimas_buscar: "Procurar",
            estatisticasprimas_nivel_producao_produtividade: "Nível de Produção e Produtividade (70%)",
            estatisticasprimas_pessoal: "Pessoal (30%)",
            estatisticasprimas_auditoria_externa: "Auditoria Externa (30%)",
            estatisticasprimas_seguranca: "Segurança",
            estatisticasprimas_absentismo: "Absentismo",
            estatisticasprimas_desenvolvimento_pessoal_interpessoal: "Desenvolvimento Pessoal e Interpessoal (60%)",
            estatisticasprimas_p_fabrica: "P. Fábrica (20%)",
            estatisticasprimas_p_logistica: "P. Logística (50%)",
            estatisticasprimas_e_personal: "E. Personal (30%)",
            estatisticasprimas_p_linha: "P. Linha (50%)",
            estatisticasprimas_p_individual: "P. Individual (30%)",
            estatisticasprimas_total: "Total",
            estatisticasprimas_pontualidade: "Pontualidade (40%)",
            estatisticasprimas_conhecimento: "Conhecimento",
            estatisticasprimas_resolucao_problemas: "Resolução de Problemas",
            estatisticasprimas_responsabilidade: "Responsabilidade",
            estatisticasprimas_cooperacao: "Cooperação",
            estatisticasprimas_autonomia: "Autonomia",
            estatisticasprimas_organizacao: "Organização",
            estatisticasprimas_total_pessoal: "Total Pessoal",
            estatisticasprimas_limpieza_organizacion: "Limpeza/Organização (50%)",
            estatisticasprimas_seguridad_ambiente: "Segurança/Ambiente (50%)",
            estatisticasprimas_numero_accidentes: "Nº de Acidentes",
            estatisticasprimas_p_seguranca: "P.",
            estatisticasprimas_numero_faltas_injustificadas: "Nº Faltas Inj.",
            estatisticasprimas_p_absentismo: "P.",
            evaluacionlogistica_error_editar_evaluacion: "Erro ao editar a avaliação",
            evaluacionlogistica_camiones_cargados: "Camiões carregados",
            evaluacionlogistica_bobinas_recibidas: "Bobinas recebidas",
            evaluacionlogistica_acciones: "Ações",
            evaluacionlogistica_titulo: "Avaliação Logística",
            evaluacionlogistica_ingresar_evaluacion: "Inserir avaliação",
            evaluacionlogistica_guardar_evaluacion: "Salvar Avaliação",
            evaluacionlogistica_registros_existentes: "Registros Existentes",
            evaluacionlogistica_editar_evaluacion: "Editar Avaliação",
            evaluacionlogistica_cancelar: "Cancelar",
            evaluacionlogistica_guardar: "Salvar",
            performanceoperador_colaborador: "Colaborador",
            performanceoperador_mes: "Mês",
            performanceoperador_linha: "Linha",
            performanceoperador_funcao: "Função",
            performanceoperador_qt_bobines: "Qt. Bobines",
            performanceoperador_qt_fases: "Qt. Fases",
            performanceoperador_qt_lotes: "Qt. Lotes",
            performanceoperador_qt_turnos: "Qt. Turnos",
            performanceoperador_med_fases_bob: "Med. Fases/Bob",
            performanceoperador_obj_fases_turno_mes: "Obj - Fases/Turno Mês",
            performanceoperador_med_paquetes_turno: "Med. Pacotes/Turno",
            performanceoperador_obj_paquetes_turno_mes: "Obj - Pacotes/Turno Mês",
            performanceoperador_med_bobines_turno: "Med. Bobines/Turno",
            performanceoperador_obj_bobines_turno_mes: "Obj - Bobines/Turno Mês",
            performanceoperador_ton_mes: "Ton/mês",
            performanceoperador_obj_ton_turno_mes: "Obj - Ton/Turno Mês",
            performanceoperador_real_ton_total_mes: "Real - Ton/(total) Mês",
            performanceoperador_obj_ton_total_mes: "Obj - Ton(total) Mês",
            performanceoperador_ponderacao_bob: "Ponderação Bob.",
            performanceoperador_ponderacao_lotes_fases: "Ponderação Lotes/Fases",
            performanceoperador_resultado_individual: "Resultado Individual",
            performanceoperador_resultado_linha: "Resultado Linha",
            performanceoperador_resultado_fabrica: "Resultado Fábrica",
            performanceoperador_resultado_global: "Resultado Global",
            performanceoperador_titulo: "Performance do Operador",
            registoauditoria_error_registrar_auditoria: "Erro ao registrar a auditoria",
            registoauditoria_seguranca_ambiente: "Segurança/Ambiente",
            registoauditoria_limpeza_organizacao: "Limpeza/Organização",
            registo_auditoria_title: "Registo de Auditoria",
            registo_auditoria_ingresar_registros: "Inserir Registros",
            registo_auditoria_editar_auditoria: "Editar Auditoria",
            mes_janeiro: "Janeiro",
            mes_fevereiro: "Fevereiro",
            mes_marco: "Março",
            mes_abril: "Abril",
            mes_maio: "Maio",
            mes_junho: "Junho",
            mes_julho: "Julho",
            mes_agosto: "Agosto",
            mes_setembro: "Setembro",
            mes_outubro: "Outubro",
            mes_novembro: "Novembro",
            mes_dezembro: "Dezembro",
            resultado_primas_error_registrar_prima: "Erro ao registrar a prima",
            resultado_primas_error_editar_prima: "Erro ao editar a prima",
            resultado_primas_numero_colaborador: "Nº Colaborador",
            resultado_primas_nome: "Nome",
            resultado_primas_funcao: "Função",
            resultado_primas_total: "Total",
            resultado_primas_gravar: "Gravar",
            resultado_primas_alterar: "Alterar",
            resultado_primas_titulo: "Resultado Primas",
            resultado_primas_alterar_prima: "Alterar Prima",
            resultado_primas_modificar_valor: "Modifique o valor da prima.",
            resultado_primas_prima: "Prima",
            listarcolab_colaborador_editado_correctamente: "Colaborador editado corretamente!",
            listarcolab_error_editar_colaborador: "Erro ao editar colaborador",
            listarcolab_id: "ID",
            listarcolab_colaborador: "Colaborador",
            listarcolab_numero_colaborador: "Nº Colaborador",
            listarcolab_acesso: "Acesso",
            listarcolab_ativo: "Ativo",
            listarcolab_bloqueado: "Bloqueado",
            listarcolab_perfil: "Perfil",
            listarcolab_acoes: "Ações",
            listarcolab_titulo: "Listar Colaboradores",
            listarcolab_editar_colaborador: "Editar Colaborador",
            listarcolab_nome: "Nome",
            listarcolab_apelido: "Apelido",
            listarcolab_acesso_plataforma: "Acesso à Plataforma",
            listarcolab_password: "Senha",
            registarcolab_colaborador_registrado_correctamente: "Colaborador registrado corretamente!",
            registarcolab_error_registrando_colaborador: "Erro ao registrar colaborador",
            registarcolab_registrar: "Registar",
            controlof_of_alterada_exito: "OF alterada com sucesso.",
            controlof_error_alterar_of: "Erro ao alterar OF.",
            controlof_agregar_bobina: "Por favor, adicione pelo menos uma bobina.",
            controlof_of_registrada_exito: "OF registrada com sucesso.",
            controlof_error_registrar_of: "Erro ao registrar OF.",
            controlof_numero_of: "Número de OF",
            controlof_acao: "Ação",
            controlof_abrir: "Abrir",
            controlof_fechar: "Fechar",
            controlof_registro_manual_of: "Registro Manual de OF",
            controlof_bobinas: "Bobinas",
            controlof_bobina: "Bobina",
            controlof_numero: "Número",
            controlof_largura: "Largura",
            controlof_espessura: "Espessura",
            controlof_peso_cortar: "Peso a Cortar",
            controlof_fases: "Fases",
            controlof_lotes_por_fase: "Nº de Lotes por Fase",
            controlof_familia: "Família",
            controlof_observacoes: "Observações",
            controlof_remover_bobina: "Remover Bobina",
            controlof_adicionar_bobina: "Adicionar Bobina",
            producaoanalise_rango_datas_invalido: "Insira um intervalo de datas válido.",
            producaoanalise_estado: "Estado",
            producaoanalise_periodo_turno: "Período",
            producaoanalise_linha: "Linha",
            producaoanalise_colaborador: "Colaborador",
            producaoanalise_numero_auxiliar: "Nº Auxiliar",
            producaoanalise_tempo_corte: "Tempo de Corte (min.)",
            producaoanalise_of: "OF",
            producaoanalise_bobine: "Bobine",
            producaoanalise_peso_cortado: "Peso Cortado",
            producaoanalise_paragem: "Paragem (min.)",
            producaoanalise_info: "Info",
            producaoanalise_data: "Data",
            producaoanalise_hora_inicio: "Hora Início",
            producaoanalise_hora_fim: "Hora Fim",
            producaoanalise_familia: "Família",
            producaoanalise_espessura: "Espessura",
            producaoanalise_largura: "Largura",
            producaoanalise_fases_t: "Fases T",
            producaoanalise_fases_c: "Fases C",
            producaoanalise_lotes_t: "Lotes T",
            producaoanalise_lotes_c: "Lotes C",
            producaoanalise_obs: "Observações",
            producaoanalise_detalhes_adicionais: "Detalhes Adicionais",
            producaoanalise_turno: "Turno",
            producaoanalise_data_inicio: "Data de Início",
            producaoanalise_data_fim: "Data de Fim",
            producaoanalise_peso_inicial: "Peso Inicial",
            producaoanalise_peso_total: "Peso Total",
            producaoanalise_assunto: "Assunto",
            producaoanalise_tempo: "Tempo",
            producaoanalise_categoria: "Categoria",
            producaoanalise_titulo: "Análise de Produção",
            producaoanalise_detalles_linea: "Detalhes da Linha",
            producaoanalise_paragens: "Paragens",
            producaodiaria_titulo: "Produção Diária",
            registoproducao_erro_buscar_ultimo_registo: "Erro ao buscar último registo da linha.",
            registoproducao_nenhum_registo_encontrado: "Nenhum registo encontrado para esta linha.",
            registoproducao_visualizando_como_admin: "Você está visualizando esta linha como administrador.",
            registoproducao_linha_finalizada: "Esta linha está finalizada e não pode ser acessada.",
            registoproducao_linha_ocupada: "Esta linha está ocupada por outro operador.",
            registoproducao_nao_pode_alterar_linha: "Não pode alterar a linha até finalizar a produção.",
            registoproducao_selecione_auxiliar_valido: "Selecione um auxiliar válido.",
            registoproducao_peso_maior_zero: "O peso a cortar deve ser maior que zero.",
            registoproducao_peso_maior_total: "O peso a cortar não pode ser maior que o peso total.",
            registoproducao_hora_inicio_maior_atual: "A hora de início não pode ser maior que a hora atual.",
            registoproducao_hora_inicio_menor_fim: "A hora de início não pode ser menor que a hora de fim do último registo.",
            registoproducao_tempo_atraso: "Tempo de atraso: {{minutos}} minutos.",
            registoproducao_insira_comentario_atraso: "Por favor, insira um comentário explicando a razão do atraso.",
            registoproducao_erro_iniciar_producao: "Erro ao iniciar produção.",
            registoproducao_sucesso_iniciar_producao: "Produção iniciada com sucesso.",
            registoproducao_erro_registar_parametros: "Erro ao registar parâmetros da máquina.",
            registoproducao_sucesso_registar_parametros: "Parâmetros da máquina registados com sucesso.",
            registoproducao_erro_buscar_categorias_paragens: "Erro ao buscar categorias de paragens.",
            registoproducao_hora_inicio_menor_producao: "A hora de início não pode ser menor que a hora de início da produção.",
            registoproducao_hora_fim_menor_inicio: "A hora de fim deve ser maior que a hora de início.",
            registoproducao_erro_registar_paragem: "Erro ao registar paragem.",
            registoproducao_sucesso_registar_paragem: "Paragem registada com sucesso.",
            registoproducao_fases_cortadas_acima_planeadas: "As fases cortadas não podem ser mais de uma acima das fases planeadas.",
            registoproducao_lotes_cortados_acima_planeados: "Os lotes cortados não podem ser mais de uma acima dos lotes planeados.",
            registoproducao_peso_cortado_maior: "O peso cortado não pode ser maior que o peso a cortar.",
            registoproducao_peso_cortado_menor: "O peso cortado é menor que o peso a cortar. Marque como 'Não Finalizado'.",
            registoproducao_hora_fim_tolerancia_excedida: "A hora de fim não pode ser maior que a hora atual com mais de 15 minutos de tolerância.",
            registoproducao_hora_fim_diferenca: "Hora de fim com diferença de {{minutos}} minutos após a hora atual.",
            registoproducao_paragens_fora_intervalo: "Existem paragens fora do intervalo de produção. Edite-as antes de continuar.",
            registoproducao_erro_atualizar_horas_paragem: "Erro ao atualizar horas da paragem.",
            registoproducao_sucesso_atualizar_horas_paragem: "Horas da paragem atualizadas com sucesso.",
            registoproducao_erro_forcar_fecho: "Erro ao forçar fecho da produção.",
            registoproducao_sucesso_forcar_fecho: "Produção forçada com sucesso.",
            registoproducao_erro_atualizar_linhas_forcar_fecho: "Erro ao atualizar linhas após forçar fecho.",
            registoproducao_titulo: "Registo Produção",
            registoproducao_sem_registos_linha: "Sem registos na linha.",
            registoproducao_legenda_cores: "Legenda de Cores",
            registoproducao_legenda_registo_aberto: "Linha com registo aberto (Só pode ser selecionada por um ADMIN).",
            registoproducao_legenda_registo_nao_finalizado: "Linha com registo não finalizado.",
            registoproducao_legenda_linha_disponivel: "Linha disponível.",
            registoproducao_ultimo_registo_linha: "Último Registo da Linha",
            registoproducao_selecao_ordem_fabrico: "Seleção Ordem de Fabrico",
            registoproducao_selecione_ordem_fabrico: "Selecione a Ordem de Fabrico",
            registoproducao_selecao_bobine: "Seleção Bobine",
            registoproducao_selecione_bobine: "Selecione a Bobine",
            registoproducao_dados_ordem_fabrico: "Dados da Ordem de Fabrico",
            registoproducao_familia: "Família",
            registoproducao_largura: "Largura",
            registoproducao_espessura: "Espessura",
            registoproducao_numero_lotes: "Nº Lotes",
            registoproducao_numero_bandas: "Nº Bandas",
            registoproducao_numero_fases: "Nº Fases",
            registoproducao_observacoes_of: "Observações OF",
            registoproducao_dados_of_nao_finalizada: "Dados da OF não finalizada",
            registoproducao_peso_restante: "Peso Restante",
            registoproducao_lotes_restantes: "Lotes Restantes",
            registoproducao_fases_restantes: "Fases Restantes",
            registoproducao_controlo_registo_inicio: "Controlo do Registo de Produção - Início",
            registoproducao_iniciar_producao: "Iniciar Produção",
            registoproducao_dados_of_atual: "Dados da OF Atual",
            registoproducao_parametros_maquina: "Parâmetros da Máquina",
            registoproducao_velocidade: "Velocidade",
            registoproducao_penetracao: "Penetração",
            registoproducao_prensa: "Prensa",
            registoproducao_filtros: "Filtros",
            registoproducao_traccao: "Tracção",
            registoproducao_basculacao: "Basculação",
            registoproducao_ponte: "Ponte",
            registoproducao_contra_rolo: "Contra-Rolo {{index}}",
            registoproducao_basculacao_entrada: "Basculação - Entrada",
            registoproducao_basculacao_saida: "Basculação - Saída",
            registoproducao_aplanadora: "Aplanadora",
            registoproducao_aplanadora_grande: "Grande",
            registoproducao_aplanadora_pequena: "Pequena",
            registoproducao_registar_parametros: "Registar Parâmetros da Máquina",
            registoproducao_enviar_notificacao: "Enviar Notificação de Qualidade",
            registoproducao_registar_paragens: "Registar Paragens",
            registoproducao_corte_nao_finalizado: "Corte Não Finalizado",
            registoproducao_corte_finalizado: "Corte Finalizado",
            registoproducao_forcar_fecho: "Forçar Fecho",
            registoproducao_alterar_linha: "Alterar Linha",
            registoproducao_registar_paragem: "Registar Paragem",
            registoproducao_confirmar_peso: "Confirmar Peso",
            registoproducao_confirmar_peso_mensagem: "O peso a cortar ({{pesoCortar}} kg) não coincide com o peso programado. Tem certeza de que deseja continuar com este peso? (dar doble click para confirmar)",
            registoproducao_editar_horas_paragem: "Editar Horas da Paragem",
            registoproducao_forcar_fecho_producao: "Forçar Fecho de Produção",
            registoproducao_editar: "Editar",
            registoproducao_motivo: "Motivo",
            validar_button_back_to_login: "Voltar ao Login",
            validarregistos_delete_success: "Registro eliminado com sucesso!",
            validarregistos_delete_error: "Erro ao eliminar o registro",
            validarregistos_validate_success: "Registos validados com sucesso!",
            validarregistos_validate_error: "Erro ao validar os registos",
            validarregistos_edit_success: "Registo editado com sucesso!",
            validarregistos_edit_error: "Erro ao editar o registo",
            validarregistos_aviso_tempo_paragem_maior_100: "Tempo de paragem maior que 100.",
            validarregistos_aviso_tempo_corte_igual_0: "Tempo de corte igual a 0.",
            validarregistos_aviso_tempo_corte_maior_100: "Tempo de corte maior que 100.",
            validarregistos_aviso_peso_cortado_diferente: "O peso cortado é diferente ao peso programado.",
            validarregistos_aviso_lotes_cortados_diferente: "O número de lotes cortados é diferente ao número de lotes programados.",
            validarregistos_aviso_fases_cortadas_diferente: "O número de fases cortadas é diferente ao número de fases programadas.",
            validarregistos_aviso_peso_a_cortar_diferente: "O peso a cortar é diferente ao peso programado.",
            producaoanalise_peso_i: "Peso Inicial",
            producaoanalise_peso_t: "Peso Total",
            producaoanalise_peso_c: "Peso Cortado",
            validarregistos_titulo: "Validação Registos de Produção",
            validarregistos_validar_selecionados: "Validar Selecionados",
            validarregistos_editar_registro: "Editar Registo",
            producaoanalise_fases_programadas: "Fases Programadas",
            producaoanalise_fases_cortadas: "Fases Cortadas",
            producaoanalise_peso_programado: "Peso Programado",
            validarregistos_confirmar_eliminacion: "Confirmar Eliminação",
            validarregistos_confirmar_eliminacion_texto: "Tem certeza de que deseja eliminar este registo?",
            validarregistos_detalhes_aviso: "Detalhes do Aviso",
            validarregistos_no_hay_detalles: "Não há detalhes disponíveis.",
            controlof_abertura_fecho: "Abertura/Fecho Manual de OFs.",
            controlof_titulo: "Controle Manual de OFs",
            controlof_seleccionar_of_linea: "Selecionar OF por Linha",
            controlof_cerrar_ofs: "Fechar OFs",
            controlof_buscar_of: "Buscar OF",
            controlof_seleccionar: "Selecionar",
            controlof_confirmar_cierre_of: "Tem certeza de que deseja fechar esta OF?",
            controlof_seleccionar_almenos_of: "Deve selecionar pelo menos uma OF.",
            controlof_cerradas_sucess: "As OFs selecionadas foram fechadas com sucesso.",
            control_cerradas_error: "Erro ao fechar as OFs selecionadas.",
            registoproducao_numero_lote: "Número do Lote",
            registoproducao_observacao: "Observação",
            registoproducao_erro_enviar_notificacao: "Erro ao enviar a notificação",
            registoproducao_sucesso_enviar_notificacao: "Notificação enviada com sucesso",
            registoproducao_sincronizar_ofs: "Sincronizar OFs",
            registoproducao_sucesso_sincronizar_ofs: "Sincronização de OFs realizada com sucesso.",
            registoproducao_erro_sincronizar_ofs: "Erro ao sincronizar as OFs.",
            registoproducao_filtros_nenhum: "Nenhum",
            registoproducao_filtros_branco_novo: "Branco Novo",
            registoproducao_filtros_cinza_novo: "Cinza Novo",
            registoproducao_filtros_fib1: "FIB1",
            registoproducao_filtros_branco_usado: "Branco Usado",
            registoproducao_filtros_cinza_usado: "Cinza Usado",
            registoproducao_filtros_ambos: "Ambos",
            validar_loading2: "Carregando...",
            construcao_paletes_add_article: "Por favor, adicione pelo menos um artigo.",
            construcao_paletes_fill_waste_field: "Por favor, preencha o campo de desperdício para todos os artigos.",
            construcao_paletes_registered_successfully: "Palete registrado corretamente.",
            construcao_paletes_error_registering: "Erro ao registrar o palete.",
            construcao_paletes_passaporte: "Passaporte",
            construcao_paletes_barrote: "Barrote",
            construcao_paletes_quantidade: "Quantidade",
            construcao_paletes_desperdicio: "Desperdício (mm)",
            construcao_paletes_title: "Construção de Paletes",
            construcao_paletes_modelo_palete: "Modelo Palete",
            construcao_paletes_comprimento: "Comprimento",
            construcao_paletes_quantidade_paletes: "Quantidade Paletes",
            entradamadeira_complete_campos_artigo: "Por favor, preencha todos os campos e adicione pelo menos um artigo.",
            entradamadeira_registro_exitoso: "Registro efetuado com sucesso.",
            entradamadeira_error_registrar: "Erro ao registrar.",
            entradamadeira_artigo: "Artigo",
            entradamadeira_qt: "Qt",
            entradamadeira_comprimento: "Comp",
            entradamadeira_largura: "Larg",
            entradamadeira_altura: "Altura",
            entradamadeira_total_m3: "Total m3",
            entradamadeira_title: "Entrada de Madeira",
            entradamadeira_fornecedor: "Fornecedor",
            entradamadeira_guia_remessa: "Guia-Remessa",
            entradamadeira_adicionar_artigo: "Adicionar Artigo",
            historicoentradas_un_inicial: "Un (Inicial)",
            historicoentradas_qt_m3: "Qt m3",
            historicoentradas_documento: "Documento",
            historicoentradas_title: "Histórico de Entradas",
            historicoentradas_vista: "Vista",
            historicoentradas_por_barrote: "Por Barrote",
            historicoentradas_por_documento: "Por Documento",
            historicoentradas_unidades: "Unidades",
            historicoentradas_m3: "M3",
            historicoentradas_qt: "Qt",
            listaopp_comprimento_mm: "Comp. (mm)",
            listaopp_altura_mm: "Alt. (mm)",
            listaopp_largura_mm: "Larg. (mm)",
            listaopp_quantidade_barrotes_un: "Qt. B (un)",
            listaopp_m3_total: "m3 Total",
            listaopp_desperdicio_mm: "Desperdício (mm)",
            listaopp_desperdicio_total_m3: "Desp. Total (m3)",
            listaopp_paletes_un: "Paletes (un)",
            loteopp_registro_exitoso: "Registro efetuado com sucesso.",
            loteopp_error_registrar: "Erro ao registrar LoteOpp.",
            loteopp_title: "Registrar Lote e OPP",
            loteopp_lote: "Lote",
            stockmadeira_registro_exitoso: "Registros finalizados com sucesso.",
            stockmadeira_error_registrar: "Erro ao finalizar os registros.",
            stockmadeira_quantidade_restante: "Quantidade Restante",
            stockmadeira_title: "Stock de Madeira",
            stockmadeira_finalizar_seleccionados: "Finalizar Selecionados",
            stockmadeira_confirmar_finalizacion: "Confirmar Finalização",
            stockmadeira_confirmar_finalizacion_texto: "Tem certeza de que deseja finalizar esses registros?",
            stockmadeira_cancelar: "Cancelar",
            stockmadeira_finalizar: "Finalizar",
            listatarefas_frequencia: "Frequência",
            listatarefas_tipo: "Tipo",
            listatarefas_tarefa: "Tarefa",
            listatarefas_sequencia: "Sequência",
            realizarmanutencao_linea_sin_tareas: "Esta linha não tem tarefas.",
            realizarmanutencao_error_obtener_tareas: "Erro ao obter as tarefas.",
            realizarmanutencao_seleccionar_tarefa: "Deve selecionar pelo menos uma tarefa.",
            realizarmanutencao_estado_requerido: "Estado requerido.",
            realizarmanutencao_motivo_requerido: "Motivo requerido.",
            realizarmanutencao_tareas_estado_motivo: "Todas as tarefas devem ter um estado OK ou NOK, e as tarefas com NOK devem ter um motivo.",
            realizarmanutencao_guardar_exito: "Tarefas salvas com sucesso.",
            realizarmanutencao_guardar_error: "Erro ao salvar as tarefas.",
            realizarmanutencao_por_fazer: "Por fazer",
            realizarmanutencao_title: "Realizar Manutenção",
            realizarmanutencao_tipo_manutencao: "Tipo de Manutenção",
            realizarmanutencao_ver_tarefas: "Ver Tarefas",
            realizarmanutencao_comentario: "Breve comentário sobre a Manutenção",
            registotarefas_comentarios: "Comentários",
            registotarefas_tarefas_realizadas: "Tarefas R.",
            registotarefas_incidencias: "Incidências",
            registotarefas_detalhe: "Detalhe",
            registotarefas_comentario: "Comentário",
            registotarefas_title: "Registo de Tarefas de Manutenção",
            registotarefas_sabado: "Sábado",
            registotarefas_segunda: "Segunda",
            registotarefas_terca: "Terça",
            registotarefas_quarta: "Quarta",
            registotarefas_quinta: "Quinta",
            registotarefas_sexta: "Sexta",
            en_desarrollo: "Em desenvolvimento...",












            
            
        },
    },
    en: {
        translation: {
            elegir_empresa: "Choose your company",
            navbar_error_session: "Error logging out",
            navbar_error_servidor: "Server error",
            navbar_menu_produccion: "Production",
            navbar_submenu_produccion_validar: "Validate Records",
            navbar_submenu_produccion_registro: "Production Record",
            navbar_menu_registros: "Records",
            navbar_submenu_registros_colaborador: "Register Collaborator",
            navbar_submenu_registros_listar: "List Collaborators",
            navbar_menu_supervision: "Supervision",
            navbar_submenu_supervision_control: "Manual OF Control",
            navbar_submenu_supervision_diaria: "Daily Production",
            navbar_submenu_supervision_analisis: "Production Analysis",
            navbar_menu_manutencion: "Maintenance",
            navbar_submenu_manutencion_tareas: "Task List",
            navbar_submenu_manutencion_registro: "Maintenance Record",
            navbar_submenu_manutencion_realizar: "Perform Maintenance",
            navbar_menu_madera: "Wood",
            navbar_submenu_madera_loteopp: "Batch - OPP",
            navbar_submenu_madera_entrada: "Wood Entry",
            navbar_submenu_madera_historico: "Entry History",
            navbar_submenu_madera_listaopp: "OPP List",
            navbar_submenu_madera_stock: "Stock",
            navbar_submenu_madera_construccion: "Pallet Construction",
            navbar_menu_primas: "Bonuses",
            navbar_submenu_primas_evaluacion_colaborador: "Collaborator Evaluation",
            navbar_submenu_primas_rendimiento_operador: "Operator Performance",
            navbar_submenu_primas_estadisticas: "Statistics",
            navbar_submenu_primas_resultados: "Results",
            navbar_submenu_primas_evaluacion_logistica: "Logistics Evaluation",
            navbar_submenu_primas_registro_auditoria: "Audit Record",
            navbar_menu_tarefas: "Tasks",
            navbar_submenu_tarefas_loteopp: "Batch - OPP",
            navbar_submenu_tarefas_manutencion_nivel1: "1st Level Maintenance",
            navbar_logout: "Logout",
            produccion_anual_titulo_grafico: "Annual Production by Month",
            produccion_diaria_titulo_grafico: "Daily Production by Line",
            simpletable_totales: "Totals:",
            tarefasporfazer_title: "Notifications",
            tarefasporfazer_no_notifications: "No pending notifications",
            tarefasporfazer_notification: "Maintenance was not performed on {{date}} for line {{line}}",
            tarefasporfazer_error_array: "An array was expected, but received:",
            tarefasporfazer_error_fetch_lines: "Error fetching lines:",
            tarefasporfazer_error_fetch_records: "Error fetching daily records:",
            validatedselect_required_field: "Required field",
            sessionchecker_session_expired: "Session expired",
            sessionchecker_home_title: "Home",
            sessionchecker_plafesa_login_title: "Plafesa - Login",
            sessionchecker_ibersteel_login_title: "Ibersteel - Login",
            sessionchecker_plafesa_dashboard_title: "Plafesa - Dashboard",
            sessionchecker_ibersteel_dashboard_title: "Ibersteel - Dashboard",
            home_welcome_message: "Welcome to {{companyName}}'s Web",
            login_title: "Login",
            login_user_label: "User Number",
            login_password_label: "Password",
            login_button: "Log in",
            login_switch_company: "Go to {{company}}",
            login_error_message: "Login error",
            login_server_error: "Server connection error",
            validar_title: "Validate Shift",
            validar_user_label: "Collaborator Number",
            validar_select_turno: "Select Shift",
            validar_turno_manha: "Morning",
            validar_turno_tarde: "Afternoon",
            validar_turno_noite: "Night",
            validar_select_auxiliar: "Select Assistant",
            validar_auxiliar_none: "No Assistant",
            validar_button_validate: "Validate",
            validar_dialog_title: "Confirm Shift",
            validar_dialog_text: "Are you sure you are in this shift \"{{turno}}\"?",
            validar_dialog_cancel: "Cancel",
            validar_dialog_confirm: "Confirm",
            validar_loading: "Validating...",
            validar_error_fetch_session: "Error fetching session",
            validar_error_fetch_auxiliares: "Error fetching assistants",
            validar_error_select_auxiliar: "Select a valid assistant.",
            validar_error_select_turno: "Select a valid shift.",
            validar_error_invalid_turno: "The selected shift is not valid.",
            validar_error_validate_session: "Error validating session",
            validar_turno_terminado: "The previous shift has ended. Please create a new shift.",
            avaliacaocolaborador_enero: "January",
            avaliacaocolaborador_febrero: "February",
            avaliacaocolaborador_marzo: "March",
            avaliacaocolaborador_abril: "April",
            avaliacaocolaborador_mayo: "May",
            avaliacaocolaborador_junio: "June",
            avaliacaocolaborador_julio: "July",
            avaliacaocolaborador_agosto: "August",
            avaliacaocolaborador_septiembre: "September",
            avaliacaocolaborador_octubre: "October",
            avaliacaocolaborador_noviembre: "November",
            avaliacaocolaborador_diciembre: "December",
            avaliacaocolaborador_preencha_todos_os_campos: "Please complete all fields.",
            avaliacaocolaborador_avaliacao_registrada_corretamente: "Evaluation successfully registered.",
            avaliacaocolaborador_erro_ao_registrar_avaliacao: "Error registering the evaluation.",
            avaliacaocolaborador_topico: "Topic",
            avaliacaocolaborador_descricao: "Description",
            avaliacaocolaborador_nivel1: "Level 1",
            avaliacaocolaborador_nivel2: "Level 2",
            avaliacaocolaborador_nivel3: "Level 3",
            avaliacaocolaborador_nivel4: "Level 4",
            avaliacaocolaborador_conhecimento: "Knowledge",
            avaliacaocolaborador_conhecimento_descricao: "Acquires and understands technical knowledge, applying it rigorously in specific contexts.",
            avaliacaocolaborador_resolucao_problemas: "Problem Solving",
            avaliacaocolaborador_resolucao_problemas_descricao: "Adopts appropriate strategies for problem-solving.",
            avaliacaocolaborador_responsabilidade: "Responsibility",
            avaliacaocolaborador_responsabilidade_descricao: "Adapts attitudes and behaviors in different contexts, demonstrating a sense of responsibility.",
            avaliacaocolaborador_cooperacao: "Cooperation",
            avaliacaocolaborador_cooperacao_descricao: "Cooperates with others, working in a team and valuing different perspectives.",
            avaliacaocolaborador_autonomia: "Autonomy",
            avaliacaocolaborador_autonomia_descricao: "Demonstrates autonomy and commitment.",
            avaliacaocolaborador_organizacao: "Organization",
            avaliacaocolaborador_organizacao_descricao: "Is organized and seeks solutions to difficulties.",
            avaliacaocolaborador_formulario_titulo: "Collaborator Evaluation Form",
            avaliacaocolaborador_ano: "Year",
            avaliacaocolaborador_trimestre: "Quarter",
            avaliacaocolaborador_trimestre_janeiro_marco: "January - March",
            avaliacaocolaborador_trimestre_abril_junho: "April - June",
            avaliacaocolaborador_trimestre_julho_setembro: "July - September",
            avaliacaocolaborador_trimestre_outubro_dezembro: "October - December",
            avaliacaocolaborador_semestre: "Semester",
            avaliacaocolaborador_semestre_1: "January - June",
            avaliacaocolaborador_semestre_2: "July - December",
            avaliacaocolaborador_avaliacao_mensal: "Monthly Evaluation",
            avaliacaocolaborador_pontualidade: "Punctuality",
            avaliacaocolaborador_acidentes: "Accidents",
            avaliacaocolaborador_faltas: "Absences",
            avaliacaocolaborador_fte: "FTE",
            avaliacaocolaborador_flexibilidade_polivalencia: "Flexibility and Versatility",
            avaliacaocolaborador_disponibilidade: "Availability",
            avaliacaocolaborador_questionario_avaliacao: "Evaluation Questionnaire",
            avaliacaocolaborador_enviar: "Submit",
            estatisticasprimas_no_se_encontraron_registros: "No records found",
            estatisticasprimas_error_obtener_datos: "Error fetching data",
            estatisticasprimas_colaborador: "Collaborator",
            estatisticasprimas_mes: "Month",
            estatisticasprimas_numero_accidentes: "No. of Accidents",
            estatisticasprimas_p_seguranca: "P.",
            estatisticasprimas_numero_faltas_injustificadas: "No. of Unjustified Absences",
            estatisticasprimas_p_absentismo: "P.",
            estatisticasprimas_resultado_global: "Global Result",
            estatisticasprimas_titulo: "Bonus Statistics",
            estatisticasprimas_vista: "View",
            estatisticasprimas_operador: "Operator",
            estatisticasprimas_logistica: "Logistics",
            estatisticasprimas_ano: "Year",
            estatisticasprimas_buscar: "Search",
            estatisticasprimas_nivel_producao_produtividade: "Production and Productivity Level (70%)",
            estatisticasprimas_pessoal: "Personal (30%)",
            estatisticasprimas_auditoria_externa: "External Audit (30%)",
            estatisticasprimas_seguranca: "Safety",
            estatisticasprimas_absentismo: "Absenteeism",
            estatisticasprimas_desenvolvimento_pessoal_interpessoal: "Personal and Interpersonal Development (60%)",
            estatisticasprimas_p_fabrica: "Factory P. (20%)",
            estatisticasprimas_p_logistica: "Logistics P. (50%)",
            estatisticasprimas_e_personal: "Personal E. (30%)",
            estatisticasprimas_p_linha: "Line P. (50%)",
            estatisticasprimas_p_individual: "Individual P. (30%)",
            estatisticasprimas_total: "Total",
            estatisticasprimas_pontualidade: "Punctuality (40%)",
            estatisticasprimas_conhecimento: "Knowledge",
            estatisticasprimas_resolucao_problemas: "Problem Solving",
            estatisticasprimas_responsabilidade: "Responsibility",
            estatisticasprimas_cooperacao: "Cooperation",
            estatisticasprimas_autonomia: "Autonomy",
            estatisticasprimas_organizacao: "Organization",
            estatisticasprimas_total_pessoal: "Total Personal",
            estatisticasprimas_limpieza_organizacion: "Cleaning/Organization (50%)",
            estatisticasprimas_seguridad_ambiente: "Safety/Environment (50%)",
            evaluacionlogistica_error_editar_evaluacion: "Error editing evaluation",
            evaluacionlogistica_camiones_cargados: "Loaded Trucks",
            evaluacionlogistica_bobinas_recibidas: "Received Coils",
            evaluacionlogistica_acciones: "Actions",
            evaluacionlogistica_titulo: "Logistics Evaluation",
            evaluacionlogistica_ingresar_evaluacion: "Enter Evaluation",
            evaluacionlogistica_guardar_evaluacion: "Save Evaluation",
            evaluacionlogistica_registros_existentes: "Existing Records",
            evaluacionlogistica_editar_evaluacion: "Edit Evaluation",
            evaluacionlogistica_cancelar: "Cancel",
            evaluacionlogistica_guardar: "Save",
            performanceoperador_colaborador: "Collaborator",
            performanceoperador_mes: "Month",
            performanceoperador_linha: "Line",
            performanceoperador_funcao: "Function",
            performanceoperador_qt_bobines: "Qty. Coils",
            performanceoperador_qt_fases: "Qty. Phases",
            performanceoperador_qt_lotes: "Qty. Batches",
            performanceoperador_qt_turnos: "Qty. Shifts",
            performanceoperador_med_fases_bob: "Avg. Phases/Coil",
            performanceoperador_obj_fases_turno_mes: "Obj - Phases/Shift Month",
            performanceoperador_med_paquetes_turno: "Avg. Packages/Shift",
            performanceoperador_obj_paquetes_turno_mes: "Obj - Packages/Shift Month",
            performanceoperador_med_bobines_turno: "Avg. Coils/Shift",
            performanceoperador_obj_bobines_turno_mes: "Obj - Coils/Shift Month",
            performanceoperador_ton_mes: "Ton/Month",
            performanceoperador_obj_ton_turno_mes: "Obj - Ton/Shift Month",
            performanceoperador_real_ton_total_mes: "Real - Ton/(total) Month",
            performanceoperador_obj_ton_total_mes: "Obj - Ton(total) Month",
            performanceoperador_ponderacao_bob: "Coil Weighting",
            performanceoperador_ponderacao_lotes_fases: "Batch/Phase Weighting",
            performanceoperador_resultado_individual: "Individual Result",
            performanceoperador_resultado_linha: "Line Result",
            performanceoperador_resultado_fabrica: "Factory Result",
            performanceoperador_resultado_global: "Global Result",
            performanceoperador_titulo: "Operator Performance",
            registoauditoria_error_registrar_auditoria: "Error registering audit",
            registoauditoria_seguranca_ambiente: "Safety/Environment",
            registoauditoria_limpeza_organizacao: "Cleaning/Organization",
            registo_auditoria_title: "Audit Record",
            registo_auditoria_ingresar_registros: "Enter Records",
            registo_auditoria_editar_auditoria: "Edit Audit",
            mes_janeiro: "January",
            mes_fevereiro: "February",
            mes_marco: "March",
            mes_abril: "April",
            mes_maio: "May",
            mes_junho: "June",
            mes_julho: "July",
            mes_agosto: "August",
            mes_setembro: "September",
            mes_outubro: "October",
            mes_novembro: "November",
            mes_dezembro: "December",
            resultado_primas_error_registrar_prima: "Error registering the bonus",
            resultado_primas_error_editar_prima: "Error editing the bonus",
            resultado_primas_numero_colaborador: "Collaborator No.",
            resultado_primas_nome: "Name",
            resultado_primas_funcao: "Function",
            resultado_primas_total: "Total",
            resultado_primas_gravar: "Save",
            resultado_primas_alterar: "Modify",
            resultado_primas_titulo: "Bonus Results",
            resultado_primas_alterar_prima: "Modify Bonus",
            resultado_primas_modificar_valor: "Modify the bonus value.",
            resultado_primas_prima: "Bonus",
            listarcolab_colaborador_editado_correctamente: "Collaborator successfully edited!",
            listarcolab_error_editar_colaborador: "Error editing collaborator",
            listarcolab_id: "ID",
            listarcolab_colaborador: "Collaborator",
            listarcolab_numero_colaborador: "Collaborator No.",
            listarcolab_acesso: "Access",
            listarcolab_ativo: "Active",
            listarcolab_bloqueado: "Blocked",
            listarcolab_perfil: "Profile",
            listarcolab_acoes: "Actions",
            listarcolab_titulo: "List Collaborators",
            listarcolab_editar_colaborador: "Edit Collaborator",
            listarcolab_nome: "Name",
            listarcolab_apelido: "Last Name",
            listarcolab_acesso_plataforma: "Platform Access",
            listarcolab_password: "Password",
            registarcolab_colaborador_registrado_correctamente: "Collaborator successfully registered!",
            registarcolab_error_registrando_colaborador: "Error registering collaborator",
            registarcolab_registrar: "Register",
            controlof_of_alterada_exito: "OF successfully modified.",
            controlof_error_alterar_of: "Error modifying OF.",
            controlof_agregar_bobina: "Please add at least one coil.",
            controlof_of_registrada_exito: "OF successfully registered.",
            controlof_error_registrar_of: "Error registering OF.",
            controlof_numero_of: "OF Number",
            controlof_acao: "Action",
            controlof_abrir: "Open",
            controlof_fechar: "Close",
            controlof_registro_manual_of: "Manual OF Registration",
            controlof_bobinas: "Coils",
            controlof_bobina: "Coil",
            controlof_numero: "Number",
            controlof_largura: "Width",
            controlof_espessura: "Thickness",
            controlof_peso_cortar: "Weight to Cut",
            controlof_fases: "Phases",
            controlof_lotes_por_fase: "No. of Batches per Phase",
            controlof_familia: "Family",
            controlof_observacoes: "Observations",
            controlof_remover_bobina: "Remove Coil",
            controlof_adicionar_bobina: "Add Coil",
            producaoanalise_rango_datas_invalido: "Enter a valid date range.",
            producaoanalise_estado: "State",
            producaoanalise_periodo_turno: "Period",
            producaoanalise_linha: "Line",
            producaoanalise_colaborador: "Collaborator",
            producaoanalise_numero_auxiliar: "Auxiliary No.",
            producaoanalise_tempo_corte: "Cutting Time (min.)",
            producaoanalise_of: "OF",
            producaoanalise_bobine: "Coil",
            producaoanalise_peso_cortado: "Cut Weight",
            producaoanalise_paragem: "Stop (min.)",
            producaoanalise_info: "Info",
            producaoanalise_data: "Date",
            producaoanalise_hora_inicio: "Start Time",
            producaoanalise_hora_fim: "End Time",
            producaoanalise_familia: "Family",
            producaoanalise_espessura: "Thickness",
            producaoanalise_largura: "Width",
            producaoanalise_fases_t: "Phases T",
            producaoanalise_fases_c: "Phases C",
            producaoanalise_lotes_t: "Batches T",
            producaoanalise_lotes_c: "Batches C",
            producaoanalise_obs: "Observations",
            producaoanalise_detalhes_adicionais: "Additional Details",
            producaoanalise_turno: "Shift",
            producaoanalise_data_inicio: "Start Date",
            producaoanalise_data_fim: "End Date",
            producaoanalise_peso_inicial: "Initial Weight",
            producaoanalise_peso_total: "Total Weight",
            producaoanalise_assunto: "Subject",
            producaoanalise_tempo: "Time",
            producaoanalise_categoria: "Category",
            producaoanalise_titulo: "Production Analysis",
            producaoanalise_detalles_linea: "Line Details",
            producaoanalise_paragens: "Stops",
            producaodiaria_titulo: "Daily Production",
            registoproducao_erro_buscar_ultimo_registo: "Error fetching the last record of the line.",
            registoproducao_nenhum_registo_encontrado: "No record found for this line.",
            registoproducao_visualizando_como_admin: "You are viewing this line as an administrator.",
            registoproducao_linha_finalizada: "This line is finished and cannot be accessed.",
            registoproducao_linha_ocupada: "This line is occupied by another operator.",
            registoproducao_nao_pode_alterar_linha: "You cannot change the line until production is finished.",
            registoproducao_selecione_auxiliar_valido: "Select a valid assistant.",
            registoproducao_peso_maior_zero: "The weight to cut must be greater than zero.",
            registoproducao_peso_maior_total: "The weight to cut cannot be greater than the total weight.",
            registoproducao_hora_inicio_maior_atual: "The start time cannot be later than the current time.",
            registoproducao_hora_inicio_menor_fim: "The start time cannot be earlier than the end time of the last record.",
            registoproducao_tempo_atraso: "Delay time: {{minutos}} minutes.",
            registoproducao_insira_comentario_atraso: "Please enter a comment explaining the reason for the delay.",
            registoproducao_erro_iniciar_producao: "Error starting production.",
            registoproducao_sucesso_iniciar_producao: "Production started successfully.",
            registoproducao_erro_registar_parametros: "Error registering machine parameters.",
            registoproducao_sucesso_registar_parametros: "Machine parameters registered successfully.",
            registoproducao_erro_buscar_categorias_paragens: "Error fetching stop categories.",
            registoproducao_hora_inicio_menor_producao: "The start time cannot be earlier than the production start time.",
            registoproducao_hora_fim_menor_inicio: "The end time must be later than the start time.",
            registoproducao_erro_registar_paragem: "Error registering the stop.",
            registoproducao_sucesso_registar_paragem: "Stop registered successfully.",
            registoproducao_fases_cortadas_acima_planeadas: "The cut phases cannot exceed the planned phases by more than one.",
            registoproducao_lotes_cortados_acima_planeados: "The cut batches cannot exceed the planned batches by more than one.",
            registoproducao_peso_cortado_maior: "The cut weight cannot be greater than the weight to cut.",
            registoproducao_peso_cortado_menor: "The cut weight is less than the weight to cut. Mark as 'Not Finished'.",
            registoproducao_hora_fim_tolerancia_excedida: "The end time cannot exceed the current time by more than 15 minutes.",
            registoproducao_hora_fim_diferenca: "End time differs by {{minutos}} minutes after the current time.",
            registoproducao_paragens_fora_intervalo: "There are stops outside the production interval. Edit them before continuing.",
            registoproducao_erro_atualizar_horas_paragem: "Error updating stop times.",
            registoproducao_sucesso_atualizar_horas_paragem: "Stop times updated successfully.",
            registoproducao_erro_forcar_fecho: "Error forcing production closure.",
            registoproducao_sucesso_forcar_fecho: "Production successfully forced to close.",
            registoproducao_erro_atualizar_linhas_forcar_fecho: "Error updating lines after forcing closure.",
            registoproducao_titulo: "Production Record",
            registoproducao_sem_registos_linha: "No records on the line.",
            registoproducao_legenda_cores: "Color Legend",
            registoproducao_legenda_registo_aberto: "Line with an open record (Can only be selected by an ADMIN).",
            registoproducao_legenda_registo_nao_finalizado: "Line with an unfinished record.",
            registoproducao_legenda_linha_disponivel: "Available line.",
            registoproducao_ultimo_registo_linha: "Last Line Record",
            registoproducao_selecao_ordem_fabrico: "Manufacturing Order Selection",
            registoproducao_selecione_ordem_fabrico: "Select the Manufacturing Order",
            registoproducao_selecao_bobine: "Coil Selection",
            registoproducao_selecione_bobine: "Select the Coil",
            registoproducao_dados_ordem_fabrico: "Manufacturing Order Data",
            registoproducao_familia: "Family",
            registoproducao_largura: "Width",
            registoproducao_espessura: "Thickness",
            registoproducao_numero_lotes: "No. of Batches",
            registoproducao_numero_bandas: "No. of Strips",
            registoproducao_numero_fases: "No. of Phases",
            registoproducao_observacoes_of: "OF Observations",
            registoproducao_dados_of_nao_finalizada: "Data of the unfinished OF",
            registoproducao_peso_restante: "Remaining Weight",
            registoproducao_lotes_restantes: "Remaining Batches",
            registoproducao_fases_restantes: "Remaining Phases",
            registoproducao_controlo_registo_inicio: "Production Record Control - Start",
            registoproducao_iniciar_producao: "Start Production",
            registoproducao_dados_of_atual: "Current OF Data",
            registoproducao_parametros_maquina: "Machine Parameters",
            registoproducao_velocidade: "Speed",
            registoproducao_penetracao: "Penetration",
            registoproducao_prensa: "Press",
            registoproducao_filtros: "Filters",
            registoproducao_traccao: "Traction",
            registoproducao_basculacao: "Tilting",
            registoproducao_ponte: "Bridge",
            registoproducao_contra_rolo: "Counter-Roller {{index}}",
            registoproducao_basculacao_entrada: "Tilting - Entry",
            registoproducao_basculacao_saida: "Tilting - Exit",
            registoproducao_aplanadora: "Planer",
            registoproducao_aplanadora_grande: "Large",
            registoproducao_aplanadora_pequena: "Small",
            registoproducao_registar_parametros: "Register Machine Parameters",
            registoproducao_enviar_notificacao: "Send Quality Notification",
            registoproducao_registar_paragens: "Register Stops",
            registoproducao_corte_nao_finalizado: "Cut Not Finished",
            registoproducao_corte_finalizado: "Cut Finished",
            registoproducao_forcar_fecho: "Force Closure",
            registoproducao_alterar_linha: "Change Line",
            registoproducao_registar_paragem: "Register Stop",
            registoproducao_confirmar_peso: "Confirm Weight",
            registoproducao_confirmar_peso_mensagem: "The weight to cut ({{pesoCortar}} kg) does not match the total program weight. Are you sure you want to proceed with this weight? (double-click to confirm)",
            registoproducao_editar_horas_paragem: "Edit Stop Times",
            registoproducao_forcar_fecho_producao: "Force Production Closure",
            registoproducao_editar: "Edit",
            registoproducao_motivo: "Reason",
            validar_button_back_to_login: "Back to Login",
            validarregistos_delete_success: "Record successfully deleted!",
            validarregistos_delete_error: "Error deleting the record",
            validarregistos_validate_success: "Records successfully validated!",
            validarregistos_validate_error: "Error validating the records",
            validarregistos_edit_success: "Record successfully edited!",
            validarregistos_edit_error: "Error editing the record",
            validarregistos_aviso_tempo_paragem_maior_100: "Stop time greater than 100.",
            validarregistos_aviso_tempo_corte_igual_0: "Cutting time equals 0.",
            validarregistos_aviso_tempo_corte_maior_100: "Cutting time greater than 100.",
            validarregistos_aviso_peso_cortado_diferente: "The cut weight is different from the programmed weight.",
            validarregistos_aviso_lotes_cortados_diferente: "The number of cut batches is different from the programmed number.",
            validarregistos_aviso_fases_cortadas_diferente: "The number of cut phases is different from the programmed number.",
            validarregistos_aviso_peso_a_cortar_diferente: "The weight to cut is different from the programmed weight.",
            producaoanalise_peso_i: "Initial Weight",
            producaoanalise_peso_t: "Total Weight",
            producaoanalise_peso_c: "Cut Weight",
            validarregistos_titulo: "Production Records Validation",
            validarregistos_validar_selecionados: "Validate Selected",
            validarregistos_editar_registro: "Edit Record",
            producaoanalise_fases_programadas: "Programmed Phases",
            producaoanalise_fases_cortadas: "Cut Phases",
            producaoanalise_peso_programado: "Programmed Weight",
            validarregistos_confirmar_eliminacion: "Confirm Deletion",
            validarregistos_confirmar_eliminacion_texto: "Are you sure you want to delete this record?",
            validarregistos_detalhes_aviso: "Warning Details",
            validarregistos_no_hay_detalles: "No details available.",
            controlof_abertura_fecho: "Manual Opening/Closing of OFs.",
            controlof_titulo: "Manual OF Control",
            controlof_seleccionar_of_linea: "Select OF by Line",
            controlof_cerrar_ofs: "Close OFs",
            controlof_buscar_of: "Search OF",
            controlof_seleccionar: "Select",
            controlof_confirmar_cierre_of: "Are you sure you want to close this OF?",
            controlof_seleccionar_almenos_of: "You must select at least one OF.",
            controlof_cerradas_sucess: "The selected OFs were successfully closed.",
            control_cerradas_error: "Error closing the selected OFs.",
            registoproducao_numero_lote: "Batch Number",
            registoproducao_observacao: "Observation",
            registoproducao_erro_enviar_notificacao: "Error sending the notification",
            registoproducao_sucesso_enviar_notificacao: "Notification sent successfully",
            registoproducao_sincronizar_ofs: "Synchronize OFs",
            registoproducao_sucesso_sincronizar_ofs: "OF synchronization completed successfully.",
            registoproducao_erro_sincronizar_ofs: "Error synchronizing OFs.",
            registoproducao_filtros_nenhum: "None",
            registoproducao_filtros_branco_novo: "White New",
            registoproducao_filtros_cinza_novo: "Gray New",
            registoproducao_filtros_fib1: "FIB1",
            registoproducao_filtros_branco_usado: "White Used",
            registoproducao_filtros_cinza_usado: "Gray Used",
            registoproducao_filtros_ambos: "Both",
            validar_loading2: "Loading...",
            construcao_paletes_add_article: "Please add at least one article.",
            construcao_paletes_fill_waste_field: "Please fill in the waste field for all articles.",
            construcao_paletes_registered_successfully: "Pallet registered successfully.",
            construcao_paletes_error_registering: "Error registering the pallet.",
            construcao_paletes_passaporte: "Passport",
            construcao_paletes_barrote: "Beam",
            construcao_paletes_quantidade: "Quantity",
            construcao_paletes_desperdicio: "Waste (mm)",
            construcao_paletes_title: "Pallet Construction",
            construcao_paletes_modelo_palete: "Pallet Model",
            construcao_paletes_comprimento: "Length",
            construcao_paletes_quantidade_paletes: "Pallet Quantity",
            entradamadeira_complete_campos_artigo: "Please complete all fields and add at least one article.",
            entradamadeira_registro_exitoso: "Successful registration.",
            entradamadeira_error_registrar: "Error registering.",
            entradamadeira_artigo: "Article",
            entradamadeira_qt: "Qty",
            entradamadeira_comprimento: "Length",
            entradamadeira_largura: "Width",
            entradamadeira_altura: "Height",
            entradamadeira_total_m3: "Total m3",
            entradamadeira_title: "Wood Entry",
            entradamadeira_fornecedor: "Supplier",
            entradamadeira_guia_remessa: "Delivery Note",
            entradamadeira_adicionar_artigo: "Add Article",
            historicoentradas_un_inicial: "Units (Initial)",
            historicoentradas_qt_m3: "Qty m3",
            historicoentradas_documento: "Document",
            historicoentradas_title: "Entry History",
            historicoentradas_vista: "View",
            historicoentradas_por_barrote: "By Beam",
            historicoentradas_por_documento: "By Document",
            historicoentradas_unidades: "Units",
            historicoentradas_m3: "M3",
            historicoentradas_qt: "Qty",
            listaopp_comprimento_mm: "Length (mm)",
            listaopp_altura_mm: "Height (mm)",
            listaopp_largura_mm: "Width (mm)",
            listaopp_quantidade_barrotes_un: "Qty B (units)",
            listaopp_m3_total: "Total m3",
            listaopp_desperdicio_mm: "Waste (mm)",
            listaopp_desperdicio_total_m3: "Total Waste (m3)",
            listaopp_paletes_un: "Pallets (units)",
            loteopp_registro_exitoso: "Registration successful.",
            loteopp_error_registrar: "Error registering LoteOpp.",
            loteopp_title: "Register Lot and OPP",
            loteopp_lote: "Lot",
            stockmadeira_registro_exitoso: "Records successfully finalized.",
            stockmadeira_error_registrar: "Error finalizing records.",
            stockmadeira_quantidade_restante: "Remaining Quantity",
            stockmadeira_title: "Wood Stock",
            stockmadeira_finalizar_seleccionados: "Finalize Selected",
            stockmadeira_confirmar_finalizacion: "Confirm Finalization",
            stockmadeira_confirmar_finalizacion_texto: "Are you sure you want to finalize these records?",
            stockmadeira_cancelar: "Cancel",
            stockmadeira_finalizar: "Finalize",
            listatarefas_frequencia: "Frequency",
            listatarefas_tipo: "Type",
            listatarefas_tarefa: "Task",
            listatarefas_sequencia: "Sequence",
            realizarmanutencao_linea_sin_tareas: "This line has no tasks.",
            realizarmanutencao_error_obtener_tareas: "Error fetching tasks.",
            realizarmanutencao_seleccionar_tarefa: "You must select at least one task.",
            realizarmanutencao_estado_requerido: "State required.",
            realizarmanutencao_motivo_requerido: "Reason required.",
            realizarmanutencao_tareas_estado_motivo: "All tasks must have a state of OK or NOK, and tasks with NOK must have a reason.",
            realizarmanutencao_guardar_exito: "Tasks saved successfully.",
            realizarmanutencao_guardar_error: "Error saving tasks.",
            realizarmanutencao_por_fazer: "To do",
            realizarmanutencao_title: "Perform Maintenance",
            realizarmanutencao_tipo_manutencao: "Maintenance Type",
            realizarmanutencao_ver_tarefas: "View Tasks",
            realizarmanutencao_comentario: "Brief comment about the Maintenance",
            registotarefas_comentarios: "Comments",
            registotarefas_tarefas_realizadas: "Tasks Done",
            registotarefas_incidencias: "Incidents",
            registotarefas_detalhe: "Detail",
            registotarefas_comentario: "Comment",
            registotarefas_title: "Maintenance Task Records",
            registotarefas_sabado: "Saturday",
            registotarefas_segunda: "Monday",
            registotarefas_terca: "Tuesday",
            registotarefas_quarta: "Wednesday",
            registotarefas_quinta: "Thursday",
            registotarefas_sexta: "Friday",
            en_desarrollo: "In development...",
        },
    },
};

i18n
    .use(LanguageDetector) // Detecta el idioma del navegador
    .use(initReactI18next) // Integra con React
    .init({
        resources,
        fallbackLng: "en", // Idioma por defecto
        interpolation: {
        escapeValue: false, // React ya escapa los valores
        },
    });

export default i18n;