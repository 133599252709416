import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { companyConfig } from '../../utils/config';
import SimpleTable from '../../components/SimpleTable';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importar el hook de traducción

const ListaOpp = () => {
    const { t } = useTranslation(); // Hook para traducción
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState([]);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState(null); // Columna por la que se ordena
    const [orderDirection, setOrderDirection] = useState('asc'); // Dirección de la ordenación ('asc' o 'desc')

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchEntries = useCallback(async () => {
        if (!user) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/listaopp`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (result.data && Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Error fetching entries:', result);
            }
        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    },[page, rowsPerPage, searchTerm, user]);

    useEffect(() => {
        fetchEntries();
    }, [fetchEntries]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleViewOpp = async (opp) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${user.company}/api/oppdetails/${opp}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                const groupedData = result.reduce((acc, item) => {
                    const key = `${item.modelo_palete}-${item.quantidade}`;
                    if (!acc[key]) {
                        acc[key] = {
                            modelo_palete: item.modelo_palete,
                            quantidade: item.quantidade,
                            passaportes: []
                        };
                    }
                    acc[key].passaportes.push({
                        passaporte: item.passaporte,
                        comprimento: item.comprimento,
                        altura: item.altura,
                        largura: item.largura,
                        quantidade_barrotes: item.quantidade_barrotes,
                        m3: item.m3,
                        desperdicio: item.desperdicio,
                        desperdicio_m3: item.desperdicio_m3
                    });
                    return acc;
                }, {});

                setDialogData(Object.values(groupedData));
                setOpenDialog(true);
            } else {
                console.error('Error fetching opp details:', result);
            }
        } catch (error) {
            console.error('Error fetching opp details:', error);
        }
    };

    const companySettings = user ? companyConfig[user.company] : {};
    const StyledTableHead = styled(TableHead)({
        backgroundColor: companySettings.color,
        '& th': {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    });
    const columns = [
        { id: 'data', label: t('producaoanalise_data'), sortable: true },
        { id: 'oppdata', label: 'N OPP', sortable: true },
        { id: 'mov', label: t('evaluacionlogistica_acciones'), sortable: false },
    ];

    const handleSort = (columnId) => {
            if (orderBy === columnId) {
                // Si ya se está ordenando por esta columna, alternar la dirección
                setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
            } else {
                // Si es una nueva columna, establecerla como la columna de ordenación
                setOrderBy(columnId);
                setOrderDirection('asc');
            }
        };
    
        const recordMatchesSearch = (record, searchTerm) => {
            if (!searchTerm) return true;
            return Object.values(record).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        };
        
        const filteredRecords = data
            .filter((record) => recordMatchesSearch(record, searchTerm)) // Filtrar por búsqueda general
    
    
            const sortedRecords = useMemo(() => {
                if (!orderBy) return filteredRecords; // Si no hay columna seleccionada, no ordenar
                return [...filteredRecords].sort((a, b) => {
                    const aValue = a[orderBy];
                    const bValue = b[orderBy];
            
                    if (aValue === null || aValue === undefined) return 1;
                    if (bValue === null || bValue === undefined) return -1;
            
                    // Si la columna es una fecha, convertir a objetos Date
                    if (orderBy === 'data') {
                        return orderDirection === 'asc'
                            ? new Date(aValue) - new Date(bValue)
                            : new Date(bValue) - new Date(aValue);
                    }
            
                    // Si la columna es un número, comparar como números
                    if (typeof aValue === 'number' || !isNaN(aValue)) {
                        return orderDirection === 'asc'
                            ? parseFloat(aValue) - parseFloat(bValue)
                            : parseFloat(bValue) - parseFloat(aValue);
                    }
            
                    // Si la columna es texto, comparar como cadenas
                    if (typeof aValue === 'string') {
                        return orderDirection === 'asc'
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    }
            
                    return 0; // Si no se puede comparar, no cambiar el orden
                });
            }, [filteredRecords, orderBy, orderDirection]);
    
        const paginatedRecords = useMemo(() => {
            return sortedRecords.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );
        }, [sortedRecords, page, rowsPerPage]);

    const tableData = paginatedRecords.map((entry) => ({
        data: entry.data,
        oppdata: entry.oppdata,
        mov: <Button variant="contained" color="primary" onClick={() => handleViewOpp(entry.opp)}><FaEye /></Button>,
    }));

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 4, marginTop: 4 }}>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginRight: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: companySettings.color }}>
                    {t('navbar_submenu_madera_listaopp')}
                </Typography>
                <Box sx={{ flexGrow: 1, height: 2, backgroundColor: companySettings.color, marginLeft: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    label={t('estatisticasprimas_buscar')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPage(0); // Reinicia la paginación al buscar
                    }}
                    style={{ marginBottom: '20px' }}
                />
            </Box>
            <SimpleTable 
                columns={columns} 
                data={tableData}
                onSort={handleSort}
                orderBy={orderBy}
                orderDirection={orderDirection} />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.filter((record) => recordMatchesSearch(record, searchTerm)).length} // Total después de filtrar
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <DialogTitle>{t('producaoanalise_detalhes_adicionais')}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell>{t('construcao_paletes_modelo_palete')}</TableCell>
                                    <TableCell>{t('listaopp_paletes_un')}</TableCell>
                                    <TableCell>{t('construcao_paletes_passaporte')}</TableCell>
                                    <TableCell>{t('listaopp_comprimento_mm')}</TableCell>
                                    <TableCell>{t('listaopp_altura_mm')}</TableCell>
                                    <TableCell>{t('listaopp_largura_mm')}</TableCell>
                                    <TableCell>{t('listaopp_quantidade_barrotes_un')}</TableCell>
                                    <TableCell>{t('listaopp_m3_total')}</TableCell>
                                    <TableCell>{t('listaopp_desperdicio_mm')}</TableCell>
                                    <TableCell>{t('listaopp_desperdicio_total_m3')}</TableCell>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {dialogData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item.passaportes.map((passaporte, pIndex) => (
                                            <TableRow key={pIndex}>
                                                {pIndex === 0 && (
                                                    <>
                                                        <TableCell rowSpan={item.passaportes.length}>{item.modelo_palete}</TableCell>
                                                        <TableCell rowSpan={item.passaportes.length}>{item.quantidade}</TableCell>
                                                    </>
                                                )}
                                                <TableCell>{passaporte.passaporte}</TableCell>
                                                <TableCell>{passaporte.comprimento}</TableCell>
                                                <TableCell>{passaporte.altura}</TableCell>
                                                <TableCell>{passaporte.largura}</TableCell>
                                                <TableCell>{passaporte.quantidade_barrotes}</TableCell>
                                                <TableCell>{passaporte.m3}</TableCell>
                                                <TableCell>{passaporte.desperdicio}</TableCell>
                                                <TableCell>{passaporte.desperdicio_m3}</TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">{t('controlof_fechar')}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ListaOpp;